import Cookies from 'js-cookie'; // TODO this was upgraded from 2 to 3
import { jwtDecode } from 'jwt-decode'; // TODO this upgraded from 2 to 4

import config from '@/config';
import { urls } from '@/constants';

const { APP_DOMAIN: domain } = config;

const getUser = () => {
  const accessToken = getAccessToken();
  if (!accessToken) return null;
  const decodedToken = jwtDecode<{ user?: { id: number } }>(accessToken);
  return decodedToken.user;
};

export const getAccessToken = () => Cookies.get('access_token');

export const getRefreshToken = () => Cookies.get('refresh_token');

export const removeAccessToken = () =>
  Cookies.remove('access_token', { domain });

export const removeRefreshToken = () =>
  Cookies.remove('refresh_token', { domain });

export const setAccessToken = (accessToken: string) =>
  Cookies.set('access_token', accessToken, {
    domain,
    expires: 365,
  });

export const setRefreshToken = (refreshToken: string) =>
  Cookies.set('refresh_token', refreshToken, {
    domain,
    expires: 365,
  });

export const getAccessTokenUserId = () => {
  const user = getUser();
  return user?.id;
};

export const logout = () => {
  removeAccessToken();
  removeRefreshToken();
  window.location.replace(urls.lumiq);
};
