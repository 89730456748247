import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { SESSION_SLICE_KEY } from './session.constants';
import {
  checkForExistingTokens,
  clearAccessToken,
  clearTokens,
  setTokens,
} from './session.thunks';
import { SessionActionPayload, SessionState } from './session.types';

const initialState: SessionState = {
  isLoading: true,
  data: undefined,
};

const extraReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(
    checkForExistingTokens.fulfilled,
    (
      state: SessionState,
      { payload }: { payload: SessionActionPayload | undefined },
    ) => {
      state.isLoading = false;
      state.data = payload;
    },
  );
  builder.addCase(checkForExistingTokens.rejected, (state: SessionState) => {
    state.isLoading = false;
    state.data = undefined;
  });
  builder.addCase(
    setTokens.fulfilled,
    (state: SessionState, { payload }: { payload: SessionActionPayload }) => {
      state.data = payload;
    },
  );
  builder.addCase(clearTokens.fulfilled, (state: SessionState) => {
    state.data = initialState.data;
  });
  builder.addCase(clearAccessToken.fulfilled, (state: SessionState) => {
    state.data = initialState.data;
  });
};

const sessionSlice = createSlice({
  name: SESSION_SLICE_KEY,
  initialState,
  reducers: {
    setSession: (state, { payload }) => payload,
    clearSession: state => {
      state = initialState;
    },
  },
  extraReducers,
});

export const sessionActions = sessionSlice.actions;
export const { reducer: sessionReducer } = sessionSlice;
