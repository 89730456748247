import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import {
  pricingCpdMinute,
  pricingInterviews,
  pricingOffline,
  pricingPersonalPlan,
  pricingTracker,
} from '@/images';
import { selectUserRegion } from '@/store';

const perksList = region => {
  const CP = region === 'CAN' ? 'CPD' : 'CPE';
  return [
    {
      title: 'Interviews, Not Lectures',
      description: "Personal stories and experiences from today's leaders.",
      img: pricingInterviews,
      region: ['USA', 'CAN'],
    },
    {
      title: 'Convenience',
      description: `Earn ${CP} while at the gym, folding laundry, or walking your dog.`,
      img: pricingPersonalPlan,
      region: ['USA', 'CAN'],
    },
    {
      title: 'Offline Listening',
      description:
        'Download episodes and series so you can listen without internet access.',
      img: pricingOffline,
      region: ['USA', 'CAN'],
    },
    {
      title: 'CPD by the Minute',
      description:
        "Micro-learning chapters means you're" +
        " not committed to finishing episodes you don't like.",
      img: pricingCpdMinute,
      region: ['CAN'],
    },
    {
      title: 'New Episodes Every Week',
      description:
        'Choose from our extensive library of episodes' +
        ' or series spanning categories from technical, to practical, to soft skills and more.',
      img: pricingInterviews,
      region: ['USA', 'CAN'],
    },
    {
      title: `LumiQ ${CP} Tracker`,
      description:
        `${CP} hours and certificates are automatically stored in our easy-to-use ` +
        `${CP} tracker.`,
      img: pricingTracker,
      region: ['USA', 'CAN'],
    },
  ].filter(perk => perk.region.includes(region));
};

export const Perks = () => {
  const region = useSelector(selectUserRegion);
  const perks = perksList(region);
  return (
    <Box
      sx={{
        maxWidth: 1320,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 4,
        bgcolor: 'white',
        borderRadius: 4,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
      }}>
      <Typography variant="hero3">
        ENJOY {region === 'CAN' ? 'CPD' : 'CPE'}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 6,
        }}>
        {perks.map((perk, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 4,
              mb: 4,
              gap: 1,
              maxWidth: 342,
            }}>
            <Box
              component="img"
              src={perk.img}
              alt="perk icon"
              sx={{ width: 155 }}
            />
            <Typography variant="h4">{perk.title}</Typography>
            <Typography variant="body2">{perk.description}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
