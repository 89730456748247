import {
  Box,
  Button as MuiButton,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import * as R from 'ramda';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@/components';
import { Routes } from '@/constants';
import { Link } from '@/containers';
import { appleLogo, googleLogo } from '@/images';
import { useLoginMutation } from '@/store';
import { getOauthURL } from '@/utils';

import { FormConfig, LoginFormState } from './utils';

type LoginFormErrorState = {
  email?: string;
  password?: string;
};

type LoginFormProps = {
  inputRef: React.RefObject<HTMLDivElement>;
  onSignUpClick?: () => void;
  onLoginSuccess?: () => void;
};

export const LoginForm = ({
  inputRef,
  onSignUpClick,
  onLoginSuccess,
}: LoginFormProps) => {
  const location = useLocation();

  const [form, setForm] = useState<LoginFormState>({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState<LoginFormErrorState>({
    email: undefined,
    password: undefined,
  });
  const [failedSubmitCount, setFailedSubmitCount] = useState(0);

  const [login, { isLoading }] = useLoginMutation();

  const onChange = ({
    target: { id, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevState => ({
      ...prevState,
      [id]: value,
    }));
    setErrors(prevState => ({
      ...prevState,
      [id]: null,
    }));
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const newErrors: LoginFormErrorState = {};
    R.pipe(
      R.keys<LoginFormState>,
      R.forEach<keyof LoginFormState>(id => {
        if (!FormConfig[id].validator(form)) {
          newErrors[id] = FormConfig[id].errorMessage;
        }
      }),
    )(form);
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    const { error: requestError } = (await login({
      email: form.email.trim(),
      password: form.password.trim(),
    })) as { error: FetchBaseQueryError };

    if (requestError) {
      setFailedSubmitCount(prevState => prevState + 1);
      setErrors({
        email:
          failedSubmitCount >= 2
            ? 'Try entering a different email, like your company or personal email.'
            : undefined,
        password:
          (requestError?.status === 400 &&
            (requestError?.data as { message: string })?.message) ||
          'Something went wrong. Please try again later.',
      });
      return;
    }

    setFailedSubmitCount(0);
    onLoginSuccess?.();
  };

  const googleOauthURL = getOauthURL('google');
  const appleOauthURL = getOauthURL('apple');

  return (
    <form onSubmit={onSubmit}>
      <Box ref={inputRef}>
        <Typography variant="h1">Log in to continue learning</Typography>
        <Box sx={styles.textFieldContainer}>
          <Typography variant="body3">Email</Typography>
          <TextField
            id="email"
            onChange={onChange}
            error={!!errors.email}
            fullWidth
            autoComplete="email"
          />
          {errors.email && (
            <Typography color="red" variant="body3">
              {errors.email}
            </Typography>
          )}
        </Box>
        <Box sx={styles.textFieldContainer}>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body3">Password</Typography>
            <Box sx={{ ml: 'auto' }}>
              <Link to={Routes.FORGOT_PASSWORD} inline>
                <Typography color="gray" variant="body3">
                  Forgot Password?
                </Typography>
              </Link>
            </Box>
          </Box>
          <TextField
            type="password"
            id="password"
            onChange={onChange}
            error={!!errors.password}
            fullWidth
            autoComplete="current-password"
          />
          {errors.password && (
            <Typography color="red" variant="body3">
              {errors.password}
            </Typography>
          )}
        </Box>
        <Button
          disabled={isLoading}
          label="Log In"
          skinny={false}
          sx={{ mt: 3 }}
          type="submit"
        />
        <Box sx={styles.registerLinkContainer}>
          <Typography sx={{ textWrap: 'nowrap', mr: 0.5 }}>
            New to LumiQ?
          </Typography>
          <Link
            {...(onSignUpClick
              ? { onClick: onSignUpClick }
              : {
                  to: { pathname: Routes.REGISTER, search: location.search },
                })}>
            Sign Up
          </Link>
        </Box>
        <Box sx={styles.otherWaysText}>
          <Box sx={styles.horizontalLine} />
          <Typography variant="subtitle1" sx={styles.loginOptions}>
            Other ways to log in
          </Typography>
          <Box sx={styles.horizontalLine} />
        </Box>
        <MuiButton href={googleOauthURL} sx={styles.oauthButton}>
          <Box
            component="img"
            alt="google logo"
            src={googleLogo}
            sx={styles.oauthIcon}
          />
          <Typography variant="button2">Log in with Google</Typography>
        </MuiButton>
        <MuiButton href={appleOauthURL} sx={styles.oauthButton}>
          <Box
            component="img"
            alt="apple logo"
            src={appleLogo}
            sx={styles.oauthIcon}
          />
          <Typography variant="button2">Log in with Apple</Typography>
        </MuiButton>
      </Box>
    </form>
  );
};

const styles = {
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    mt: 2,
  },
  submitContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    mt: 3,
  },
  registerLinkContainer: {
    backgroundColor: 'blueLight',
    borderRadius: 2,
    p: 1,
    mt: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textWrap: 'nowrap',
    flexWrap: 'wrap',
  },
  oauthButton: {
    mt: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    color: 'blueBlack',
    border: (theme: Theme) => `1px solid ${theme.palette.blueBlack}`,
    p: 1.5,
    borderRadius: 2,
  },
  oauthIcon: {
    height: 24,
    mr: 3,
  },
  otherWaysText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    mt: 5,
  },
  horizontalLine: {
    border: (theme: Theme) => `1px solid ${theme.palette.blueBlack}`,
    height: 0,
    flex: 1,
  },
  loginOptions: { flexGrow: 0, mx: 3 },
};
