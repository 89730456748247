import { createSvgIcon } from '@mui/material/utils';

export const ShareIcon = createSvgIcon(
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 12.672C12.816 12.672 12.204 12.942 11.736 13.365L5.319 9.63C5.364 9.423 5.4 9.216 5.4 9C5.4 8.784 5.364 8.577 5.319 8.37L11.664 4.671C12.15 5.121 12.789 5.4 13.5 5.4C14.994 5.4 16.2 4.194 16.2 2.7C16.2 1.206 14.994 0 13.5 0C12.006 0 10.8 1.206 10.8 2.7C10.8 2.916 10.836 3.123 10.881 3.33L4.536 7.029C4.05 6.579 3.411 6.3 2.7 6.3C1.206 6.3 0 7.506 0 9C0 10.494 1.206 11.7 2.7 11.7C3.411 11.7 4.05 11.421 4.536 10.971L10.944 14.715C10.899 14.904 10.872 15.102 10.872 15.3C10.872 16.749 12.051 17.928 13.5 17.928C14.949 17.928 16.128 16.749 16.128 15.3C16.128 13.851 14.949 12.672 13.5 12.672Z"
      fill="currentColor"
    />
  </svg>,
  'Share',
);
