import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';

import { ALL_REGION, ALL_TOPIC } from '@/constants';
import { technicalSearchApi } from '@/store/technical-search-api';

import { TECHNICAL_SEARCH_SLICE_KEY } from './technical-search.constants';

type TechnicalSearchState = {
  selectedCategory?: string;
  selectedRegions: Record<string, boolean>;
  selectedTopic: string;
};

const initialState: TechnicalSearchState = {
  selectedCategory: undefined,
  selectedRegions: { [ALL_REGION]: true },
  selectedTopic: ALL_TOPIC,
};

const extraReducers = (
  builder: ActionReducerMapBuilder<TechnicalSearchState>,
) => {
  builder.addMatcher(
    technicalSearchApi.endpoints.fetchTechnicalSearchCategories.matchFulfilled,
    (state, { payload }) => {
      state.selectedCategory = state.selectedCategory ?? payload[0];
    },
  );
};

export const technicalSearchSlice = createSlice({
  name: TECHNICAL_SEARCH_SLICE_KEY,
  initialState,
  reducers: {
    selectCategory: (state, { payload }: { payload: string }) => {
      if (payload !== state.selectedCategory) {
        state.selectedCategory = payload;
      }
    },
    selectTopic: (state, { payload }: { payload: string }) => {
      if (state.selectedTopic === payload) {
        state.selectedTopic = ALL_TOPIC;
      } else {
        state.selectedTopic = payload;
      }
    },
    selectRegion: (state, { payload }: { payload: string }) => {
      if (payload === ALL_REGION) {
        state.selectedRegions = { [ALL_REGION]: true };
      } else if (
        state.selectedRegions[payload] &&
        Object.keys(state.selectedRegions).length === 1
      ) {
        state.selectedRegions = { [ALL_REGION]: true };
      } else if (state.selectedRegions[payload]) {
        state.selectedRegions = R.omit([payload], state.selectedRegions);
      } else {
        state.selectedRegions = R.omit([ALL_REGION], {
          ...state.selectedRegions,
          [payload]: true,
        });
      }
    },
  },
  extraReducers,
});

export const technicalSearchActions = technicalSearchSlice.actions;
export const { reducer: technicalSearchReducer } = technicalSearchSlice;
