import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Modals, Routes } from '@/constants';
import { premiumLocked } from '@/images';
import { uiActions } from '@/store';

import { AppModal } from '../AppModal';

export const PremiumLockedModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <AppModal modalName={Modals.PREMIUM_LOCKED}>
      <Box
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <img
          src={premiumLocked}
          alt="Premium Locked"
          style={{ width: '100%' }}
        />
        <Typography variant="h3" mt={3}>
          You've found a Premium episode!
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, color: 'gray' }}>
          Premium content is not available on a free trial.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, color: 'gray' }}>
          Upgrade now to listen!
        </Typography>
        <Box sx={{ mt: 2, width: 160 }}>
          <Button
            variant="primary"
            label="Upgrade Now"
            onClick={() => {
              navigate(Routes.PRICING);
              dispatch(uiActions.closeActiveModal());
            }}
          />
        </Box>
        <Box sx={{ mt: 2, width: 160 }}>
          <Button
            variant="tertiary"
            label="Close"
            onClick={() => dispatch(uiActions.closeActiveModal())}
          />
        </Box>
      </Box>
    </AppModal>
  );
};
