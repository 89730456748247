import { createSvgIcon } from '@mui/material/utils';

export const DirectionsIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.4077 10.585L11.4207 2.585C10.641 1.805 9.37149 1.805 8.59178 2.585L0.584781 10.585C-0.194927 11.365 -0.194927 12.635 0.584781 13.415L8.59178 21.415C9.37149 22.195 10.641 22.195 11.4207 21.415L19.4077 13.415C20.1974 12.625 20.1974 11.365 19.4077 10.585ZM11.5007 14.495V11.995H8.002V14.995H6.00275V10.995C6.00275 10.445 6.45258 9.995 7.00237 9.995H11.5007V7.495L14.9994 10.995L11.5007 14.495Z"
      fill="currentColor"
    />
  </svg>,
  'Directions',
);
