import { Box, Fade } from '@mui/material';

import { LearningPathCard, LearningPathCardSkeleton } from '@/components';
import { EVENT_CONSTANTS } from '@/services';

export const LearningPathsGrid = ({ lps, isLoading }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gap: {
          xs: 2,
          md: 4,
        },
        gridTemplateColumns: {
          xs: 'repeat(2, 1fr)',
          sm: 'repeat(auto-fit, 177px)',
          md: 'repeat(auto-fit, 240px)',
        },
      }}>
      {isLoading
        ? Array.from({ length: 9 }).map((_, index) => (
            <Box key={index}>
              <LearningPathCardSkeleton key={index} />
            </Box>
          ))
        : lps.map((lp, index) => (
            <Fade key={lp.groupId} in timeout={Math.log10(index + 2) * 2000}>
              <Box>
                <LearningPathCard
                  learningPath={lp}
                  referrer={{
                    page: EVENT_CONSTANTS.PAGE.LEARNING_PATHS,
                    component: EVENT_CONSTANTS.COMPONENT.LEARNING_PATH_CARD,
                  }}
                />
              </Box>
            </Fade>
          ))}
    </Box>
  );
};
