import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import {
  PlanInfo,
  UpdatePasswordForm,
  UpdatePaymentInfo,
  UpdateUserInfoForm,
} from '@/containers';

export const Profile = () => {
  return (
    <>
      <Typography variant="h1">Profile</Typography>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 10,
        }}
        container
        spacing={4}>
        <Grid item xs={false} md={2} />
        <Grid item xs={12} md={8} sx={{ width: '100%' }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <PlanInfo />
            </Grid>
            <Grid item xs={12}>
              <UpdateUserInfoForm />
            </Grid>
            <Grid item xs={12}>
              <UpdatePasswordForm />
            </Grid>
            <Grid item xs={12}>
              <UpdatePaymentInfo />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
