import { Box } from '@mui/material';
import { useState } from 'react';

import { RadioBoxButton } from '@/components';

import { styles } from '../styles';
import { getDesignationYearOptions } from '../utils';
import { ExtraBold } from './ExtraBold';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalTitle } from './ModalTitle';

export const CanCpaYearForm = ({ goToPrevStep, goToNextStep }) => {
  const [form, setForm] = useState();

  const designationYearOptions = getDesignationYearOptions();

  return (
    <Box sx={styles.formContainer}>
      <Header Title={<Title />} />
      <Box sx={styles.formPrimarySection}>
        {Object.keys(designationYearOptions)
          .reverse()
          .map(year => (
            <Box key={year} sx={styles.checkboxContainer}>
              <RadioBoxButton
                label={designationYearOptions[year]}
                checked={form === year}
                onChange={() => setForm(year)}
              />
            </Box>
          ))}
      </Box>
      <Footer
        handlePrev={() => goToPrevStep()}
        handleNext={() => goToNextStep(form)}
        isNextButtonDisabled={!isFormValid(form)}
      />
    </Box>
  );
};

const isFormValid = form => !!form;

const Title = () => {
  return (
    <ModalTitle>
      As a <ExtraBold>CPA</ExtraBold> in <ExtraBold>Canada</ExtraBold>, what
      year did you get your designation?
    </ModalTitle>
  );
};
