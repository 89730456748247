import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Alert, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Skeleton } from '@/components';
import {
  EpisodeBannerSkeleton,
  EpisodeDetails,
  EpisodeDetailsNew,
} from '@/containers';
import { AnalyticsService } from '@/services';
import { selectUserRegion, useFetchEpisodeQuery } from '@/store';

export const Episode = () => {
  const { episodeId } = useParams();
  const isNewEpisodePage = useFeatureIsOn('new-episode-page');
  const region = useSelector(selectUserRegion);

  // TODO useFetchEpisodequery causes unecessary
  // fetching waterfalls in EpisodeDetails
  const {
    data: episode,
    isLoading,
    isUninitialized,
  } = useFetchEpisodeQuery({ episodeId, region, includeArchived: true });

  // Try to fetch non-region episode if not found
  const { data: fallbackEpisode, isLoading: isFallbackLoading } =
    useFetchEpisodeQuery(
      { episodeId, includeArchived: true },
      { skip: isUninitialized || isLoading || episode },
    );

  useEffect(() => {
    const targetEpisode = episode || fallbackEpisode;
    if (targetEpisode) {
      AnalyticsService.pageViewEpisode(targetEpisode);
    }
  }, [episode, fallbackEpisode]);

  const Component = isNewEpisodePage ? EpisodeDetailsNew : EpisodeDetails;

  if (isLoading || isUninitialized || isFallbackLoading) {
    return (
      <Box>
        <Box>
          <EpisodeBannerSkeleton />
        </Box>
        <Box mt={2}>
          {Array.from({ length: 8 }).map((_, i) => (
            <Skeleton key={i} width="80%" />
          ))}
        </Box>
      </Box>
    );
  }

  if (episode) {
    return (
      <Box>
        <Component episode={episode} />
      </Box>
    );
  }

  if (fallbackEpisode) {
    return (
      <Box>
        <Alert severity="warning" sx={{ mb: 2 }}>
          <Typography variant="select">
            This episode is NOT NASBA QAS Self-Study Sponsor Qualified
          </Typography>
        </Alert>
        <Component episode={fallbackEpisode} bannerActions={[]} />
      </Box>
    );
  }

  return (
    <Alert severity="error">
      <Typography variant="select">
        Sorry, the episode you were looking for was not found.
      </Typography>
    </Alert>
  );
};
