export const PlanTypes = {
  TIME: 'Time Plan',
  USAGE: 'Usage Plan',
  NONE: 'No Plan',
  FREE_TRIAL: 'Free Trial',
} as const;

export const ProvinceCodes = {
  AB: 'AB',
  BC: 'BC',
  MB: 'MB',
  NB: 'NB',
  NL: 'NL',
  NS: 'NS',
  NT: 'NT',
  NU: 'NU',
  ON: 'ON',
  PE: 'PE',
  QC: 'QC',
  SK: 'SK',
  YT: 'YT',
} as const;

export const FreeTrialDefaults = {
  CREDITS: 5,
  HOURS: 1,
} as const;
