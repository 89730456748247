import { Box, Hidden, Typography } from '@mui/material';

import { useAccessInfo } from '@/hooks';

export const NavBarRemainingUsage = () => {
  const navbarAccessTypeInfo = useAccessInfo();

  if (!navbarAccessTypeInfo) return null;
  const {
    showExpiry,
    showUsage,
    infoTitle,
    usageRemaining,
    usageUnits,
    expiryDate,
  } = navbarAccessTypeInfo;

  return (
    <Hidden mdDown>
      {showExpiry && (
        <Box>
          <Box sx={styles.topRow}>
            <Typography variant="caption" color="white">
              Subscription Valid Until
            </Typography>
          </Box>
          <Typography variant="subtitle2" color="white">
            {expiryDate}
          </Typography>
        </Box>
      )}
      {showUsage && (
        <Box sx={styles.labelContainer}>
          <Typography variant="body3" color="white">
            {infoTitle}
          </Typography>
          <Typography variant="subtitle2" color="white">
            {`${usageRemaining} ${usageUnits} left`}
          </Typography>
        </Box>
      )}
    </Hidden>
  );
};

const styles = {
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer',
  },
};
