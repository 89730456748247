import * as R from 'ramda';

import { EpisodeStatus, UserEpisode } from '@/types';

export const pluckIdsOfStatus = (status: EpisodeStatus) =>
  R.pipe(
    R.filter((e: UserEpisode) => e.status === status),
    Object.values,
    R.pluck('episodeId'),
  );
