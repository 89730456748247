export const EpisodeStatus = {
  NONE: 'none',
  COMPLETED: 'completed',
  ON_GOING: 'onGoing',
  QUIZ_READY: 'quizReady',
  ASSESSMENT_READY: 'assessmentReady',
  VERIFIED: 'verified',
} as const;

export const FsmEpisodeStatus = {
  COMPLETED: 'completed',
  READY: 'ready',
  ON_GOING: 'on_going',
  QUIZ_READY: 'quiz_ready',
  VERIFIED: 'verified',
};
export const ETHICS_FIELDS = [
  'REGULATORY_ETHICS',
  'BEHAVIORAL_ETHICS',
] as const;

export type EthicsField = (typeof ETHICS_FIELDS)[number];
