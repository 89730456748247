import { Box } from '@mui/material';

export const CertificateTypeIcon = ({ colour = 'teal', ml = 0 }) => {
  return (
    <Box
      sx={{
        backgroundColor: colour,
        borderRadius: '50%',
        height: 10,
        width: 10,
        minWidth: 10,
        ml,
      }}
    />
  );
};
