import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { EpisodeGrid } from '@/components';
import { EVENT_CONSTANTS } from '@/services';
import {
  selectIsFsmPlayer,
  selectSessionUserId,
  selectUserRegion,
  useFetchEpisodesQuery,
  useFetchUserQuizzesQuery,
} from '@/store';
import { useFetchSessionQuizzesQuery } from '@/store/player-fsm-api';

import { selectQuizReadyEpisodeIds } from '../../utils';
import { EmptyView } from '../EmptyView';

export const QuizReady = () => {
  const userId = useSelector(selectSessionUserId);
  const userRegion = useSelector(selectUserRegion);

  const isFSMPlayerUser = useSelector(selectIsFsmPlayer);

  const { quizReadyEpisodeIds: episodeIdsOld, isLoading: isLoadingOld } =
    useFetchUserQuizzesQuery(undefined, {
      skip: !userId || isFSMPlayerUser !== false,
      selectFromResult: result => {
        if (!result.data) return result;
        return {
          ...result,
          quizReadyEpisodeIds: selectQuizReadyEpisodeIds(result.data),
        };
      },
    });

  const { quizReadyEpisodeIds: episodeIdsNew, isLoading: isLoadingNew } =
    useFetchSessionQuizzesQuery(undefined, {
      skip: !userId || !isFSMPlayerUser,
      selectFromResult: result => ({
        ...result,
        quizReadyEpisodeIds: result.data?.map(quiz => quiz.episodeId) || [],
      }),
    });

  const quizReadyEpisodeIds = isFSMPlayerUser ? episodeIdsNew : episodeIdsOld;
  const isLoading = isFSMPlayerUser ? isLoadingNew : isLoadingOld;

  useFetchEpisodesQuery(
    {
      episodeIds: quizReadyEpisodeIds,
      region: userRegion,
      includeArchived: true,
    },
    {
      skip: !quizReadyEpisodeIds?.length,
    },
  );

  return (
    <Box>
      {!isLoading && !quizReadyEpisodeIds?.length && (
        <EmptyView
          title="No active quizzes"
          subtitle="You do not have any pending quizzes to complete."
        />
      )}
      {!isLoading && quizReadyEpisodeIds?.length > 0 && (
        <EpisodeGrid
          episodeIds={quizReadyEpisodeIds}
          referrer={{
            page: EVENT_CONSTANTS.PAGE.MY_LUMIQ,
            section: EVENT_CONSTANTS.SECTION.QUIZZES,
          }}
        />
      )}
    </Box>
  );
};
