export const CPD_REQUIREMENTS_LIST = [
  {
    region: 'AB',
    label: 'Alberta',
    reportingDate: 'March 1st',
    descriptionLines: ['Exempt in the year you obtain your designation'],
    link: 'https://www.cpaalberta.ca/Members/CPD-Reporting',
  },
  {
    region: 'BC-YT',
    label: 'BC (incl. Yukon)',
    reportingDate: 'January 31st',
    link: 'https://www.bccpa.ca/member-practice-regulation/continuing-professional-development-cpd/cpd-requirements',
  },
  {
    region: 'MB',
    label: 'Manitoba',
    reportingDate: 'January 31st',
    link: 'https://cpamb.ca/main/main/regulatory/Continuing-Professional-Development--CPD-.aspx',
  },
  {
    region: 'NB',
    label: 'New Brunswick',
    reportingDate: 'January 31st',
    link: 'https://www.cpanewbrunswick.ca/Main/Main/Continuing-Professional-Development-Policy.aspx',
  },
  {
    region: 'NL',
    label: 'Newfoundland and Labrador',
    reportingDate: 'January 31st',
    descriptionLines: [
      'New members may be exempt, see CPANL website for more information',
    ],
    link: 'https://cpanl.ca/CPANL/CPANL/Members/CPD-Policy-and-Requirements.aspx',
  },
  {
    region: 'NS',
    label: 'Nova Scotia',
    reportingDate: 'March 31st',
    descriptionLines: [
      'New members may be exempt, see CPANS website for more information',
    ],
    link: 'https://cpans.ca/Main/Main/Regulatory/CPD-Reporting/CPD_Reporting.aspx',
  },
  {
    region: 'ON',
    label: 'Ontario',
    reportingDate: 'June 1st',
    link: 'https://www.cpaontario.ca/members/regulations-guidance/continuing-professional-development',
  },
  {
    region: 'PE',
    label: 'Prince Edward Island',
    reportingDate: 'January 31st',
    descriptionLines: [
      'New members may be partially exempt, see CPAPEI website for more information',
    ],
    link: 'https://www.cpapei.ca/Main/Main/Continuing-Professional-Development-Policy.aspx',
  },
  {
    region: 'QC',
    label: 'Quebec',
    reportingDate: 'September 30th',
    descriptionList: [
      'Calendar year is based on Sept. 1 to Aug. 31',
      'As of September 1, 2018, three-year period is now rolling similar to other provinces',
      'Annual min. hours is 10, and three year min. hours is 75',
      'A minimum of 4 hours of structured training in areas related to professional ethics per three year rolling period (Please refer to the link below for details around the qualifying criteria for ethics hours)',
      'New members may be partially exempt, see CPAQuebec website for more information',
      'Please refer to CPA Quebec as the requirements may differ based on the type of designation an individual holds',
    ],
    link: 'https://cpaquebec.ca/fr/membres-cpa/obligations/formation-continue-obligatoire/exigences/',
  },
  {
    region: 'SK',
    label: 'Saskatchewan',
    reportingDate: 'February 15th',
    link: 'https://www.cpask.ca/public/CKeditorUpload/Member_Information/Documents/FAQS-FOR-CPD-REPORTING.pdf',
  },
  {
    region: 'NT-NU',
    label: 'NWT/Nunavut',
    reportingDate: 'January 31st',
    descriptionLines: [
      'New members may be exempt, see CPA-NWT-NU website for more information',
    ],
    link: 'https://www.cpa-nwt-nu.ca/en/professional-development/cpd-policy-and-requirements',
  },
  {
    region: 'BM',
    label: 'Bermuda',
    reportingDate: 'March 31st',
    descriptionList: [
      'Minimum of 20 hours of verifiable CPD in a calendar year',
      'Minimum of 4 hours of verifiable learning in professional ethics over a 3-year cycle',
      'New members may be exempt, see CPA Bermuda website for more information',
    ],
    link: 'https://www.cpabermuda.bm/-/media/provinces/bermuda/cpa-bermuda-cpd-policy-and-guidelines.pdf',
  },
];
