import {
  ChevronRight,
  ConfirmationNumberRounded,
  OndemandVideo,
  PlayCircleOutline,
} from '@mui/icons-material';
import { Box, IconButton, List, ListItem, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchEpisodeChapterProgress } from 'src/hooks/useFetchEpisodeChapterProgress';

import { Button, EthicsPill } from '@/components';
import { EVENT_CONSTANTS } from '@/services';
import {
  playerThunks,
  selectEpisodeProgress,
  selectIsEpisodeRedeemRequired,
  selectUserRegion,
  userEpisodesThunks,
} from '@/store';
import { convertSecondsToMinutes } from '@/utils';

import { ChapterStatusBadge } from './ChapterStatusBadge';

export const EpisodeChapters = ({
  episode,
  chapters,
  onSelectChapter,
  selectedChapterIndex,
  referrer,
}) => {
  const { episodeId } = episode;
  const region = useSelector(selectUserRegion);
  const isEpisodeRedeemRequired = useSelector(state =>
    selectIsEpisodeRedeemRequired(state, episodeId),
  );

  const location = referrer;

  const dispatch = useDispatch();

  const selectedChapterId = chapters?.[selectedChapterIndex]?.chapterId;

  // Only triggers and signals the initial fetching
  // the response data gets pushed to the EntityAdapter and selected via `selectEpisodeProgress`
  const { isLoading: isUserChapterProgressLoading } =
    useFetchEpisodeChapterProgress(episodeId);
  // Selects the chapter data from the EntityAdapter as it gets continuously updated for e.g. set_position
  const episodeChaptersProgress = useSelector(state =>
    selectEpisodeProgress(state, episodeId),
  );

  const handleSelectChapter = useCallback(
    chapterId => {
      if (selectedChapterId === chapterId) return;
      onSelectChapter?.(chapterId);
    },
    [selectedChapterId, onSelectChapter],
  );

  const episodeDuration = convertSecondsToMinutes(episode.duration);
  return isUserChapterProgressLoading ? null : (
    <Box mt={4}>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
        <Typography variant="h1" sx={{ fontWeight: 600 }}>
          All Chapters
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 400 }}>
          &nbsp;/{episodeDuration} mins
        </Typography>
      </Box>
      <List
        sx={{
          backgroundColor: 'grayLight',
          borderRadius: '10px 0px 0px 10px',
          mt: 2,
        }}>
        {chapters?.map((chapter, i) => (
          <ListItem
            sx={[
              styles.listItem,
              selectedChapterId === chapter.chapterId &&
                styles.listItemSelected,
            ]}
            key={chapter.chapterId}
            onClick={() => handleSelectChapter(i)}>
            <Box
              sx={[
                styles.listItemContent,
                selectedChapterId === chapter.chapterId &&
                  styles.listItemContentSelected,
              ]}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}>
                <ChapterStatusBadge
                  userChapter={episodeChaptersProgress?.find(
                    uc => uc.id === chapter.chapterId,
                  )}
                />
                <Typography>{`Chapter ${i + 1} | ${convertSecondsToMinutes(chapter.duration)} mins`}</Typography>
                <Typography sx={{ mt: 0.5 }}>{chapter.name}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'gray',
                  ml: 2,
                }}>
                {region === 'CAN' && chapter.isEthics && (
                  <Box mr={1}>
                    <EthicsPill showDuration={false} />
                  </Box>
                )}
                {isEpisodeRedeemRequired && i !== 0 && chapter.isVerifiable ? (
                  <Button
                    label="Redeem"
                    variant="secondary"
                    skinny={false}
                    startIcon={<ConfirmationNumberRounded />}
                    onClick={() => {
                      dispatch(
                        userEpisodesThunks.initiateRedemption({ episodeId }),
                      );
                    }}
                  />
                ) : (
                  <>
                    <IconButton
                      onClick={() =>
                        dispatch(
                          playerThunks.chapterPlayPressed({
                            activeChapter: {
                              episodeId,
                              chapterId: chapter.chapterId,
                            },
                            location: {
                              ...location,
                              button: EVENT_CONSTANTS.BUTTON.PLAY_CHAPTER,
                            },
                            referrer,
                          }),
                        )
                      }>
                      {chapter.type === 'video' ? (
                        <OndemandVideo />
                      ) : (
                        <PlayCircleOutline />
                      )}
                    </IconButton>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 25,
                      }}>
                      <ChevronRight />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const styles = {
  listItem: {
    '&:hover': {
      cursor: 'pointer',
    },
    p: 0,
    pl: 2,
  },
  listItemSelected: theme => ({
    boxSizing: 'border-box',
    pl: 1.5,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  }),
  listItemContent: theme => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    p: 2,
    borderBottom: `1px solid ${theme.palette.blueBlackLight}`,
  }),
  listItemContentSelected: {
    backgroundColor: 'white',
  },
};
