import { fetchEpisodeQuizzesRequest } from '@/requests';
import { Quiz, Region } from '@/types';
import { camelCaseKeys } from '@/utils';

import { postgrestApi } from '../postgrest-api';

type FetchEpisodeQuizzesParams = {
  episodeId: string;
  region: Region;
};

export const quizzesApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchEpisodeQuizzes: builder.query<Quiz[], FetchEpisodeQuizzesParams>({
      query: fetchEpisodeQuizzesRequest,
      transformResponse: response => camelCaseKeys<Quiz[]>(response),
    }),
  }),
});

export const { useFetchEpisodeQuizzesQuery } = quizzesApi;
