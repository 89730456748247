import { Modals } from '@/constants';

import { AppModal } from '../AppModal';
import { EpisodeEvaluationModalContent } from './EpisodeEvaluationModalContent';

export const EpisodeEvaluationModal = () => {
  return (
    <AppModal modalName={Modals.EPISODE_EVALUATION}>
      <EpisodeEvaluationModalContent />
    </AppModal>
  );
};
