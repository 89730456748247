import { RootState } from '../store';

export const selectSelectedCategory = (state: RootState) =>
  state.technicalSearch.selectedCategory;

export const selectSelectedRegions = (state: RootState) =>
  state.technicalSearch.selectedRegions || {};

export const selectSelectedTopic = (state: RootState) =>
  state.technicalSearch.selectedTopic;
