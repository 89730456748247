import { Box, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { legalNameCertificate, legalNameCertificateCa } from '@/images';
import { useFetchUserProfileQuery } from '@/store';

import { OnboardingFormStepsContext } from '../OnboardingForm';
import { FOOTER_HEIGHT, styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalSubtitle } from './ModalSubtitle';
import { ModalTitle } from './ModalTitle';

export const NameForm = ({ goToPrevStep, goToNextStep }) => {
  const formContext = useContext(OnboardingFormStepsContext);

  const { data: userProfile } = useFetchUserProfileQuery();

  const [form, setForm] = useState({
    first: '',
    last: '',
  });
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [errors, setErrors] = useState({
    first: null,
    last: null,
  });

  useEffect(() => {
    if (!isFormTouched && userProfile) {
      setForm({
        first: userProfile.firstName || '',
        last: userProfile.lastName || '',
      });
    }
  }, [isFormTouched, userProfile]);

  const handleChange = event => {
    if (!isFormTouched) setIsFormTouched(true);

    const { id, value } = event.target;

    setForm(prevState => ({
      ...prevState,
      [id]: value,
    }));

    if (errors[id] && isNameValid(value)) {
      setErrors(prevState => ({ ...prevState, [id]: null }));
    }
  };

  const handleNext = () => {
    if (isFormValid(form)) {
      goToNextStep({
        first: form.first.trim(),
        last: form.last.trim(),
      });
    } else {
      setErrors({
        first: isNameValid(form.first) ? null : 'Please enter a valid name.',
        last: isNameValid(form.last) ? null : 'Please enter a valid name.',
      });
    }
  };

  return (
    <Box sx={styles.formContainer}>
      <Header Title={<Title />} Subtitle={<Subtitle />} />
      <Box sx={styles.formPrimarySection}>
        <Box
          sx={{
            gap: 1,
            display: 'flex',
            zIndex: 1,
            position: 'relative',
            backgroundColor: 'white',
            pb: 1,
          }}>
          <TextField
            id={'first'}
            label="First Name"
            variant="outlined"
            onChange={handleChange}
            value={form.first}
            fullWidth
            error={!!errors.first}
            helperText={errors.first}
          />
          <TextField
            id={'last'}
            label="Last Name"
            variant="outlined"
            onChange={handleChange}
            value={form.last}
            fullWidth
            error={!!errors.last}
            helperText={errors.last}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: FOOTER_HEIGHT,
          left: '5%',
          width: '90%',
          height: {
            xs: 160,
            sm: 240,
          },
          backgroundImage: props =>
            `url(${
              formContext.userRegion === 'CAN'
                ? legalNameCertificateCa
                : legalNameCertificate
            })`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
        }}
      />
      <Footer handlePrev={() => goToPrevStep()} handleNext={handleNext} />
    </Box>
  );
};

const isFormValid = form => isNameValid(form.first) && isNameValid(form.last);

const isNameValid = name => name?.trim().length > 0;

const Title = () => <ModalTitle>Enter your legal name.</ModalTitle>;

const Subtitle = () => (
  <ModalSubtitle>
    It is important that you enter your legal first and last name in order for
    us to receive and report accurate certificates.
  </ModalSubtitle>
);
