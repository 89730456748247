import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Carousel, CarouselPill, CarouselPillSkeleton } from '@/components';
import { LibraryUrlSearchParams, Routes } from '@/constants';
import { selectUserRegion, useFetchCategoriesQuery } from '@/store';

const { CATEGORY } = LibraryUrlSearchParams;

export const CategoriesCarousel = () => {
  const navigate = useNavigate();

  const userRegion = useSelector(selectUserRegion);

  const {
    data: categories,
    isUninitialized,
    isError,
    isLoading,
  } = useFetchCategoriesQuery({ region: userRegion });

  const createOnClickHandler = category => () => {
    navigate({
      pathname: Routes.LIBRARY,
      search: `?${CATEGORY}=${category.id}`,
    });
  };

  return (
    <>
      {isError && null}
      {!isError && (
        <Carousel title="Popular Categories" uKey={'Popular Categories'}>
          {isUninitialized || isLoading
            ? Array.from({ length: 10 }).map((_, idx) => (
                <Box key={idx} mr={2}>
                  <CarouselPillSkeleton />
                </Box>
              ))
            : categories.map(c => (
                <CarouselPill
                  key={c.id}
                  label={c.name}
                  onClick={createOnClickHandler(c)}
                />
              ))}
        </Carousel>
      )}
    </>
  );
};
