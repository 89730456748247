import { Drawer, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { useAccessInfo } from '@/hooks';
import { selectIsSidebarOpen, selectSessionUserId, uiActions } from '@/store';

import { SidebarList } from './components';

export const AppSidebar = () => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const navbarAccessTypeInfo = useAccessInfo();
  const spacingMultiplier = navbarAccessTypeInfo?.showUpgradeBanner ? 2 : 1;

  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const userId = useSelector(selectSessionUserId);

  const handleSidebarClose = () => {
    dispatch(uiActions.toggleSidebarDrawer());
  };

  const drawerPaperStyles = ({ dimensions }) => ({
    [`& .MuiDrawer-paper`]: {
      bgcolor: 'blueBlack',
      width: dimensions.sidebar.width + 'px',
      pt: {
        md: `${dimensions.header.height + (userId ? 0 : dimensions.regionBanner.height)}px`,
        xs: `${dimensions.header.height * spacingMultiplier + (userId ? 0 : dimensions.regionBanner.height)}px`,
      },
    },
  });

  return isDesktop ? (
    <Drawer variant="permanent" sx={drawerPaperStyles}>
      <SidebarList />
    </Drawer>
  ) : (
    <Drawer
      variant="temporary"
      anchor="left"
      open={isSidebarOpen}
      onClose={handleSidebarClose}
      sx={drawerPaperStyles}>
      <SidebarList />
    </Drawer>
  );
};
