import { Box, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';

import { technicalSearchActions } from '@/store';

import { TechnicalFilterPill } from '../TechnicalFilterPill';

export const TopicOptions = ({ topics, selectedTopic }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const onTopicSelect = topic => {
    dispatch(technicalSearchActions.selectTopic(topic));
  };

  return (
    <Box sx={[styles.topicOptions, isMobile && styles.topicOptionsScroll]}>
      {topics.map(topic => (
        <Box key={topic} sx={{ mr: 0.75, mb: 1.25 }}>
          <TechnicalFilterPill
            isSelected={selectedTopic === topic}
            label={topic}
            onClick={() => onTopicSelect(topic)}
          />
        </Box>
      ))}
    </Box>
  );
};

const styles = {
  topicOptions: {
    display: 'flex',
    pt: 2.5,
    pb: 1.25,
    flexWrap: 'wrap',
  },
  topicOptionsScroll: {
    flexWrap: 'nowrap',
    overflow: 'scroll',
    scrollbarWidth: 'none',
  },
};
