import { PostgrestEndpoints } from '@/requests';
import { AnalyticsService, EVENTS } from '@/services';
import { camelCaseKeys } from '@/utils';

import { postgrestApi } from '../postgrest-api';
import { setTokens } from '../session';
import { handleOnQueryStartedError } from '../store.utils';

type LoginPayload = {
  email: string;
  password: string;
};

type LoginResponse = {
  accessToken: string;
  refreshToken: string;
  userId: string;
};

type SendResetPasswordEmailPayload = {
  email: string;
};

type ValidatePasswordResetTokenPayload = {
  token: string;
};

type ResetPasswordWithTokenPayload = {
  password: string;
  token: string;
};

type RegisterPayload = {
  email: string;
  password: string;
  offerId?: string;
  inviteToken?: string;
  searchParams: object;
};

type RegisterResponse = {
  accessToken: string;
  refreshToken: string;
  userId: string;
};

export const authApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: ({ email, password }) => ({
        url: PostgrestEndpoints.LOGIN,
        method: 'POST',
        body: {
          email,
          password,
        },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          AnalyticsService.logEvent(EVENTS.AUTH_EVENTS.LOGIN.INTENT);
          const {
            data: { accessToken, refreshToken },
          } = await queryFulfilled;

          await dispatch(setTokens({ accessToken, refreshToken }));
          AnalyticsService.logEvent(EVENTS.AUTH_EVENTS.LOGIN.SUCCESS);
        } catch (error: any) {
          const payload = await error?.meta?.request?.json?.();
          AnalyticsService.logEvent(EVENTS.AUTH_EVENTS.LOGIN.FAIL, {
            email: payload?.email,
            reason: error?.error?.data?.message,
          });
          handleOnQueryStartedError(error);
        }
      },
      transformResponse: (res: any) => {
        return {
          accessToken: res.access_token,
          refreshToken: res.refresh_token,
          userId: res.user.user_id,
        };
      },
    }),
    sendResetPasswordEmail: builder.mutation<
      any,
      SendResetPasswordEmailPayload
    >({
      query: ({ email }) => ({
        url: PostgrestEndpoints.FORGOT_PASSWORD,
        method: 'POST',
        body: { email },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          AnalyticsService.logEvent(
            EVENTS.AUTH_EVENTS.SEND_PASSWORD_RESET_EMAIL.INTENT,
          );
          await queryFulfilled;
          AnalyticsService.logEvent(
            EVENTS.AUTH_EVENTS.SEND_PASSWORD_RESET_EMAIL.SUCCESS,
          );
        } catch (error: any) {
          AnalyticsService.logEvent(
            EVENTS.AUTH_EVENTS.SEND_PASSWORD_RESET_EMAIL.FAIL,
            {
              reason: error?.error?.data?.message,
            },
          );
          handleOnQueryStartedError(error);
        }
      },
    }),
    validatePasswordResetToken: builder.query<
      boolean,
      ValidatePasswordResetTokenPayload
    >({
      query: ({ token }) => ({
        url: PostgrestEndpoints.VALIDATE_PASSWORD_RESET_TOKEN,
        method: 'POST',
        body: { _password_reset_token: token },
      }),
    }),
    resetPasswordWithToken: builder.mutation<
      any,
      ResetPasswordWithTokenPayload
    >({
      query: ({ password, token }) => ({
        url: PostgrestEndpoints.RESET_PASSWORD_WITH_TOKEN,
        method: 'POST',
        body: {
          _password_reset_token: token,
          _password: password,
        },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          AnalyticsService.logEvent(EVENTS.AUTH_EVENTS.PASSWORD_RESET.INTENT);
          await queryFulfilled;
          AnalyticsService.logEvent(EVENTS.AUTH_EVENTS.PASSWORD_RESET.SUCCESS);
        } catch (error: any) {
          AnalyticsService.logEvent(EVENTS.AUTH_EVENTS.PASSWORD_RESET.FAIL, {
            reason: error?.error?.data?.message,
          });
          handleOnQueryStartedError(error);
        }
      },
    }),
    register: builder.mutation<RegisterResponse, RegisterPayload>({
      query: ({ email, password, offerId, inviteToken, searchParams }) => ({
        url: PostgrestEndpoints.REGISTER,
        method: 'POST',
        body: {
          email,
          password,
          args: {
            ...searchParams,
            ...(offerId ? { offer_id: offerId } : {}),
            ...(inviteToken ? { invite_token: inviteToken } : {}),
          },
        },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          AnalyticsService.logEvent(EVENTS.AUTH_EVENTS.REGISTER.INTENT);
          const {
            data: { accessToken, refreshToken },
          } = await queryFulfilled;

          await dispatch(setTokens({ accessToken, refreshToken }));
          AnalyticsService.logEvent(EVENTS.AUTH_EVENTS.REGISTER.SUCCESS);
        } catch (error: any) {
          const payload = await error?.meta?.request?.json?.();
          AnalyticsService.logEvent(EVENTS.AUTH_EVENTS.REGISTER.FAIL, {
            email: payload?.email,
            reason: error?.error?.data?.message,
          });
          handleOnQueryStartedError(error);
        }
      },
      transformResponse: (res: any) => {
        return {
          accessToken: res.access_token,
          refreshToken: res.refresh_token,
          userId: res.user_id,
        };
      },
    }),
    validateInviteToken: builder.query<boolean, { inviteToken: string }>({
      query: ({ inviteToken }) => ({
        url: PostgrestEndpoints.VALIDATE_INVITE_TOKEN,
        method: 'POST',
        body: { invite_token: inviteToken },
      }),
    }),
    getInviteDetails: builder.query<
      { organizationName: string; claimedByUserId: string },
      { inviteToken: string }
    >({
      query: ({ inviteToken }) => ({
        url: PostgrestEndpoints.GET_INVITE_DETAILS,
        method: 'POST',
        body: { invite_token: inviteToken },
      }),
      transformResponse: (response: any) => camelCaseKeys(response),
    }),
  }),
});

export const {
  useLoginMutation,
  useSendResetPasswordEmailMutation,
  useValidatePasswordResetTokenQuery,
  useResetPasswordWithTokenMutation,
  useRegisterMutation,
  useValidateInviteTokenQuery,
  useGetInviteDetailsQuery,
} = authApi;
