export const SortByOptions = {
  LATEST: {
    key: 'LATEST',
    text: 'Latest',
  },
  ALPHABETICAL: {
    key: 'ALPHABETICAL',
    text: 'Alphabetical',
  },
  DURATION_ASC: {
    key: 'DURATION_ASC',
    text: 'Duration (Ascending)',
  },
  DURATION_DESC: {
    key: 'DURATION_DESC',
    text: 'Duration (Descending)',
  },
  CREDIT_ASC: {
    key: 'CREDIT_ASC',
    text: 'Credit (Ascending)',
  },
  CREDIT_DESC: {
    key: 'CREDIT_DESC',
    text: 'Credit (Descending)',
  },
};

type MapKeys = Array<keyof typeof SortByOptions>;

const SORT_BY_NON_NASBA: MapKeys = [
  'LATEST',
  'ALPHABETICAL',
  'DURATION_ASC',
  'DURATION_DESC',
];
const SORT_BY_NASBA: MapKeys = [
  'LATEST',
  'ALPHABETICAL',
  'CREDIT_ASC',
  'CREDIT_DESC',
  'DURATION_ASC',
  'DURATION_DESC',
];

const getSortByOptions = (keys: MapKeys) =>
  keys.reduce((acc, key) => ({ ...acc, [key]: SortByOptions[key] }), {});

export const SortByOptionsNonNasba = getSortByOptions(SORT_BY_NON_NASBA);
export const SortByOptionsNasba = getSortByOptions(SORT_BY_NASBA);

export const FormatOptions = {
  ALL: {
    key: 'ALL',
    text: 'All',
  },
  AUDIO: {
    key: 'AUDIO',
    text: 'Audio',
  },
  VIDEO: {
    key: 'VIDEO',
    text: 'Video',
  },
};

export const NASBA_CATEGORY_TABS = [
  { value: 'categories', label: 'Categories' },
  { value: 'nasbaFos', label: 'NASBA Fields of Study' },
];

export const EpisodeFilterDefaults = {
  sortBy: SortByOptions.LATEST.key,
  onlyEthics: false,
  hidePlayed: false,
  selectedFos: [],
  selectedCategories: [],
  selectedTab: NASBA_CATEGORY_TABS[0].value,
  format: FormatOptions.ALL.key,
};

export const LibraryUrlSearchParams = {
  CATEGORY: 'cat',
  NASBA_CATEGORY: 'nasbacat',
};
