import {
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
} from '@mui/icons-material';
import { Box, MenuItem } from '@mui/material';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';

import { Select } from '@/components';
import { technicalSearchActions } from '@/store';

export const RegionOptionsMobile = ({ regions, selectedRegions }) => {
  const dispatch = useDispatch();

  const onRegionSelect = e => {
    const { value: newSelectedRegions } = e.target;
    const clickeditem = R.symmetricDifference(
      Object.keys(selectedRegions),
      newSelectedRegions,
    )[0];
    dispatch(technicalSearchActions.selectRegion(clickeditem));
  };

  const selectedKeys = Object.keys(selectedRegions);
  const label =
    selectedKeys.length === 0 || selectedKeys.length === regions.length
      ? 'Select Designation'
      : selectedKeys.sort().join(', ');

  return (
    <Box sx={styles.container}>
      <Select
        selectedOptions={Object.keys(selectedRegions)}
        title={label}
        isMultiselect={true}
        onChange={onRegionSelect}>
        {regions.map(region => (
          <MenuItem sx={styles.selectOption} key={region} value={region}>
            {selectedRegions[region] ? (
              <IndeterminateCheckBox sx={styles.checkbox} />
            ) : (
              <CheckBoxOutlineBlank sx={styles.checkbox} />
            )}
            {region}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    mb: 1,
  },
  selectOption: {
    p: 0.5,
    whiteSpace: 'unset',
    wordBreak: 'break-word',
    height: 40,
  },
  checkbox: {
    color: 'blueBlack',
    ml: 0.5,
    mr: 1,
  },
};
