import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';

import config from '@/config';
import { AppRouteWrapper } from '@/containers';
import { AnalyticsService, initLogRocket, initSentry } from '@/services';

export const AnonymousSessionLoader = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    window.Intercom('boot', {
      user_id: null,
      app_id: config.INTERCOM_APP_ID,
      vertical_padding: 100,
      hide_default_launcher: isMobile,
    });
  }, [isMobile]);

  useEffect(() => {
    initSentry({});
    initLogRocket({});
    AnalyticsService.initialize({});
  }, []);

  return <AppRouteWrapper />;
};
