import { alphanumericSort, camelCaseKeys } from '@/utils';

export const technicalSearchEpisodeTransform = e => ({
  ...camelCaseKeys(e),
  categories: [
    ...new Set(e.tags.filter(tag => !!tag.category).map(tag => tag.category)),
  ].join(', '),
  topics: [
    ...new Set(e.tags.filter(tag => !!tag.topic).map(tag => tag.topic)),
  ].join(', '),
  subTypes: [
    ...new Set(e.tags.filter(tag => !!tag.sub_type).map(tag => tag.sub_type)),
  ]
    .sort(alphanumericSort)
    .join(', '),
  regions: [
    ...new Set(e.tags.filter(tag => !!tag.region).map(tag => tag.region)),
  ]
    .sort()
    .join(', '),
});
