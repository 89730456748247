import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectUserRegion, useFetchCategoriesQuery } from '@/store';

import {
  AdditionalResources,
  EpisodeChapterList,
  EpisodeControlButtons,
  EpisodeDescription,
  RelatedEpisodes,
  Speakers,
} from './components';

export const EpisodeDetailsNew = ({ episode }) => {
  const region = useSelector(selectUserRegion);

  const referrer = {};

  const { data: categories } = useFetchCategoriesQuery(
    { categoryIds: episode.categories, region },
    {
      skip: !episode.categories?.length,
      selectFromResult: result => {
        return {
          ...result,
          data: result.data?.filter(
            category => category.name !== 'Ethics' && category.name !== 'Video',
          ),
        };
      },
    },
  );

  return (
    <Box sx={styles.root}>
      <Box sx={styles.leftColumn}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: 212,
            // mb: 3,
          }}>
          <Typography variant="h2">{episode.name}</Typography>
          <Typography variant="body2" sx={{ my: 1 }}>
            {episode.hook}
          </Typography>
          {region === 'CAN' ? (
            <Typography variant="body1" sx={{ color: 'neutral35' }}>
              {categories?.map(c => c.name).join(', ')}
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ color: 'neutral35' }}>
              Field of Study: {episode.CPEDetails?.fieldOfStudy}
            </Typography>
          )}
          <Box sx={{ mt: 'auto' }}>
            <EpisodeControlButtons episode={episode} referrer={referrer} />
          </Box>
        </Box>
        <EpisodeDescription episode={episode} />
        <RelatedEpisodes
          relatedEpisodes={episode.relatedEpisodes}
          referrer={{
            ...referrer,
            episodeId: episode.episodeId,
            episodeName: episode.name,
          }}
        />
      </Box>
      <Box sx={styles.rightColumn}>
        <Box sx={{ position: 'relative', width: '100%', height: 212 }}>
          <Box
            component="img"
            src={episode.cover}
            alt="episode cover"
            sx={{
              borderRadius: 6,
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
          />
          {!!episode.companyLogo && (
            <Box
              component="img"
              src={episode.companyLogo}
              alt="company logo"
              sx={{
                position: 'absolute',
                bottom: 16,
                left: 16,
                borderRadius: 2,
                height: 60,
                width: 60,
              }}
            />
          )}
        </Box>
        <EpisodeChapterList episode={episode} referrer={referrer} />
        <AdditionalResources episode={episode} />
        <Speakers episode={episode} />
      </Box>
    </Box>
  );
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  leftColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    ml: 3,
    minWidth: {
      xs: 272,
      lg: 342,
    },
    width: { xs: 272, lg: 342 },
    gap: 4,
  },
};
