import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Modals, Routes } from '@/constants';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  playerThunks,
  selectActiveModalParams,
  selectIsB2CUser,
  selectIsEpisodeRedeemRequired,
  selectIsPlaybackRejected,
  selectPlaybackRejectedChapter,
  selectUserRegion,
  uiActions,
  useFetchEpisodeQuery,
  useFetchPlanQuery,
  useRedeemEpisodeMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

import { RedeemModalContent as RedeemModalContentComponent } from './RedeemModalContent.component';

export const RedeemModalContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { episodeId } = useSelector(selectActiveModalParams) || {};
  const region = useSelector(selectUserRegion);
  const isB2CUser = useSelector(selectIsB2CUser);
  const isRedeemRequired = useSelector(state =>
    selectIsEpisodeRedeemRequired(state, episodeId),
  );
  const isPlaybackRejected = useSelector(selectIsPlaybackRejected);
  const playbackRejectedChapter = useSelector(selectPlaybackRejectedChapter);
  const antiCheatingActive = useFeatureIsOn('assessment-anti-cheating');
  const [redeemEpisode, { isLoading: isRedeeming }] =
    useRedeemEpisodeMutation();
  const { data: plan } = useFetchPlanQuery();
  const { data: episode } = useFetchEpisodeQuery(
    { episodeId, region },
    { skip: !episodeId },
  );

  const onRedeemEpisode = async () => {
    try {
      await redeemEpisode({
        episodeId,
        antiCheatingActive,
      }).unwrap();
      AnalyticsService.episodeRedeem({ episode });
      if (
        isPlaybackRejected &&
        playbackRejectedChapter.episodeId === episodeId
      ) {
        dispatch(
          playerThunks.changeActiveChapter({
            activeChapter: playbackRejectedChapter,
            location: {
              component: EVENT_CONSTANTS.COMPONENT.MODAL,
              modalName: Modals.REDEEM,
              details: EVENT_CONSTANTS.AUTO_PLAY,
            },
          }),
        );
      }
    } catch (error) {
      SnackbarUtils.error('Failed to redeem episode');
    }
  };

  const onBuySubscription = () => {
    if (isB2CUser) {
      navigate(Routes.PRICING);
    } else {
      window.Intercom?.('show');
    }
    dispatch(uiActions.closeActiveModal());
  };

  const closeModal = () => dispatch(uiActions.closeActiveModal());

  return (
    <RedeemModalContentComponent
      {...{
        episode,
        plan,
        isRedeemRequired,
        isRedeeming,
        isB2CUser,
        onRedeemEpisode,
        onBuySubscription,
        closeModal,
      }}
    />
  );
};
