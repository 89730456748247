import { AccessTime, EventAvailable } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CreditType, Modals, Routes, TrackerMessages } from '@/constants';
import { uiActions, useDownloadCertificateMutation } from '@/store';
import { createRouteWithParams, SnackbarUtils } from '@/utils';

import { CertificateMenu, CertificateTypeIcon } from './';

export const Certificate = ({ credit }) => {
  const dispatch = useDispatch();

  const [downloadCertificate, { isLoading: isCertificateDownloading }] =
    useDownloadCertificateMutation();
  const navigate = useNavigate();

  const dotColour = credit.type === CreditType.LUMIQ ? 'teal' : 'orange';

  const onEdit = () => {
    dispatch(
      uiActions.setActiveModal({
        name: Modals.TRACKER_ADD_EDIT,
        params: { credit },
      }),
    );
  };

  const onDelete = () => {
    dispatch(
      uiActions.setActiveModal({
        name: Modals.TRACKER_DELETE,
        params: { credit },
      }),
    );
  };

  const onDownload = async () => {
    const { error } = await downloadCertificate({
      certificateId: credit.creditId,
    });
    if (error) SnackbarUtils.error(TrackerMessages.DOWNLOAD_ERROR);
  };

  const onCertificateClick = event => {
    event.preventDefault();
    if (credit.type === CreditType.CUSTOM) {
      onEdit();
    } else if (credit.type === CreditType.LUMIQ) {
      navigate(
        {
          pathname: createRouteWithParams(Routes.EPISODE, {
            episodeId: credit.episodeId,
          }),
        },
        {
          state: {
            referrer: { page: 'cpd tracker', component: 'certificate' },
          },
        },
      );
    }
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.dotContainer}>
        <CertificateTypeIcon colour={dotColour} />
        {!!credit.ethicsCredit && (
          <CertificateTypeIcon colour={'green'} ml={-0.5} />
        )}
      </Box>
      <Box sx={styles.contentContainer} onClick={onCertificateClick}>
        <Typography variant="select" sx={styles.title}>
          {credit.name}
        </Typography>
        <Box sx={styles.subtitleContainer}>
          <EventAvailable sx={styles.icon} />
          <Typography variant="caption" sx={styles.subtitle}>
            {format(credit.dateCompleted, 'MMM d, yyyy')}
          </Typography>
          <AccessTime sx={styles.icon} />
          <Typography variant="caption" sx={styles.subtitle}>
            {Math.round(credit.credit * 60)} mins of CPD
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.menuContainer}>
        <CertificateMenu
          onDelete={onDelete}
          onDownload={onDownload}
          isDownloading={isCertificateDownloading}
          onEdit={onEdit}
          credit={credit}
        />
      </Box>
    </Box>
  );
};

const styles = {
  root: {
    alignItems: 'flex-start',
    borderBottom: theme => `solid 1px ${theme.palette.grayBrightLight}`,
    display: 'flex',
    justifyContent: 'flex-start',
    py: 3,
  },
  dotContainer: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
  },
  contentContainer: {
    cursor: 'pointer',
    mx: 1,
    flex: 1,
  },
  menuContainer: {
    alignSelf: 'center',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    color: 'blue',
    mb: 1,
    wordBreak: 'break-word',
  },
  subtitleContainer: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    color: 'grayBright',
  },
  subtitle: {
    mr: 1.5,
  },
  icon: {
    fontSize: 14,
    mr: 0.5,
  },
};
