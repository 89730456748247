import { createSvgIcon } from '@mui/material/utils';

export const LumiQCombinationMarkIcon = createSvgIcon(
  <svg
    width="100"
    height="26"
    viewBox="0 0 100 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_26_746)">
      <path
        d="M26 12.9829C26 5.82345 20.1765 0 12.9829 0C5.7892 0 0 5.82345 0 12.9829C0 20.1423 5.82345 25.9657 12.9829 25.9657C14.9697 25.9657 16.8195 25.5204 18.498 24.7325L15.278 21.5125C14.6271 21.9578 13.805 22.2319 12.9486 22.2319C10.8933 22.2319 9.14625 20.7246 8.80369 18.7721V7.9473C8.80369 5.65217 10.6535 3.76812 12.9486 3.76812C15.278 3.76812 17.1278 5.65217 17.1278 7.9473V10.3452H14.3874C14.1133 10.3452 13.9078 10.5507 13.9078 10.8248C13.9078 11.0988 14.1133 11.3043 14.3874 11.3043H17.1278V12.6061H14.3874C14.1133 12.6061 13.9078 12.8116 13.9078 13.0856C13.9078 13.3597 14.1133 13.5652 14.3874 13.5652H17.0935V14.8669H14.3874C14.1133 14.8669 13.9078 15.0725 13.9078 15.3465C13.9078 15.6206 14.1133 15.8261 14.3874 15.8261H17.0935V18.9091C17.025 19.1831 16.9223 19.5257 16.751 19.8682L20.4848 23.6021C23.8076 21.2385 26 17.3676 26 12.9829Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M34.4118 5.98782C34.4118 4.54494 35.2077 3.86859 36.2378 3.86859C37.2211 3.86859 38.017 4.54494 38.017 5.98782V20.0108C38.017 21.4537 37.2211 22.13 36.2378 22.13C35.2077 22.13 34.4118 21.4988 34.4118 20.0108V5.98782Z"
      fill="currentColor"
    />
    <path
      d="M52.2976 20.1022C52.2976 21.5 51.408 22.1301 50.4247 22.1301C49.8629 22.1301 49.3478 21.9046 49.0201 21.4537C48.2709 21.9497 47.2409 22.1301 46.3513 22.1301C42.6523 22.1301 40.4517 20.0559 40.4517 16.4938V11.1281C40.4517 9.68523 41.2477 9.00888 42.231 9.00888C43.261 9.00888 44.057 9.64014 44.057 11.1281V16.3585C44.057 17.5309 44.9466 18.5229 46.164 18.6581C47.475 18.7483 48.5987 17.8465 48.6923 16.584V11.1281C48.6923 9.73032 49.5351 9.00888 50.4247 9.00888C51.5016 9.00888 52.2508 9.54996 52.2976 11.1281V20.1022Z"
      fill="currentColor"
    />
    <path
      d="M54.5919 10.9928C54.5919 9.59505 55.4346 8.96379 56.4179 8.96379C56.9798 8.96379 57.4948 9.18924 57.8225 9.64014C58.5717 9.14415 59.6018 8.96379 60.4914 8.96379C62.2238 8.96379 63.5816 9.41469 64.6117 10.2263C65.6417 9.3696 67.14 8.9187 68.9661 8.96379C72.1499 9.05397 74.6783 11.6241 74.6783 14.6902V19.9657C74.6783 21.4086 73.8823 22.085 72.8991 22.085C71.869 22.085 71.073 21.4537 71.073 19.9657V14.7353C71.073 13.563 70.1834 12.571 68.9661 12.4357C67.6551 12.3455 66.5314 13.2473 66.4377 14.5099V19.9657C66.4377 21.4086 65.6417 22.085 64.6585 22.085C63.6284 22.085 62.8324 21.4537 62.8324 19.9657V14.7804C62.8324 13.6081 61.9428 12.6161 60.7255 12.4808C59.4145 12.3455 58.2908 13.2924 58.1971 14.5549V20.0108C58.1971 21.4086 57.3543 22.1301 56.4647 22.1301C55.3878 22.1301 54.6387 21.589 54.5919 20.0108V10.9928Z"
      fill="currentColor"
    />
    <path
      d="M77.0662 20.0108C77.0662 21.4537 77.8622 22.1301 78.8922 22.1301C79.8755 22.1301 80.6715 21.4988 80.6715 20.0108V11.1281C80.6715 9.68524 79.8755 9.00889 78.8922 9.00889C77.8622 9.00889 77.0662 9.68524 77.0662 11.1281V20.0108ZM78.8454 3.86864C79.7818 3.86864 80.6246 4.68026 80.6246 5.58206C80.6246 6.52894 79.7818 7.34056 78.8454 7.34056C77.8622 7.34056 77.0194 6.57403 77.0194 5.58206C77.0662 4.68026 77.8622 3.86864 78.8454 3.86864Z"
      fill="currentColor"
    />
    <path
      d="M98.885 19.2894L97.8081 18.2523C98.6041 16.8094 99.0723 15.0058 99.0723 12.8866C99.0723 7.20528 95.7948 3.82353 91.019 3.82353C86.2432 3.82353 82.9657 7.16019 82.9657 12.8866C82.9657 18.568 86.2432 22.085 91.019 22.085C92.7514 22.085 94.2965 21.6341 95.5607 20.7323L96.4971 21.6341C97.1526 22.2653 98.2295 22.2653 98.885 21.6341C99.5874 20.9577 99.5874 19.9207 98.885 19.2894ZM91.0658 18.7483C88.3502 18.7483 86.6178 16.5389 86.6178 12.8866C86.6178 9.3696 88.3502 7.29546 91.0658 7.29546C93.7815 7.29546 95.5139 9.41469 95.5139 12.8866C95.5139 13.9237 95.3734 14.8706 95.0925 15.6371C94.437 15.096 93.4537 15.1411 92.8451 15.7273C92.1896 16.3585 92.1896 17.3956 92.8451 18.0269L93.0792 18.2523C92.4705 18.568 91.815 18.7483 91.0658 18.7483Z"
      fill="currentColor"
    />
    <defs>
      <clipPath id="clip0_26_746">
        <rect width="26" height="26" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>,
  'LumiQCombinationMark',
);
