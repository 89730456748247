import { Box, TextField } from '@mui/material';
import { useState } from 'react';

import { styles } from '../styles';
import { ExtraBold } from './ExtraBold';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalSubtitle } from './ModalSubtitle';
import { ModalTitle } from './ModalTitle';

export const UsaEaNumberForm = ({ goToPrevStep, goToNextStep }) => {
  const [form, setForm] = useState('');
  const [error, setError] = useState();

  const handleChange = event => {
    const { value } = event.target;
    setForm(value);

    if (error && isFormValid(value)) setError();
  };

  const handleNext = () => {
    if (isFormValid(form)) {
      goToNextStep(form);
    } else {
      setError(
        'Your PTIN should be an eight digit number preceded by the letter "P"',
      );
    }
  };

  return (
    <Box sx={styles.formContainer}>
      <Header Title={<Title />} Subtitle={<Subtitle />} />
      <Box sx={styles.formPrimarySection}>
        <TextField
          id={'PTIN'}
          value={form}
          onChange={handleChange}
          label="PTIN"
          variant="outlined"
          fullWidth
          error={!!error}
          helperText={error}
        />
      </Box>
      <Footer
        handlePrev={() => goToPrevStep()}
        handleNext={handleNext}
        isNextButtonDisabled={!form}
      />
    </Box>
  );
};

const isFormValid = form => {
  const ptinRegexPattern = /^P\d{8}$/;
  return ptinRegexPattern.test(form);
};

const Title = () => {
  return (
    <ModalTitle>
      As an <ExtraBold>EA</ExtraBold> in the{' '}
      <ExtraBold>United States of America</ExtraBold>, what is your Preparer Tax
      Identification Number?
    </ModalTitle>
  );
};

const Subtitle = () => (
  <ModalSubtitle>
    It is important that you enter the correct information for us to receive and
    report accurate certificates.
  </ModalSubtitle>
);
