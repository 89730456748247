import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { playerFsmApi } from '@/store';

import { PLAYER_FSM_SLICE_KEY } from './player-fsm.constants';
import { SessionStateWithId } from './player-fsm.types';

export const playerSessionDetailAdapter = createEntityAdapter({
  selectId: (playerSession: SessionStateWithId) => playerSession.episodeId,
});

export const playerFsmSlice = createSlice({
  name: PLAYER_FSM_SLICE_KEY,
  initialState: {
    details: playerSessionDetailAdapter.getInitialState(),
  },
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(
        playerFsmApi.endpoints.startPlayerSession.matchFulfilled,
        playerFsmApi.endpoints.resumeLastPlayerSession.matchFulfilled,
        playerFsmApi.endpoints.findPlayerSessionByEpisodeId.matchFulfilled,
      ),
      (state, { payload }) => {
        if (payload) {
          playerSessionDetailAdapter.setOne(state.details, {
            ...payload.state,
            sessionId: payload.sessionId,
          });
        }
      },
    );
    builder.addMatcher(
      isAnyOf(
        playerFsmApi.endpoints.playPlayerSession.matchFulfilled,
        playerFsmApi.endpoints.setPosition.matchFulfilled,
        playerFsmApi.endpoints.seekPosition.matchFulfilled,
        playerFsmApi.endpoints.answerQuiz.matchFulfilled,
      ),
      (state, { payload, meta }) => {
        const sessionId = meta.arg.originalArgs.sessionId;
        playerSessionDetailAdapter.setOne(state.details, {
          ...payload.state.newState,
          sessionId,
        });
      },
    );
  },
});

export const { reducer: playerFsmReducer } = playerFsmSlice;
