import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Routes } from '@/constants';
import { LumiQCombinationMarkIcon } from '@/icons';
import { uiActions } from '@/store';

export const NavbarLogo = () => {
  const dispatch = useDispatch();

  return (
    <Box
      component={Link}
      to={Routes.DISCOVER}
      onClick={() => dispatch(uiActions.closeSidebarDrawer())}
      sx={styles.logoContainer}>
      <LumiQCombinationMarkIcon sx={styles.logo} />
    </Box>
  );
};

const styles = {
  logoContainer: theme => ({
    display: 'flex',
    alignItems: 'center',
    ml: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      ml: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  }),
  logo: {
    ml: 3,
    color: 'white',
    width: 100,
    height: 26,
    '&:hover': {
      cursor: 'pointer',
    },
  },
};
