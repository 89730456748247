import { useTheme } from '@mui/material';

export const ProgressRing = ({ colour, fill, percent, radius, stroke }) => {
  const theme = useTheme();

  const normalizedRadius = radius - stroke;
  const circumference = 2 * Math.PI * normalizedRadius;
  const strokeDashoffset = circumference - (percent / 100) * circumference;

  return (
    <svg height={radius * 2} width={radius * 2}>
      <circle
        cx={radius}
        cy={radius}
        fill={fill}
        r={normalizedRadius}
        stroke={theme.palette.grayLight}
        strokeWidth={stroke}
      />
      <circle
        cx={radius}
        cy={radius}
        fill="none"
        r={normalizedRadius}
        stroke={colour}
        strokeDasharray={`${circumference}, ${circumference}`}
        strokeLinecap="round"
        strokeWidth={stroke}
        style={{ strokeDashoffset }}
        transform={`rotate(-90, ${radius}, ${radius})`}
      />
    </svg>
  );
};
