import { Box, Skeleton } from '@mui/material';

export const OfferPlaceholder = () => {
  return (
    <Box
      sx={{
        borderRadius: 4,
        overflow: 'hidden',
      }}>
      <Skeleton variant="rectangular" width={311} height={661} />
    </Box>
  );
};
