import { alphanumericSort } from '@/utils';

import { TECHNICAL_SEARCH_SORT_OPTIONS } from './constants';

export const sortEpisodes = ({ episodes, sortMethod }) => {
  if (!TECHNICAL_SEARCH_SORT_OPTIONS.includes(sortMethod?.column))
    return episodes;
  return [...episodes].sort((a, b) => {
    if (sortMethod.direction === 'asc') {
      return alphanumericSort(a[sortMethod.column], b[sortMethod.column], {
        direction: sortMethod.direction,
      });
    }
    return alphanumericSort(b[sortMethod.column], a[sortMethod.column], {
      direction: sortMethod.direction,
    });
  });
};
