import { Box, Radio, Typography, useTheme } from '@mui/material';

import { RadioBoxCheckedIcon, RadioBoxUncheckedIcon } from '@/icons';

export const RadioBoxButton = ({ label, checked, onChange, ...props }) => {
  const { gradients, palette } = useTheme();

  return (
    <Box
      sx={{
        borderRadius: 2.5,
        width: '100%',
        height: 56,
        background: checked ? gradients.primary2 : palette.grayLight,
        color: checked ? 'text.white' : 'text.black',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={onChange}>
      <Radio
        disableRipple
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        color="default"
        icon={<RadioBoxUncheckedIcon sx={styles.icon} />}
        checkedIcon={<RadioBoxCheckedIcon sx={styles.icon} />}
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <Typography variant="body2">{label}</Typography>
    </Box>
  );
};

const styles = {
  icon: {
    width: 24,
    height: 24,
    color: '#E4E4E4',
  },
};
