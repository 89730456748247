export const PASSWORD_REQUIREMENTS = [
  {
    validator: (password: string) => password?.length >= 8,
    message: 'At least 8 characters',
  },
  {
    validator: (password: string) => password?.match(/(?=.*[A-Z])/),
    message: 'At least 1 uppercase letter',
  },
  {
    validator: (password: string) => password?.match(/(?=.*[a-z])/),
    message: 'At least 1 lowercase letter',
  },
  {
    validator: (password: string) => password?.match(/(?=.*[0-9])/),
    message: 'At least 1 number',
  },
  {
    validator: (password: string) => password?.match(/^\S*$/),
    message: 'No spaces',
  },
];
