import * as R from 'ramda';

export const selectQuizReadyEpisodeIds = episodes =>
  R.pipe(
    R.filter(R.propEq(false, 'completed')),
    R.pluck('episodeId'),
    R.uniq,
  )(episodes);

export const selectBookmarkedEpisodeIds = episodes =>
  R.pipe(
    R.filter(R.propEq(true, 'isBookmarked')),
    R.sort(R.descend(R.prop('bookmarkedAt'))),
    R.pluck('episodeId'),
    R.uniq,
  )(episodes);

export const selectTabs = ({
  userRegion,
  incompleteQuizCount = 0,
  incompleteAssessmentsCount = 0,
}) => {
  return [
    { value: 'inProgress', label: 'In Progress' },
    userRegion === 'USA'
      ? {
          value: 'assessments',
          label: `Assessments (${incompleteAssessmentsCount})`,
        }
      : { value: 'quizzes', label: `Quizzes (${incompleteQuizCount})` },
    { value: 'bookmarked', label: 'Bookmarked' },
    { value: 'completed', label: 'Completed' },
  ];
};
