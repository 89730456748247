import {
  fetchChapterContentRequest,
  fetchChapterRequest,
  fetchEpisodeChaptersRequest,
  fetchTrailerContentRequest,
} from '@/requests';
import { Chapter, ChapterUrl, Region } from '@/types';

import { postgrestApi } from '../postgrest-api';
import { selectSessionUserId } from '../session';
import { RootState } from '../store';
import { transformChapter, transformChapterUrl } from './chapters-api.utils';

type FetchChapterParams = {
  chapterId: string;
  region: Region;
};

type FetchEpisodeChaptersParams = {
  episodeId: string;
  region: Region;
};

type FetchChapterUrlsParams = {
  episodeId: string;
  region: Region;
};

export const chaptersApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchChapter: builder.query<Chapter | undefined, FetchChapterParams>({
      query: fetchChapterRequest,
      transformResponse: (data: unknown[]) =>
        data[0] ? transformChapter(data[0]) : undefined,
      keepUnusedDataFor: 3600,
    }),
    fetchEpisodeChapters: builder.query<Chapter[], FetchEpisodeChaptersParams>({
      query: fetchEpisodeChaptersRequest,
      transformResponse: (data: unknown[]) =>
        data.map(chapter => transformChapter(chapter)),
    }),
    fetchChapterURLs: builder.query<ChapterUrl[], FetchChapterUrlsParams>({
      queryFn: async (
        { episodeId, region },
        redux,
        extraOptions,
        baseQuery,
      ) => {
        const userId = selectSessionUserId(redux.getState() as RootState);
        const request = userId
          ? fetchChapterContentRequest
          : fetchTrailerContentRequest;

        const { data: chapterUrls, error } = await baseQuery(
          request({ episodeId }),
        );

        if (error) return { error };
        return {
          data: (chapterUrls as unknown[]).map(c =>
            transformChapterUrl(c, region),
          ),
        };
      },
    }),
  }),
});

export const {
  useFetchChapterQuery,
  useFetchChapterURLsQuery,
  useFetchEpisodeChaptersQuery,
} = chaptersApi;
