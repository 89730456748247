import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Box, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';

import { Select } from '@/components';
import { technicalSearchActions } from '@/store';

export const CategoryOptionsMobile = ({ categories, selectedCategory }) => {
  const dispatch = useDispatch();

  const onCategorySelect = e => {
    dispatch(technicalSearchActions.selectCategory(e.target.value));
  };

  const label = selectedCategory || 'Select Category';

  return (
    <Box sx={styles.container}>
      <Select
        selectedOptions={[selectedCategory]}
        title={label}
        isMultiselect={false}
        onChange={onCategorySelect}>
        {categories.map(category => (
          <MenuItem sx={styles.selectOption} key={category} value={category}>
            {selectedCategory === category ? (
              <RadioButtonChecked sx={styles.checkbox} />
            ) : (
              <RadioButtonUnchecked sx={styles.checkbox} />
            )}
            {category}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    mb: 1,
  },
  selectOption: {
    p: 0.5,
    whiteSpace: 'unset',
    wordBreak: 'break-word',
    height: 40,
  },
  checkbox: {
    color: 'blueBlack',
    ml: 0.5,
    mr: 1,
  },
};
