import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { enableDeveloperConsoleUtils } from '@/utils';

import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { initLogger } from './services';

const mountApp = async () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  initLogger();
  enableDeveloperConsoleUtils();

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};

mountApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
