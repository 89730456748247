import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageTabs } from '@/components';
import {
  selectAssessmentReadyEpisodeIds,
  selectIsFsmPlayer,
  selectSessionUserId,
  selectUserRegion,
  useFetchUserQuizzesQuery,
} from '@/store';
import { useFetchSessionQuizzesQuery } from '@/store/player-fsm-api';

import {
  AssessmentReady,
  Bookmarked,
  Completed,
  InProgress,
  QuizReady,
} from './components';
import { selectTabs } from './utils';

export const MyLumiq = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  const userId = useSelector(selectSessionUserId);
  const userRegion = useSelector(selectUserRegion);

  const isFSMPlayerUser = useSelector(selectIsFsmPlayer);

  const { incompleteQuizCount: quizCountOld } = useFetchUserQuizzesQuery(
    undefined,
    {
      skip: !userId || isFSMPlayerUser !== false,
      selectFromResult: result => ({
        ...result,
        incompleteQuizCount: result.data?.filter(i => !i.completed).length,
      }),
    },
  );

  const { incompleteQuizCount: quizCountNew } = useFetchSessionQuizzesQuery(
    undefined,
    {
      skip: !userId || !isFSMPlayerUser,
      selectFromResult: result => ({
        ...result,
        incompleteQuizCount: result.data?.reduce(
          (acc, e) => acc + e.availableQuizCount,
          0,
        ),
      }),
    },
  );

  const incompleteQuizCount = isFSMPlayerUser ? quizCountNew : quizCountOld;

  const incompleteAssessmentsCount = useSelector(
    selectAssessmentReadyEpisodeIds,
  )?.length;

  const tabs = useMemo(
    () =>
      selectTabs({
        userRegion,
        incompleteQuizCount,
        incompleteAssessmentsCount,
      }),
    [userRegion, incompleteQuizCount, incompleteAssessmentsCount],
  );

  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  useEffect(() => {
    if (initialized) return;

    const urlParamsObject = Object.fromEntries(
      new URLSearchParams(location.search),
    );
    const parsedTab = urlParamsObject?.tab;

    if (parsedTab) {
      const newSelectedTab =
        tabs.findIndex(tab => tab.value === parsedTab) !== -1
          ? tabs.findIndex(tab => tab.value === parsedTab)
          : 0;
      setSelectedTab(tabs[newSelectedTab].value);
    }

    setInitialized(true);
  }, [location, tabs, initialized]);

  useEffect(() => {
    // Update query params when tab changes
    if (!initialized) return;

    const urlParams = new URLSearchParams(location.search);
    const paramToUpdateIndex = Array.from(urlParams.keys()).find(key =>
      key.startsWith('tab'),
    );
    if (paramToUpdateIndex) {
      urlParams.set(paramToUpdateIndex, selectedTab);
    }

    navigate(
      {
        pathname: location.pathname,
        search: urlParams.toString(),
      },
      { replace: true },
    );
  }, [navigate, selectedTab, location.pathname, location.search, initialized]);

  return (
    <Box>
      <Box mb={2.5}>
        <Typography variant="h1">My LumiQ</Typography>
      </Box>
      <Box mb={2.5}>
        <PageTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onChange={(e, newVal) => setSelectedTab(newVal)}
        />
      </Box>

      <Box>
        {selectedTab === tabs[0].value && <InProgress />}
        {userRegion === 'CAN' && selectedTab === tabs[1].value && <QuizReady />}
        {userRegion === 'USA' && selectedTab === tabs[1].value && (
          <AssessmentReady />
        )}

        {selectedTab === tabs[2].value && <Bookmarked />}
        {selectedTab === tabs[3].value && <Completed />}
      </Box>
    </Box>
  );
};
