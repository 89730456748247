import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { EpisodeGrid } from '@/components';
import { selectUserRegion, useFetchEpisodesQuery } from '@/store';

export const RelatedEpisodes = ({ relatedEpisodes, referrer }) => {
  const region = useSelector(selectUserRegion);

  const { data: episodes } = useFetchEpisodesQuery(
    {
      episodeIds: relatedEpisodes,
      region,
    },
    {
      skip: !relatedEpisodes?.length,
    },
  );

  if (!relatedEpisodes?.length) return null;

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        Related Episodes
      </Typography>
      <EpisodeGrid
        episodeIds={episodes?.map(e => e.episodeId) || relatedEpisodes}
        referrer={referrer}
      />
    </Box>
  );
};
