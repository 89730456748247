import { AccessTime, LocalActivity } from '@mui/icons-material';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectUserRegion } from '@/store';
import { convertSecondsToHoursMinutes } from '@/utils';

export const CreditsDurationInfo = ({ credits, duration, durationPlayed }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const userRegion = useSelector(selectUserRegion);
  const isNasba = userRegion === 'USA';

  const Icon =
    durationPlayed > 0 ? AccessTime : isNasba ? LocalActivity : AccessTime;
  const label =
    durationPlayed > 0
      ? `${convertSecondsToHoursMinutes(duration - durationPlayed)}${isMobile ? '' : ' left'}`
      : isNasba
        ? `${credits} Credits`
        : convertSecondsToHoursMinutes(duration);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon sx={{ mr: 0.5, fontSize: { xs: 14, md: 20 } }} />
      <Typography
        variant={isMobile ? 'caption' : 'h6'}
        sx={{ fontWeight: 700 }}>
        {label}
      </Typography>
    </Box>
  );
};
