import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectPlayerProgress, selectPlayerSeekToPosition } from '@/store';
import { convertMsToHHMMSS } from '@/utils';

export const ProgressInfo = ({ chapter }) => {
  const chapterDuration = chapter?.duration;
  const progress = useSelector(selectPlayerProgress);
  const seekToPosition = useSelector(selectPlayerSeekToPosition);
  const position = progress.position || seekToPosition;

  const formattedCurrentPosition = convertMsToHHMMSS(position * 1000);
  const formattedChapterDuration = convertMsToHHMMSS(
    (chapterDuration ?? 0) * 1000,
  );

  return (
    <Box sx={styles.progress}>
      <span>
        {formattedCurrentPosition} / {formattedChapterDuration}
      </span>
    </Box>
  );
};

const styles = {
  progress: theme => ({
    color: 'aqua',
    mr: 2.5,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.typography.pxToRem(10),
    },
  }),
};
