import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Routes } from '@/constants';

import { CPD_REQUIREMENTS_LIST } from './constants';

export const CPDRequirements = () => {
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <Box sx={styles.titleContainer}>
        <Typography variant="h3">CPD Requirements</Typography>
        <Button
          variant="primary"
          label="Go to CPD Tracker"
          fullWidth={false}
          onClick={() => navigate(Routes.TRACKER)}
        />
      </Box>
      <Box sx={styles.paper}>
        <Grid container>
          <Grid item sm={12}>
            <Box sx={styles.section}>
              <Typography variant="body3">
                You can read on below to find all the details of your CPD
                requirements.
                <br />
                <strong>
                  All of the episodes and minutes listened to on LumiQ are fully
                  verifiable and will count towards your CPD requirements.
                </strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box sx={styles.section}>
              <Typography sx={styles.sectionTitle}>
                What qualifies as CPD?
              </Typography>
              <Typography variant="body3">
                Any learning activity that is:
                <List sx={styles.list}>
                  <ListItem sx={styles.listItem}>
                    Quantifiable (in relation to specified time and duration)
                  </ListItem>
                  <ListItem sx={styles.listItem}>
                    Directly related to the competencies needed to carry on your
                    employment or practice as a CPA
                  </ListItem>
                  <ListItem sx={styles.listItem}>
                    Relevant to your current professional needs and/or long-term
                    career interests, and
                  </ListItem>
                  <ListItem sx={styles.listItem}>
                    Contains significant intellectual or practical content.
                  </ListItem>
                </List>
              </Typography>
            </Box>
            <Box sx={styles.section}>
              <Typography sx={styles.sectionTitle}>
                What are my CPD Requirements?
              </Typography>
              <Typography variant="body3">
                CPA’s are required to complete 120 hours of CPD every
                consecutive three-year rolling period (i.e. 2018, 2019, 2020
                then 2019, 2020, 2021 and so on). 60 of those 120 hours must be
                verifiable and 60 can be unverifiable. Within the 60 verifiable
                hours, you must also complete four (4) verifiable ethics hours
                in every rolling triennial (recurring every 3 years) period.
                Verifiable hours must be tracked and documented, whereas
                unverifiable hours do not have that requirement.
              </Typography>
            </Box>
            <Box sx={styles.section}>
              <Typography sx={styles.sectionTitle}>
                What makes CPD verifiable?
              </Typography>
              <Typography variant="body3">
                Verifiable CPD means that you must have received documentation
                as proof of completion (e.g. a certificate). If you do not have
                supporting documentation, those hours will be considered
                unverifiable CPD. Acceptable documentation could vary for the
                type of CPD you obtain.
              </Typography>
            </Box>
            <Box sx={styles.section}>
              <Typography sx={styles.sectionTitle}>
                What makes CPD unverifiable?
              </Typography>
              <Typography variant="body3">
                Unverifiable CPD means independent and informal learning
                activities and may include the following:
                <List sx={styles.list}>
                  <ListItem sx={styles.listItem}>On-the-job training</ListItem>
                  <ListItem sx={styles.listItem}>Self-studies</ListItem>
                  <ListItem sx={styles.listItem}>
                    Casual reading of professional journals or magazines
                  </ListItem>
                </List>
              </Typography>
            </Box>
            <Box sx={styles.section}>
              <Typography sx={styles.sectionTitle}>
                New Ethics Requirement
              </Typography>
              <Typography variant="body3">
                For the 3 year period ending December 31, 2021, and each rolling
                triennial period thereafter, CPA’s are required to complete 4
                verifiable hours of professional ethics.
              </Typography>
            </Box>
            <Box sx={styles.section}>
              <Typography sx={styles.sectionTitle}>
                What are the rules? Unless otherwise stated in the next chart:
              </Typography>
              <Box>
                <List sx={styles.list}>
                  <ListItem sx={styles.listItem}>
                    The minimum requirement is 10 verifiable hours per year, and
                    60 verifiable hours over three years.
                  </ListItem>
                  <ListItem sx={styles.listItem}>
                    The relevant 'CPD year' is the calendar year (Jan 1 to Dec
                    31)
                  </ListItem>
                  <ListItem sx={styles.listItem}>
                    The 'three-year period' is 'rolling', which means any random
                    three-year period must meet the minimum hours requirement
                    (Year 1, 2, 3 and then 2, 3, 4 and so on)
                  </ListItem>
                  <ListItem sx={styles.listItem}>
                    Minimum of 4 verifiable hours in Ethics for each 3 year
                    period
                  </ListItem>
                  <ListItem sx={styles.listItem}>
                    You will NOT be exempt in the year you obtained your
                    designation
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box sx={styles.section}>
              <Box sx={styles.table}>
                <Typography sx={styles.sectionTitle}>
                  Provincial Specifics
                </Typography>
                <Box>
                  {CPD_REQUIREMENTS_LIST.map(prov => (
                    <Accordion
                      key={prov.region}
                      sx={styles.panel}
                      expanded={expanded === prov.region}
                      onChange={handleChange(prov.region)}>
                      <AccordionSummary
                        sx={styles.panelSummary}
                        expandIcon={<ExpandMore />}>
                        <Typography variant="body3">{prov.label}</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={styles.panelDetails}>
                        <Typography variant="select">
                          Annual Reporting Date: {prov.reportingDate}
                          <br />
                          {prov.descriptionLines &&
                            prov.descriptionLines.map((desc, idx) => (
                              <p key={idx}>{desc}</p>
                            ))}
                          {prov.descriptionList && (
                            <List sx={styles.list}>
                              {prov.descriptionList.map((desc, idx) => (
                                <ListItem
                                  key={idx}
                                  sx={{ display: 'list-item', py: 0, px: 1 }}>
                                  {desc}
                                </ListItem>
                              ))}
                            </List>
                          )}
                          <a
                            href={prov.link}
                            target="_blank"
                            rel="noopener noreferrer">
                            Visit Website
                          </a>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Box sx={styles.section}>
              <Typography variant="body3">
                <strong>Disclaimer:</strong> The Continuing Professional
                Development (CPD) requirements below should ONLY be used for
                informational purposes. Their purpose is to compile and simplify
                the CPD requirements, however, this should not be relied on as a
                comprehensive guide. It is each CPA’s responsibility to refer to
                the official CPA guidance and/or contact your specific
                regulatory body for further clarification.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const styles = {
  paper: {
    color: 'gray',
    borderRadius: 2,
    border: theme => `solid 1px ${theme.palette.grayBrightLight}`,
    boxShadow: theme => `0 2 5 0 ${theme.palette.grayBrightLight}`,
    boxSizing: 'border-box',
    py: 3,
    px: 4,
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 2,
  },
  sectionTitle: theme => ({
    ...theme.typography.h5,
    mb: 1,
  }),
  section: {
    mb: 4,
  },
  list: {
    pl: 2,
    listStyle: 'decimal',
  },
  listItem: theme => ({
    ...theme.typography.body3,
    display: 'list-item',
    py: 0,
    px: 1,
  }),
  table: {
    backgroundColor: 'tealLight',
    borderRadius: 2,
    p: 5,
    ml: {
      xs: 0,
      md: 3,
    },
  },
  panel: {
    border: theme => `1 solid ${theme.palette.blueBlackLight}`,
    borderRadius: 1,
    boxShadow: 'none',
    mb: 1,
  },
  panelSummary: {
    px: 2,
  },
  panelDetails: {
    px: 2,
    pb: 2,
  },
};
