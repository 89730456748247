import { VideoLibraryOutlined } from '@mui/icons-material';
import { Box, Typography, useMediaQuery } from '@mui/material';

export const EpisodeCount = ({ episodeCount, isAbbreviated = false }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <VideoLibraryOutlined sx={{ mr: 0.5, fontSize: { xs: 14, md: 20 } }} />
      <Typography
        variant={isMobile ? 'caption' : 'h6'}
        sx={{ fontWeight: 700 }}>
        {episodeCount + ' ' + (isAbbreviated ? 'Ep.' : 'Episodes')}
      </Typography>
    </Box>
  );
};
