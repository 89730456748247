import { RootState } from '../store';

export const selectSessionUserId = (state: RootState) =>
  state.session.data?.userId;
export const selectSessionIsLoading = (state: RootState) =>
  state.session.isLoading;
export const selectAccessToken = (state: RootState) =>
  state.session.data?.accessToken;
export const selectRefreshToken = (state: RootState) =>
  state.session.data?.refreshToken;
