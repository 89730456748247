import { createApi } from '@reduxjs/toolkit/query/react';

import { NODE_BASE_URL } from '@/requests';
import { createBaseQueryWithRefresh } from '@/store/store.utils';

import { NodeApiTags } from '../store.constants';

export const nodeApi = createApi({
  reducerPath: 'nodeApi',
  baseQuery: createBaseQueryWithRefresh({ baseUrl: NODE_BASE_URL }),
  endpoints: builder => ({}),
  tagTypes: Object.values(NodeApiTags),
});
