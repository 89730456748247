import * as R from 'ramda';

export const CPA_BUTTON_NAME = {
  SIGN_UP: 'CpaSignUp',
  BACK_TO_LUMIQ: 'BackToLumiq',
  CONTENT_END_MODAL: 'CpaContentEndModal',
  SIGNUP_MODAL: 'CpaSignUpModal',
  CONTENT_VERIFIABLE_MODAL: 'CpaContentVerifiableModal',
  CANADA_LOGO: 'CpaCanadaLogo',
};

export const BUTTON_NAME = {
  DOWNLOAD_APP: 'downloadApp',
};

const createAsyncApiEvents = (event: string) => ({
  INTENT: `${event}.intent`,
  SUCCESS: `${event}.success`,
  FAIL: `${event}.fail`,
});

const AUTH_EVENTS = {
  ...R.map(createAsyncApiEvents, {
    LOGIN: 'accounts.login',
    REGISTER: 'accounts.register',
    SEND_PASSWORD_RESET_EMAIL: 'password.send_reset_email',
    PASSWORD_RESET: 'password.reset',
  }),
};

const ASSESSMENT_API_EVENTS = {
  SUBMIT: 'assessment.submit',
};

const ASSESSMENT_EVENTS = {
  ATTEMPT: 'assessment.attempt',
  CLOSE: 'assessment.close',
  PASS: 'assessment.pass',
  FAIL: 'assessment.fail',
  ...R.map(createAsyncApiEvents, ASSESSMENT_API_EVENTS),
};

const CHAPTER_EVENTS = {
  START: 'chapter.start',
  COMPLETE: 'chapter.complete',
  PLAY: 'chapter.play',
  SEEK: 'chapter.seek',
  PAUSE: 'chapter.pause',
  RESUME: 'chapter.resume',
  PLAYBACK_SPEED_CHANGED: 'chapter.playback_speed_changed',
};

const EPISODE_EVENTS = {
  IMPRESSION: 'episode.impression',
  START: 'episode.start',
  LISTENED: 'episode.listened',
  COMPLETE: 'episode.complete',
  BOOKMARK: 'episode.bookmark',
  REDEEM: 'episode.redeem',
  SHARE: 'episode.share',
};

const LEARNING_PATH_EVENTS = {
  IMPRESSION: 'learning_path.impression',
};

const EPISODE_DETAIL_EVENTS = {
  SWITCH_TAB: 'episode_detail.switch_tab',
};

const EPISODE_FEEDBACK_EVENTS = {
  FEEDBACK: 'episode_feedback',
  EVALUATION: 'episode_evaluation',
};

const FILTER_LIBRARY_EVENTS = {
  FILTER: 'filter_library.filter',
  VIEW_EPISODE: 'filter_library.view_episode',
  FILTER_REMOVE: 'filter_library.filter_remove',
  FILTER_CLEAR: 'filter_library.filter_clear',
};

const MODAL_EVENTS = {
  OPEN: 'modal.open',
  CLOSE: 'modal.close',
};

const ONBOARDING_API_EVENTS = {
  UPDATE_DESIGNATION: 'onboarding.update_designation',
  UPDATE_PROFILE: 'onboarding.update_profile',
  UPDATE_JOB_DETAILS: 'onboarding.update_job_details',
  RESEND_B2B: 'onboarding.resend_b2b_invitation',
  FINALIZE: 'onboarding.finalize',
};

const ONBOARDING_EVENTS = {
  START: 'onboarding.start',
  STEP: 'onboarding.step',
  FINISH: 'onboarding.finish',
  ...R.map(createAsyncApiEvents, ONBOARDING_API_EVENTS),
};

const PAGE_EVENTS = {
  LOAD: 'page.load',
  VIEW: 'page.view',
  VIEW_EPISODE: 'page.view_episode',
};

const PLAN_API_EVENTS = {
  PURCHASE: 'plan.purchase',
};

const PLAN_EVENTS = {
  CLICK_BUY: 'plan.click_buy',
  ...R.map(createAsyncApiEvents, PLAN_API_EVENTS),
};

const QUIZ_EVENTS = {
  ATTEMPT: 'quiz.attempt',
  PASS: 'quiz.pass',
  FAIL: 'quiz.fail',
};

const TECHNICAL_SEARCH_EVENTS = {
  FILTER: 'technical_search.filter',
  VIEW_EPISODE: 'technical_search.view_episode',
  SORT: 'technical_search.sort',
};

const TRACK_PLAYER_EVENTS = {
  ADJUST_VOLUME: 'track_player.adjust_volume',
  BOOKMARK: 'track_player.bookmark',
  CHANGE_POSITION: 'track_player.change_position',
  CHANGE_SPEED: 'track_player.change_speed',
  EPISODE_FEEDBACK: 'track_player.episode_feedback',
  MUTE: 'track_player.mute',
  PAUSE: 'track_player.pause',
  PLAY_RESUME: 'track_player.play_resume',
  REWIND: 'track_player.rewind',
  SKIP_CHAPTER: 'track_player.skip_chapter',
  VIEW_EPISODE_DETAILS: 'track_player.view_episode_details',
};

const VIDEO_PLAYER_EVENTS = {
  FULLSCREEN: 'video_player.fullscreen',
  PAUSE: 'video_player.pause',
  PLAY: 'video_player.play_resume',
};

export const EVENTS = {
  ASSESSMENT_EVENTS,
  AUTH_EVENTS,
  CHAPTER_EVENTS,
  EPISODE_EVENTS,
  LEARNING_PATH_EVENTS,
  EPISODE_DETAIL_EVENTS,
  EPISODE_FEEDBACK_EVENTS,
  FILTER_LIBRARY_EVENTS,
  MODAL_EVENTS,
  ONBOARDING_EVENTS,
  PAGE_EVENTS,
  PLAN_EVENTS,
  QUIZ_EVENTS,
  TECHNICAL_SEARCH_EVENTS,
  TRACK_PLAYER_EVENTS,
  VIDEO_PLAYER_EVENTS,
  DEBUG: 'debug',
  VISUAL_AID_VIEW: 'visual_aid.view',
  PRODUCT_FEEDBACK: 'product_feedback',
  BUTTON_CLICK: 'button.click',
  CLICK_THROUGH: 'click_through',
  RESOURCE_VIEW: 'resource.view',
};

// Location constants

const BUTTON = {
  ASSESSMENT: 'assessment',
  BOOKMARK: 'bookmark',
  EPISODE_DETAILS: 'episode details',
  PLAY: 'play',
  PLAY_CHAPTER: 'play chapter',
  PLAY_NOW: 'play now',
  PREVIEW: 'preview',
  REDEEM: 'redeem',
  SEEK_THIRTY: 'seek 30s back',
  SHARE: 'share',
  SKIP_NEXT: 'skip next',
  SKIP_PREVIOUS: 'skip previous',
  SPEED: 'change speed',
  TRAILER: 'trailer',
};

const COMPONENT = {
  EPISODE_CARD: 'episode card',
  EPISODE_BANNER: 'episode details banner',
  FEATURED_BANNER: 'featured banner',
  LEARNING_PATH_CARD: 'learning path card',
  LEARNING_PATH_EPISODE: 'learning path episode card',
  MINI_PLAYER: 'mini player',
  MODAL: 'modal',
  RELATED_EPISODE: 'related episode',
  SEARCH_ROW: 'search row',
  THREE_DOT_MENU: 'three-dot menu',
  TRACK_PLAYER: 'track player',
  VIDEO_PLAYER: 'video player',
};

const PAGE = {
  CATEGORIES: 'categories',
  DISCOVER: 'discover',
  EPISODE_DETAILS: 'episode details',
  FILTER_LIBRARY: 'filter library',
  LEARNING_PATHS: 'learning paths',
  LEARNING_PATH_DETAILS: 'learning path details',
  MY_LUMIQ: 'my lumiq',
  SEARCH: 'search',
  TECHNICAL: 'technical search',
  TRACKER: 'tracker',
  ONBOARDING: 'onboarding',
};

const SECTION = {
  ASSESSMENTS: 'assessments',
  BOOKMARKED: 'bookmarked episodes',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in progress',
  QUIZZES: 'quizzes',
};

export const EVENT_CONSTANTS = {
  AUTO_PLAY: 'auto play chapter',
  BUTTON: BUTTON,
  COMPONENT: COMPONENT,
  EPISODE_IMAGE: 'episode cover image',
  EPISODE_TITLE: 'episode title',
  PAGE: PAGE,
  SECTION: SECTION,
  CLEAR_ALL: 'clear all',
};
