import { Box } from '@mui/material';
import * as R from 'ramda';
import { useState } from 'react';

import { RadioBoxButton } from '@/components';
import { designationCertificate } from '@/images';

import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalTitle } from './ModalTitle';

export const IsDesignatedForm = ({ goToNextStep }) => {
  const [form, setForm] = useState();

  return (
    <Box sx={styles.formContainer}>
      <Header Title={<Title />} />
      <Box
        sx={[
          styles.formPrimarySection,
          { display: 'flex', flexDirection: 'column' },
        ]}>
        <Box sx={styles.checkboxContainer}>
          <RadioBoxButton
            label={"Yes, I'm designated"}
            checked={form === true}
            onChange={() => setForm(true)}
          />
        </Box>
        <Box sx={styles.checkboxContainer}>
          <RadioBoxButton
            label={"No, I'm not designated"}
            checked={form === false}
            onChange={() => setForm(false)}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box
            component="img"
            src={designationCertificate}
            sx={{ width: 140 }}
          />
        </Box>
      </Box>
      <Footer
        handleNext={() => goToNextStep(form)}
        isNextButtonDisabled={!isFormValid(form)}
      />
    </Box>
  );
};

const isFormValid = form => !R.isNil(form);

const Title = () => (
  <ModalTitle>Do you have an accounting or finance designation?</ModalTitle>
);
