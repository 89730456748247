import { Search } from '@mui/icons-material';
import {
  Box,
  Hidden,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import format from 'date-fns/format';
import { useState } from 'react';

import { Button, ButtonProgressSpinner } from '@/components';
import { Routes, TrackerMessages } from '@/constants';
import { Link } from '@/containers';
import { useDownloadCertificateSummaryMutation } from '@/store';
import { createRouteWithParams, SnackbarUtils } from '@/utils';

import { CertificateDownloadLink, CreditMenu } from './components';

export const CreditsTable = ({ credits = [], dateRange }) => {
  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));

  const [downloadCertificateSummary, { isLoading: isSummaryDownloading }] =
    useDownloadCertificateSummaryMutation();

  const onDownloadSummary = async () => {
    if (isSummaryDownloading) return;
    const { error } = await downloadCertificateSummary({
      fromDate: dateRange.fromDate,
      toDate: dateRange.toDate,
    });
    if (error) SnackbarUtils.error(TrackerMessages.DOWNLOAD_ERROR);
  };

  const filteredCredits = credits.filter(pdh =>
    pdh.name.toLowerCase().includes(searchString.toLowerCase()),
  );

  return (
    <>
      <Box sx={styles.headerContainer}>
        <Typography variant="h5" sx={styles.title}>
          History
        </Typography>
        <TextField
          sx={styles.searchBar}
          variant="outlined"
          InputProps={{
            sx: styles.searchInput,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={e => setSearchString(e.target.value)}
          placeholder="Search Episodes"
        />
        <Button
          onClick={onDownloadSummary}
          disabled={!credits.length || isSummaryDownloading}
          isLoading={isSummaryDownloading}
          startIcon={isSummaryDownloading && <ButtonProgressSpinner />}
          label="Download All Certificates"
          fullWidth={isMdDown}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{ backgroundColor: 'grayBrightMid', textWrap: 'nowrap' }}>
            <TableRow>
              <TableCell sx={styles.headerCell} align="left">
                Episode Title
              </TableCell>
              <Hidden mdDown>
                <TableCell sx={styles.headerCell} align="left">
                  Field of Study
                </TableCell>
                <TableCell sx={styles.headerCell} align="left">
                  Credits
                </TableCell>
                <TableCell sx={styles.headerCell} align="left">
                  Completed On
                </TableCell>
                <TableCell sx={styles.headerCell} align="center">
                  Certificate
                </TableCell>
              </Hidden>
              <Hidden mdUp>
                <TableCell sx={styles.headerCell} align="center">
                  Info
                </TableCell>
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCredits && filteredCredits.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No certificates to display
                </TableCell>
              </TableRow>
            )}
            {filteredCredits &&
              filteredCredits.length !== 0 &&
              (rowsPerPage > 0
                ? filteredCredits.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  )
                : filteredCredits
              ).map(row => (
                <TableRow key={row.creditId}>
                  <TableCell component="th" scope="row">
                    <Link
                      inline={true}
                      to={{
                        pathname: createRouteWithParams(Routes.EPISODE, {
                          episodeId: row.episodeId,
                        }),
                      }}
                      state={{ referrer: { page: 'cpe tracker' } }}>
                      {row.name}
                    </Link>
                  </TableCell>
                  <Hidden mdDown>
                    <TableCell sx={styles.bodyCell} align="left">
                      {row.fieldsOfStudy[0].name}
                    </TableCell>
                    <TableCell sx={styles.bodyCell} align="left">
                      {row.credit.toFixed(1)}
                    </TableCell>
                    <TableCell sx={styles.bodyCell} align="left">
                      {format(row.dateCompleted, 'MMM d, yyyy')}
                    </TableCell>
                    <TableCell align="center">
                      <CertificateDownloadLink creditId={row.creditId} />
                    </TableCell>
                  </Hidden>
                  <Hidden mdUp>
                    <TableCell align="center">
                      <CreditMenu credit={row} />
                    </TableCell>
                  </Hidden>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{ display: 'flex' }}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={rowsPerPage}
                colSpan={6}
                count={filteredCredits.length}
                page={page}
                onPageChange={(_event, newPage) => {
                  setPage(newPage);
                }}
                onRowsPerPageChange={event => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
                slotProps={{
                  select: {
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  },
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

const styles = {
  headerContainer: {
    mb: 3,
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
  },
  title: {
    mr: 'auto',
    mb: { xs: 2, md: 0 },
  },
  searchBar: {
    mr: { xs: 0, md: 2 },
    mb: { xs: 2, md: 0 },
  },
  searchInput: {
    input: {
      padding: 1,
    },
  },
  headerCell: theme => ({
    ...theme.typography.subtitle2,
  }),
  bodyCell: theme => ({
    ...theme.typography.select,
  }),
};
