import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Routes } from '@/constants';
import { magnifyingGlassIllustration } from '@/images';

export const EmptyView = ({ title, subtitle }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: 4,
      }}>
      <Box mb={2}>
        <img
          src={magnifyingGlassIllustration}
          alt="Magnifying Glass Illustration"
          style={{ height: 140 }}
        />
      </Box>
      <Typography mb={2} variant="h1">
        {title}
      </Typography>
      <Typography mb={3} variant="subtitle1" sx={{ color: 'text.grayLight' }}>
        {subtitle}
      </Typography>
      <Button
        label="Go to Discover"
        fullWidth={false}
        onClick={() => navigate(Routes.DISCOVER)}
      />
    </Box>
  );
};
