import * as Sentry from '@sentry/react';
import React from 'react';

import { AppErrorBoundary } from './AppErrorBoundary.component';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      componentStack: '',
      error: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    const err = new Error('FatalReactError', { cause: error });
    Sentry.captureException(err, scope => {
      scope.setLevel('fatal');
      scope.setContext('react', { componentStack: errorInfo.componentStack });
    });
  }

  render() {
    const { hasError, error } = this.state;
    const View = this.props.View || AppErrorBoundary;

    if (hasError) {
      return <View error={error} />;
    }

    return this.props.children;
  }
}
