import { Facebook, Instagram, LinkedIn, X } from '@mui/icons-material';
import { Box, Grid, Hidden, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { urls } from '@/constants';
import { Link } from '@/containers';
import { nasbaLogo } from '@/images';
import { selectUserRegion } from '@/store';

export const AppFooter = () => {
  const userRegion = useSelector(selectUserRegion);
  const footerSections = [
    {
      label: 'About Us',
      link: urls.lumiq,
    },
    {
      label: 'Terms & Conditions',
      link: urls.lumiqTC,
    },
    {
      label: 'Privacy Policy',
      link: urls.lumiqPrivacy,
    },
    {
      label: 'Contact Us',
      onClick: () => window.Intercom?.('show'),
    },
    {
      label: 'FAQ',
      link: userRegion === 'USA' ? urls.faqUS : urls.faq,
    },
  ];

  return (
    <Box sx={styles.container}>
      <Hidden xsDown>
        <Box sx={styles.rowContainer}>
          <Box sx={styles.sectionContainer}>
            {footerSections.map(item => (
              <Box key={item.label} mb={{ xs: 1, sm: 1.5 }}>
                <Link
                  to={item.link || '#'}
                  onClick={item.onClick}
                  target={item.link ? '_blank' : undefined}>
                  {item.label}
                </Link>
              </Box>
            ))}
          </Box>
          <Box>
            <Box mb={7.5}>
              <Typography variant="subtitle1">Follow us</Typography>
              <Grid container spacing={4}>
                {socialSections.map(({ label, link, Icon }) => (
                  <Grid item xs={3} key={label}>
                    <Link to={link} target="_blank" rel="noopener noreferrer">
                      <Icon
                        sx={{
                          ...styles.icon,
                          color: theme => theme.palette.social[label],
                        }}
                      />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Hidden>
      <Box>
        {userRegion === 'USA' && (
          <Box sx={styles.nasbaContainer}>
            <Box
              component="img"
              src={nasbaLogo}
              sx={styles.nasbaLogo}
              alt="nasba logo"
            />
            <Typography sx={styles.nasbaText}>
              LumiQ is registered with the National Association of State Boards
              of Accountancy (NASBA) as a sponsor of continuing professional
              education on the National Registry of CPE Sponsors. State boards
              of accountancy have the final authority on the acceptance of
              individual courses for CPE credit. Complaints regarding registered
              sponsors may be submitted to the National Registry of CPE Sponsors
              through its website:{' '}
              <a href="https://www.nasbaregistry.org/">www.nasbaregistry.org</a>
              <br />
              LumiQ Inc. Sponsor ID Numbers: NASBA (146039), New York (002996),
              Texas (010643), California (RRS-038-0822), Florida (0009257),
              Nebraska (S22-08)
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const socialSections = [
  {
    Icon: LinkedIn,
    label: 'linkedin',
    link: urls.social.linkedin,
  },
  {
    Icon: Facebook,
    label: 'facebook',
    link: urls.social.facebook,
  },
  {
    Icon: Instagram,
    label: 'instagram',
    link: urls.social.instagram,
  },
  {
    Icon: X,
    label: 'x',
    link: urls.social.x,
  },
];

const styles = {
  container: theme => ({
    alignItems: 'flex-start',
    borderTop: `${theme.typography.pxToRem(2)} solid ${theme.palette.blueBlackLight}`,
    mt: 6,
    mx: 'auto',
    maxWidth: theme.typography.pxToRem(1440),
    pt: 6,
    px: 5,
    pb: 9,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      p: 2,
      pb: 5,
      backgroundColor: theme.palette.grayLight,
    },
  }),
  rowContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    mb: 4,
  },
  icon: {
    width: 40,
    height: 40,
  },
  nasbaContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    mb: 10,
    mt: 2.5,
  },
  nasbaText: theme => ({
    '& a': {
      color: 'blue',
    },
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.caption,
    },
  }),
  nasbaLogo: {
    height: 'auto',
    mr: 2.5,
    width: theme => theme.typography.pxToRem(90),
  },
};
