import { ChapterStatus } from '@/constants';
import { Chapter, UserChapter, UserQuiz } from '@/types';

export const getChapterStatus = ({
  chapter,
  userChapter,
  userQuiz,
}: {
  chapter: Chapter;
  userChapter: UserChapter;
  userQuiz: UserQuiz[] | undefined;
}) => {
  const isQuizCompleted = userQuiz?.every(quiz => quiz.completed);
  const isChapterCompleted = !!userChapter?.completed;
  const isChapterStarted = !!userChapter?.progress;
  const isChapterVerifiable = !!chapter?.isVerifiable;
  if (isQuizCompleted) {
    return ChapterStatus.VERIFIED;
  }
  if (isChapterCompleted && isChapterVerifiable) {
    return ChapterStatus.QUIZ_READY;
  }
  if (isChapterCompleted && !isChapterVerifiable) {
    return ChapterStatus.COMPLETED;
  }
  if (isChapterStarted) {
    return ChapterStatus.ON_GOING;
  }
  return ChapterStatus.NONE;
};
