import { useDispatch, useSelector } from 'react-redux';

import { ChapterStatus } from '@/constants';
import { EVENT_CONSTANTS } from '@/services';
import {
  playerThunks,
  selectActiveChapterId,
  selectIsEpisodeRedeemRequired,
  selectIsPlayerPlaying,
  userEpisodesThunks,
} from '@/store';

import { EpisodeChapterTile as EpisodeChapterTileComponent } from './EpisodeChapterTile.component';

/*
  TODO: what should the checkmark icon do when clicked? right now it opens the chapter modal. should it send you to the Tracker page? what about for nasba users?
  TODO: Instead of highlight logic being "highlight all QUIZ_READY + the next NONE/IN_PROGRESS",
  should it be "hightlight all QUIZ_READY ready + all IN_PROGRESS, but if there are none highlight the next NONE"?
*/
export const EpisodeChapterTile = ({
  index,
  chapter,
  userChapter,
  isPrimaryChapter,
  referrer,
}) => {
  const dispatch = useDispatch();

  const isPlayerPlaying = useSelector(selectIsPlayerPlaying);
  const activeChapterId = useSelector(selectActiveChapterId);
  const isEpisodeRedeemRequired = useSelector(selectIsEpisodeRedeemRequired);

  const chapterStatus = userChapter?.status;
  const isChapterVerifiable = chapter.isVerifiable;

  const progressPercentage =
    ((userChapter?.progress?.playedDuration ?? 0) / chapter.duration) * 100;

  const onTileClick = () => {
    // TODO: add this when modal is implemented
    console.log('open chapter modal');
  };

  const onIconClick = event => {
    event.stopPropagation();
    if (isEpisodeRedeemRequired && isChapterVerifiable && index > 0) {
      dispatch(
        userEpisodesThunks.initiateRedemption({ episodeId: chapter.episodeId }),
      );
    } else if (chapterStatus === ChapterStatus.QUIZ_READY) {
      // TODO: add this when modal is implemented
      console.log('open chapter modal');
    } else {
      dispatch(
        playerThunks.chapterPlayPressed({
          activeChapter: {
            episodeId: chapter.episodeId,
            chapterId: chapter.chapterId,
          },
          location: {
            ...(referrer?.location ?? {}),
            button: EVENT_CONSTANTS.BUTTON.PLAY_CHAPTER,
          },
          referrer,
        }),
      );
    }
  };

  return (
    <EpisodeChapterTileComponent
      index={index}
      onIconClick={onIconClick}
      onTileClick={onTileClick}
      chapterType={chapter.type}
      chapterName={chapter.name}
      chapterStatus={chapterStatus}
      chapterProgressPercentage={progressPercentage}
      isEpisodeRedeemRequired={isEpisodeRedeemRequired}
      isChapterPlaying={
        isPlayerPlaying && activeChapterId === chapter.chapterId
      }
      isChapterVerifiable={isChapterVerifiable}
      isPrimaryChapter={isPrimaryChapter}
    />
  );
};
