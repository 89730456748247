import { fetchPDHoursRequest } from '@/requests';
import { postgrestApi } from '@/store/postgrest-api';
import { PostgrestApiTags } from '@/store/store.constants';
import { Credit } from '@/types';

import { transformCredit } from './tracker-pg-api.utils';

type FetchPDHoursParams = {
  dateRange: {
    fromDate: string;
    toDate: string;
  };
};

export const trackerPostgrestApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchPDHours: builder.query<Credit[], FetchPDHoursParams>({
      query: fetchPDHoursRequest,
      transformResponse: (response: unknown[]) => response.map(transformCredit),
      providesTags: [PostgrestApiTags.PD_HOURS],
    }),
  }),
});

export const { useFetchPDHoursQuery } = trackerPostgrestApi;
