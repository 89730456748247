import { useSelector } from 'react-redux';

import { selectUserRegion } from '@/store';

import { CPDTracker, CPETracker } from './containers';

export const Tracker = () => {
  const userRegion = useSelector(selectUserRegion);
  return userRegion === 'CAN' ? <CPDTracker /> : <CPETracker />;
};
