import { Box, Typography } from '@mui/material';
import { useState } from 'react';

import { Link } from '@/containers';

import { SpeakerBioModal } from '../';

// TODO: make this collapsible
export const Speakers = ({ episode }) => {
  const [showSpeakerBio, setShowSpeakerBio] = useState(false);

  const { speakers, speakerBio } = episode;

  if (!speakers?.length) return null;

  const onSpeakerBioClick = () => setShowSpeakerBio(true);

  return (
    <>
      <Box>
        <Typography variant="h3">Speakers</Typography>
        <Box sx={styles.listContainer}>
          {speakers.map((speaker, index) => (
            <Box key={index} sx={styles.speakerContainer}>
              <Link to={speaker.companyUrl} target="_blank">
                <Box
                  component="img"
                  alt="company logo"
                  src={speaker.companyLogo}
                  sx={styles.companyLogo}
                />
              </Link>
              <Box sx={styles.speakerText}>
                <Link onClick={onSpeakerBioClick}>
                  <Typography variant="h4">{speaker.name}</Typography>
                </Link>
                <Typography variant="body2">{speaker.title}</Typography>
                <Link to={speaker.companyUrl} target="_blank">
                  <Typography variant="subtitle1">
                    {speaker.companyName}
                  </Typography>
                </Link>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <SpeakerBioModal
        isOpened={showSpeakerBio}
        setOpened={setShowSpeakerBio}
        speakerBio={speakerBio}
      />
    </>
  );
};

const styles = {
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  speakerContainer: {
    display: 'flex',
    flexDirection: 'row',
    my: 1.5,
  },
  speakerText: {
    display: 'flex',
    flexDirection: 'column',
    ml: 1,
  },
  companyLogo: {
    width: 64,
    height: 64,
    objectFit: 'cover',
    borderRadius: 2,
  },
};
