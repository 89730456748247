import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Box } from '@mui/material';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import {
  APP_LAYOUT_PX,
  BookmarkedEpisodesCarousel,
  CategoriesCarousel,
  CategoryEpisodesCarousel,
  FeaturedEpisodesBannerSlide,
  NewEpisodesCarousel,
  OrganizationPlaylistCarousel,
  RecommendedEpisodesCarousel,
  TrendingEpisodesCarousel,
} from '@/containers';
import { selectUserRegion, useFetchCategoriesQuery } from '@/store';

export const Discover = () => {
  const userRegion = useSelector(selectUserRegion);

  const { data = [], isFetching } = useFetchCategoriesQuery({
    region: userRegion,
  });

  // Split categories into special features and the rest (if feature is turned on)
  const discoverReorder = useFeatureIsOn('discover-reorder');
  const [specialFeatures, ...categories] = discoverReorder
    ? [...data]?.sort((a, b) => (a.name === 'Special Features') * -1)
    : [null, ...data];
  const isRecommendedEpisodesEnabled = useFeatureIsOn(
    'episode-recommendations',
  );

  return (
    <Box>
      <Box sx={{ mb: 3, mx: R.map(R.negate, APP_LAYOUT_PX) }}>
        <FeaturedEpisodesBannerSlide />
      </Box>
      <Box mb={3}>
        <CategoriesCarousel />
      </Box>
      {specialFeatures && (
        <Box mb={3}>
          <CategoryEpisodesCarousel category={specialFeatures} />
        </Box>
      )}
      {isRecommendedEpisodesEnabled && (
        <Box mb={3}>
          <RecommendedEpisodesCarousel />
        </Box>
      )}
      <Box mb={3}>
        <TrendingEpisodesCarousel />
      </Box>
      <Box
        sx={{
          backgroundColor: 'grayLight',
          mx: R.map(R.negate, APP_LAYOUT_PX),
          px: 6,
          py: 3,
          mb: 3,
        }}>
        <NewEpisodesCarousel />
      </Box>
      <Box mb={3}>
        <BookmarkedEpisodesCarousel />
      </Box>
      <Box mb={3}>
        <OrganizationPlaylistCarousel />
      </Box>
      {!isFetching &&
        categories?.map(category => (
          <Box mt={4} key={category.id}>
            <CategoryEpisodesCarousel category={category} />
          </Box>
        ))}
    </Box>
  );
};
