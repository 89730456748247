import { Box } from '@mui/material';

import { PLAN_OPTIONS } from './constants';
import { PlanOption } from './PlanOption';

export const PlanOptions = ({ onPersonalPlan }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: 3,
      }}>
      {PLAN_OPTIONS.map(option => (
        <PlanOption
          key={option.name}
          option={option}
          onPersonalPlan={onPersonalPlan}
        />
      ))}
    </Box>
  );
};
