import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { ListenRejectedReason, Modals, Routes } from '@/constants';
import { iconAccess, iconAudio, iconBalance, iconStats } from '@/images';
import { selectActiveModalParams, selectUserRegion, uiActions } from '@/store';

import { AppModal } from '../AppModal';

export const InvalidPlanModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reason } = useSelector(selectActiveModalParams) || {};
  const region = useSelector(selectUserRegion);

  return (
    <AppModal modalName={Modals.INVALID_PLAN} maxWidth="md">
      <Box sx={styles.container}>
        <Typography variant="h1" mt={2}>
          {reason === ListenRejectedReason.FREE_TRIAL_ENDED
            ? 'Your Free Trial Has Ended'
            : 'You Do Not Have a Valid Subscription'}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Purchase a subscription now to unlock 600+ episodes of verifiable{' '}
          {region === 'USA' ? 'CPE' : 'CPD'}
        </Typography>
        <Box mt={3}>
          <Box sx={styles.perks}>
            <img src={iconAccess} alt="Access" style={{ maxWidth: 40 }} />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Unlimited access
            </Typography>
          </Box>
          <Box sx={styles.perks}>
            <img src={iconAudio} alt="Access" style={{ maxWidth: 40 }} />
            <Typography variant="body1" sx={{ ml: 1 }}>
              700+ Episodes of Content
            </Typography>
          </Box>
          <Box sx={styles.perks}>
            <img src={iconBalance} alt="Access" style={{ maxWidth: 40 }} />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Ethics Episodes
            </Typography>
          </Box>
          <Box sx={styles.perks}>
            <img src={iconStats} alt="Access" style={{ maxWidth: 40 }} />
            <Typography variant="body1" sx={{ ml: 1 }}>
              {region === 'USA' ? 'CPE' : 'CPD'}&nbsp;tracking
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 2, width: 160 }}>
          <Button
            variant="primary"
            onClick={() => {
              navigate(Routes.PRICING);
              dispatch(uiActions.closeActiveModal());
            }}
            label={
              reason === ListenRejectedReason.FREE_TRIAL_ENDED
                ? 'Upgrade Now'
                : 'View Pricing'
            }
          />
        </Box>
        <Box sx={{ mt: 2, width: 160 }}>
          <Button
            variant="tertiary"
            label="Close"
            onClick={() => dispatch(uiActions.closeActiveModal())}
          />
        </Box>
      </Box>
    </AppModal>
  );
};

const styles = {
  container: theme => ({
    width: '100%',
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  }),
  perks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
    mb: 2,
  },
};
