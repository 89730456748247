import * as R from 'ramda';

import { Chapter, ChapterUrl, Region } from '@/types';
import { camelCaseKeys } from '@/utils';

export const transformChapter = (chapter: unknown): Chapter =>
  R.pipe(camelCaseKeys, c => ({
    chapterId: c.firestoreChapterId,
    firestoreChapterId: c.firestoreChapterId,
    episodeId: c.episodeId,
    episodeRevisionId: c.episodeRevisionId,
    postgrestChapterId: c.chapterId,
    name: c.name,
    description: c.description,
    formattedDescription: c.formattedDescription,
    duration: c.duration,
    type: c.type,
    resources: c.resources,
    chapterPosition: c.chapterPosition,
    isTrailer: c.isTrailer,
    isVerifiable: c.isVerifiable,
    isEthics: c.isEthics,
    isArchived: c.isArchived,
    quizIds: c.quizIds,
    region: (c.region === 'usa' ? 'USA' : 'CAN') as Region,
  }))(chapter);

export const transformChapterUrl = (
  chapterUrl: any,
  region: Region,
): ChapterUrl =>
  R.pipe(camelCaseKeys, c => ({
    chapterId: c.contentId,
    episodeId: c.episodeId,
    file:
      region === 'USA'
        ? {
            url: c.nasbaFile.url ?? c.file.url,
            storageUrl: c.nasbaFile.storageUrl ?? c.file.storageUrl,
          }
        : {
            url: c.file.url,
            storageUrl: c.file.storageUrl,
          },
  }))(chapterUrl);
