import { AddCircle } from '@mui/icons-material';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Modals, Routes } from '@/constants';
import {
  selectIsFsmPlayer,
  uiActions,
  useFetchPDHoursQuery,
  useFetchTriennialSummaryQuery,
  useFetchUserQuizzesQuery,
} from '@/store';
import { useFetchSessionQuizzesQuery } from '@/store/player-fsm-api';

import {
  Certificates,
  Overview,
  Summary,
  TrackerFooter,
} from '../../components';
import { selectCPDTrackerCurrentDateRange } from '../../utils';

export const CPDTracker = () => {
  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState();

  const { data: pdHours, isLoading: isPDHoursLoading } = useFetchPDHoursQuery(
    {
      dateRange,
    },
    {
      skip: !dateRange,
    },
  );
  const {
    data: { triennialSummary, region: province } = {},
    isLoading: isSummaryLoading,
    isSuccess: isSummarySuccess,
  } = useFetchTriennialSummaryQuery();

  const isFSMPlayerUser = useSelector(selectIsFsmPlayer);

  const { incompleteQuizCount: quizCountOld } = useFetchUserQuizzesQuery(
    undefined,
    {
      skip: isFSMPlayerUser !== false,
      selectFromResult: result => ({
        ...result,
        incompleteQuizCount: result.data?.filter(i => !i.completed).length,
      }),
    },
  );

  const { incompleteQuizCount: quizCountNew } = useFetchSessionQuizzesQuery(
    undefined,
    {
      skip: !isFSMPlayerUser,
      selectFromResult: result => ({
        ...result,
        incompleteQuizCount: result.data?.reduce(
          (acc, e) => acc + e.availableQuizCount,
          0,
        ),
      }),
    },
  );

  const incompleteQuizCount = isFSMPlayerUser ? quizCountNew : quizCountOld;

  const navigate = useNavigate();

  useEffect(() => {
    if (!isSummarySuccess) return;
    setDateRange(selectCPDTrackerCurrentDateRange(province));
  }, [isSummarySuccess, province]);

  if (isPDHoursLoading || isSummaryLoading || !dateRange) {
    return <CircularProgress />;
  }

  const yearlyRequirement = triennialSummary
    ? triennialSummary?.triennialRequirement / 3
    : null;

  const onRemainingQuizzesClick = () =>
    navigate(`${Routes.MY_LUMIQ}?tab=quizzes`);

  const onAddHoursClick = () => {
    dispatch(uiActions.setActiveModal({ name: Modals.TRACKER_ADD_EDIT }));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h1">CPD Tracker</Typography>
            <Box sx={{ display: 'flex' }}>
              {incompleteQuizCount > 0 && (
                <Box sx={{ pr: 2, display: { xs: 'none', md: 'inline' } }}>
                  <Button
                    onClick={onRemainingQuizzesClick}
                    variant="gradientPrimary"
                    fullWidth={false}
                    label={`${incompleteQuizCount} Quiz${incompleteQuizCount > 1 ? 'zes' : ''} Remaining`}
                  />
                </Box>
              )}
              <Button
                onClick={onAddHoursClick}
                fullWidth={false}
                startIcon={<AddCircle />}
                label={'Add Hours'}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Overview
                yearlyRequirement={yearlyRequirement}
                credits={pdHours}
                dateRange={dateRange}
                setDateRange={setDateRange}
                province={province}
              />
            </Grid>
            {!!triennialSummary && (
              <Grid item xs={12}>
                <Summary
                  yearlyRequirement={yearlyRequirement}
                  summary={triennialSummary}
                  province={province}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Certificates credits={pdHours} dateRange={dateRange} />
        </Grid>
        {!!province && (
          <Grid item xs={12}>
            <TrackerFooter />
          </Grid>
        )}
      </Grid>
    </>
  );
};
