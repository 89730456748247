import { Box } from '@mui/material';

import { ChapterStatus, EpisodeStatus } from '@/constants';

export const CircleStatusIcon = ({
  status = EpisodeStatus.NONE,
  sx = { width: 10 },
}) => (
  <Box
    sx={[
      styles.root,
      {
        width: sx.width,
        height: sx.width,
      },
      [
        ChapterStatus.COMPLETED,
        ChapterStatus.VERIFIED,
        EpisodeStatus.COMPLETED,
      ].includes(status) && styles.fullGreen,
      [
        ChapterStatus.QUIZ_READY,
        EpisodeStatus.QUIZ_READY,
        EpisodeStatus.ASSESSMENT_READY,
      ].includes(status) && styles.fullBlue,
      [ChapterStatus.ON_GOING, EpisodeStatus.ON_GOING].includes(status) &&
        styles.halfBlue,
    ]}
  />
);

const styles = {
  root: {
    borderRadius: '50%',
    backgroundColor: 'blueBlackLight',
    mr: 1,
  },
  fullGreen: {
    backgroundColor: 'green',
  },
  fullBlue: {
    backgroundColor: 'blue400',
  },
  halfBlue: {
    backgroundImage: theme =>
      `linear-gradient(90deg, ${theme.palette.blue400} 50%, transparent 50%)`,
  },
};
