import * as R from 'ramda';

import { LUMIQ_ORGANIZATION_NAME } from '@/constants';

export const sortLearningPaths = lps => {
  if (!lps) return {};

  const isOrgLp = lp => lp.organizationName !== LUMIQ_ORGANIZATION_NAME;
  const orgLps = lps.filter(isOrgLp);
  const universalLps = lps.filter(lp => !isOrgLp(lp));

  return {
    ...R.groupBy(R.prop('organizationName'))(orgLps),
    ...R.groupBy(R.prop('category'))(universalLps),
  };
};

export const calculateDurationLeft = ({ lps, userEpisodes }) => {
  if (!lps) return undefined;

  return lps.map(lp => ({
    ...lp,
    durationPlayed: lp.episodes.reduce(
      (sum, episode) =>
        sum + (userEpisodes[episode.episodeId]?.playedDuration || 0),
      0,
    ),
  }));
};

export const getCategoryCopy = ({ category, userRegion }) => {
  if (userRegion === 'USA' && category === 'Finance & Accounting') {
    return 'Finance, Accounting & Tax';
  }

  return category;
};
