export const ChapterStatus = {
  NONE: 'none',
  ON_GOING: 'onGoing',
  QUIZ_READY: 'quizReady',
  VERIFIED: 'verified',
  COMPLETED: 'completed',
} as const;

export const FsmChapterStatus = {
  READY: 'ready',
  ON_GOING: 'on_going',
  QUIZ_READY: 'quiz_ready',
  VERIFIED: 'verified',
  COMPLETED: 'completed',
} as const;
