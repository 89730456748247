import { Checkbox, FormControlLabel, Typography } from '@mui/material';

export const LabelCheckbox = ({ checked, onCheck, title }) => {
  return (
    <FormControlLabel
      sx={styles.checkboxContainer}
      value={checked}
      label={
        <Typography
          variant="subtitle2"
          sx={{
            ...styles.defaultFont,
            ...(checked ? styles.selected : styles.unselected),
          }}>
          {title}
        </Typography>
      }
      labelPlacement={'end'}
      control={
        <Checkbox
          sx={styles.checkbox}
          checked={checked}
          onChange={() => onCheck(!checked)}
        />
      }
    />
  );
};

const styles = {
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    userSelect: 'none',
  },
  selected: {
    color: 'primary.main',
  },
  unselected: {
    color: 'grayDark',
  },
  defaultFont: {
    '&:hover': {
      color: 'primary.main',
    },
  },
  checkbox: {
    padding: 0.5,
    '&.MuiCheckbox-root': {
      color: 'grayDark',
    },
    '&.Mui-checked': {
      color: 'primary.main',
    },
  },
};
