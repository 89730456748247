import { Facebook, Instagram, LinkedIn, X } from '@mui/icons-material';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthPageWrapper, Button } from '@/components';
import { emailRegex, Routes, urls } from '@/constants';
import { Link } from '@/containers';
import { check } from '@/images';
import { useSendResetPasswordEmailMutation } from '@/store';

export const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const navigate = useNavigate();

  const [sendResetPasswordEmail, { isLoading }] =
    useSendResetPasswordEmailMutation();

  const onSubmit = async () => {
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    const { error: requestError } = await sendResetPasswordEmail({
      email,
    });

    if (requestError && requestError?.status !== 400) {
      setError('Something went wrong. Please try again or contact support.');
    } else {
      setIsSubmitted(true);
    }
  };

  return (
    <AuthPageWrapper>
      {isSubmitted ? (
        <>
          <Box
            component="img"
            src={check}
            alt="checkmark"
            sx={{ width: 60, mb: 1 }}
          />
          <Typography variant="h2">Email Sent!</Typography>
          <Typography variant="body2" color="gray" sx={{ my: 2 }}>
            An email with instructions to reset your password has been sent to{' '}
            {email}. If you do not receive the email, confirm {email} is the
            email account you registered with when you signed up for LumiQ.
          </Typography>
          <Typography variant="body2" color="gray" sx={{ my: 2 }}>
            While you wait for our email, check us out on social media!
          </Typography>
          <Grid container spacing={4}>
            {socialSections.map(({ label, link, Icon }) => (
              <Grid item xs={3} key={label}>
                <Link to={link} target="_blank" rel="noopener noreferrer">
                  <Icon
                    sx={{
                      ...styles.icon,
                      color: theme => theme.palette.social[label],
                    }}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
          <Button
            onClick={() => navigate(Routes.LOGIN)}
            variant="gradientPrimary"
            label="Back to Login"
            skinny={false}
            sx={{ mt: 2, mx: 'auto' }}
          />
        </>
      ) : (
        <>
          <Typography variant="h2">Forgot Password</Typography>
          <Typography variant="body2" color="gray" sx={{ my: 2 }}>
            Enter the email you used to sign up below and we'll send you a link
            to reset your password.
          </Typography>
          <TextField
            id="email"
            variant="outlined"
            fullWidth
            size="small"
            placeholder={'Email'}
            onChange={({ target: { value } }) => setEmail(value)}
            error={!!error}
          />
          {error && (
            <Typography color="red" variant="body2" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
          <Button
            disabled={isLoading}
            onClick={onSubmit}
            variant="gradientPrimary"
            label="Send me instructions"
            skinny={false}
            sx={{ mt: 2 }}
          />
        </>
      )}
    </AuthPageWrapper>
  );
};

const styles = {
  icon: {
    width: 40,
    height: 40,
  },
};

const socialSections = [
  {
    Icon: LinkedIn,
    label: 'linkedin',
    link: urls.social.linkedin,
  },
  {
    Icon: Facebook,
    label: 'facebook',
    link: urls.social.facebook,
  },
  {
    Icon: Instagram,
    label: 'instagram',
    link: urls.social.instagram,
  },
  {
    Icon: X,
    label: 'x',
    link: urls.social.x,
  },
];
