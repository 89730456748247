import {
  Bookmark,
  BookmarkBorder,
  ConfirmationNumberRounded,
  Feedback,
  ListAlt,
} from '@mui/icons-material';
import { Box } from '@mui/material';

import { Button, ButtonVariants } from '@/components';
import { EpisodeStatus } from '@/constants';
import { PlayArrowFilledIcon, PlayArrowOutlinedIcon } from '@/icons';

const PrimaryButtons = {
  PLAY: 'play',
  REDEEM: 'redeem',
  ASSESSMENT: 'assessment',
  FEEDBACK: 'feedback',
};

export const EpisodeControlButtons = ({
  region,
  isEpisodeRedeemRequired,
  episodeStatus,
  onRedeem,
  onPlayNow,
  isBookmarked,
  isBookmarking,
  onToggleBookmark,
  onTrailer,
  onTakeAssessment,
  onGiveFeedback,
}) => {
  const primaryButton = getPrimaryButton({
    isEpisodeRedeemRequired,
    episodeStatus,
  });

  const playButtonText = getPlayButtonText(episodeStatus);

  return (
    <Box sx={{ display: 'flex', gap: 1, mt: 2, flexWrap: 'wrap' }}>
      <Box sx={{ display: 'flex', gap: 1, mr: 'auto' }}>
        {isEpisodeRedeemRequired ? (
          <Button
            variant={
              primaryButton === PrimaryButtons.REDEEM
                ? ButtonVariants.PRIMARY
                : ButtonVariants.SECONDARY
            }
            label="Redeem"
            fullWidth={false}
            skinny={false}
            onClick={onRedeem}
            startIcon={<ConfirmationNumberRounded />}
          />
        ) : (
          <Button
            variant={
              primaryButton === PrimaryButtons.PLAY
                ? ButtonVariants.PRIMARY
                : ButtonVariants.SECONDARY
            }
            label={playButtonText}
            fullWidth={false}
            skinny={false}
            onClick={onPlayNow}
            startIcon={<PlayArrowFilledIcon />}
          />
        )}
        <Button
          variant={ButtonVariants.TERTIARY}
          label="Preview"
          fullWidth={false}
          skinny={false}
          onClick={onTrailer}
          startIcon={<PlayArrowOutlinedIcon />}
        />
        <Button
          variant={ButtonVariants.TERTIARY}
          label="Bookmark"
          fullWidth={false}
          skinny={false}
          onClick={onToggleBookmark}
          disabled={isBookmarking}
          startIcon={isBookmarked ? <Bookmark /> : <BookmarkBorder />}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>
        {region === 'USA' && (
          <Button
            variant={
              primaryButton === PrimaryButtons.ASSESSMENT
                ? ButtonVariants.PRIMARY
                : ButtonVariants.SECONDARY
            }
            label={
              episodeStatus === EpisodeStatus.VERIFIED
                ? 'View Assessment'
                : 'Take Assessment'
            }
            fullWidth={false}
            skinny={false}
            onClick={onTakeAssessment}
            startIcon={<ListAlt />}
          />
        )}
        {episodeStatus === EpisodeStatus.VERIFIED && (
          <Button
            variant={
              primaryButton === PrimaryButtons.FEEDBACK
                ? ButtonVariants.PRIMARY
                : ButtonVariants.TERTIARY
            }
            label="Give Feedback"
            fullWidth={false}
            skinny={false}
            onClick={onGiveFeedback}
            startIcon={<Feedback />}
          />
        )}
      </Box>
    </Box>
  );
};

const getPrimaryButton = ({ isEpisodeRedeemRequired, episodeStatus }) => {
  if (isEpisodeRedeemRequired) return PrimaryButtons.REDEEM;
  if (episodeStatus === EpisodeStatus.VERIFIED) return PrimaryButtons.FEEDBACK;
  if (episodeStatus === EpisodeStatus.ASSESSMENT_READY)
    return PrimaryButtons.ASSESSMENT;
  return PrimaryButtons.PLAY;
};

const getPlayButtonText = episodeStatus => {
  if (
    episodeStatus === EpisodeStatus.VERIFIED ||
    episodeStatus === EpisodeStatus.COMPLETED
  )
    return 'Play Again';
  if (!episodeStatus || episodeStatus === EpisodeStatus.NONE) return 'Play Now';
  return 'Continue';
};
