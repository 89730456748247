import { useEffect, useRef, useState } from 'react';

export const useDebouncedCallback = (
  callback: (...args: any[]) => void,
  delay?: number,
) => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (...args: any[]) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const useDebouncedValue = <T = string | number>(
  value: T,
  delay?: number,
) => {
  const [debouncedValue, setDebouncedValue] = useState<T>();
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
};
