import { createSvgIcon } from '@mui/material/utils';

export const LumiQPictureMarkIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 12C20 6.48549 15.5204 2 9.98682 2C4.45323 2 0 6.48549 0 12C0 17.5145 4.47958 22 9.98682 22C11.5152 22 12.9381 21.657 14.2292 21.0501L11.7523 18.5699C11.2516 18.9129 10.6192 19.124 9.96047 19.124C8.37945 19.124 7.03557 17.9631 6.77207 16.4591V8.12137C6.77207 6.35356 8.19499 4.90237 9.96047 4.90237C11.7523 4.90237 13.1752 6.35356 13.1752 8.12137V9.96834H11.0672C10.8564 9.96834 10.6983 10.1266 10.6983 10.3377C10.6983 10.5488 10.8564 10.7071 11.0672 10.7071H13.1752V11.7098H11.0672C10.8564 11.7098 10.6983 11.8681 10.6983 12.0792C10.6983 12.2902 10.8564 12.4485 11.0672 12.4485H13.1489V13.4512H11.0672C10.8564 13.4512 10.6983 13.6095 10.6983 13.8206C10.6983 14.0317 10.8564 14.19 11.0672 14.19H13.1489V16.5646C13.0962 16.7757 13.0171 17.0396 12.8854 17.3034L15.7576 20.1794C18.3136 18.3588 20 15.3773 20 12Z"
      fill="white"
    />
    <path
      d="M20 12C20 6.48549 15.5204 2 9.98682 2C4.45323 2 0 6.48549 0 12C0 17.5145 4.47958 22 9.98682 22C11.5152 22 12.9381 21.657 14.2292 21.0501L11.7523 18.5699C11.2516 18.9129 10.6192 19.124 9.96047 19.124C8.37945 19.124 7.03557 17.9631 6.77207 16.4591V8.12137C6.77207 6.35356 8.19499 4.90237 9.96047 4.90237C11.7523 4.90237 13.1752 6.35356 13.1752 8.12137V9.96834H11.0672C10.8564 9.96834 10.6983 10.1266 10.6983 10.3377C10.6983 10.5488 10.8564 10.7071 11.0672 10.7071H13.1752V11.7098H11.0672C10.8564 11.7098 10.6983 11.8681 10.6983 12.0792C10.6983 12.2902 10.8564 12.4485 11.0672 12.4485H13.1489V13.4512H11.0672C10.8564 13.4512 10.6983 13.6095 10.6983 13.8206C10.6983 14.0317 10.8564 14.19 11.0672 14.19H13.1489V16.5646C13.0962 16.7757 13.0171 17.0396 12.8854 17.3034L15.7576 20.1794C18.3136 18.3588 20 15.3773 20 12Z"
      fill="currentColor"
    />
  </svg>,
  'LumiQPictureMark',
);
