export const CheckBoxIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
      fill="white"
    />
    <path
      d="M16.2702 7L15.4054 7.91153C13.5 9.92394 12.0318 11.6113 10.2862 13.4946L8.51193 11.9128L7.57711 11.0817L6 13.0456L6.93483 13.8767L9.58029 16.2359L10.4388 17L11.2337 16.1622C13.4292 13.8433 14.9897 11.994 17.1351 9.72785L18 8.81631L16.2702 7Z"
      fill="#3784C3"
    />
  </svg>
);
