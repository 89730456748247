import { Alert, Typography } from '@mui/material';

export const WarningBanners = ({ episode }) => {
  return (
    <>
      {episode.isArchived && (
        <Alert severity="warning" sx={{ my: 2 }}>
          <Typography variant="select">
            This episode has been archived
          </Typography>
        </Alert>
      )}
    </>
  );
};
