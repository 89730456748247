import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { EpisodeGrid } from '@/components';
import { EVENT_CONSTANTS } from '@/services';
import {
  selectAssessmentReadyEpisodeIds,
  selectUserEpisodes,
  selectUserRegion,
  useFetchEpisodesQuery,
} from '@/store';

import { EmptyView } from '../EmptyView';

export const AssessmentReady = () => {
  const userRegion = useSelector(selectUserRegion);

  const { isLoading } = useSelector(selectUserEpisodes);
  const assessmentReadyEpisodeIds = useSelector(
    selectAssessmentReadyEpisodeIds,
  );

  useFetchEpisodesQuery(
    {
      episodeIds: assessmentReadyEpisodeIds,
      region: userRegion,
      includeArchived: true,
    },
    {
      skip: !assessmentReadyEpisodeIds.length,
    },
  );

  return (
    <Box>
      {!isLoading && !assessmentReadyEpisodeIds.length && (
        <EmptyView
          title="No active assessments"
          subtitle="You do not have any pending assessments to complete."
        />
      )}
      {assessmentReadyEpisodeIds.length > 0 && (
        <EpisodeGrid
          episodeIds={assessmentReadyEpisodeIds}
          referrer={{
            page: EVENT_CONSTANTS.PAGE.MY_LUMIQ,
            section: EVENT_CONSTANTS.SECTION.ASSESSMENTS,
          }}
        />
      )}
    </Box>
  );
};
