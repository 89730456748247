import { Box, Typography } from '@mui/material';
import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { EpisodeGrid } from '@/components';
import { EpisodeStatus } from '@/constants';
import { FilterSort } from '@/containers';
import { magnifyingGlassIllustration } from '@/images';
import { EVENT_CONSTANTS } from '@/services';
import {
  selectUserRegion,
  useFetchCategoriesQuery,
  useFetchFieldsOfStudyQuery,
  useFetchLibraryEpisodesQuery,
  userEpisodesSelectors,
} from '@/store';

import { formatLibraryPageFilters } from './utils';

export const Library = () => {
  const [filterList, setFilterList] = useState(null);

  const userRegion = useSelector(selectUserRegion);
  const userEpisodes = useSelector(state =>
    userEpisodesSelectors.selectEntities(state),
  );

  const { data: categories } = useFetchCategoriesQuery({ region: userRegion });
  useFetchFieldsOfStudyQuery();

  const filters = filterList
    ? formatLibraryPageFilters({
        filterList,
        isNasba: userRegion === 'USA',
        categories: R.indexBy(R.prop('id'), categories),
      })
    : {};

  const { data: episodes, isLoading } = useFetchLibraryEpisodesQuery(
    {
      region: userRegion,
      filters,
    },
    {
      skip: !filterList,
    },
  );

  const episodesToShow = useMemo(() => {
    if (!filterList?.hidePlayed) return episodes;
    return episodes?.filter(
      e => userEpisodes?.[e.episodeId]?.status !== EpisodeStatus.VERIFIED,
    );
  }, [filterList?.hidePlayed, episodes, userEpisodes]);

  const isEmpty = R.isEmpty(episodesToShow);

  return (
    <Box>
      <Box sx={{ mb: 2.5 }}>
        <Typography variant="h1">Filter Library</Typography>
      </Box>
      <FilterSort onFilter={setFilterList} />
      {isLoading && <EpisodeGrid isLoading />}
      {!isLoading && isEmpty && (
        <Box sx={styles.noResults}>
          <img
            style={styles.image}
            src={magnifyingGlassIllustration}
            alt="no results"
          />
          <Typography variant="h3" sx={styles.noResultsText}>
            {'Seems like there are no results with your filter setup.'}
            <br />
            {'Change your filters and try again!'}
          </Typography>
        </Box>
      )}
      {!isLoading && !isEmpty && (
        <EpisodeGrid
          episodeIds={episodesToShow?.map(e => e.episodeId)}
          referrer={{ page: EVENT_CONSTANTS.PAGE.FILTER_LIBRARY }}
        />
      )}
    </Box>
  );
};

const styles = {
  noResults: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 5,
  },
  image: {
    maxWidth: 160,
  },
  noResultsText: {
    textAlign: 'center',
    margin: 4,
  },
};
