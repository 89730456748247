import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Box from '@mui/material/Box';
import { useRef, useState } from 'react';
import Slider from 'react-slick';

export const BannerSlide = ({ children }) => {
  let sliderRef = useRef();
  const [carouselPosition, setCarouselPosition] = useState(0);

  const onChange = index => {
    setCarouselPosition(index);
    sliderRef.slickGoTo(index);
  };

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 400,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: setCarouselPosition,
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Slider
        ref={slider => {
          sliderRef = slider;
        }}
        {...sliderSettings}>
        {children}
      </Slider>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {Array.from({ length: children.length }).map((_, index) => (
          <Box
            key={index}
            onClick={() => onChange(index)}
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              ml: 1,
              cursor: 'pointer',
              bgcolor: index === carouselPosition ? 'blueBlack' : 'grayBright',
            }}
          />
        ))}
      </Box>
    </Box>
  );
};
