import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppLoader } from '@/containers';
import { useCheckAccessTokenOnDocumentFocus } from '@/hooks';
import { checkForExistingTokens, selectSessionIsLoading } from '@/store';

export const SessionLoader = () => {
  const dispatch = useDispatch();
  const sessionIsLoading = useSelector(selectSessionIsLoading);

  useEffect(() => {
    dispatch(checkForExistingTokens());
  }, [dispatch]);

  useCheckAccessTokenOnDocumentFocus();

  return sessionIsLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
      <CircularProgress />
    </Box>
  ) : (
    <AppLoader />
  );
};
