import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { EpisodeGrid } from '@/components';
import { EVENT_CONSTANTS } from '@/services';
import {
  selectInProgressEpisodeIds,
  selectUserEpisodes,
  selectUserRegion,
  useFetchEpisodesQuery,
} from '@/store';

import { EmptyView } from '../EmptyView';

export const InProgress = () => {
  const userRegion = useSelector(selectUserRegion);

  const { isLoading } = useSelector(selectUserEpisodes);
  const inProgressEpisodeIds = useSelector(selectInProgressEpisodeIds);

  useFetchEpisodesQuery(
    {
      episodeIds: inProgressEpisodeIds,
      region: userRegion,
      includeArchived: true,
    },
    {
      skip: !inProgressEpisodeIds.length,
    },
  );

  return (
    <Box>
      {!isLoading && !inProgressEpisodeIds.length && (
        <EmptyView
          title="No episodes in progress"
          subtitle="You will see all your ongoing progress here once you start an episode."
        />
      )}
      {!isLoading && inProgressEpisodeIds.length > 0 && (
        <EpisodeGrid
          episodeIds={inProgressEpisodeIds}
          referrer={{
            page: EVENT_CONSTANTS.PAGE.MY_LUMIQ,
            section: EVENT_CONSTANTS.SECTION.IN_PROGRESS,
          }}
        />
      )}
    </Box>
  );
};
