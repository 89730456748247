import { createSvgIcon } from '@mui/material/utils';

export const ExploreIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 10.9C9.39 10.9 8.9 11.39 8.9 12C8.9 12.61 9.39 13.1 10 13.1C10.61 13.1 11.1 12.61 11.1 12C11.1 11.39 10.61 10.9 10 10.9ZM10 2C4.48 2 0 6.48 0 12C0 17.52 4.48 22 10 22C15.52 22 20 17.52 20 12C20 6.48 15.52 2 10 2ZM12.19 14.19L4 18L7.81 9.81L16 6L12.19 14.19Z"
      fill="currentColor"
    />
  </svg>,
  'Explore',
);
