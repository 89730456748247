import { useEffect, useState } from 'react';

export const useFocusListener = () => {
  const [hasFocus, setHasFocus] = useState(document.hasFocus());

  const setToFocus = () => setHasFocus(true);
  const setToBlur = () => setHasFocus(false);

  useEffect(() => {
    window.addEventListener('focus', setToFocus);
    window.addEventListener('blur', setToBlur);
    return () => {
      window.removeEventListener('focus', setToFocus);
      window.removeEventListener('blur', setToBlur);
    };
  }, []);

  return hasFocus;
};
