import { Close } from '@mui/icons-material';
import { Box, Dialog, IconButton, Typography, useTheme } from '@mui/material';

import { Markdown } from '@/components';

export const SpeakerBioModal = ({ isOpened, setOpened, speakerBio }) => {
  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpened}
      onClose={() => setOpened(false)}>
      <Box sx={{ py: 4, px: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
          <Typography variant="h1">Speakers</Typography>
          <IconButton onClick={() => setOpened(false)}>
            <Close />
          </IconButton>
        </Box>
        <Markdown sx={theme.typography.body2}>{speakerBio}</Markdown>
      </Box>
    </Dialog>
  );
};
