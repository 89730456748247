import { CloudDownload, MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

import { ButtonProgressSpinner } from '@/components';
import { CreditType } from '@/constants';

export const CertificateMenu = ({
  onDownload,
  isDownloading,
  onEdit,
  onDelete,
  credit,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const isLumiqCert = credit.type === CreditType.LUMIQ;
  const downloadDisabled =
    !credit.fileName || !credit.fileSize || !credit.fileUri || isDownloading;

  const handleClick = event => {
    event.stopPropagation();
    if (isLumiqCert) {
      onDownload();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleOnDownload = event => {
    handleClose(event);
    onDownload();
  };

  const handleOnEdit = event => {
    handleClose(event);
    onEdit();
  };

  const handleOnDelete = event => {
    handleClose(event);
    onDelete();
  };

  const menuButtons = [
    {
      key: 'edit',
      label: 'Edit Certificate',
      disabled: isLumiqCert, // just as a safety measure, should never get rendered anyways
      onClick: handleOnEdit,
    },
    {
      key: 'download',
      label: 'Download',
      disabled: downloadDisabled,
      onClick: handleOnDownload,
    },
    {
      key: 'delete',
      label: 'Delete',
      disabled: isLumiqCert, // just as a safety measure, should never get rendered anyways
      onClick: handleOnDelete,
    },
  ];

  return (
    <>
      {isLumiqCert ? (
        <IconButton onClick={handleClick} disabled={downloadDisabled}>
          {isDownloading ? (
            <ButtonProgressSpinner />
          ) : (
            <CloudDownload sx={styles.menuIcon} />
          )}
        </IconButton>
      ) : (
        <IconButton onClick={handleClick}>
          <MoreVert sx={styles.menuIcon} />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {menuButtons.map(button => (
          <MenuItem
            key={button.key}
            onClick={button.onClick}
            disabled={button.disabled}>
            {button.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const styles = {
  menuIcon: {
    color: 'blueBlack',
    fontSize: 20,
  },
};
