import { enqueueSnackbar, OptionsObject } from 'notistack';

const toast = (msg: string, options: OptionsObject = { variant: 'default' }) =>
  enqueueSnackbar(msg, { preventDuplicate: true, ...options });

export const SnackbarUtils = {
  success: (msg: string) => toast(msg, { variant: 'success' }),
  warning: (msg: string) => toast(msg, { variant: 'warning' }),
  info: (msg: string) => toast(msg, { variant: 'info' }),
  error: (msg: string) => toast(msg, { variant: 'error' }),
};
