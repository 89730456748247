import { useDispatch } from 'react-redux';

import { PageTabs } from '@/components';
import { technicalSearchActions } from '@/store';

export const CategoryOptionsDesktop = ({ categories, selectedCategory }) => {
  const dispatch = useDispatch();

  const onCategorySelect = (e, newVal) => {
    dispatch(technicalSearchActions.selectCategory(newVal));
  };
  return (
    <PageTabs
      tabs={categories.map(c => ({ label: c, value: c }))}
      selectedTab={selectedCategory}
      onChange={onCategorySelect}
    />
  );
};
