import { Modals } from '@/constants';

import { AppModal } from '../AppModal';
import { TrackerAddEditModalContent } from './TrackerAddEditModalContent';

export const TrackerAddEditModal = () => {
  return (
    <AppModal modalName={Modals.TRACKER_ADD_EDIT} fullWidth scroll="body">
      <TrackerAddEditModalContent />
    </AppModal>
  );
};
