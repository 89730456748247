import { Check, Close, Error } from '@mui/icons-material';
import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthPageWrapper, Button } from '@/components';
import { Routes } from '@/constants';
import { check } from '@/images';
import {
  useResetPasswordWithTokenMutation,
  useValidatePasswordResetTokenQuery,
} from '@/store';

import { PASSWORD_REQUIREMENTS } from './constants';

export const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const token = new URLSearchParams(location.search).get('token');

  const { data: isTokenValid, isLoading: isTokenValidationLoading } =
    useValidatePasswordResetTokenQuery({ token });

  const [resetPassword, { isLoading: isSubmitLoading }] =
    useResetPasswordWithTokenMutation();

  const onSubmit = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    const invalidRequirements = PASSWORD_REQUIREMENTS.filter(
      ({ validator }) => !validator(password),
    );
    if (invalidRequirements.length) {
      setError('Password does not meet minimum complexity requirements.');
      return;
    }

    const { data: passwordResetSuccessful, error: requestError } =
      await resetPassword({
        password,
        token,
      });
    if (requestError || !passwordResetSuccessful) {
      setError('Something went wrong. Please try again or contact support.');
      return;
    }

    setIsSubmitted(true);
  };

  return (
    <AuthPageWrapper>
      {isTokenValidationLoading && <CircularProgress sx={{ my: 12 }} />}
      {!isTokenValidationLoading && !isTokenValid && (
        <>
          <Error sx={{ color: 'red', width: 60, height: 60, mb: 1 }} />
          <Typography variant="h3">Invalid Token</Typography>
          <Typography variant="body2" color="gray" sx={{ my: 2 }}>
            This password reset link is invalid or has expired.
          </Typography>
          <Button
            onClick={() => navigate(Routes.FORGOT_PASSWORD)}
            variant="gradientPrimary"
            label="Resend Email"
            skinny={false}
            sx={{ mt: 1, mx: 'auto' }}
          />
        </>
      )}
      {!isTokenValidationLoading && isTokenValid && !isSubmitted && (
        <>
          <Typography variant="h2">Reset Password</Typography>
          <Typography variant="body2" color="gray" sx={{ my: 2 }}>
            Enter your new password below.
          </Typography>
          <TextField
            id="password"
            type="password"
            variant="outlined"
            fullWidth
            size="small"
            placeholder={'New Password'}
            onChange={({ target: { value } }) => {
              if (!isEditing) setIsEditing(true);
              setPassword(value);
            }}
            error={!!error}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              textAlign: 'left',
              my: 2,
              ml: 1,
            }}>
            <Typography variant="body2" color="grayDark">
              Your password must contain:
            </Typography>
            {PASSWORD_REQUIREMENTS.map((requirement, idx) => (
              <Box
                key={idx}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  mt: 1,
                }}>
                {!isEditing || requirement.validator(password) ? (
                  <Check
                    sx={{
                      fontSize: 12,
                      color: isEditing ? 'green' : 'grayDark',
                      mr: 1,
                    }}
                  />
                ) : (
                  <Close sx={{ fontSize: 12, color: 'red', mr: 1 }} />
                )}
                <Typography
                  variant="body2"
                  sx={{
                    color: !isEditing
                      ? 'grayDark'
                      : requirement.validator(password)
                        ? 'green'
                        : 'red',
                  }}>
                  {requirement.message}
                </Typography>
              </Box>
            ))}
          </Box>
          <TextField
            id="confirmPassword"
            type="password"
            variant="outlined"
            fullWidth
            size="small"
            placeholder={'Confirm New Password'}
            onChange={({ target: { value } }) => {
              if (!isEditing) setIsEditing(true);
              setConfirmPassword(value);
            }}
            error={!!error}
          />
          {error && (
            <Typography color="red" variant="body2" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
          <Button
            disabled={isSubmitLoading}
            onClick={onSubmit}
            variant="gradientPrimary"
            label="Confirm"
            skinny={false}
            sx={{ mt: 2 }}
          />
        </>
      )}
      {!isTokenValidationLoading && isTokenValid && isSubmitted && (
        <>
          <Box
            component="img"
            src={check}
            alt="checkmark"
            sx={{ width: 60, mb: 1 }}
          />
          <Typography variant="h3">Password Successfully Reset!</Typography>
          <Typography variant="body2" color="gray" sx={{ my: 2 }}>
            You can log in with your new password.
          </Typography>
          <Button
            onClick={() => navigate(Routes.LOGIN)}
            variant="gradientPrimary"
            label="Log In"
            skinny={false}
            sx={{ mt: 1, mx: 'auto' }}
          />
        </>
      )}
    </AuthPageWrapper>
  );
};
