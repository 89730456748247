import { Box, Hidden, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { Skeleton } from '@/components';
import { Routes } from '@/constants';
import { Link } from '@/containers';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  selectActiveChapterId,
  selectActiveEpisodeId,
  selectIsChangingChapter,
  selectUserRegion,
  useFetchChapterQuery,
  useFetchEpisodeQuery,
} from '@/store';
import { createRouteWithParams } from '@/utils';

export const ChapterInfo = ({ referrer }) => {
  const activeEpisodeId = useSelector(selectActiveEpisodeId);
  const activeChapterId = useSelector(selectActiveChapterId);
  const isChangingChapter = useSelector(selectIsChangingChapter);
  const region = useSelector(selectUserRegion);

  const { data: episode, isLoading: isEpisodeLoading } = useFetchEpisodeQuery({
    episodeId: activeEpisodeId,
    region,
  });
  const { data: chapter, isLoading: isChapterLoading } = useFetchChapterQuery({
    chapterId: activeChapterId,
    region,
  });

  const clickEpisodeDetails = location => {
    AnalyticsService.trackPlayerViewEpisode({
      chapter,
      episode,
      location: { ...referrer, ...location },
      referrer: {
        ...referrer,
        component: EVENT_CONSTANTS.COMPONENT.TRACK_PLAYER,
      },
    });
  };

  const isLoading = isChapterLoading || isEpisodeLoading || isChangingChapter;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.coverContainer}>
        {isLoading ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Link
            to={
              episode.hidden
                ? '#'
                : {
                    pathname: createRouteWithParams(Routes.EPISODE, {
                      episodeId: episode.episodeId,
                    }),
                  }
            }
            state={{
              referrer: {
                ...referrer,
                button: EVENT_CONSTANTS.EPISODE_IMAGE,
                component: EVENT_CONSTANTS.COMPONENT.TRACK_PLAYER,
              },
            }}
            onClick={() => {
              clickEpisodeDetails({
                button: EVENT_CONSTANTS.EPISODE_IMAGE,
                component: EVENT_CONSTANTS.COMPONENT.TRACK_PLAYER,
              });
            }}>
            <Box
              component="img"
              sx={styles.cover}
              src={`${episode.companyLogo}`}
              alt="company logo"
            />
          </Link>
        )}
      </Box>
      <Hidden mdDown>
        <Box sx={styles.infoContainer}>
          {isLoading ? (
            <>
              <Skeleton height={32} />
              <Skeleton height={24} width={120} />
            </>
          ) : (
            <>
              <Link
                color="white"
                to={
                  episode.hidden
                    ? '#'
                    : {
                        pathname: createRouteWithParams(Routes.EPISODE, {
                          episodeId: episode.episodeId,
                        }),
                      }
                }
                state={{
                  referrer: {
                    ...referrer,
                    button: EVENT_CONSTANTS.EPISODE_TITLE,
                    component: EVENT_CONSTANTS.COMPONENT.TRACK_PLAYER,
                  },
                }}
                onClick={() => {
                  clickEpisodeDetails({
                    button: EVENT_CONSTANTS.EPISODE_TITLE,
                    component: EVENT_CONSTANTS.COMPONENT.TRACK_PLAYER,
                  });
                }}>
                <Typography variant="h5" gutterBottom noWrap>
                  {episode.name}
                </Typography>
              </Link>
              <Typography variant="body3" noWrap>
                {chapter.isTrailer ? 'Trailer' : chapter.name}
              </Typography>
            </>
          )}
        </Box>
      </Hidden>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  coverContainer: theme => ({
    alignItems: 'center',
    display: 'flex',
    height: theme.typography.pxToRem(90),
    justifyContent: 'center',
    width: theme.typography.pxToRem(90),
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(66),
      width: theme.typography.pxToRem(66),
    },
  }),
  cover: theme => ({
    display: 'block',
    height: theme.typography.pxToRem(90),
    width: theme.typography.pxToRem(90),
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(66),
      width: theme.typography.pxToRem(66),
    },
  }),
  progress: {
    color: 'aqua',
    fontSize: theme => theme.typography.pxToRem(32),
  },
  infoContainer: theme => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    pl: 2.5,
    flexGrow: 1,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(66),
    },
  }),
};
