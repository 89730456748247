export const PostgrestApiTags = {
  USER_PROFILE: 'userProfile',
  EPISODE: 'episode',
  PLAN: 'plan',
  PD_HOURS: 'pdHours',
  PAYMENT_METHODS: 'paymentMethods',
  USER_TAGS: 'userTags',
  PLAYER_SESSIONS: 'playerSessions',
  SESSION_QUIZZES: 'sessionQuizzes',
  PLAYER_CONFIG: 'playerConfig',
  USER_REGISTRATION_STATE: 'userRegistrationState',
} as const;

export const NodeApiTags = {
  USER_EPISODES: 'userEpisodes',
  USER_CHAPTER_PROGRESS: 'userChapterProgress',
  USER_QUIZZES: 'userQuizzes',
  USER_REGION: 'userRegion',
  TRIENNIAL_SUMMARY: 'triennialSummary',
} as const;
