import { createSvgIcon } from '@mui/material/utils';

export const FlagIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5333 4.35294L12 2H0V22H2.66667V13.7647H10.1333L10.6667 16.1176H20V4.35294H12.5333Z"
      fill="currentColor"
    />
  </svg>,
  'Flag',
);
