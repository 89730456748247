import { Box, Grid } from '@mui/material';

import {
  totalCredits,
  totalEthicsCount,
  totalNonTechCount,
  totalTechCount,
} from '@/images';

import { DetailBox } from '../../components';

export const CPESummary = ({ credits }) => {
  const creditsTotal = {
    total: 0,
    ethics: 0,
    technical: 0,
    nonTechnical: 0,
  };

  if (credits?.length) {
    credits.forEach(pd => {
      creditsTotal.total += pd.credit;
      creditsTotal.ethics += pd.ethicsCredit;
      creditsTotal.technical += pd.technicalCredit;
      creditsTotal.nonTechnical += pd.credit - pd.technicalCredit;
    });

    creditsTotal.total = Math.round(creditsTotal.total * 10) / 10;
    creditsTotal.ethics = Math.round(creditsTotal.ethics * 10) / 10;
    creditsTotal.technical = Math.round(creditsTotal.technical * 10) / 10;
    creditsTotal.nonTechnical = Math.round(creditsTotal.nonTechnical * 10) / 10;
  }

  const isLoading = !credits;

  return (
    <Box>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}>
        <Grid item xs={6} s={6} md={3} sx={styles.gridCell}>
          <DetailBox
            title="Total Credits"
            count={creditsTotal?.total}
            img={totalCredits}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={6} s={6} md={3} sx={styles.gridCell}>
          <DetailBox
            title="Ethics"
            count={creditsTotal?.ethics}
            img={totalEthicsCount}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={6} s={6} md={3} sx={styles.gridCell}>
          <DetailBox
            title="Technical"
            count={creditsTotal?.technical}
            img={totalTechCount}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={6} s={6} md={3} sx={styles.gridCell}>
          <DetailBox
            title="Non-Technical"
            count={creditsTotal?.nonTechnical}
            img={totalNonTechCount}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  gridCell: {
    display: 'flex',
    justifyContent: 'center',
  },
};
