import {
  FormatOptions,
  LibraryUrlSearchParams,
  NASBA_CATEGORY_TABS,
  SortByOptions,
} from '@/constants';

const { CATEGORY } = LibraryUrlSearchParams;

export const parseQueryIntoFilters = ({ query }) => {
  const fosArray = query.fos?.split(',') || [];
  const categoryArray = query[CATEGORY]?.split(',') || [];
  return {
    sortBy: SortByOptions[query.sortBy]?.key || SortByOptions.LATEST.key,
    onlyEthics: query.onlyEthics === 'true',
    selectedFos: fosArray || [],
    selectedCategories: categoryArray || [],
    hidePlayed: query.hidePlayed === 'true',
    selectedTab:
      query.selectedTab &&
      NASBA_CATEGORY_TABS.find(tab => tab.value === query.selectedTab)
        ? query.selectedTab
        : NASBA_CATEGORY_TABS[0].value,
    format: FormatOptions[query.format]?.key || FormatOptions.ALL.key,
  };
};

export const parseFiltersIntoQuery = ({
  sortBy,
  selectedFos,
  selectedCategories,
  onlyEthics,
  hidePlayed,
  selectedTab,
  format,
}) => {
  const queryParams = [];
  if (sortBy !== SortByOptions.LATEST.key) queryParams.push(`sortBy=${sortBy}`);
  if (selectedFos.length > 0) queryParams.push(`fos=${selectedFos.toString()}`);
  if (selectedCategories.length > 0)
    queryParams.push(`${CATEGORY}=${selectedCategories.toString()}`);
  if (onlyEthics) queryParams.push('onlyEthics=true');
  if (hidePlayed) queryParams.push('hidePlayed=true');
  if (selectedTab !== NASBA_CATEGORY_TABS[0].value)
    queryParams.push(`selectedTab=${selectedTab}`);
  if (format !== FormatOptions.ALL.key) queryParams.push(`format=${format}`);
  if (queryParams.length === 0) return '';
  return `?${queryParams.join('&')}`;
};
