import { Box, Typography, useMediaQuery } from '@mui/material';
import * as R from 'ramda';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

import { LUMIQ_ORGANIZATION_NAME, Routes } from '@/constants';
import { CreditsDurationInfo, EpisodeCount } from '@/containers';
import { AnalyticsService } from '@/services';
import { createRouteWithParams } from '@/utils';

const HEADER_HEIGHT = { xs: 24, md: 40 };
export const DEFAULT_CARD_HEIGHT = { xs: 196, md: 308 };

export const LearningPathCard = ({ learningPath, referrer }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const [progressWidth, setProgressWidth] = useState(0);

  const navigateToLp = () =>
    navigate(
      createRouteWithParams(Routes.LEARNING_PATH, {
        learningPathId: learningPath.groupId,
      }),
      {
        state: {
          referrer, // TODO discuss
        },
      },
    );

  const { ref } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: inView => {
      if (inView) {
        setProgressWidth(
          (learningPath.durationPlayed / learningPath.duration) * 100,
        );

        AnalyticsService.learningPathImpression({
          learningPath,
          location: referrer,
        });
      }
    },
  });

  const isOrgLp = learningPath.organizationName !== LUMIQ_ORGANIZATION_NAME;
  const episodeCount = learningPath.episodes.length || 0;

  const CARD_HEIGHT = R.mergeWith(
    R.add,
    DEFAULT_CARD_HEIGHT,
    isOrgLp ? HEADER_HEIGHT : {},
  );

  return (
    <Box
      ref={ref}
      onClick={navigateToLp}
      sx={{
        width: '100%',
        height: CARD_HEIGHT,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 2,
        backgroundColor: 'white',
        border: 0.5,
        borderColor: 'grayBrightDark',
        overflow: 'hidden',
        cursor: 'pointer',
      }}>
      {isOrgLp && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'grayBrightMid',
            height: HEADER_HEIGHT,
            width: '100%',
          }}>
          <Typography
            variant={isMobile ? 'caption' : 'h6'}
            sx={{ fontWeight: 700 }}>
            {learningPath.organizationName}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          pt: { xs: 1, md: 3 },
        }}>
        <Box
          sx={{
            width: {
              xs: 94,
              md: 140,
            },
            height: {
              xs: 94,
              md: 140,
            },
          }}
          component="img"
          src={learningPath.coverImage}
          alt="learning path cover"
        />
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
          }}>
          <Typography variant="h4" textAlign="center" px={1}>
            {learningPath.title}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'relative',
          height: HEADER_HEIGHT,
          width: '100%',
          backgroundColor: 'grayBrightMid',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: { xs: 1, md: 2 },
        }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            bgcolor: 'blue30',
            width: `${progressWidth}%`,
            transition: 'width 1s',
          }}
        />
        <Box zIndex={1}>
          <CreditsDurationInfo
            credits={learningPath.credits}
            duration={learningPath.duration}
            durationPlayed={learningPath.durationPlayed}
          />
        </Box>
        <Box zIndex={1}>
          <EpisodeCount episodeCount={episodeCount} isAbbreviated={isMobile} />
        </Box>
      </Box>
    </Box>
  );
};
