import { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';

import { Modals, PlayerErrorMessages } from '@/constants';
import { log } from '@/services';
import {
  playerActions,
  playerThunks,
  selectActiveChapterId,
  selectActiveEpisodeId,
  selectIsPlaying,
  selectIsTrackLoaded,
  selectPlayerSeekToPosition,
  selectPlayerSpeed,
  selectPlayerVolume,
  selectUserRegion,
  uiActions,
  useFetchChapterURLsQuery,
} from '@/store';

export const AudioVideoPlayer = () => {
  const dispatch = useDispatch();
  const trackPlayerRef = useRef();
  const isPlayerPlaying = useSelector(selectIsPlaying);
  const volume = useSelector(selectPlayerVolume);
  const playerSpeed = useSelector(selectPlayerSpeed);
  const isTrackLoaded = useSelector(selectIsTrackLoaded);
  const seekToPosition = useSelector(selectPlayerSeekToPosition);

  const userRegion = useSelector(selectUserRegion);

  const activeEpisodeId = useSelector(selectActiveEpisodeId);
  const activeChapterId = useSelector(selectActiveChapterId);

  const { playerStreamUrl } = useFetchChapterURLsQuery(
    {
      episodeId: activeEpisodeId,
      region: userRegion,
    },
    {
      selectFromResult: result => {
        if (!result.data) return result;
        const content = result?.data?.find(
          ({ chapterId }) => chapterId === activeChapterId,
        );
        const { url } = content?.file || {};
        return {
          ...result,
          playerStreamUrl: url,
        };
      },
    },
  );

  useEffect(() => {
    const seekToNewPosition = async () => {
      try {
        await trackPlayerRef.current.seekTo(seekToPosition, 'seconds');
      } catch (error) {
        dispatch(playerThunks.seekToPosition.rejected, {
          message: error.message,
        });

        log(error);
      }
    };

    if (isTrackLoaded && seekToPosition) {
      seekToNewPosition();
    }
  }, [dispatch, isTrackLoaded, seekToPosition]);

  const onPlay = () => {
    dispatch(playerThunks.playPlayer());
  };

  const onPause = () => {
    dispatch(playerThunks.pausePlayer());
  };

  const onReady = () => {
    dispatch(playerActions.setTrackLoaded(true));
  };

  const onBuffer = () => {
    dispatch(playerActions.setBuffering(true));
  };

  const onBufferEnd = () => {
    dispatch(playerActions.setBuffering(false));
  };

  const onSeek = val => {
    dispatch(playerThunks.seekToPosition.fulfilled({ position: val }));
  };

  const onProgress = ({ playedSeconds, loadedSeconds }) => {
    try {
      if (!isTrackLoaded || !playedSeconds) return;

      const progress = {
        position: Math.floor(playedSeconds),
        buffered: Math.floor(loadedSeconds),
      };

      dispatch(playerThunks.updatePlayerProgress({ progress }));
    } catch (error) {
      log(error);
    }
  };

  const onEnded = () => {
    dispatch(playerThunks.chapterEnded());
  };

  const onError = error => {
    if (
      PlayerErrorMessages.BROWSER_AUTOPLAY_PROHIBITED.some(errorMessage =>
        error.message?.includes(errorMessage),
      )
    ) {
      dispatch(playerThunks.pausePlayer());
      dispatch(
        uiActions.setActiveModal({ name: Modals.BROWSER_AUTOPLAY_PROHIBITED }),
      );
      return;
    }

    log(
      new Error('AudioVideoPlayer library error handler', {
        cause: new Error(error?.message),
      }),
      {
        playerStreamUrl,
      },
      error,
    );
  };

  return (
    <ReactPlayer
      ref={trackPlayerRef}
      url={playerStreamUrl}
      playing={isPlayerPlaying}
      volume={volume / 100}
      playbackRate={playerSpeed}
      onPlay={onPlay}
      onPause={onPause}
      onEnded={onEnded}
      onProgress={onProgress}
      onSeek={onSeek}
      onError={onError}
      onReady={onReady}
      onBuffer={onBuffer}
      onBufferEnd={onBufferEnd}
      playsinline={true} // required for iOS Safari
      controls={false}
      width="100%"
      height="100%"
      config={{
        file: {
          attributes: {
            onContextMenu: e => e.preventDefault(),
            disablePictureInPicture: true,
          },
        },
      }}
    />
  );
};
