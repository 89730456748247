import { Box, Typography } from '@mui/material';

import { CertificateTypeIcon } from './CertificateTypeIcon';
import { OverviewProgressRing } from './OverviewProgressRing';

export const OverviewChart = ({ credits, yearlyRequirement }) => {
  const { total, ethics, lumiq, lumiqEthics, external, externalEthics } =
    credits;

  const totalPercent = yearlyRequirement
    ? (Math.min(total, yearlyRequirement) / yearlyRequirement) * 100
    : 100;
  const externalPercent = total
    ? Math.round(((external - externalEthics) / total) * totalPercent)
    : 0;
  const lumiqPercent = total
    ? Math.round(((lumiq - lumiqEthics) / total) * totalPercent)
    : 0;
  const ethicsPercent = total ? Math.round((ethics / total) * totalPercent) : 0;
  const roundedTotalPercent = Math.round(totalPercent);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.chartContainer}>
        <Box sx={styles.progressRing}>
          <OverviewProgressRing
            ethicsPercent={ethicsPercent}
            externalPercent={externalPercent}
            lumiqPercent={lumiqPercent}
            radius={80}
            stroke={20}
          />
          {!!yearlyRequirement && (
            <Box sx={styles.progressRingContent}>
              <Typography variant="h3">{roundedTotalPercent}%</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={styles.legendContainer}>
        <Box sx={styles.legendItem}>
          <CertificateTypeIcon colour="teal" />
          <Typography sx={styles.legendLabel}>LumiQ Hours</Typography>
        </Box>
        <Box sx={styles.legendItem}>
          <CertificateTypeIcon colour="green" />
          <Typography sx={styles.legendLabel}>Ethic Hours</Typography>
        </Box>
        <Box sx={styles.legendItem}>
          <CertificateTypeIcon colour="orange" />
          <Typography sx={styles.legendLabel}>External Hours</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  chartContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 auto',
    height: 160,
    width: 160,
    justifyContent: 'center',
  },
  progressRing: {
    position: 'relative',
  },
  progressRingContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: 160,
    width: 160,
    left: 0,
    top: 0,
    position: 'absolute',
  },
  legendContainer: {
    flex: '0 1 auto',
    ml: 3,
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    pb: 1,
  },
  legendLabel: theme => ({
    ...theme.typography.select,
    color: 'gray',
    pl: 1,
  }),
};
