import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { PrivateRoutes, Routes } from '@/constants';
import { selectSessionUserId } from '@/store';
import { getRedirectSearchParams } from '@/utils';

export const useRouteProtection = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userId = useSelector(selectSessionUserId);

  const isPrivateRoute = useMemo(() => {
    return Object.values(PrivateRoutes).some(route =>
      matchPath(route, pathname),
    );
  }, [pathname]);

  const isAllowed = userId || !isPrivateRoute;

  useEffect(() => {
    if (!isAllowed) {
      navigate(
        {
          pathname: Routes.LOGIN,
          search: getRedirectSearchParams(),
        },
        { replace: true },
      );
    }
  }, [navigate, isAllowed]);

  return { isAllowed };
};
