import { createAsyncThunk } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '@/utils';

import { SESSION_SLICE_KEY } from './session.constants';

type Token = {
  user: {
    id: string;
  };
};

export const checkForExistingTokens = createAsyncThunk(
  `${SESSION_SLICE_KEY}/checkForExistingTokens`,
  async (_, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (!accessToken || !refreshToken) {
        await dispatch(clearTokens());
        return undefined;
      }

      return {
        accessToken,
        refreshToken,
        userId: jwtDecode<Token>(accessToken)?.user.id,
      };
    } catch (error) {
      await dispatch(clearTokens());
      throw error;
    }
  },
);

export const setTokens = createAsyncThunk(
  `${SESSION_SLICE_KEY}/setTokens`,
  async ({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken: string;
  }) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);

    return {
      accessToken,
      refreshToken,
      userId: jwtDecode<Token>(accessToken).user.id,
    };
  },
);

export const clearAccessToken = createAsyncThunk(
  `${SESSION_SLICE_KEY}/clearAccessToken`,
  async () => {
    removeAccessToken();
  },
);

export const clearTokens = createAsyncThunk(
  `${SESSION_SLICE_KEY}/clearTokens`,
  async () => {
    removeAccessToken();
    removeRefreshToken();
  },
);
