import { Box, Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton } from '@/components';
import { ALL_TOPIC } from '@/constants';
import { AnalyticsService } from '@/services';
import {
  selectSelectedCategory,
  selectSelectedRegions,
  selectSelectedTopic,
  selectUserRegion,
  technicalSearchActions,
  useFetchTechnicalSearchCategoriesQuery,
  useFetchTechnicalSearchEpisodesQuery,
  useFetchTechnicalSearchRegionsQuery,
  useFetchTechnicalSearchTopicsQuery,
} from '@/store';

import {
  CategoryOptionsDesktop,
  CategoryOptionsMobile,
  EpisodeTableDesktop,
  EpisodeTableMobile,
  RegionOptionsDesktop,
  RegionOptionsMobile,
  TopicOptions,
} from './components';

export const Technical = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const userRegion = useSelector(selectUserRegion);
  const selectedRegions = useSelector(selectSelectedRegions);
  const selectedCategory = useSelector(selectSelectedCategory);
  const selectedTopic = useSelector(selectSelectedTopic);

  const referrer = {
    selectedRegions: Object.keys(selectedRegions),
    selectedCategory,
    selectedTopic,
  };

  const {
    data: categories = [],
    isSuccess: isCategoriesFetched,
    isLoading: isLoadingCategories,
  } = useFetchTechnicalSearchCategoriesQuery();
  const { data: regions = [], isLoading: isLoadingRegions } =
    useFetchTechnicalSearchRegionsQuery();

  const { data: topics = [] } = useFetchTechnicalSearchTopicsQuery(
    {
      selectedCategory,
      selectedRegions,
      userRegion,
    },
    {
      skip: !isCategoriesFetched,
    },
  );

  const {
    data: episodes = [],
    isLoading: isEpisodesLoading,
    isUninitialized: isEpisodesUninitialized,
  } = useFetchTechnicalSearchEpisodesQuery(
    {
      selectedCategory,
      selectedRegions,
      selectedTopic,
      userRegion,
    },
    {
      skip: !isCategoriesFetched,
    },
  );

  useEffect(() => {
    // Reset selected topic if it's not in the list of filtered topics
    if (topics.length > 0 && !topics.includes(selectedTopic)) {
      dispatch(technicalSearchActions.selectTopic(ALL_TOPIC));
    }
  }, [dispatch, topics, selectedTopic]);

  useEffect(() => {
    if (selectedCategory && selectedTopic && selectedRegions) {
      AnalyticsService.technicalSearchFilter({
        selectedRegions: Object.keys(selectedRegions),
        selectedCategory,
        selectedTopic,
      });
    }
  }, [selectedTopic, selectedRegions, selectedCategory]);

  return (
    <Box>
      <Box sx={styles.topContainer}>
        <Box sx={{ mb: 2.5 }}>
          <Typography variant="h1">Technical</Typography>
        </Box>
        {!isMobile && !isLoadingRegions && (
          <RegionOptionsDesktop
            regions={regions}
            selectedRegions={selectedRegions}
          />
        )}
        {!isMobile && isLoadingRegions && (
          <Skeleton variant="rounded" width={400} height={40} />
        )}
      </Box>
      {isLoadingCategories ? (
        <Box mb={2}>
          <Skeleton variant="rounded" width={400} height={40} />
        </Box>
      ) : (
        <Box sx={styles.filterContainer}>
          {isMobile && (
            <RegionOptionsMobile
              regions={regions}
              selectedRegions={selectedRegions}
            />
          )}
          {isMobile ? (
            <CategoryOptionsMobile
              categories={categories}
              selectedCategory={selectedCategory}
            />
          ) : (
            <CategoryOptionsDesktop
              categories={categories}
              selectedCategory={selectedCategory}
            />
          )}
          <TopicOptions topics={topics} selectedTopic={selectedTopic} />
        </Box>
      )}

      {isEpisodesLoading || isEpisodesUninitialized ? (
        Array.from({ length: 10 }).map((_, index) => (
          <Box key={index} mb={2}>
            <Skeleton variant="rounded" width="100%" height={120} />
          </Box>
        ))
      ) : isMobile ? (
        <EpisodeTableMobile episodes={episodes} referrer={referrer} />
      ) : (
        <EpisodeTableDesktop episodes={episodes} referrer={referrer} />
      )}
    </Box>
  );
};

const styles = {
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
};
