import { Box, Grid, useMediaQuery } from '@mui/material';
import * as R from 'ramda';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  LabelCheckbox,
  MultiSelectDropdown,
  PageTabs,
  SelectedOptions,
  SingleSelectDropdown,
  Skeleton,
} from '@/components';
import {
  EpisodeFilterDefaults,
  FormatOptions,
  NASBA_CATEGORY_TABS,
  SortByOptions,
  SortByOptionsNasba,
  SortByOptionsNonNasba,
} from '@/constants';
import { AnalyticsService } from '@/services';
import {
  selectUserRegion,
  useFetchCategoriesQuery,
  useFetchFieldsOfStudyQuery,
} from '@/store';

import { parseFiltersIntoQuery, parseQueryIntoFilters } from './utils';

export const FilterSort = ({ onFilter }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const userRegion = useSelector(selectUserRegion);

  const { data: fieldsOfStudy = {}, isLoading: isFieldsOfStudyLoading } =
    useFetchFieldsOfStudyQuery();

  const { data: categoriesData, isLoading: isCategoriesLoading } =
    useFetchCategoriesQuery({ region: userRegion });

  const categories = useMemo(
    () =>
      R.mapObjIndexed(R.prop('name'), R.indexBy(R.prop('id'), categoriesData)),
    [categoriesData],
  );

  const isNasba = userRegion === 'USA';

  const [initialized, setInitialized] = useState(false);
  const [filters, setFilters] = useState(EpisodeFilterDefaults);

  useEffect(() => {
    // Initialize filters from query params
    if (initialized) return;

    const urlParams = new URLSearchParams(location.search);
    const parsedFilters = parseQueryIntoFilters({
      query: Object.fromEntries(urlParams),
    });

    setFilters(parsedFilters);
    setInitialized(true);
  }, [initialized, location.search, setFilters]);

  useEffect(() => {
    // Update query params when filters change
    if (!initialized) return;

    const search = parseFiltersIntoQuery(filters);

    navigate(
      {
        pathname: location.pathname,
        search,
      },
      { replace: true },
    );
  }, [navigate, filters, location.pathname, initialized]);

  useEffect(() => {
    // Refetch data with new filters when filters change
    if (!initialized) return;

    onFilter({
      sortBy: SortByOptions[filters.sortBy]?.key || SortByOptions.LATEST.key,
      onlyEthics: !!filters.onlyEthics,
      selectedFos: filters.selectedFos || [],
      selectedCategories: filters.selectedCategories || [],
      hidePlayed: !!filters.hidePlayed,
      selectedTab: filters.selectedTab || NASBA_CATEGORY_TABS[0].value,
      format: FormatOptions[filters.format]?.key || FormatOptions.ALL.key,
    });
  }, [filters, initialized, onFilter]);

  useEffect(() => {
    // Update category list(s) and fire analytic event when filters update
    if (isCategoriesLoading || isFieldsOfStudyLoading) return;

    const categoryList =
      filters.selectedCategories?.map(c => categories[c]) || [];
    const fosList = filters.selectedFos?.map(c => fieldsOfStudy[c]) || [];

    AnalyticsService.filterLibrary({
      filters,
      categoriesFilter: categoryList,
      fieldsOfStudyFilter: fosList,
    });
  }, [
    filters,
    categories,
    isCategoriesLoading,
    fieldsOfStudy,
    isFieldsOfStudyLoading,
  ]);

  const isValidSortBy = Object.keys(
    isNasba ? SortByOptionsNasba : SortByOptionsNonNasba,
  ).includes(filters.sortBy);

  if (isCategoriesLoading || isFieldsOfStudyLoading)
    return (
      <Fragment>
        <Skeleton />
        <Skeleton />
      </Fragment>
    );

  return (
    <Box sx={styles.container}>
      {isNasba && (
        <Box sx={styles.tabContainer}>
          <PageTabs
            tabs={NASBA_CATEGORY_TABS}
            selectedTab={filters.selectedTab}
            onChange={(e, newVal) =>
              setFilters(state => ({ ...state, selectedTab: newVal }))
            }
          />
        </Box>
      )}
      <Grid container direction={isMobile ? 'column' : 'row'}>
        <Box sx={styles.filterBox}>
          {isNasba && filters.selectedTab === NASBA_CATEGORY_TABS[1].value ? (
            <MultiSelectDropdown
              selectedOptions={filters.selectedFos}
              options={fieldsOfStudy}
              onSave={value =>
                setFilters(state => ({ ...state, selectedFos: value }))
              }
              selectedTab={filters.selectedTab}
              title={'Filter By: Field of Study'}
            />
          ) : (
            <MultiSelectDropdown
              selectedOptions={filters.selectedCategories}
              options={categories}
              onSave={value =>
                setFilters(state => ({ ...state, selectedCategories: value }))
              }
              title={'Filter By: Category'}
            />
          )}
        </Box>
        <Box sx={styles.filterBox}>
          <SingleSelectDropdown
            selectedOption={
              isValidSortBy ? filters.sortBy : EpisodeFilterDefaults.sortBy
            }
            options={isNasba ? SortByOptionsNasba : SortByOptionsNonNasba}
            onSave={value => setFilters(state => ({ ...state, sortBy: value }))}
            title={'Sort By: '}
          />
        </Box>
        <Box sx={styles.filterBox}>
          <SingleSelectDropdown
            selectedOption={filters.format}
            options={FormatOptions}
            onSave={value => setFilters(state => ({ ...state, format: value }))}
            title={'Media Type: '}
          />
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
          {!isNasba && (
            <Box sx={styles.filterBox}>
              <LabelCheckbox
                checked={filters.onlyEthics}
                onCheck={value =>
                  setFilters(state => ({ ...state, onlyEthics: value }))
                }
                title="SHOW ETHICS ONLY"
              />
            </Box>
          )}
          <Box sx={styles.filterBox}>
            <LabelCheckbox
              checked={filters.hidePlayed}
              onCheck={value =>
                setFilters(state => ({ ...state, hidePlayed: value }))
              }
              title="HIDE COMPLETED EPISODES"
            />
          </Box>
        </Box>
      </Grid>
      {isNasba && filters.selectedTab === NASBA_CATEGORY_TABS[1].value ? (
        <SelectedOptions
          selectedOptions={filters.selectedFos}
          options={fieldsOfStudy}
          onClear={value =>
            setFilters(state => ({ ...state, selectedFos: value }))
          }
        />
      ) : (
        <SelectedOptions
          selectedOptions={filters.selectedCategories}
          options={categories}
          onClear={value =>
            setFilters(state => ({ ...state, selectedCategories: value }))
          }
        />
      )}
    </Box>
  );
};

const styles = {
  container: {
    mb: 1.25,
  },
  tabContainer: {
    mb: 2.5,
  },
  filterBox: {
    display: 'flex',
    mr: 1.25,
    justifyContent: {
      xs: 'flex-start',
      sm: 'center',
    },
    mt: {
      xs: 1.25,
      sm: 0,
    },
    mb: {
      xs: 0,
      sm: 1.25,
    },
  },
};
