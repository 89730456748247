import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, Box, Typography } from '@mui/material';
import * as R from 'ramda';
import { useState } from 'react';

import { Accordion, AccordionSummary, CheckboxButton } from '@/components';
import {
  selectCountryOptions,
  selectDesignationTypeOptions,
  useFetchDesignationOptionsQuery,
} from '@/store';

import { Link } from '../../Link';
import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalSubtitle } from './ModalSubtitle';
import { ModalTitle } from './ModalTitle';

export const DesignationTypesForm = ({ goToPrevStep, goToNextStep }) => {
  const { designationTypeOptions, countryOptions, isLoading } =
    useFetchDesignationOptionsQuery(undefined, {
      selectFromResult: result => ({
        ...result,
        designationTypeOptions: selectDesignationTypeOptions(result.data),
        countryOptions: selectCountryOptions(result.data),
      }),
    });

  const [form, setForm] = useState(() =>
    R.map(() => ({}), designationTypeOptions),
  );
  const [selectedCountry, setSelectedCountry] = useState();

  const handleChange = (countryCode, designationCode) => {
    const isChecked = !!form?.[countryCode]?.[designationCode];

    setForm(prevState =>
      isChecked
        ? {
            ...prevState,
            [countryCode]: R.omit([designationCode], prevState[countryCode]),
          }
        : {
            ...prevState,
            [countryCode]: {
              ...prevState[countryCode],
              [designationCode]: true,
            },
          },
    );
  };

  const handleAccordionChange = (countryCode, isExpanded) => {
    setSelectedCountry(isExpanded ? countryCode : false);
  };

  if (isLoading) return null;

  return (
    <>
      <Box sx={styles.formContainer}>
        <Header Title={<Title />} Subtitle={<Subtitle />} />
        <Box sx={styles.formPrimarySection}>
          {Object.keys(designationTypeOptions).map(countryCode => (
            <Accordion
              disableGutters
              key={countryCode}
              expanded={selectedCountry === countryCode}
              onChange={(_, isExpanded) =>
                handleAccordionChange(countryCode, isExpanded)
              }>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle1">
                  {countryOptions[countryCode].name}{' '}
                  {Object.keys(form[countryCode]).length ? (
                    <span>({Object.keys(form[countryCode]).length})</span>
                  ) : null}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ width: '100%' }}>
                  {Object.entries(
                    designationTypeOptions?.[selectedCountry] || {},
                  ).map(([designationCode, designationLabel]) => (
                    <Box
                      key={selectedCountry + designationCode}
                      sx={{
                        mb: 1,
                      }}>
                      <CheckboxButton
                        id={designationCode}
                        label={designationLabel}
                        checked={!!form?.[selectedCountry]?.[designationCode]}
                        onChange={() =>
                          handleChange(countryCode, designationCode)
                        }
                      />
                    </Box>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
      <Footer
        handlePrev={() => goToPrevStep()}
        handleNext={() => goToNextStep(form)}
        isNextButtonDisabled={!isFormValid(form)}
      />
    </>
  );
};

const isFormValid = form => {
  const designationsSelected = R.pipe(
    Object.values,
    R.chain(Object.keys),
  )(form);

  return designationsSelected.length > 0;
};

const Title = () => (
  <ModalTitle>
    Select your designation(s) from the list below. Check all that apply.
  </ModalTitle>
);

const Subtitle = () => (
  <ModalSubtitle>
    Don&apos;t see your country&apos;s designation?{' '}
    <Link inline onClick={() => window.Intercom?.('show')}>
      Let us know
    </Link>
  </ModalSubtitle>
);
