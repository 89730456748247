import { ChapterStatus } from '@/constants';
import { Chapter, UserChapterProgress } from '@/types';

export const getNextChapterIndexInListenQueue = ({
  chapters,
  episodeChaptersProgress,
}: {
  chapters: Chapter[];
  episodeChaptersProgress: UserChapterProgress[];
}) => {
  const primaryChapterIndex = chapters?.findIndex(chapter => {
    const { status } =
      episodeChaptersProgress?.find(uc => uc.id === chapter.chapterId) || {};
    return (
      !status ||
      status === ChapterStatus.NONE ||
      status === ChapterStatus.ON_GOING
    );
  });
  return primaryChapterIndex;
};
