import { Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Routes } from '@/constants';
import {
  selectUserProfileResult,
  uiActions,
  useSubmitPurchaseFeedbackMutation,
} from '@/store';

const HEARD_ABOUT_US_OPTIONS = [
  'I searched for CPE/CPD providers',
  'I saw an ad for LumiQ',
  'At a conference',
  'From a friend/colleague',
  'I saw a post on LinkedIn',
  'I saw LumiQ in a publication/news article',
  'Other',
];

export const PaymentCompleted = ({ orderId, onClose }) => {
  const [heardAboutUs, setHeardAboutUs] = useState('');
  const [otherText, setOtherText] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: profile } = useSelector(selectUserProfileResult);

  const [submitPurchaseFeedback, { isLoading }] =
    useSubmitPurchaseFeedbackMutation();

  const isOtherSelected = heardAboutUs === 'Other';

  const onSubmit = async () => {
    await submitPurchaseFeedback({ orderId, heardAboutUs, otherText });
    dispatch(uiActions.closeActiveModal());
    onClose();
    navigate(Routes.DISCOVER);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h2" sx={{ textAlign: 'center', mt: 2 }}>
        Purchase Successful
      </Typography>
      <Typography sx={{ mt: 2, textAlign: 'center' }}>
        Thanks for your purchase! A receipt has been sent to{' '}
        {profile?.email ?? 'your email'}.
      </Typography>
      <Typography sx={{ mt: 3 }}>How did you hear about us?</Typography>
      <Select
        value={heardAboutUs}
        onChange={event => setHeardAboutUs(event.target.value)}
        displayEmpty
        sx={{ mt: 1 }}>
        <MenuItem value={''} disabled>
          Select *
        </MenuItem>
        {HEARD_ABOUT_US_OPTIONS.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {isOtherSelected && (
        <TextField
          required
          label="How did you hear about us?"
          onChange={event => setOtherText(event.target.value)}
          sx={{ mt: 2 }}
        />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button
          label={`Go to App`}
          fullWidth={false}
          onClick={onSubmit}
          disabled={
            heardAboutUs === '' || (isOtherSelected && !otherText) || isLoading
          }
        />
      </Box>
    </Box>
  );
};
