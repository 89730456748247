import { createSvgIcon } from '@mui/material/utils';

export const AssessmentIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7778 2H2.22222C1 2 0 3 0 4.22222V19.7778C0 21 1 22 2.22222 22H17.7778C19 22 20 21 20 19.7778V4.22222C20 3 19 2 17.7778 2ZM6.66667 17.5556H4.44444V9.77778H6.66667V17.5556ZM11.1111 17.5556H8.88889V6.44444H11.1111V17.5556ZM15.5556 17.5556H13.3333V13.1111H15.5556V17.5556Z"
      fill="currentColor"
    />
  </svg>,
  'Assessment',
);
