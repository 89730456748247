import * as R from 'ramda';

import { snakeCaseKeys } from '@/utils';

export const transformDesignationsFormForApi = R.pipe(
  ({ userCountry, userDesignations, designationOptions }) => {
    if (!userDesignations) {
      return [
        {
          ...designationOptions.find(
            (d: any) => !d.designated && d.countryCode === userCountry,
          ),
          geographicRegion: null,
          certificationId: null,
          yearOfDesignation: null,
        },
      ];
    }

    const payload = designationOptions.map((d: any) => {
      if (!userDesignations[d.countryCode]?.[d.designation] || !d.designated)
        return null;

      const isCanCpa = d.countryCode === 'CAN' && d.designation === 'CPA';
      const isUsaCpa = d.countryCode === 'USA' && d.designation === 'CPA';
      const isUsaEa = d.countryCode === 'USA' && d.designation === 'EA';
      const isBmuCpa = d.countryCode === 'BMU' && d.designation === 'CPA';

      if (isCanCpa) {
        return {
          ...d,
          geographicRegion: Object.keys(userDesignations.CAN.CPA.provinces),
          yearOfDesignation: +userDesignations.CAN.CPA.year,
          certificationId: null,
        };
      }

      if (isUsaCpa) {
        return {
          ...d,
          geographicRegion: Object.keys(userDesignations.USA.CPA),
          yearOfDesignation: null,
          certificationId: null,
        };
      }

      if (isUsaEa) {
        return {
          ...d,
          geographicRegion: null,
          yearOfDesignation: null,
          certificationId: userDesignations.USA.EA,
        };
      }

      if (isBmuCpa) {
        return {
          ...d,
          geographicRegion: null,
          yearOfDesignation: +userDesignations.BMU.CPA.year,
          certificationId: null,
        };
      }

      return {
        ...d,
        geographicRegion: null,
        certificationId: null,
        yearOfDesignation: null,
      };
    });

    return R.filter(i => !R.isNil(i), payload);
  },
  R.map(snakeCaseKeys),
);
