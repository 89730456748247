import { createSvgIcon } from '@mui/material/utils';

export const ViewModuleIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9667 4V11.2222H7.03333V4H12.9667ZM14.0778 11.2222H20V4H14.0778V11.2222ZM12.9667 19.5556V12.3333H7.03333V19.5556H12.9667ZM14.0778 12.3333V19.5556H20V12.3333H14.0778ZM5.92222 12.3333H0V19.5556H5.92222V12.3333ZM5.92222 11.2222V4H0V11.2222H5.92222Z"
      fill="currentColor"
    />
  </svg>,
  'ViewModule',
);
