import { Box, Hidden, Typography } from '@mui/material';

import { Skeleton } from '@/components';

export const ChapterInfo = ({ episode, chapter, isLoading }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.coverContainer}>
        {isLoading ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Box
            component="img"
            sx={styles.cover}
            src={episode.companyLogo}
            alt="company logo"
          />
        )}
      </Box>
      <Hidden smDown>
        <Box sx={styles.infoContainer}>
          {isLoading ? (
            <>
              <Skeleton height={32} />
              <Skeleton height={24} width={120} />
            </>
          ) : (
            <>
              <Typography sx={styles.episodeName} noWrap>
                {episode.name}
              </Typography>
              <Typography sx={styles.chapterName} noWrap>
                {chapter.name}
              </Typography>
            </>
          )}
        </Box>
      </Hidden>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  coverContainer: theme => ({
    alignItems: 'center',
    display: 'flex',
    height: theme.typography.pxToRem(90),
    justifyContent: 'center',
    width: theme.typography.pxToRem(90),
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(66),
      width: theme.typography.pxToRem(66),
    },
  }),
  cover: theme => ({
    display: 'block',
    height: theme.typography.pxToRem(90),
    width: theme.typography.pxToRem(90),
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(66),
      width: theme.typography.pxToRem(66),
    },
  }),
  progress: {
    color: 'aqua',
    fontSize: theme => theme.typography.pxToRem(32),
  },
  infoContainer: theme => ({
    height: theme.typography.pxToRem(90),
    pl: 2.5,
    paddingTop: theme.typography.pxToRem(25),
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(66),
    },
  }),
  episodeName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  chapterName: {
    fontSize: 16,
  },
};
