import { createSlice } from '@reduxjs/toolkit';

import { AnalyticsService } from '@/services';
import { Modal } from '@/types';

const name = 'ui';

type UiState = {
  modals: Modal[];
  isSidebarOpen: boolean;
};

const initialState: UiState = {
  modals: [],
  isSidebarOpen: false,
};

export const uiSlice = createSlice({
  name,
  initialState,
  reducers: {
    setActiveModal: (state, { payload }: { payload: Modal }) => {
      state.modals = [payload, ...state.modals];
    },
    closeActiveModal: state => {
      if (state.modals.length === 0) return;

      AnalyticsService.closeModal(state.modals[0].name);
      state.modals = state.modals.slice(1);
    },
    toggleSidebarDrawer: state => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    closeSidebarDrawer: state => {
      state.isSidebarOpen = false;
    },
  },
});

export const uiActions = uiSlice.actions;
export const { reducer: uiReducer } = uiSlice;
export const UI_SLICE_KEY = name;
export const UI_SLICE_INITIAL_STATE = initialState;
