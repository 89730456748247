import { Box } from '@mui/material';

import { welcomeSign } from '@/images';
import { useFetchUserProfileQuery } from '@/store';

import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalSubtitle } from './ModalSubtitle';
import { ModalTitle } from './ModalTitle';

export const Introduction = ({ goToNextStep }) => {
  const { data: user } = useFetchUserProfileQuery();

  const displayName = user?.firstName;

  return (
    <Box sx={styles.formContainer}>
      <Header
        Title={<Title userName={displayName} />}
        Subtitle={<Subtitle userName={displayName} />}
      />
      <Box
        sx={[
          styles.formPrimarySection,
          {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        ]}>
        <Box component="img" src={welcomeSign} sx={{ width: 140 }} />
      </Box>
      <Footer handleNext={() => goToNextStep()} />
    </Box>
  );
};

const Title = ({ userName }) =>
  userName ? (
    <ModalTitle variant="h1">Hi{userName && ` ${userName}`},</ModalTitle>
  ) : (
    <ModalTitle variant="h1">Welcome to LumiQ</ModalTitle>
  );

const Subtitle = ({ userName }) =>
  userName ? (
    <ModalSubtitle variant="subtitle1">
      We need some information about you in order to ensure compliance with
      regulatory requirements while also offering you the most tailored content.
      Please answer a few short questions about yourself (it will take 60
      seconds).
    </ModalSubtitle>
  ) : (
    <ModalSubtitle variant="subtitle1">
      Please answer a few questions about yourself to serve up relevant content
      and tailor your learning experience, it will take 60 seconds.
    </ModalSubtitle>
  );
