export const PASSWORD_REQUREMENT_TEXT =
  'Your password must be at least 8 characters long, contain uppercase and lowercase letters, numbers, and must not contain spaces';

export const RegisterErrorTypes = [
  {
    response: 'Email already exists',
    message: 'An account with this email is already registered',
    field: 'email',
  },
  {
    response: 'Email domain not allowed',
    message: 'Email domain not allowed',
    field: 'email',
  },
  {
    response: 'Invalid password',
    message: PASSWORD_REQUREMENT_TEXT,
    field: 'password',
  },
];
