import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';

import { Region } from '@/types';

import { APP_SETTINGS_SLICE_KEY } from './app-settings.constants';

export const REGION_KEY = 'userRegion';

type AppSettingsState = {
  region: Region;
};

const initialState: AppSettingsState = {
  region: (localStorage.getItem(REGION_KEY) || 'CAN') as Region,
};

export const updateRegion = createAsyncThunk(
  `${APP_SETTINGS_SLICE_KEY}/updateRegion`,
  async (payload: { region: Region }) => {
    localStorage.setItem(REGION_KEY, payload.region);
    return payload;
  },
);

const extraReducers = (builder: ActionReducerMapBuilder<AppSettingsState>) => {
  builder.addCase(updateRegion.fulfilled, (state, { payload }) => {
    state.region = payload.region;
  });
};

export const appSettingsSlice = createSlice({
  name: APP_SETTINGS_SLICE_KEY,
  reducers: {},
  initialState,
  extraReducers,
});

export const appSettingsActions = appSettingsSlice.actions;
export const { reducer: appSettingsReducer } = appSettingsSlice;
