import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { EpisodeGrid } from '@/components';
import { EVENT_CONSTANTS } from '@/services';
import {
  selectCompletedEpisodeIds,
  selectUserEpisodes,
  selectUserRegion,
  useFetchEpisodesQuery,
} from '@/store';

import { EmptyView } from '../EmptyView';

export const Completed = () => {
  const userRegion = useSelector(selectUserRegion);

  const { isLoading } = useSelector(selectUserEpisodes);
  const completedEpisodeIds = useSelector(selectCompletedEpisodeIds);

  useFetchEpisodesQuery(
    {
      episodeIds: completedEpisodeIds,
      region: userRegion,
      includeArchived: true,
    },
    {
      skip: !completedEpisodeIds.length,
    },
  );

  return (
    <Box>
      {!isLoading && !completedEpisodeIds.length && (
        <EmptyView
          title="No completed episodes"
          subtitle="You will see all your completed episodes here once you finish all related quizzes or assessments."
        />
      )}
      {!isLoading && completedEpisodeIds.length > 0 && (
        <EpisodeGrid
          episodeIds={completedEpisodeIds}
          referrer={{
            page: EVENT_CONSTANTS.PAGE.MY_LUMIQ,
            section: EVENT_CONSTANTS.SECTION.COMPLETED,
          }}
        />
      )}
    </Box>
  );
};
