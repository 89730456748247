import { RootState } from '../store';
import { PLAYER_FSM_SLICE_KEY } from './player-fsm.constants';
import { playerSessionDetailAdapter } from './player-fsm.slice';

export const {
  selectById: selectPlayerSessionDetailState,
  selectEntities: selectPlayerSessionDetailStates,
} = playerSessionDetailAdapter.getSelectors(
  (state: RootState) =>
    state[PLAYER_FSM_SLICE_KEY].details ??
    playerSessionDetailAdapter.getInitialState(),
);
