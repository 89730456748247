import BorderColor from '@mui/icons-material/BorderColor';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Pause from '@mui/icons-material/Pause';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { PlayArrowOutlinedIcon } from '@/icons';

import { IconNames } from './constants';

const Icons = {
  [IconNames.PAUSE]: Pause,
  [IconNames.PLAY_ARROW]: PlayArrowOutlinedIcon,
  [IconNames.BORDER_COLOR]: BorderColor,
  [IconNames.CHECK_CIRCLE_OUTLINE]: CheckCircleOutline,
};

export const EpisodeToolbar = ({
  sx,
  text,
  iconName,
  onClick,
  MenuButton,
  percentage = 0,
}) => {
  const Icon = Icons[iconName];
  const [progressWidth, setProgressWidth] = useState(0);

  const { ref } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: inView => {
      if (inView) {
        setProgressWidth(percentage);
      }
    },
  });

  return (
    <Box
      ref={ref}
      sx={[
        {
          position: 'relative',
          width: '100%',
          maxWidth: ({ dimensions }) => dimensions.episodeCardCover.width,
          height: ({ dimensions }) => dimensions.episodeCardToolbar.height,
          bgcolor: 'grayBrightMid',
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
          pl: 1,
          overflow: 'hidden',
        },
        sx,
      ]}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          width: `${progressWidth}%`,
          transition: 'width 1s',
          bgcolor: 'blue30',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflow: 'hidden',
          height: '100%',
          position: 'relative',
          zIndex: 1,
        }}>
        <Box
          sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
          onClick={onClick}>
          <Icon
            sx={{
              width: 18,
              height: 18,
            }}
          />
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              ml: {
                xs: 0.5,
                sm: 1,
              },
            }}
            variant="subtitle2">
            {text}
          </Typography>
        </Box>
        {MenuButton && <MenuButton />}
      </Box>
    </Box>
  );
};
