import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectUserRegion, useGetOffersQuery } from '@/store';

import { CAN_SKUS, USA_SKUS } from './constants';
import { Offer } from './Offer';
import { OfferPlaceholder } from './OfferPlaceholder';

export const OffersList = () => {
  const region = useSelector(selectUserRegion);
  const { data: offers, isLoading: isOffersLoading } = useGetOffersQuery({
    skus: region === 'CAN' ? CAN_SKUS : USA_SKUS,
  });
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: 3,
      }}>
      {isOffersLoading
        ? [...Array(2).keys()].map(i => <OfferPlaceholder key={i} />)
        : offers.map(offer => <Offer key={offer.sku} offer={offer} />)}
    </Box>
  );
};
