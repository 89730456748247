import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

import { EpisodeChapterTile } from '../';
import { getNextChapterIndexInListenQueue } from './utils';

export const EpisodeChapterList = ({
  chapters,
  episodeChaptersProgress,
  referrer,
}) => {
  const primaryChapterIndex = useMemo(
    () =>
      getNextChapterIndexInListenQueue({ chapters, episodeChaptersProgress }),
    [chapters, episodeChaptersProgress],
  );

  return (
    <Box sx={styles.listContainer}>
      <Typography variant="h3">Chapters & Quizzes</Typography>
      {chapters?.map((chapter, index) => (
        <EpisodeChapterTile
          key={index}
          index={index}
          chapter={chapter}
          isPrimaryChapter={primaryChapterIndex === index}
          userChapter={episodeChaptersProgress?.find(
            uc => uc.id === chapter.chapterId,
          )}
          referrer={referrer}
        />
      ))}
    </Box>
  );
};

const styles = {
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'blue10',
    p: 2,
    borderRadius: 4,
  },
};
