import { Close } from '@mui/icons-material';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components';
import { Modals, urls } from '@/constants';
import { AppModal } from '@/containers';
import { AnalyticsService, CPA_BUTTON_NAME } from '@/services';
import { selectSessionUserId, uiActions } from '@/store';

export const CPAContentEndModal = ({ cpaCanadaContent }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const isAnonymous = !useSelector(selectSessionUserId);

  const onCloseModal = () => {
    AnalyticsService.closeModal(
      isAnonymous ? Modals.CPA_SIGNUP : Modals.CPA_LISTEN_VERIFIABLE,
    );
    dispatch(uiActions.closeActiveModal());
  };

  if (!cpaCanadaContent) return null;

  return (
    <AppModal
      modalName={Modals.CPA_CONTENT_END}
      fullScreen={isMobile}
      disableBackdropClick={true}>
      <Box
        sx={{
          border: 1,
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          px: { xs: 2, md: 6 },
          py: 6,
        }}>
        <Button
          fullWidth={false}
          startIcon={<Close />}
          skinny={false}
          variant="tertiary"
          onClick={onCloseModal}
          sx={{ position: 'absolute', top: 4, left: 4 }}
        />
        <Box>
          <Typography variant="h3" mb={4}>
            {cpaCanadaContent.modalEpFinished.header}
          </Typography>
          <Typography variant="body1">
            {cpaCanadaContent.modalEpFinished.subHeader}
          </Typography>
          <Button
            label={cpaCanadaContent.modalEpFinished.ctaBtnText}
            skinny={false}
            sx={{ mt: 4, mb: 1 }}
            onClick={() => {
              AnalyticsService.buttonClick(CPA_BUTTON_NAME.CONTENT_END_MODAL);
              window.open(
                urls.accounts.root + cpaCanadaContent.modalEpFinished.ctaLink,
                '_self',
              );
            }}
          />
          {isAnonymous && (
            <Box>
              <Typography variant="caption">
                {cpaCanadaContent.modalEpFinished.footer}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </AppModal>
  );
};
