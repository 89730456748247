import { Close, Share } from '@mui/icons-material';
import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import * as R from 'ramda';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonProgressSpinner } from '@/components';
import { graphDone, thumbsDown, thumbsUp } from '@/images';
import { AnalyticsService } from '@/services';
import {
  selectActiveModalParams,
  selectUserRegion,
  uiActions,
  useFetchEpisodeQuery,
  useSubmitEpisodeEvaluationMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

export const EpisodeEvaluationModalContent = () => {
  const dispatch = useDispatch();
  const { episodeId } = useSelector(selectActiveModalParams) || {};
  const region = useSelector(selectUserRegion);

  const [feedback, setFeedback] = useState(null);
  const [textFeedback, setTextFeedback] = useState('');
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [answers, setAnswers] = useState({});

  const { data: episode, isLoading } = useFetchEpisodeQuery(
    { episodeId, region },
    { skip: !episodeId },
  );

  const [submitEvaluationRequest, { isLoading: isSubmitting }] =
    useSubmitEpisodeEvaluationMutation();

  if (isLoading)
    return (
      <Box
        sx={{
          height: 400,
          width: 350,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <CircularProgress />
      </Box>
    );

  const closeModal = () => dispatch(uiActions.closeActiveModal());

  const onShare = () => {
    navigator.clipboard.writeText(episode.deepLink);
    AnalyticsService.episodeShare({ episode });
    SnackbarUtils.success('Link copied to clipboard');
  };

  const questions = [
    {
      text: 'Were the stated learning objectives met?',
      value: answers.q1,
      updateValue: value => setAnswers({ ...answers, q1: value }),
    },
    {
      text: 'Were the stated prerequisite requirements appropriate and sufficient?',
      value: answers.q2,
      updateValue: value => setAnswers({ ...answers, q2: value }),
    },
    {
      text: 'Were the program materials relevant and did they contribute to the achievement of the learning objectives?',
      value: answers.q3,
      updateValue: value => setAnswers({ ...answers, q3: value }),
    },
    {
      text: 'Was the time allotted to the learning activity appropriate?',
      value: answers.q4,
      updateValue: value => setAnswers({ ...answers, q4: value }),
    },
    {
      text: 'Were the instructors effective?',
      value: answers.q5,
      updateValue: value => setAnswers({ ...answers, q5: value }),
    },
  ];

  const isFormReady =
    !R.isNil(feedback) && questions.every(q => !R.isNil(q.value));

  const submitFeedback = async () => {
    await submitEvaluationRequest({
      episodeId: episode.episodeId,
      evaluation: {
        enjoyedPodcast: feedback,
        textFeedback,
        ...answers,
      },
    });

    AnalyticsService.episodeEvaluation({
      enjoyedPodcast: feedback,
      episode,
      textFeedback,
      ...answers,
    });

    setIsFeedbackSubmitted(true);
  };

  if (isFeedbackSubmitted)
    return (
      <Box sx={styles.thanksContainer}>
        <Box sx={styles.closeIcon} onClick={closeModal}>
          <Close />
        </Box>
        <Typography sx={styles.header} variant="h1">
          Thank you for your feedback!
        </Typography>
        <Box component="img" alt="" sx={styles.thanksImg} src={graphDone} />
        <Typography sx={styles.textFeedback}>
          Your information will help us make LumiQ better for you.
        </Typography>
        <Button startIcon={<Share />} label="Share Episode" onClick={onShare} />
        <Box sx={{ mt: 2, width: '100%' }}>
          <Button variant="tertiary" onClick={closeModal} label="Close" />
        </Box>
      </Box>
    );

  return (
    <Box sx={styles.feedbackContainer}>
      <Box sx={styles.topContent}>
        <Box sx={styles.closeIcon} onClick={closeModal}>
          <Close />
        </Box>
        <Typography sx={styles.header} variant="h1">
          How did you enjoy the podcast?
        </Typography>
        <Box sx={styles.episodeInfo}>
          <Box
            component="img"
            alt="Episode Cover"
            sx={styles.episodeCover}
            src={`${episode.cover}?width=250`}
          />
          <Typography sx={styles.episodeTitle}>{episode.name}</Typography>
          <Typography sx={styles.episodeSubtext}>
            {episode.speakers?.map(s => s.name).join(', ')}
          </Typography>
          <Typography sx={styles.episodeSubtext}>
            {episode.companyName}
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.feedback}>
        <Box sx={styles.feedbackUpDown} onClick={() => setFeedback(true)}>
          <Box
            component="img"
            alt="Thumbs Up"
            sx={[
              styles.thumbsImg,
              feedback === true && styles.thumbsImgSelected,
              feedback === false && styles.opacity,
            ]}
            src={thumbsUp}
          />
          <Typography>It was great!</Typography>
        </Box>
        <Box sx={styles.feedbackUpDown} onClick={() => setFeedback(false)}>
          <Box
            component="img"
            alt="Thumbs Down"
            sx={[
              styles.thumbsImg,
              feedback === false && styles.thumbsImgSelected,
              feedback === true && styles.opacity,
            ]}
            src={thumbsDown}
          />
          <Typography sx={styles.thumbsText}>Not much...</Typography>
        </Box>
      </Box>
      <Box sx={styles.questionnaireHolder}>
        <Typography>
          Please take a moment to offer your feedback. Rank each item below,
          with
          <Typography component="span" sx={styles.highlightedText}>
            {' '}
            1 being the lowest
          </Typography>{' '}
          and
          <Typography component="span" sx={styles.highlightedText}>
            {' '}
            5 the highest
          </Typography>{' '}
          score:
        </Typography>
        {questions.map(({ text, value, updateValue }) => (
          <Box sx={styles.scoreHolder}>
            <Typography>{text}</Typography>
            <Box sx={styles.buttonHolder}>
              {Array.from({ length: 5 }).map((_, i) => (
                <Box
                  onClick={() => updateValue(i + 1)}
                  sx={[
                    styles.circleButton,
                    value === i + 1 && styles.circleButtonActive,
                  ]}>
                  {i + 1}
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={styles.textFeedback}>
        <Typography sx={{ mb: 1 }}>Other Comments</Typography>
        <TextField
          multiline
          value={textFeedback}
          variant="outlined"
          onChange={e => setTextFeedback(e.target.value)}
          fullWidth
          inputProps={{
            style: {
              height: '175px',
            },
          }}
          placeholder="Sound/audio quality? Was it too long? Any recommendation is welcome!"
        />
      </Box>
      <Box sx={styles.bottomContent}>
        <Button
          disabled={!isFormReady || isSubmitting}
          startIcon={isSubmitting && <ButtonProgressSpinner />}
          onClick={submitFeedback}
          label="Submit"
        />
      </Box>
    </Box>
  );
};

const styles = {
  feedbackContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'fit-content',
    p: {
      md: 3,
      xs: 1,
    },
  },
  thanksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: 4,
  },
  topContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mb: 2,
  },
  header: {
    mb: 1,
    textAlign: 'center',
  },
  episodeInfo: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
  episodeCover: {
    mt: 1,
    width: 200,
  },
  episodeTitle: {
    mt: 1,
  },
  episodeSubtext: {
    color: 'gray',
  },
  feedback: {
    display: 'flex',
    justifyContent: 'center',
    mb: 2,
  },
  feedbackUpDown: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    width: 155,
    height: 140,
    justifyContent: 'space-between',
  },
  thumbsImg: {
    width: 100,
    transform: 'rotate(315deg)',
    cursor: 'pointer',
  },
  opacity: {
    opacity: 0.5,
  },
  thumbsImgSelected: {
    width: 110,
  },
  textFeedback: {
    mx: 4,
    mb: 4,
    color: 'gray',
  },
  bottomContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  thanksImg: {
    width: 150,
  },
  questionnaireHolder: {
    color: 'gray',
    mx: 4,
  },
  scoreHolder: {
    pl: 0,
    mt: 2,
  },
  highlightedText: {
    color: 'teal',
    fontWeight: 'bold',
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  circleButton: {
    height: 38,
    width: 38,
    borderRadius: '50%',
    m: {
      md: 2,
      xs: 0,
    },
    backgroundColor: 'tealLight',
    color: 'teal',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    fontWeight: 'bold',
    flexShrink: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  circleButtonActive: {
    backgroundColor: theme => `${theme.palette.teal} !important`,
    color: 'white !important',
  },
  closeIcon: {
    cursor: 'pointer',
    ml: 'auto',
  },
};
