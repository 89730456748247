import { Feedback } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components';
import { EpisodeStatus, Modals } from '@/constants';
import { selectUserRegion, uiActions, userEpisodesSelectors } from '@/store';

// TODO: hide this button if eval already submitted? or maybe let people update the feedback record?
export const EpisodeEvaluation = ({ episodeId }) => {
  const dispatch = useDispatch();

  const region = useSelector(selectUserRegion);
  const userEpisode = useSelector(state =>
    userEpisodesSelectors.selectById(state, episodeId),
  );

  if (region === 'CAN' || userEpisode?.status !== EpisodeStatus.VERIFIED)
    return null;

  return (
    <Button
      label="Episode Evaluation"
      startIcon={<Feedback />}
      variant="secondary"
      skinny={false}
      onClick={() => {
        dispatch(
          uiActions.setActiveModal({
            name: Modals.EPISODE_EVALUATION,
            params: { episodeId },
          }),
        );
      }}
    />
  );
};
