import {
  isRejected,
  isRejectedWithValue,
  Middleware,
  miniSerializeError,
} from '@reduxjs/toolkit';
import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery as rtkBaseQuery,
  FetchBaseQueryError,
  RootState as RtkRootState,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { ListenRejectedReason } from '@/constants';
import {
  POSTGREST_BASE_URL,
  PostgrestErrors,
  refreshAccessTokenRequest,
} from '@/requests';
import { log, logBreadcrumb } from '@/services';
import {
  clearTokens,
  selectAccessToken,
  selectRefreshToken,
  setTokens,
} from '@/store/session';

import { RootState } from './store';

const mutex = new Mutex();

const createBaseQueryWithAuthCredentials: ({
  baseUrl,
}: {
  baseUrl: string;
}) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
  ({ baseUrl }) =>
  (args, redux, extraOptions) => {
    const token = selectAccessToken(redux.getState() as RootState);

    return rtkBaseQuery({
      baseUrl,
      credentials: 'same-origin', // TODO
      prepareHeaders: headers => {
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
      },
    })(args, redux, extraOptions);
  };

export const createBaseQueryWithRefresh =
  ({ baseUrl }: { baseUrl: string }) =>
  async (args: string | FetchArgs, redux: BaseQueryApi, extraOptions: {}) => {
    const baseQuery = createBaseQueryWithAuthCredentials({ baseUrl });

    await mutex.waitForUnlock();
    const result = await baseQuery(args, redux, extraOptions);
    const isAuthError = result.error?.status === 401;

    if (!isAuthError) return result;
    if (isAuthError && mutex.isLocked()) {
      await mutex.waitForUnlock();
      return baseQuery(args, redux, extraOptions);
    }
    if (isAuthError && !mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = selectRefreshToken(redux.getState() as RootState);
        if (!refreshToken) {
          await redux.dispatch(clearTokens());
          return result;
        }

        const { data, error } = await rtkBaseQuery({
          baseUrl: POSTGREST_BASE_URL, // always POSTGREST for both apis
        })(refreshAccessTokenRequest({ refreshToken }), redux, extraOptions);

        if (error) throw error;

        await redux.dispatch(
          setTokens({ accessToken: (data as any).access_token, refreshToken }),
        );

        return baseQuery(args, redux, extraOptions);
      } catch (error) {
        log(new Error('Error refreshing token'), error);
        await redux.dispatch(clearTokens());
        window.location.reload();
      } finally {
        release();
      }
    }
    return result;
  };

export const errorLogger: Middleware<{}, RtkRootState<{}, string, 'nodeApi'>> =
  redux => next => action => {
    logRtkQueryError(action);
    logHandledThunkError(action);
    logUnhandledThunkError(action);

    return next(action);
  };

export const rtkQueryError: (error: unknown) => {
  error: FetchBaseQueryError;
} = error => ({
  error: {
    status: 'CUSTOM_ERROR',
    data: miniSerializeError(error),
    error: 'UNHANDLED_ERROR',
  },
});

export const handleOnQueryStartedError = (error: any) => {
  if (error.isUnhandledError !== false) throw error;
};

const isRTKQueryError = (action: any) => {
  return (
    isRejected(action) &&
    ['executeQuery', 'executeMutation'].some(type =>
      action.type?.includes(type),
    )
  );
};

const isHandledThunkError = (action: any) => {
  return (
    isRejectedWithValue(action) &&
    !['executeQuery', 'executeMutation'].some(type =>
      action.type?.includes(type),
    )
  );
};

const isUnhandledThunkError = (action: any) => {
  return (
    isRejected(action) &&
    !isRejectedWithValue(action) &&
    !['executeQuery', 'executeMutation'].some(type =>
      action.type?.includes(type),
    )
  );
};

const getErrorMessageFromAction = (action: any) => {
  const message =
    action.payload?.data?.details || // postgrest api error code
    action.payload?.data?.message || // postgrest api error message
    action.payload?.data?.error?.message || // node api error message
    action.payload?.data?.error || // node api error message
    action.payload?.error?.message ||
    action.error?.message;

  if (typeof message === 'string') return message;
  else {
    logBreadcrumb({
      message: 'Parsed error message was not a string',
      data: { message },
    });
    return 'Unknown';
  }
};

const logRtkQueryError = (action: any) => {
  if (isRTKQueryError(action)) {
    if (
      action.error?.name === 'ConditionError' ||
      action.payload?.status === 'FETCH_ERROR' ||
      action.payload?.status === 'PARSING_ERROR' ||
      Object.values(PostgrestErrors.Onboarding).includes(
        action.payload?.data?.details,
      )
    )
      return;

    const endpoint = action.meta.arg?.endpointName;

    log(
      new Error(
        `${action.type?.replace('rejected', endpoint)}: ${getErrorMessageFromAction(action)}`,
      ),
      {
        type: action.type,
        endpoint,
        args: action.meta.arg?.originalArgs,
        payload: action.payload,
        error: action.error,
      },
    );
  }
};

const logHandledThunkError = (action: any) => {
  if (isHandledThunkError(action)) {
    const rejectionReason = action.payload?.reason;

    if (rejectionReason in ListenRejectedReason) return;

    log(new Error(action.type), {
      type: action.type,
      args: action.meta?.arg,
      error: action.error,
      payload: action.payload,
    });
  }
};

const logUnhandledThunkError = (action: any) => {
  if (isUnhandledThunkError(action)) {
    log(new Error(`${action.type}: ${getErrorMessageFromAction(action)}`), {
      type: action.type,
      args: action.meta?.arg,
      error: action.error,
      payload: action.payload,
    });
  }
};
