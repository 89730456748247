import { configureStore as configureReduxStore } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import * as R from 'ramda';

import { APP_SETTINGS_SLICE_KEY, appSettingsReducer } from './app-settings';
import { EPISODES_SLICE_KEY, episodesReducer } from './episodes';
import { nodeApi } from './node-api';
import { PLAYER_SLICE_KEY, playerReducer } from './player';
import { PLAYER_FSM_SLICE_KEY, playerFsmReducer } from './player-fsm';
import { postgrestApi } from './postgrest-api';
import { SESSION_SLICE_KEY, sessionReducer } from './session';
import { errorLogger } from './store.utils';
import {
  TECHNICAL_SEARCH_SLICE_KEY,
  technicalSearchReducer,
} from './technical-search';
import { UI_SLICE_KEY, uiReducer } from './ui';

export const rootReducer = {
  [postgrestApi.reducerPath]: postgrestApi.reducer,
  [nodeApi.reducerPath]: nodeApi.reducer,
  [EPISODES_SLICE_KEY]: episodesReducer,
  [PLAYER_SLICE_KEY]: playerReducer,
  [PLAYER_FSM_SLICE_KEY]: playerFsmReducer,
  [SESSION_SLICE_KEY]: sessionReducer,
  [UI_SLICE_KEY]: uiReducer,
  [APP_SETTINGS_SLICE_KEY]: appSettingsReducer,
  [TECHNICAL_SEARCH_SLICE_KEY]: technicalSearchReducer,
};

export const rootMiddleware = [postgrestApi.middleware, nodeApi.middleware];

export const configureStore = () =>
  configureReduxStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(
        ...rootMiddleware,
        errorLogger,
        LogRocket.reduxMiddleware({
          // LogRocket should be the last middleware in the list
          stateSanitizer: state => ({
            ...state,
            episodes: {},
            postgrestApi: {
              ...state.postgrestApi,
              queries: {
                ...state.postgrestApi.queries,
                // Remove the data field from all queries
                ...R.mapObjIndexed(
                  (query: Object) => ({
                    ...query,
                    data: undefined,
                  }),
                  state.postgrestApi.queries,
                ),
              },
            },
            nodeApi: {
              ...state.nodeApi,
              queries: {
                ...state.nodeApi.queries,
                // Remove the data field from all queries
                ...R.mapObjIndexed(
                  (query: Object) => ({
                    ...query,
                    data: undefined,
                  }),
                  state.nodeApi.queries,
                ),
              },
            },
          }),
        }),
      ),

    devTools: process.env.NODE_ENV !== 'production',
  });

export const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
