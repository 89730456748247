import { Box, Skeleton } from '@mui/material';

export const PersonalOfferPlaceholder = () => {
  return (
    <Box
      sx={{
        borderRadius: 4,
        overflow: 'hidden',
      }}>
      <Skeleton variant="rectangular" width={646} height={394} />
    </Box>
  );
};
