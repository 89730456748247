import * as R from 'ramda';

import { COUNTRIES, Country } from '@/constants';
import { DesignationOption } from '@/types';

export const selectDisclaimerDesignationOptions = (
  options: DesignationOption[],
) => options?.filter(i => i.disclaimer) || [];

export const selectNonDesignatedCountryOptions = (
  options: DesignationOption[],
) => {
  if (!options) return [];

  const countryCodes = R.pipe(
    R.filter<DesignationOption>(R.propEq(false, 'designated')),
    R.map(a => R.prop('countryCode')(a)),
    R.uniq,
  )(options);

  return R.pipe(
    R.indexBy(R.prop('code3') as any),
    R.pick(countryCodes as string[]),
  )(COUNTRIES);
};

export const selectCountryOptions = (options: DesignationOption[]) => {
  if (!options) return [];

  const countryCodes = R.pipe(
    R.map<DesignationOption, string>(R.prop('countryCode')),
    R.uniq,
  )(options);

  return R.pipe(
    R.indexBy<Country, string>(R.prop('code3')),
    R.pick(countryCodes),
  )(COUNTRIES);
};

type temp = Partial<
  Record<DesignationOption['designation'], DesignationOption['description']>
>;

export const selectDesignationTypeOptions = (options: DesignationOption[]) => {
  if (!options) return null;

  return R.pipe(
    R.filter<DesignationOption>(R.propEq(true, 'designated')),
    (value: DesignationOption[]) =>
      R.groupBy<DesignationOption, DesignationOption['countryCode']>(
        R.prop('countryCode'),
        value,
      ) as Record<DesignationOption['countryCode'], DesignationOption[]>,
    R.mapObjIndexed<
      DesignationOption[],
      Partial<
        Record<
          DesignationOption['designation'],
          DesignationOption['description']
        >
      >,
      DesignationOption['countryCode']
    >(
      R.reduce<DesignationOption, temp>(
        (accumulator: temp, option: DesignationOption) => ({
          ...accumulator,
          [option.designation]: option.description,
        }),
        {},
      ),
    ),
  )(options);
};
