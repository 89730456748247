import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { EpisodeStatus, Modals, Routes } from '@/constants';
import { EVENT_CONSTANTS } from '@/services';
import {
  playerThunks,
  selectActiveChapterId,
  selectActiveEpisodeId,
  selectEpisode,
  selectUserRegion,
  uiActions,
  useFetchChapterQuery,
  userEpisodesSelectors,
} from '@/store';
import { convertSecondsToHHMMSS, createRouteWithParams } from '@/utils';

import {
  selectEpisodePlayerPosition,
  selectIsEpisodePlaying,
} from '../../selectors';
import { EpisodeDropdownMenu } from '../EpisodeDropdownMenu';
import { IconNames } from './constants';
import { EpisodeToolbar as EpisodeToolbarComponent } from './EpisodeToolbar.component';

const { PAUSE, PLAY_ARROW, BORDER_COLOR, CHECK_CIRCLE_OUTLINE } = IconNames;

export const EpisodeToolbar = ({ episodeId, referrer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const playerPosition = useSelector(state =>
    selectEpisodePlayerPosition(state, episodeId),
  );
  const activeEpisodeId = useSelector(selectActiveEpisodeId);
  const activeChapterId = useSelector(selectActiveChapterId);
  const episode = useSelector(state => selectEpisode(state, episodeId));
  const userEpisode = useSelector(state =>
    userEpisodesSelectors.selectById(state, episode?.episodeId),
  );
  const region = useSelector(selectUserRegion);

  const userEpisodeStatus = userEpisode?.status;
  const episodeDuration = episode?.duration;
  const playedDuration = userEpisode?.playedDuration || 0;
  const episodeDurationLeft = convertSecondsToHHMMSS(
    episodeDuration - playedDuration,
  );

  const isEpisodePlaying = useSelector(state =>
    selectIsEpisodePlaying(state, episodeId),
  );
  const isTrailerActive =
    activeEpisodeId === episodeId && activeChapterId === episode.trailerId;

  const { trailerDuration } = useFetchChapterQuery(
    {
      chapterId: episode.trailerId,
      region,
    },
    {
      skip: !isTrailerActive,
      selectFromResult: result => ({
        trailerDuration: result.data?.duration,
      }),
    },
  );

  const trailerDurationLeft = convertSecondsToHHMMSS(
    trailerDuration - (playerPosition || 0),
  );

  const noProgressClickHandler = () => {
    dispatch(
      playerThunks.chapterPlayPressed({
        activeChapter: { episodeId, chapterId: episode.trailerId },
        location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.PREVIEW },
        referrer,
      }),
    );
  };

  const inProgresClickHandler = () => {
    dispatch(
      playerThunks.episodePlayPressed({
        episodeId,
        location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.PLAY },
        referrer,
      }),
    );
  };

  const quizReadyClickHandler = () => {
    navigate(
      {
        pathname: createRouteWithParams(Routes.EPISODE, {
          episodeId,
        }),
      },
      {
        state: { referrer, scrollToIncompleteQuiz: true },
      },
    );
  };

  const assessmentReadyClickHandler = () => {
    dispatch(
      uiActions.setActiveModal({
        name: Modals.ASSESSMENT,
        params: { episodeId },
      }),
    );
  };

  const episodeCompleteClickHandler = () => {
    navigate(
      {
        pathname: createRouteWithParams(Routes.EPISODE, {
          episodeId,
        }),
      },
      {
        state: { referrer },
      },
    );
  };

  const MenuButton = useCallback(
    () => <EpisodeDropdownMenu episodeId={episodeId} referrer={referrer} />,
    [episodeId, referrer],
  );

  return (
    <>
      {(!userEpisodeStatus || userEpisodeStatus === EpisodeStatus.NONE) && (
        <EpisodeToolbarComponent
          text={isTrailerActive ? `${trailerDurationLeft} left` : 'Preview'}
          iconName={isTrailerActive && isEpisodePlaying ? PAUSE : PLAY_ARROW}
          onClick={noProgressClickHandler}
          MenuButton={MenuButton}
          percentage={(playerPosition / trailerDuration) * 100}
        />
      )}
      {userEpisodeStatus === EpisodeStatus.ON_GOING && (
        <EpisodeToolbarComponent
          text={episodeDurationLeft + ' left'}
          iconName={isEpisodePlaying ? PAUSE : PLAY_ARROW}
          onClick={inProgresClickHandler}
          MenuButton={MenuButton}
          percentage={(playedDuration / episodeDuration) * 100}
        />
      )}
      {userEpisodeStatus === EpisodeStatus.QUIZ_READY && (
        <EpisodeToolbarComponent
          text="Quiz Ready"
          iconName={BORDER_COLOR}
          sx={{
            color: 'text.white',
            background: ({ gradients }) => gradients.warning,
          }}
          onClick={quizReadyClickHandler}
          MenuButton={MenuButton}
        />
      )}
      {userEpisodeStatus === EpisodeStatus.ASSESSMENT_READY && (
        <EpisodeToolbarComponent
          text="Assessment"
          iconName={BORDER_COLOR}
          sx={{
            color: 'text.white',
            background: ({ gradients }) => gradients.warning,
          }}
          onClick={assessmentReadyClickHandler}
          MenuButton={MenuButton}
        />
      )}
      {userEpisodeStatus === EpisodeStatus.VERIFIED && (
        <EpisodeToolbarComponent
          text="Completed"
          iconName={CHECK_CIRCLE_OUTLINE}
          sx={{
            color: 'text.white',
            background: ({ gradients }) => gradients.aquaToBlue,
          }}
          onClick={episodeCompleteClickHandler}
          MenuButton={MenuButton}
        />
      )}
    </>
  );
};
