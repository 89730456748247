import { Box } from '@mui/material';
import * as R from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EpisodeGrid } from '@/components';
import { EVENT_CONSTANTS } from '@/services';
import {
  selectSessionUserId,
  selectUserRegion,
  useFetchEpisodesQuery,
  useFetchUserEpisodesQuery,
} from '@/store';

import { selectBookmarkedEpisodeIds } from '../../utils';
import { EmptyView } from '../EmptyView';

export const Bookmarked = () => {
  const userId = useSelector(selectSessionUserId);
  const userRegion = useSelector(selectUserRegion);

  const { isLoading: isBookmarkedEpisodeIdsLoading, bookmarkedEpisodeIds } =
    useFetchUserEpisodesQuery(undefined, {
      skip: !userId,
      selectFromResult: result => ({
        ...result,
        bookmarkedEpisodeIds: selectBookmarkedEpisodeIds(
          Object.values(result.data?.entities || {}),
        ),
      }),
    });

  const {
    episodes,
    isLoading: isEpisodesLoading,
    isUninitialized: isEpisodesUninitialized,
  } = useFetchEpisodesQuery(
    {
      episodeIds: bookmarkedEpisodeIds,
      region: userRegion,
    },
    {
      skip: !bookmarkedEpisodeIds.length,
      selectFromResult: result => {
        if (!result.data) return result;
        return {
          ...result,
          episodes: R.indexBy(R.prop('episodeId'), result.data),
        };
      },
    },
  );

  const isLoading =
    isBookmarkedEpisodeIdsLoading ||
    (bookmarkedEpisodeIds.length > 0 && isEpisodesUninitialized) ||
    isEpisodesLoading;

  const filteredBookmarkedEpisodeIds = useMemo(() => {
    if (isLoading) return undefined;
    return bookmarkedEpisodeIds.filter(id => !!episodes[id]);
  }, [bookmarkedEpisodeIds, episodes, isLoading]);

  return (
    <Box>
      {isLoading && <EpisodeGrid isLoading />}
      {!isLoading && !filteredBookmarkedEpisodeIds.length && (
        <EmptyView
          title="No bookmarks"
          subtitle="Keep your favorite episodes here to listen and share with your colleagues."
        />
      )}
      {!isLoading && filteredBookmarkedEpisodeIds.length > 0 && (
        <EpisodeGrid
          episodeIds={filteredBookmarkedEpisodeIds}
          referrer={{
            page: EVENT_CONSTANTS.PAGE.MY_LUMIQ,
            section: EVENT_CONSTANTS.SECTION.BOOKMARKED,
          }}
        />
      )}
    </Box>
  );
};
