import { Box, Tooltip, Typography } from '@mui/material';

import { roundFloat } from '@/utils';

import { CertificateTypeIcon } from './CertificateTypeIcon';

const MAX_HEIGHT = 180;
const MIN_HEIGHT = 10;

export const BarChart = ({
  label,
  yearlyRequirement,
  totalCredits,
  ethicsCredits,
  lumiqCredits,
  lumiqEthicsCredits,
}) => {
  // only LumiQ hours excluding any LumiQ ethics
  const lumiqCreditsHeight = totalCredits
    ? Math.round(((lumiqCredits - lumiqEthicsCredits) / totalCredits) * 100)
    : 0;

  // includes both LumiQ and external ethics
  const ethicsCreditsHeight = totalCredits
    ? Math.round((ethicsCredits / totalCredits) * 100)
    : 0;

  // only External hours excluding any external ethics
  const externalCreditsHeight = totalCredits
    ? Math.round(
        ((totalCredits - lumiqCredits - (ethicsCredits - lumiqEthicsCredits)) /
          totalCredits) *
          100,
      )
    : 0;

  // the overall max height of the bar chart
  const totalHeight =
    totalCredits > yearlyRequirement
      ? MAX_HEIGHT
      : Math.max(
          Math.round((totalCredits / yearlyRequirement) * MAX_HEIGHT),
          MIN_HEIGHT,
        );

  const tooltipContents = (
    <Box sx={styles.legendContainer}>
      <Box sx={styles.legendRow}>
        <CertificateTypeIcon colour="teal" />
        <Typography sx={styles.legendLabel}>
          {roundFloat(lumiqCredits)} LumiQ Hours
        </Typography>
      </Box>
      <Box sx={styles.legendRow}>
        <CertificateTypeIcon colour="yellow" />
        <Typography sx={styles.legendLabel}>
          {roundFloat(totalCredits - lumiqCredits)} External Hours
        </Typography>
      </Box>
      <Box sx={styles.legendRow}>
        <CertificateTypeIcon colour="green" />
        <Typography sx={styles.legendLabel}>
          {roundFloat(ethicsCredits)} Ethic Hours
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box sx={styles.container}>
      <Typography variant="select">{roundFloat(totalCredits, 2)}hrs</Typography>
      <Tooltip arrow enterDelay={0} title={tooltipContents}>
        <Box sx={[styles.barChart, { height: totalHeight }]}>
          {externalCreditsHeight > 0 && (
            <Box
              sx={{
                height: `${externalCreditsHeight}%`,
                backgroundColor: 'orange',
              }}
            />
          )}
          {ethicsCreditsHeight > 0 && (
            <Box
              sx={{
                height: `${ethicsCreditsHeight}%`,
                backgroundColor: 'green',
              }}
            />
          )}
          {lumiqCreditsHeight > 0 && (
            <Box
              sx={{
                height: `${lumiqCreditsHeight}%`,
                backgroundColor: 'teal',
              }}
            />
          )}
        </Box>
      </Tooltip>
      <Typography variant="select">{label}</Typography>
    </Box>
  );
};

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    maxWidth: 100,
    p: 1,
    textAlign: 'center',
  },
  barChart: {
    backgroundColor: 'grayLight',
    borderRadius: 1,
    my: 1,
    mx: 'auto',
    width: 60,
    overflow: 'hidden',
  },
  popover: {
    pointerEvents: 'none',
  },
  legendContainer: {
    p: 2,
  },
  legendRow: {
    alignItems: 'center',
    display: 'flex',
    '&:not(:last-child)': {
      mb: 1,
    },
  },
  legendLabel: theme => ({
    ...theme.typography.select,
    color: 'white',
    pl: 1,
  }),
};
