import { Lock } from '@mui/icons-material';

import { Pill } from '@/components/Pill';

export const PremiumPill = () => {
  return (
    <Pill
      icon={<Lock fontSize="small" />}
      color="gold"
      label="PREMIUM"
      size="small"
    />
  );
};
