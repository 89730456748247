export const CreditType = { CUSTOM: 'custom', LUMIQ: 'lumiq' } as const;

export const TrackerMessages = {
  DOWNLOAD_ERROR: 'Download failed. Please try again or contact support.',
  CREATE_CUSTOM_CPD_SUCCESS: 'Record created successfully!',
  CREATE_CUSTOM_CPD_ERROR:
    'Failed to create custom CPD record. Please try again or contact support.',
  UPDATE_CUSTOM_CPD_SUCCESS: 'Record updated successfully!',
  UPDATE_CUSTOM_CPD_ERROR:
    'Failed to update custom CPD record. Please try again or contact support.',
  DELETE_CUSTOM_CPD_SUCCESS: 'Record deleted successfully!',
  DELETE_CUSTOM_CPD_ERROR:
    'Failed to delete custom CPD record. Please try again or contact support.',
} as const;
