import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

export const DateRange = ({ dateRange, onDateChange }) => {
  return (
    <Box sx={styles.container}>
      <DatePicker
        sx={styles.datePicker}
        label="Start Date"
        maxDate={dateRange.toDate}
        onChange={newValue =>
          onDateChange({ ...dateRange, fromDate: newValue })
        }
        slotProps={{
          field: { clearable: true },
        }}
      />
      <DatePicker
        sx={styles.datePicker}
        label="End Date"
        minDate={dateRange.fromDate}
        onChange={newValue => onDateChange({ ...dateRange, toDate: newValue })}
        slotProps={{
          field: { clearable: true },
        }}
      />
    </Box>
  );
};

const styles = {
  datePicker: {
    width: 200,
  },
  container: theme => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: 2,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  }),
};
