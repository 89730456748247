import { Dialog } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsService } from '@/services';
import { selectActiveModal, uiActions } from '@/store';

export const AppModal = ({
  children,
  modalName,
  disableBackdropClick,
  onClose,
  ...muiDialogProps
}) => {
  const dispatch = useDispatch();
  const activeModal = useSelector(selectActiveModal);
  const isOpen = activeModal?.name === modalName;

  const onMuiDialogClose = (event, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') return;
    onClose?.();
    dispatch(uiActions.closeActiveModal());
  };

  useEffect(() => {
    if (isOpen) {
      AnalyticsService.showModal(modalName);
    }
  }, [modalName, isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onMuiDialogClose}
      {...muiDialogProps}
      PaperProps={{
        sx: {
          color: 'text.black',
        },
      }}>
      {children}
    </Dialog>
  );
};
