import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import screenfull from 'screenfull';

import {
  playerActions,
  selectActiveChapterId,
  selectIsPlayerVideoFullscreen,
  selectUserRegion,
  useFetchChapterQuery,
} from '@/store';

import { TopControls, VideoControls, VideoNotifications } from './components';

export const VideoPlayer = ({ player }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const videoPlayerContainerRef = useRef();
  const smDownMatch = useMediaQuery(theme.breakpoints.down('sm'));

  const region = useSelector(selectUserRegion);
  const activeChapterId = useSelector(selectActiveChapterId);
  const { data: chapter } = useFetchChapterQuery({
    chapterId: activeChapterId,
    region,
  });
  const isVideoFullscreen = useSelector(selectIsPlayerVideoFullscreen);

  // TODO
  // const screenSize = useWindowSize();

  useEffect(() => {
    const onScreenfullChange = () => {
      dispatch(playerActions.setVideoFullscreen(screenfull.isFullscreen));
    };

    if (screenfull.isEnabled) {
      screenfull.on('change', onScreenfullChange);
    }

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', onScreenfullChange);
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (!screenfull.isEnabled) return;

    if (isVideoFullscreen && !screenfull.isFullscreen) {
      screenfull.request(videoPlayerContainerRef.current);
    }

    if (!isVideoFullscreen && screenfull.isFullscreen) {
      screenfull.exit();
    }
  }, [isVideoFullscreen]);

  return (
    <Box sx={styles.root}>
      {smDownMatch && (
        <div>
          <TopControls />
        </div>
      )}
      <Box
        sx={[
          styles.videoPlayer,
          isVideoFullscreen && styles.videoPlayerFullScreen,
        ]}
        ref={videoPlayerContainerRef}>
        {player}
        <Box sx={styles.videoPlayerControls}>
          <VideoControls />
        </Box>
        <Box sx={styles.videoPlayerOverlay}>
          <VideoNotifications />
        </Box>
      </Box>
      <Box sx={styles.videoContent}>
        {!smDownMatch && (
          <div>
            <TopControls />
          </div>
        )}
        <Box sx={styles.contentContainer}>
          {chapter?.tags?.length > 0 && (
            <Box sx={styles.tagsContainer}>
              {chapter.tags.map(tag => (
                <></>
                // TODO
                // <Tag key={tag} tag={tag} />
              ))}
            </Box>
          )}
          <Box sx={styles.episodeTitle}>{chapter?.name}</Box>
          <Box sx={styles.episodeDescription}>
            {chapter?.formattedDescription
              ? // TODO
                // convertToMarkdown(
                //   chapter.formattedDescription,
                //   styles.episodeDescription,
                // )
                chapter.formattedDescription
              : chapter?.description}
          </Box>
          {chapter?.resources?.length > 0 && (
            <></>
            // TODO
            // <Resources resources={chapter.resources} variant="player" />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  root: theme => ({
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      // height: props => theme.typography.pxToRem(props.screenSize.height - 160),
      height: 'calc(100% - 160px)',
      overflow: 'scroll',
    },
  }),
  videoPlayer: theme => ({
    position: 'relative',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  }),
  videoPlayerFullScreen: {
    width: '100%',
  },
  videoPlayerControls: {
    bottom: 0,
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  videoPlayerOverlay: {
    bottom: 0,
    height: '100%',
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
  },
  videoContent: theme => ({
    height: 'auto',
    ml: theme.typography.pxToRem(50),
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      marginLeft: 0,
      mt: theme.typography.pxToRem(20),
    },
  }),
  contentContainer: theme => ({
    marginTop: theme.typography.pxToRem(30),
    [theme.breakpoints.down('sm')]: {
      mt: theme.typography.pxToRem(20),
    },
  }),
  tagsContainer: {
    mb: theme => theme.typography.pxToRem(10),
  },
  episodeTitle: theme => ({
    color: 'white',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 'bold',
    lineHeight: theme.typography.pxToRem(26),
    mb: theme.typography.pxToRem(20),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  }),
  episodeDescription: {
    color: 'white',
    fontSize: theme => theme.typography.pxToRem(16),
    lineHeight: theme => theme.typography.pxToRem(26),
    mb: theme => theme.typography.pxToRem(20),
    '& a': {
      color: 'teal',
      fontSize: theme => theme.typography.pxToRem(16),
      lineHeight: theme => theme.typography.pxToRem(26),
      textDecoration: 'none',
      '&:hover': {
        color: 'teal',
        textDecoration: 'underline',
      },
      '&:hover, &:focus': {
        color: 'teal',
        textDecoration: 'underline',
      },
    },
  },
};
