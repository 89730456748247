import { Box } from '@mui/material';
import { useState } from 'react';

import { RadioBoxButton } from '@/components';
import { CanProvinces } from '@/constants';

import { styles } from '../styles';
import { ExtraBold } from './ExtraBold';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalSubtitle } from './ModalSubtitle';
import { ModalTitle } from './ModalTitle';

export const CanCpaProvincesForm = ({ goToPrevStep, goToNextStep }) => {
  const [form, setForm] = useState({});

  const handleChange = provinceCode => {
    setForm({ [provinceCode]: true });
  };

  return (
    <Box sx={styles.formContainer}>
      <Header Title={<Title />} Subtitle={<Subtitle />} />
      <Box sx={styles.formPrimarySection}>
        {Object.entries(CanProvinces).map(([key, name]) => (
          <Box key={key} sx={styles.checkboxContainer}>
            <RadioBoxButton
              label={name}
              checked={!!form[key]}
              onChange={() => handleChange(key)}
            />
          </Box>
        ))}
      </Box>
      <Footer
        handlePrev={() => goToPrevStep()}
        handleNext={() => goToNextStep(form)}
        isNextButtonDisabled={!isFormValid(form)}
      />
    </Box>
  );
};

const isFormValid = form => Object.keys(form).length === 1;

const Title = () => {
  return (
    <ModalTitle>
      As a <ExtraBold>CPA</ExtraBold> in <ExtraBold>Canada</ExtraBold>, select
      the province you&apos;re licensed in.
    </ModalTitle>
  );
};

const Subtitle = () => (
  <ModalSubtitle>
    This will help us determine what your reporting requirements are.
  </ModalSubtitle>
);
