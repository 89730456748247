import { CardGiftcard } from '@mui/icons-material';
import { Hidden } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Routes } from '@/constants';
import { getDisablePurchasing } from '@/store';

export const NavbarPricing = () => {
  const navigate = useNavigate();
  const disablePurchasing = useSelector(getDisablePurchasing);
  if (disablePurchasing) return null;

  return (
    <Hidden mdDown>
      <Button
        onClick={() => navigate(Routes.PRICING)}
        startIcon={<CardGiftcard />}
        variant="tertiary"
        label="Pricing"
        fullWidth={false}
      />
    </Hidden>
  );
};
