import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Hidden, Typography } from '@mui/material';
import { useRef } from 'react';

export const Carousel = ({ title, uKey, children, ViewAllLink, Tab }) => {
  const scrollAmount = useRef(0);

  const scrollTabs = left => {
    const scrollMax = document.getElementById(containerId).clientWidth;

    document.getElementById(containerId).lastChild.scrollTo({
      top: 0,
      left: (scrollAmount.current += left ? -500 : 500),
      behavior: 'smooth',
    });

    if (scrollAmount.current > scrollMax) {
      scrollAmount.current = scrollMax;
    } else if (scrollAmount.current < 0) {
      scrollAmount.current = 0;
    }
  };

  const containerId = `${uKey}-Carousel`;

  return (
    <Box id={containerId}>
      <Box sx={styles.titleHolder}>
        <Typography variant="h4">{title}</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          {ViewAllLink && <ViewAllLink />}
          <Hidden smDown>
            <ChevronLeft
              onClick={() => {
                scrollTabs(true);
              }}
              sx={styles.icon}
            />
            <ChevronRight
              onClick={() => {
                scrollTabs(false);
              }}
              sx={styles.icon}
            />
          </Hidden>
        </Box>
      </Box>
      {Tab && <Tab />}
      <Box sx={styles.scrollHolder}>
        <Box
          sx={{
            width: 'max-content',
            display: 'flex',
          }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  titleHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 1,
  },
  icon: {
    cursor: 'pointer',
    ml: 2.5,
  },
  scrollHolder: {
    overflowX: 'auto',
    mx: -6,
    pl: 6,
    scrollbarWidth: 'none',
  },
};
