import { BuildOutlined } from '@mui/icons-material';
import { Box, Hidden, Typography } from '@mui/material';
import { useEffect } from 'react';

import { urls } from '@/constants';

export const AppErrorBoundary = ({ error }) => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  useEffect(() => {
    const OVERLAY_NAME = 'vite-error-overlay';
    const ErrorOverlay = customElements.get(OVERLAY_NAME);
    const overlayActive = !!document.querySelector(OVERLAY_NAME);
    if (error && ErrorOverlay && isDevelopment && !overlayActive) {
      document.body.appendChild(new ErrorOverlay(error));
    }
  }, [error, isDevelopment]);

  return (
    <Box sx={styles.containerOuter}>
      <Box sx={styles.containerInner}>
        <Hidden smDown>
          <Box sx={{ mr: 10 }}>
            <BuildOutlined fontSize="large" />
          </Box>
        </Hidden>
        <Box>
          <Typography gutterBottom variant="h1">
            An Unexpected Error Occurred
          </Typography>
          <Typography variant="body1">
            Our engineers have been notified and are working on a solution.
            Please try to reload the page. If the problem persists, you can
            reach out to support at <b>{urls.supportEmail}</b>.
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.polygonBackground}></Box>
    </Box>
  );
};

const styles = {
  containerOuter: {
    position: 'relative',
    height: '100vh',
    padding: '10px 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'teal',
  },
  containerInner: {
    zIndex: 1,
    maxWidth: 900,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 400,
    padding: 5,
  },
  polygonBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    clipPath: 'polygon(100% 15%, 100% 80%, 0 90%, 0 25%)',
    width: '100%',
    height: '100%',
  },
};
