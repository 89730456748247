import { Typography } from '@mui/material';

import { ButtonProgressSpinner } from '@/components';
import { TrackerMessages } from '@/constants';
import { useDownloadCertificateMutation } from '@/store';
import { SnackbarUtils } from '@/utils';

export const CertificateDownloadLink = ({ creditId }) => {
  const [downloadCertificate, { isLoading }] = useDownloadCertificateMutation();

  const onDownloadCertificate = async () => {
    const { error } = await downloadCertificate({
      certificateId: creditId,
    });
    if (error) SnackbarUtils.error(TrackerMessages.DOWNLOAD_ERROR);
  };

  return isLoading ? (
    <ButtonProgressSpinner />
  ) : (
    <Typography
      onClick={onDownloadCertificate}
      variant="select"
      sx={{
        cursor: 'pointer',
        color: 'blue',
        ':hover': {
          textDecoration: 'underline',
        },
      }}>
      Download
    </Typography>
  );
};
