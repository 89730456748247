import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { Button } from '@/components';
import { urls } from '@/constants';
import { AnalyticsService, CPA_BUTTON_NAME } from '@/services';
import { selectSessionUserId } from '@/store';

export const CPAHeader = ({ cpaCanadaContent }) => {
  const isAnonymous = !useSelector(selectSessionUserId);
  const headerButtonText = isAnonymous ? 'Claim 5 Free Hours' : 'Back to LumiQ';

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftContainer}>
        <Box
          component="img"
          alt=""
          src={cpaCanadaContent?.header.logoCpa}
          sx={styles.cpacanadalogo}
          onClick={() => {
            AnalyticsService.buttonClick(CPA_BUTTON_NAME.CANADA_LOGO);
            window.open('/', '_self');
          }}
        />
      </Box>

      <Box sx={styles.rightContainer}>
        <Button
          label={headerButtonText}
          skinny={false}
          onClick={() => {
            AnalyticsService.buttonClick(
              isAnonymous
                ? CPA_BUTTON_NAME.SIGN_UP
                : CPA_BUTTON_NAME.BACK_TO_LUMIQ,
            );

            const url = isAnonymous
              ? urls.accounts.root + cpaCanadaContent.header.signupUrl
              : '/';
            window.open(url, '_self');
          }}
        />
      </Box>
    </Box>
  );
};

const styles = {
  container: theme => ({
    mt: 4,
    mb: 4,
    height: theme.typography.pxToRem(theme.dimensions.header.height),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      height: theme.typography.pxToRem(90),
      ml: 4,
      mr: 4,
    },
  }),
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  rightContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
  },
  cpacanadalogo: theme => ({
    height: theme.typography.pxToRem(70),
    width: theme.typography.pxToRem(323),
    [theme.breakpoints.down('xs')]: {
      width: theme.typography.pxToRem(205),
      height: theme.typography.pxToRem(47),
    },
  }),
};
