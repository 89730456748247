import { Modals } from '@/constants';

import { AppModal } from '../AppModal';
import { EpisodeFeedbackModalContent } from './EpisodeFeedbackModalContent';

export const EpisodeFeedbackModal = () => {
  return (
    <AppModal modalName={Modals.EPISODE_FEEDBACK}>
      <EpisodeFeedbackModalContent />
    </AppModal>
  );
};
