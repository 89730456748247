import { ChapterStatus, FsmChapterStatus } from '@/constants';
import { convertSecondsToMinutes } from '@/utils';

export const getChapterStatusText = ({ userChapter, region, isFsmPlayer }) => {
  if (
    userChapter.status === ChapterStatus.VERIFIED ||
    userChapter.status === FsmChapterStatus.VERIFIED
  )
    return region === 'CAN' ? 'Quiz Completed!' : 'Quizzes Completed!';
  if (
    userChapter.status === ChapterStatus.QUIZ_READY ||
    userChapter.status === FsmChapterStatus.QUIZ_READY
  )
    return region === 'CAN' ? 'Quiz Ready' : 'Quizzes Ready';
  if (userChapter.status === ChapterStatus.ON_GOING)
    if (isFsmPlayer) {
      return `In Progress - ${convertSecondsToMinutes(userChapter.progress.playableDuration - userChapter.progress.playedDuration)} min remaining`;
    } else {
      // Not sure this is correct but it certainly is wrong for FSM
      return `In Progress - ${convertSecondsToMinutes(userChapter.progress.seekableDuration - userChapter.progress.playedDuration)} min remaining`;
    }
};
