import { Error } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AuthPageWrapper } from '@/components';
import { Routes } from '@/constants';
import { useGetOfferQuery } from '@/store';

export const ValidateOffer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { offerId } = useParams();

  const {
    data: offer,
    error,
    isLoading,
  } = useGetOfferQuery({
    sku: offerId,
  });

  // Check if the offer exists and is free
  const isOfferValid =
    offer && offer.active && offer.price - offer.discount === 0;

  useEffect(() => {
    if (!isLoading && isOfferValid && !error) {
      const searchParams = Object.fromEntries(
        new URLSearchParams(location.search),
      );
      searchParams.offerId = offerId;

      navigate(
        {
          pathname:
            searchParams.view === 'login' ? Routes.LOGIN : Routes.REGISTER,
          search: new URLSearchParams(searchParams).toString(),
        },
        {
          replace: true,
        },
      );
    }
  }, [location, navigate, isLoading, error, isOfferValid, offerId]);

  return (!isLoading && !isOfferValid) || error ? (
    <AuthPageWrapper>
      <Box>
        <Error sx={{ color: 'red', width: 60, height: 60, mb: 1 }} />
        <Typography variant="h3">Uh oh!</Typography>
        <Typography variant="body1" sx={{ my: 2 }}>
          This offer is no longer valid.
        </Typography>
      </Box>
    </AuthPageWrapper>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
      <CircularProgress />
    </Box>
  );
};
