import { Box } from '@mui/material';

export const CoverImage = ({ src }) => {
  return (
    <Box
      sx={theme => ({
        float: 'right',
        flexShrink: 0,
        height: 200,
        width: 200,
        [theme.breakpoints.down('sm')]: {
          height: 120,
          width: 120,
        },
        ml: 2,
        '& img': {
          height: '100%',
          width: '100%',
        },
      })}>
      <img src={src} alt="learning path cover" />
    </Box>
  );
};
