export type LoginFormState = {
  email: string;
  password: string;
};

const isStringNotEmpty = (string: string) => string?.trim().length > 0;
const isEmailValid = (form: LoginFormState) =>
  isStringNotEmpty(form.email?.trim());
const isPasswordValid = (form: LoginFormState) =>
  isStringNotEmpty(form.password?.trim());

export const FormConfig = {
  email: {
    validator: isEmailValid,
    errorMessage: 'Please enter a valid email address.',
  },
  password: {
    validator: isPasswordValid,
    errorMessage: 'Please enter a valid password.',
  },
} as const;
