import { useSelector } from 'react-redux';

import { EpisodeCardCarousel } from '@/components';
import { selectUserRegion, useFetchRecommendedEpisodesQuery } from '@/store';

export const RecommendedEpisodesCarousel = () => {
  const region = useSelector(selectUserRegion);
  const { data: episodes, isLoading } =
    useFetchRecommendedEpisodesQuery(region);

  return (
    <>
      {episodes && episodes?.length > 0 && (
        <EpisodeCardCarousel
          title={`Recommended for You`}
          uKey="Recommended for you"
          episodes={episodes}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
