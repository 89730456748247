import { createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import * as R from 'ramda';

import {
  ChapterStatus,
  FsmChapterStatus,
  FsmEpisodeStatus,
  ListenRejectedReason,
  Modals,
  PlayerEvents,
} from '@/constants';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import { UserChapter, UserChapterProgress } from '@/types';
import { convertMsToHHMMSS, SnackbarUtils } from '@/utils';

import { chaptersApi } from '../chapters-api';
import { episodesApi } from '../episodes-api';
import { nodeApi } from '../node-api';
import { PlayerSessionTransitionResponse } from '../player-fsm/player-fsm.types';
import { playerFsmApi } from '../player-fsm-api';
import { postgrestApi } from '../postgrest-api';
import { selectSessionUserId } from '../session';
import { RootState } from '../store';
import { NodeApiTags, PostgrestApiTags } from '../store.constants';
import { uiActions } from '../ui';
import { selectUserRegion } from '../user-api';
import {
  selectChapterProgress,
  userChaptersApi,
  userEpisodeChapterProgressApi,
} from '../user-chapters-api';
import { PLAYER_SLICE_KEY } from './player.constants';
import {
  selectActiveChapterId,
  selectActiveEpisodeId,
  selectActiveSessionId,
  selectIsChangingChapter,
  selectIsFsmPlayer,
  selectIsPlayerPlaying,
  selectIsPlayerVideoFullscreen,
  selectIsSavingProgress,
  selectLastSavedProgress,
  selectPlayerAutoSaveInterval,
  selectPlayerLocationReferrer,
  selectPlayerProgress,
} from './player.selectors';
import { playerActions, playerApi } from './player.slice';
import {
  ChangeActiveChapterThunkPayload,
  ChangeActiveChapterThunkResolveValue,
  ChapterPlayPressedThunkPayload,
  EpisodePlayPressedThunkPayload,
  LogContentChangeEventsThunkPayload,
  PlayerThunkWithListenRejectReason,
  RejectWithReason,
} from './player.types';
import { getListenRejectModalParams, isChapterCompleted } from './player.utils';

export const playPlayer = createAsyncThunk<void, void, { state: RootState }>(
  `${PLAYER_SLICE_KEY}/play`,
  async (_, { dispatch, getState }) => {
    const isFsmPlayer = selectIsFsmPlayer(getState());
    const sessionId = selectActiveSessionId(getState());
    const chapterId = selectActiveChapterId(getState());
    const isPlayerPlaying = selectIsPlayerPlaying(getState());
    const position = selectPlayerProgress(getState())?.position;

    if (!isPlayerPlaying && isFsmPlayer && sessionId && chapterId) {
      dispatch(
        playerFsmApi.endpoints.playPlayerSession.initiate({
          sessionId,
          chapterId,
          position,
        }),
      );
    }
  },
);

export const pausePlayer = createAsyncThunk<void, void, { state: RootState }>(
  `${PLAYER_SLICE_KEY}/pause`,
  async (_, { dispatch, getState }) => {
    const isFsmPlayer = selectIsFsmPlayer(getState());
    const sessionId = selectActiveSessionId(getState());
    const chapterId = selectActiveChapterId(getState());
    const position = selectPlayerProgress(getState()).position;
    const isPlayerPlaying = selectIsPlayerPlaying(getState());

    if (
      isPlayerPlaying &&
      isFsmPlayer &&
      sessionId &&
      chapterId &&
      !R.isNil(position)
    ) {
      dispatch(
        playerFsmApi.endpoints.pausePlayerSession.initiate({
          sessionId,
          chapterId,
          position,
        }),
      );
    }
  },
);

const resumeLastSessionLegacy = createAsyncThunk<
  void,
  ChangeActiveChapterThunkPayload | void,
  {
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/resumeLastSessionLegacy`,
  async (_, { dispatch, getState }) => {
    const userId = selectSessionUserId(getState());

    if (userId) {
      const { chapterId, episodeId } =
        (await dispatch(
          playerApi.endpoints.fetchLastActiveChapter.initiate(undefined, {
            subscribe: false,
          }),
        ).unwrap()) || {};

      if (!chapterId || !episodeId) {
        return;
      }

      await dispatch(
        changeActiveChapter({
          activeChapter: {
            chapterId,
            episodeId,
          },
          autoPlay: false,
          initialLoad: true,
        }),
      );
    }
  },
);

const resumeLastSessionFsm = createAsyncThunk<
  ChangeActiveChapterThunkResolveValue | void,
  void,
  {
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/resumeLastSessionFsm`,
  async (_, { dispatch, getState }) => {
    const userId = selectSessionUserId(getState());
    if (!userId) return;

    const lastPlayerSession = await dispatch(
      playerFsmApi.endpoints.resumeLastPlayerSession.initiate(),
    ).unwrap();

    if (!lastPlayerSession) return;

    const {
      clientDirectives,
      sessionId,
      state: { chapters, episodeId, currentPosition },
    } = lastPlayerSession;

    if (!currentPosition) return;

    dispatch(
      playerActions.setPlaybackSpeedOptions(clientDirectives.playbackSpeeds),
    );
    dispatch(
      playerActions.setAutoSaveInterval(clientDirectives.saveIntervalDelaySec),
    );

    const { chapterId } = currentPosition;

    const newChapter = await dispatch(
      chaptersApi.endpoints.fetchChapter.initiate(
        {
          chapterId,
          region: selectUserRegion(getState()),
        },
        { subscribe: false },
      ),
    ).unwrap();

    if (!newChapter) {
      throw new Error('chapter not found');
    }

    if (newChapter.isTrailer) return;

    const isNewChapterVideo = newChapter.type === 'video';
    const isVideoFullscreen = selectIsPlayerVideoFullscreen(getState());

    const chapterProgress = chapters.find(({ id }) => id === chapterId);

    if (!chapterProgress) {
      throw new Error('Chapter progress not found');
    }

    const { currentTime } = chapterProgress.progress;
    const { duration } = newChapter;

    const isLastUserProgressAtEndOfChapter = isChapterCompleted({
      currentTime,
      duration,
    });

    const seekToPosition = isLastUserProgressAtEndOfChapter ? 0 : currentTime;

    if (seekToPosition === 0) {
      await dispatch(
        playerFsmApi.endpoints.seekPosition.initiate({
          sessionId,
          chapterId,
          position: -0,
        }),
      ).unwrap();
    }

    return {
      activeChapter: {
        chapterId,
        episodeId,
        sessionId,
      },
      autoPlay: false,
      isExpanded: false,
      isVideoFullscreen: isVideoFullscreen && isNewChapterVideo,
      seekToPosition,
    };
  },
);

const resumeLastSession = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/resumeLastSession`,
  async (_, { getState, dispatch }) => {
    const isFsmPlayer = selectIsFsmPlayer(getState());
    await (isFsmPlayer
      ? dispatch(resumeLastSessionFsm())
      : dispatch(resumeLastSessionLegacy()));
  },
);

const chapterPlayPressed = createAsyncThunk<
  void,
  ChapterPlayPressedThunkPayload,
  {
    rejectValue: PlayerThunkWithListenRejectReason;
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/chapterPlayPressed`,
  async (payload, { getState, dispatch }) => {
    const { activeChapter, location, referrer } = payload;
    const playerChapterId = selectActiveChapterId(getState());
    const isChapterActive = playerChapterId === activeChapter.chapterId;
    const region = selectUserRegion(getState());

    const { chapterId, episodeId } = activeChapter;
    const episode = await dispatch(
      episodesApi.endpoints.fetchEpisode.initiate(
        { episodeId, region },
        { subscribe: false },
      ),
    ).unwrap();
    const chapter = await dispatch(
      chaptersApi.endpoints.fetchChapter.initiate(
        { chapterId, region },
        { subscribe: false },
      ),
    ).unwrap();

    if (!chapter) {
      throw new Error('chapter not found');
    }

    if (!episode) {
      throw new Error('episode not found');
    }

    if (isChapterActive) {
      const isPlayerPlaying = selectIsPlayerPlaying(getState());
      dispatch(isPlayerPlaying ? pausePlayer() : playPlayer());

      AnalyticsService.chapterPause(
        {
          chapter,
          episode,
          isTrailer: !!chapter.isTrailer,
          isVerifiable: !!chapter.isVerifiable,
          type: chapter.type || 'audio',
          location,
          referrer,
        },
        isPlayerPlaying,
      );
    } else {
      try {
        await dispatch(changeActiveChapter(payload)).unwrap();
      } catch (error: any) {
        if (error.reason) {
          dispatch(
            handleListenRejected({
              reason: error.reason,
              episodeId: activeChapter.episodeId,
            }),
          );
        } else {
          throw error;
        }
      }
    }
  },
);

const episodePlayPressed = createAsyncThunk<
  void,
  EpisodePlayPressedThunkPayload,
  {
    rejectValue: PlayerThunkWithListenRejectReason;
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/episodePlayPressed`,
  async (payload, { getState, dispatch }) => {
    const { episodeId, location, referrer } = payload;

    const userId = selectSessionUserId(getState());
    const isFsmPlayer = selectIsFsmPlayer(getState());

    if (!userId) {
      dispatch(
        handleListenRejected({
          reason: ListenRejectedReason.NOT_LOGGED_IN,
          episodeId,
        }),
      );
      return;
    }

    const episode = await dispatch(
      episodesApi.endpoints.fetchEpisode.initiate(
        {
          episodeId,
          region: selectUserRegion(getState()),
          includeArchived: true,
        },
        { subscribe: false },
      ),
    ).unwrap();

    if (!episode) {
      throw new Error('episode not found');
    }

    if (episode.isArchived) {
      SnackbarUtils.warning('Episode has been archived');
      throw new Error('Episode has been archived');
    }

    const playerChapterId = selectActiveChapterId(getState());
    const playerEpisodeId = selectActiveEpisodeId(getState());
    const isEpisodeActive = playerEpisodeId === episodeId;
    const isTrailerActive = episode.trailerId === playerChapterId;
    const chapter = playerChapterId
      ? await dispatch(
          chaptersApi.endpoints.fetchChapter.initiate(
            {
              chapterId: playerChapterId,
              region: selectUserRegion(getState()),
            },
            {
              subscribe: false,
            },
          ),
        ).unwrap()
      : null;

    if (isEpisodeActive && !isTrailerActive) {
      const isPlayerPlaying = selectIsPlayerPlaying(getState());
      dispatch(isPlayerPlaying ? pausePlayer() : playPlayer());

      if (chapter) {
        AnalyticsService.chapterPause(
          {
            chapter,
            episode,
            isTrailer: !!chapter.isTrailer,
            isVerifiable: !!chapter.isVerifiable,
            type: chapter.type || 'audio',
            location,
            referrer,
          },
          isPlayerPlaying,
        );
      }
    } else {
      const recentChapterId = isFsmPlayer
        ? undefined
        : await dispatch(
            playerApi.endpoints.fetchEpisodeRecentChapterId.initiate(
              {
                episodeId,
              },
              {
                subscribe: false,
              },
            ),
          ).unwrap();

      try {
        await dispatch(
          changeActiveChapter({
            ...payload,
            activeChapter: { episodeId, chapterId: recentChapterId },
          }),
        ).unwrap();
      } catch (error: any) {
        if (error.reason) {
          dispatch(
            handleListenRejected({
              reason: error.reason,
              episodeId,
            }),
          );
          return;
        }

        throw error;
      }
    }
  },
);

const changeActiveChapterFsm = createAsyncThunk<
  ChangeActiveChapterThunkResolveValue,
  ChangeActiveChapterThunkPayload,
  {
    rejectValue: RejectWithReason;
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/changeActiveChapterFsm`,
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const { activeChapter, location, referrer } = payload;
    const { episodeId } = activeChapter;

    const isPlayerPlaying = selectIsPlayerPlaying(getState());
    const currentChapterId = selectActiveChapterId(getState());

    if (currentChapterId && isPlayerPlaying) {
      dispatch(pausePlayer());
    }

    const {
      clientDirectives,
      sessionId: activeSessionId,
      state: { chapters, currentPosition },
    } = await dispatch(
      playerFsmApi.endpoints.startPlayerSession.initiate({
        episodeId,
      }),
    ).unwrap();

    const lastChapterListened = currentPosition?.chapterId;
    const isLastChapterListenedTrailer = chapters.find(
      c => c.id === lastChapterListened,
    )?.isTrailer;
    const firstNonTrailerChapter = chapters.find(c => !c.isTrailer)?.id;

    const chapterId =
      activeChapter.chapterId ||
      (!isLastChapterListenedTrailer && lastChapterListened) ||
      firstNonTrailerChapter;

    if (!chapterId)
      throw new Error('No verifiable chapter ID provided or determined');

    dispatch(
      playerActions.setPlaybackSpeedOptions(clientDirectives.playbackSpeeds),
    );
    dispatch(
      playerActions.setAutoSaveInterval(clientDirectives.saveIntervalDelaySec),
    );

    try {
      await dispatch(
        playerFsmApi.endpoints.playPlayerSession.initiate({
          sessionId: activeSessionId,
          chapterId,
        }),
      ).unwrap();
    } catch (error: any) {
      if (error.status === 401) {
        return rejectWithValue({
          reason: ListenRejectedReason.NOT_LOGGED_IN,
          chapterId,
          episodeId,
        } as RejectWithReason);
      }

      // TODO constant
      if (error.data?.message === 'event (play) is not authorized') {
        return rejectWithValue({
          reason: error.data?.details,
          chapterId,
          episodeId,
        });
      }

      throw error;
    }

    const region = selectUserRegion(getState());
    const newChapter = await dispatch(
      chaptersApi.endpoints.fetchChapter.initiate(
        { chapterId, region },
        { subscribe: false },
      ),
    ).unwrap();

    if (!newChapter) {
      throw new Error('chapter not found');
    }

    const isNewChapterVideo = newChapter.type === 'video';
    const isVideoFullscreen = selectIsPlayerVideoFullscreen(getState());

    const chapterProgress = chapters.find(({ id }) => id === chapterId);

    if (!chapterProgress) {
      throw new Error('Chapter progress not found');
    }

    const { currentTime } = chapterProgress.progress;
    const { duration } = newChapter;

    const isLastUserProgressAtEndOfChapter = isChapterCompleted({
      currentTime,
      duration,
    });

    const seekToPosition = isLastUserProgressAtEndOfChapter ? 0 : currentTime;

    if (seekToPosition === 0) {
      await dispatch(
        playerFsmApi.endpoints.seekPosition.initiate({
          sessionId: activeSessionId,
          chapterId,
          position: -0,
        }),
      ).unwrap();
    }

    const episode = await dispatch(
      episodesApi.endpoints.fetchEpisode.initiate(
        { episodeId, region },
        { subscribe: false },
      ),
    ).unwrap();

    // the fsm player will handle the majority of analytics events via savePlayerProgressFsm
    AnalyticsService.chapterPlay({
      chapter: newChapter,
      episode,
      isTrailer: !!newChapter?.isTrailer,
      isVerifiable: !!newChapter?.isVerifiable,
      type: newChapter?.type || 'audio',
      location,
      referrer,
    });

    return {
      ...payload,
      activeChapter: {
        chapterId,
        episodeId,
        sessionId: activeSessionId,
      },
      isExpanded: isNewChapterVideo,
      isVideoFullscreen: isVideoFullscreen && isNewChapterVideo,
      seekToPosition,
    };
  },
);

const changeActiveChapterLegacy = createAsyncThunk<
  ChangeActiveChapterThunkResolveValue,
  ChangeActiveChapterThunkPayload,
  {
    rejectValue: RejectWithReason;
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/changeActiveChapterLegacy`,
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const { activeChapter, initialLoad = false, referrer, location } = payload;
    const { episodeId, chapterId } = activeChapter;

    if (!chapterId)
      throw new Error('ChapterId is required for legacy changeActiveChapter');

    const userId = selectSessionUserId(getState());
    const isPlayerPlaying = selectIsPlayerPlaying(getState());
    const currentChapterId = selectActiveChapterId(getState());

    if (currentChapterId && isPlayerPlaying) {
      dispatch(pausePlayer());
    }

    try {
      const data = await dispatch(
        playerApi.endpoints.fetchChapterListenAllowed.initiate(
          {
            chapterId,
          },
          {
            forceRefetch: true,
            subscribe: false,
          },
        ),
      ).unwrap();
      if (!data.response) {
        return rejectWithValue({ reason: data.reason, chapterId, episodeId });
      }
    } catch (error: any) {
      if (error.status === 401) {
        return rejectWithValue({
          reason: ListenRejectedReason.NOT_LOGGED_IN,
          chapterId,
          episodeId,
        });
      }
      throw error;
    }

    const newChapter = await dispatch(
      chaptersApi.endpoints.fetchChapter.initiate(
        {
          chapterId,
          region: selectUserRegion(getState()),
        },
        { subscribe: false },
      ),
    ).unwrap();

    if (!newChapter) {
      throw new Error('chapter not found');
    }

    const isNewChapterVideo = newChapter.type === 'video';
    const isVideoFullscreen = selectIsPlayerVideoFullscreen(getState());

    const chapterProgress = userId
      ? await dispatch(
          userChaptersApi.endpoints.fetchChapterProgress.initiate(
            {
              episodeId,
            },
            {
              forceRefetch: true,
              subscribe: false,
            },
          ),
        ).unwrap()
      : [];

    const currentTime =
      chapterProgress.find(({ id }) => id === chapterId)?.progress
        ?.currentTime || 0;

    const isLastUserProgressAtEndOfChapter = isChapterCompleted({
      currentTime,
      duration: newChapter?.duration,
    });

    const seekToPosition = isLastUserProgressAtEndOfChapter ? 0 : currentTime;

    if (!initialLoad) {
      const episodeProgress = userId
        ? await dispatch(
            userEpisodeChapterProgressApi.endpoints.fetchEpisodeChapterProgress.initiate(
              { episodeId },
              { subscribe: false },
            ),
          ).unwrap()
        : [];

      await dispatch(
        logContentChangeEvents({
          chapterId,
          episodeId,
          episodeProgress,
          location,
          referrer,
        }),
      );

      await dispatch(
        savePlayerProgressLegacy({
          chapterId,
          position: seekToPosition,
          eventType: PlayerEvents.PLAY,
        }),
      );
    }

    return {
      ...payload,
      isExpanded: !initialLoad && isNewChapterVideo,
      isVideoFullscreen: isVideoFullscreen && isNewChapterVideo,
      seekToPosition,
    };
  },
);

const changeActiveChapter = createAsyncThunk<
  ChangeActiveChapterThunkResolveValue,
  ChangeActiveChapterThunkPayload,
  {
    rejectValue: RejectWithReason;
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/changeActiveChapter`,
  async (payload, { dispatch, getState, rejectWithValue }) => {
    await dispatch(
      playerFsmApi.endpoints.fetchPlayerConfig.initiate(undefined, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
    const isFsmPlayer = selectIsFsmPlayer(getState());

    const action = await (isFsmPlayer
      ? dispatch(changeActiveChapterFsm(payload))
      : dispatch(changeActiveChapterLegacy(payload)));

    if (isRejectedWithValue(action)) {
      return rejectWithValue(action.payload as RejectWithReason);
    }

    return action.payload;
  },
);

const seekToPosition = createAsyncThunk<
  { position: number },
  { position?: number; delta?: number },
  {
    rejectValue: RejectWithReason;
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/seekToPosition`,
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const isFsmPlayer = selectIsFsmPlayer(getState());
    const action = await (isFsmPlayer
      ? dispatch(seekToPositionFsm(payload))
      : dispatch(seekToPositionLegacy(payload)));

    if (isRejectedWithValue(action)) {
      return rejectWithValue(action.payload as RejectWithReason);
    }

    return action.payload;
  },
);

const seekToPositionLegacy = createAsyncThunk<
  { position: number },
  { position?: number; delta?: number },
  {
    rejectValue: RejectWithReason;
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/seekToPositionLegacy`,
  async (payload, { dispatch, getState }) => {
    const userId = selectSessionUserId(getState());
    const playerProgress = selectPlayerProgress(getState());
    const activeChapterId = selectActiveChapterId(getState());
    const activeEpisodeId = selectActiveEpisodeId(getState());
    const region = selectUserRegion(getState());
    const isNasba = region === 'USA';

    if (
      activeChapterId === undefined ||
      activeEpisodeId === undefined ||
      playerProgress.position === undefined
    ) {
      throw new Error(
        'activeChapterId, activeEpisodeId or playerProgress not set',
      );
    }

    const episode = await dispatch(
      episodesApi.endpoints.fetchEpisode.initiate({
        episodeId: activeEpisodeId,
        region,
      }),
    ).unwrap();
    const chapter = await dispatch(
      chaptersApi.endpoints.fetchChapter.initiate(
        {
          chapterId: activeChapterId,
          region,
        },
        { subscribe: false },
      ),
    ).unwrap();
    const result = userId
      ? await dispatch(
          userChaptersApi.endpoints.fetchChapterProgress.initiate(
            {
              episodeId: activeEpisodeId,
            },
            {
              forceRefetch: true,
              subscribe: false,
            },
          ),
        ).unwrap()
      : [];
    const chapterProgress = result.find(
      ({ id }) => id === activeChapterId,
    )?.progress;

    if (!chapter || !(chapterProgress || chapter.isTrailer)) {
      throw new Error('chapter not found');
    }

    const isInTestMode = localStorage.getItem('testMode');
    const isCompleted = isChapterCompleted({
      currentTime: chapterProgress?.playedDuration || 0,
      duration: chapter.duration,
    });

    const position =
      payload.position !== undefined
        ? payload.position
        : playerProgress.position + (payload.delta ?? 0);

    const minSeekPosition = 1;
    const maxSeekPosition =
      isCompleted || chapter.isTrailer || isNasba || isInTestMode
        ? chapter.duration
        : Math.max(
            playerProgress.position,
            chapterProgress?.playedDuration || 0,
          );
    if (position > maxSeekPosition) {
      const message = `Skipping past ${convertMsToHHMMSS(maxSeekPosition * 1000)} not allowed, content must be listened to first!`;
      SnackbarUtils.info(message);
    }

    const seekToPosition = Math.max(
      Math.min(position, maxSeekPosition),
      minSeekPosition,
    );

    if (seekToPosition !== playerProgress.position) {
      dispatch(playerActions.setSeekToPosition(seekToPosition));

      await dispatch(
        savePlayerProgressLegacy({
          chapterId: activeChapterId,
          position: seekToPosition,
          eventType:
            seekToPosition > playerProgress.position
              ? PlayerEvents.SEEK_FORWARD
              : PlayerEvents.SEEK_BACK,
        }),
      );

      AnalyticsService.chapterSeek({
        chapter,
        episode,
        type: chapter.type || 'audio',
        isTrailer: !!chapter.isTrailer,
        isVerifiable: !!chapter.isVerifiable,
        isNasba,
        seekStart: playerProgress.position,
        seekLength: seekToPosition - playerProgress.position,
        alreadyListened: isCompleted,
      });
    }

    return {
      position: seekToPosition,
    };
  },
);

const seekToPositionFsm = createAsyncThunk<
  { position: number },
  { position?: number; delta?: number },
  {
    rejectValue: RejectWithReason;
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/seekToPositionFsm`,
  async (payload, { dispatch, getState }) => {
    const playerProgress = selectPlayerProgress(getState());
    const activeChapterId = selectActiveChapterId(getState());
    const activeEpisodeId = selectActiveEpisodeId(getState());
    const activeSessionId = selectActiveSessionId(getState());
    const region = selectUserRegion(getState());
    const isNasba = region === 'USA';

    if (
      activeChapterId === undefined ||
      activeEpisodeId === undefined ||
      activeSessionId === undefined ||
      playerProgress.position === undefined
    ) {
      throw new Error(
        'activeChapterId, activeEpisodeId, activeSessionId or playerProgress not set',
      );
    }

    const episode = await dispatch(
      episodesApi.endpoints.fetchEpisode.initiate({
        episodeId: activeEpisodeId,
        region,
      }),
    ).unwrap();

    if (!episode) {
      throw new Error('episode not found');
    }

    const chapter = await dispatch(
      chaptersApi.endpoints.fetchChapter.initiate(
        {
          chapterId: activeChapterId,
          region,
        },
        { subscribe: false },
      ),
    ).unwrap();

    const userChapter = selectChapterProgress(getState(), {
      episodeId: activeEpisodeId,
      chapterId: activeChapterId,
    }) as UserChapter | undefined;

    const chapterProgress = userChapter?.progress;

    if (!chapter || !(chapterProgress || chapter.isTrailer)) {
      throw new Error('chapter not found');
    }

    const isInTestMode = !!localStorage.getItem('testMode');
    const isCompleted = isChapterCompleted({
      currentTime: chapterProgress?.playedDuration || 0,
      duration: chapter.duration,
    });

    const position =
      payload.position !== undefined
        ? payload.position
        : playerProgress.position + (payload.delta ?? 0);

    // TODO avoid determining seekToPosition on the client side
    // interpret the error response from calling playerFsmApi.endpoints.seekPosition instead
    const minSeekPosition = 1;
    const maxSeekPosition =
      isCompleted || chapter.isTrailer || isNasba || isInTestMode
        ? chapter.duration
        : Math.max(
            playerProgress.position,
            chapterProgress?.playedDuration || 0,
          );
    if (position > maxSeekPosition) {
      const message = `Skipping past ${convertMsToHHMMSS(maxSeekPosition * 1000)} not allowed, content must be listened to first!`;
      SnackbarUtils.info(message);
    }

    const seekToPosition = Math.max(
      Math.min(position, maxSeekPosition),
      minSeekPosition,
    );

    if (seekToPosition !== playerProgress.position) {
      dispatch(playerActions.setSeekToPosition(seekToPosition));

      await dispatch(
        playerFsmApi.endpoints.seekPosition.initiate({
          sessionId: activeSessionId,
          chapterId: activeChapterId,
          position:
            seekToPosition > playerProgress.position
              ? seekToPosition
              : -seekToPosition,
          isInTestMode,
        }),
      ).unwrap();

      AnalyticsService.chapterSeek({
        chapter: chapter,
        episode: episode,
        type: chapter.type || 'audio',
        isTrailer: !!chapter.isTrailer,
        isVerifiable: !!chapter.isVerifiable,
        isNasba,
        seekStart: playerProgress.position,
        seekLength: seekToPosition - playerProgress.position,
        alreadyListened: isCompleted,
      });
    }

    return {
      position: seekToPosition,
    };
  },
);

const updatePlayerProgress = createAsyncThunk<
  void,
  {
    progress: { position: number };
  },
  {
    rejectValue: RejectWithReason;
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/updatePlayerProgress`,
  async (payload, { dispatch, getState }) => {
    const {
      progress: { position },
    } = payload;

    const isChangingChapter = selectIsChangingChapter(getState());
    const isSavingProgress = selectIsSavingProgress(getState());
    const lastSavedProgress = selectLastSavedProgress(getState());
    const autoSaveInterval = selectPlayerAutoSaveInterval(getState());
    const progressSinceLastSave = position - lastSavedProgress;
    const activeChapterId = selectActiveChapterId(getState());

    if (!activeChapterId) {
      throw new Error('activeChapterId not found');
    }

    const chapter = await dispatch(
      chaptersApi.endpoints.fetchChapter.initiate(
        {
          chapterId: activeChapterId,
          region: selectUserRegion(getState()),
        },
        { subscribe: false },
      ),
    ).unwrap();

    if (!chapter) {
      throw new Error('chapter not found');
    }

    const { isTrailer } = chapter;
    const isFsmPlayer = selectIsFsmPlayer(getState());

    if (
      !isChangingChapter &&
      !isSavingProgress &&
      !isTrailer &&
      progressSinceLastSave >= autoSaveInterval
    ) {
      if (isFsmPlayer) {
        const sessionId = selectActiveSessionId(getState());
        if (sessionId) {
          await dispatch(
            savePlayerProgressFsm({
              chapterId: activeChapterId,
              position,
              sessionId,
            }),
          );
        }
      } else {
        await dispatch(
          savePlayerProgressLegacy({
            chapterId: activeChapterId,
            position,
          }),
        );
      }
    }
  },
);

const savePlayerProgressLegacy = createAsyncThunk<
  { position?: number },
  {
    chapterId: string;
    position: number;
    eventType?: (typeof PlayerEvents)[keyof typeof PlayerEvents];
  },
  {
    rejectValue: RejectWithReason;
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/savePlayerProgressLegacy`,
  async (payload, { dispatch, getState }) => {
    const { chapterId, position, eventType } = payload;

    const userId = selectSessionUserId(getState());
    if (!userId) return { position: undefined };

    const chapter = await dispatch(
      chaptersApi.endpoints.fetchChapter.initiate(
        {
          chapterId,
          region: selectUserRegion(getState()),
        },
        { subscribe: false },
      ),
    ).unwrap();

    if (!chapter) {
      throw new Error('chapter not found');
    }

    const userChapter = selectChapterProgress(getState(), {
      episodeId: chapter.episodeId,
      chapterId,
    }) as UserChapterProgress;

    const event = {
      chapterId,
      progress: {
        ...userChapter?.progress,
        currentTime: position,
        playedDuration: position,
        seekableDuration: chapter.duration,
      },
      eventType,
      recordedAt: Date.now(),
    };

    const { quizRedeemed, assessmentRedeemed } = await dispatch(
      playerApi.endpoints.saveChapterProgress.initiate({
        event,
      }),
    ).unwrap();

    if (
      userChapter?.status === ChapterStatus.NONE ||
      quizRedeemed ||
      assessmentRedeemed
    ) {
      const chapters: UserChapterProgress[] = await dispatch(
        userEpisodeChapterProgressApi.endpoints.fetchEpisodeChapterProgress.initiate(
          {
            episodeId: chapter.episodeId,
          },
          {
            forceRefetch: true,
            subscribe: false,
          },
        ),
      ).unwrap();

      if (chapters.every(chapter => chapter.completed)) {
        const region = selectUserRegion(getState());
        const episode = await dispatch(
          episodesApi.endpoints.fetchEpisode.initiate({
            episodeId: chapter.episodeId,
            region,
          }),
        ).unwrap();

        dispatch(
          uiActions.setActiveModal({
            name: Modals.EPISODE_FEEDBACK,
            params: {
              episodeId: chapter.episodeId,
              chapterId: chapter.chapterId,
            },
          }),
        );

        AnalyticsService.episodeListened({
          episode,
          isNasba: region === 'USA',
        });
      }

      dispatch(
        nodeApi.util.invalidateTags([
          NodeApiTags.USER_EPISODES,
          NodeApiTags.USER_QUIZZES,
          {
            type: NodeApiTags.USER_CHAPTER_PROGRESS,
            id: chapter.episodeId,
          },
        ]),
      );
    }

    return { position };
  },
);

const savePlayerProgressFsm = createAsyncThunk<
  { position?: number },
  {
    chapterId: string;
    position: number;
    sessionId: string;
  },
  {
    rejectValue: RejectWithReason;
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/savePlayerProgressFsm`,
  async (payload, { dispatch, getState }) => {
    const { chapterId, position, sessionId } = payload;

    const userId = selectSessionUserId(getState());
    if (!userId) return { position: undefined };
    const activeEpisodeId = selectActiveEpisodeId(getState());

    if (!activeEpisodeId) {
      throw new Error('activeEpisodeId not found');
    }

    const chapter = await dispatch(
      chaptersApi.endpoints.fetchChapter.initiate(
        {
          chapterId,
          region: selectUserRegion(getState()),
        },
        { subscribe: false },
      ),
    ).unwrap();

    if (!chapter) {
      throw new Error('chapter not found');
    }

    const setPositionResponse = await dispatch(
      playerFsmApi.endpoints.setPosition.initiate({
        sessionId,
        chapterId,
        position,
      }),
    ).unwrap();

    const {
      state: { transitions },
    } = setPositionResponse;

    if (transitions.length) {
      await dispatch(logAnalyticsEventsFsm({ chapterId, setPositionResponse }));

      dispatch(
        postgrestApi.util.invalidateTags([
          PostgrestApiTags.PLAYER_SESSIONS,
          PostgrestApiTags.SESSION_QUIZZES,
          PostgrestApiTags.PLAN,
        ]),
      );
    }

    return { position };
  },
);

const chapterEnded = createAsyncThunk<
  void,
  void,
  {
    rejectValue: RejectWithReason;
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/chapterEnded`,
  async (payload, { dispatch, getState }) => {
    const activeChapterId = selectActiveChapterId(getState());
    const activeEpisodeId = selectActiveEpisodeId(getState());
    const region = selectUserRegion(getState());

    if (!activeChapterId || !activeEpisodeId) {
      throw new Error('activeChapterId or activeEpisodeId not set');
    }

    const chapter = await dispatch(
      chaptersApi.endpoints.fetchChapter.initiate(
        {
          chapterId: activeChapterId,
          region: selectUserRegion(getState()),
        },
        { subscribe: false },
      ),
    ).unwrap();

    const chapters = await dispatch(
      chaptersApi.endpoints.fetchEpisodeChapters.initiate(
        {
          episodeId: activeEpisodeId,
          region,
        },
        { subscribe: false },
      ),
    ).unwrap();
    const episode = await dispatch(
      episodesApi.endpoints.fetchEpisode.initiate({
        episodeId: activeEpisodeId,
        region,
      }),
    ).unwrap();

    if (!chapter || !chapters) {
      throw new Error('chapter not found');
    }

    const chapterPosition = chapters?.find(
      ({ chapterId }) => chapterId === activeChapterId,
    )?.chapterPosition;

    if (chapterPosition === undefined) {
      throw new Error('chapter position not found');
    }

    const nextChapter = chapters?.find(
      c => c.chapterPosition === chapterPosition + 1,
    );

    AnalyticsService.chapterComplete({
      chapter,
      episode,
      isNasba: region === 'USA',
    });

    if (nextChapter && !chapter.isTrailer) {
      try {
        await dispatch(
          changeActiveChapter({
            activeChapter: {
              episodeId: nextChapter.episodeId,
              chapterId: nextChapter.chapterId,
            },
            location: {
              button: EVENT_CONSTANTS.AUTO_PLAY,
            },
          }),
        ).unwrap();
      } catch (error: any) {
        if (error.reason) {
          dispatch(
            handleListenRejected({
              reason: error.reason,
              episodeId: activeEpisodeId,
            }),
          );
        } else {
          throw error;
        }
      }
    } else {
      dispatch(playerActions.clearActiveChapter());
    }
  },
);

const handleListenRejected = createAsyncThunk<
  void,
  {
    reason: keyof typeof ListenRejectedReason;
    episodeId: string;
  }
>(
  `${PLAYER_SLICE_KEY}/handleListenRejected`,
  async (
    payload: {
      reason: keyof typeof ListenRejectedReason;
      episodeId: string;
    },
    { dispatch },
  ) => {
    dispatch(uiActions.setActiveModal(getListenRejectModalParams(payload)));
  },
);

const logAnalyticsEventsFsm = createAsyncThunk<
  void,
  {
    chapterId: string;
    setPositionResponse: PlayerSessionTransitionResponse;
  },
  {
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/logAnalyticsEventsFsm`,
  async (payload, { getState, dispatch }) => {
    const { chapterId, setPositionResponse } = payload;
    const {
      state: { newState, transitions },
    } = setPositionResponse;

    if (transitions.length) {
      const { referrer, location } = selectPlayerLocationReferrer(getState());
      const region = selectUserRegion(getState());
      const episode = await dispatch(
        episodesApi.endpoints.fetchEpisode.initiate({
          episodeId: newState.episodeId,
          region,
        }),
      ).unwrap();
      const chapter = await dispatch(
        chaptersApi.endpoints.fetchChapter.initiate(
          { chapterId, region },
          { subscribe: false },
        ),
      ).unwrap();

      const isNasba = region === 'USA';
      const isEpisodeListenCompleted = newState.chapters.every(
        c => c.completed || !c.isVerifiable,
      );
      const chapterStartedTransition = transitions.find(
        t => t.stateType === 'chapter' && t.oldState === FsmChapterStatus.READY,
      );
      const episodeStartedTransition = transitions.find(
        t => t.stateType === 'session' && t.oldState === FsmEpisodeStatus.READY,
      );

      if (chapterStartedTransition) {
        AnalyticsService.chapterStart({
          chapter,
          episode,
          isNasba,
          location,
          referrer,
        });
      }

      if (episodeStartedTransition) {
        AnalyticsService.episodeStart({
          chapter,
          episode,
          isNasba,
          location,
          referrer,
        });
      }

      if (isEpisodeListenCompleted) {
        AnalyticsService.episodeListened({
          episode,
          isNasba,
        });

        dispatch(
          uiActions.setActiveModal({
            name: Modals.EPISODE_FEEDBACK,
            params: { episodeId: newState.episodeId, chapterId },
          }),
        );
      }
    }
  },
);

const logContentChangeEvents = createAsyncThunk<
  void,
  LogContentChangeEventsThunkPayload,
  {
    state: RootState;
  }
>(
  `${PLAYER_SLICE_KEY}/logContentChangeEvents`,
  async (payload, { dispatch, getState }) => {
    const { chapterId, episodeId, episodeProgress, location, referrer } =
      payload;

    const region = await selectUserRegion(getState());
    const isNasba = region === 'USA';

    const episode = await dispatch(
      episodesApi.endpoints.fetchEpisode.initiate(
        { episodeId, region },
        { subscribe: false },
      ),
    ).unwrap();
    const chapter = await dispatch(
      chaptersApi.endpoints.fetchChapter.initiate(
        { chapterId, region },
        { subscribe: false },
      ),
    ).unwrap();

    AnalyticsService.chapterPlay({
      chapter,
      episode,
      isTrailer: !!chapter?.isTrailer,
      isVerifiable: !!chapter?.isVerifiable,
      type: chapter?.type || 'audio',
      location,
      referrer,
    });

    if (chapter?.isTrailer) {
      return;
    }

    if (episodeProgress.every(({ status }) => status === ChapterStatus.NONE)) {
      AnalyticsService.episodeStart({
        chapter,
        episode,
        isNasba,
        location,
        referrer,
      });
    }

    const chapterStatus = episodeProgress.find(
      progress => progress.chapterId === chapterId,
    )?.status;

    if (!chapterStatus || chapterStatus === ChapterStatus.NONE) {
      AnalyticsService.chapterStart({
        chapter,
        episode,
        isNasba,
        location,
        referrer,
      });
    }
  },
);

export const playerThunks = {
  resumeLastSession,
  resumeLastSessionFsm,
  episodePlayPressed,
  chapterPlayPressed,
  changeActiveChapter,
  seekToPosition,
  updatePlayerProgress,
  savePlayerProgressLegacy,
  savePlayerProgressFsm,
  chapterEnded,
  playPlayer,
  pausePlayer,
};
