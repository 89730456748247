import { useDispatch, useSelector } from 'react-redux';

import { Modals } from '@/constants';
import { useBookmarkManagement } from '@/hooks';
import { EVENT_CONSTANTS } from '@/services';
import {
  playerThunks,
  selectIsEpisodeRedeemRequired,
  selectUserRegion,
  uiActions,
  userEpisodesSelectors,
  userEpisodesThunks,
} from '@/store';

import { EpisodeControlButtons as EpisodeControlButtonsComponent } from './EpisodeControlButtons.component';

// TODO: tweak this based on screen sizes and what buttons are visible when designs are finalized
// TODO: should the feedback button be unhighlighted if the user's already submitted feedback?
export const EpisodeControlButtons = ({ episode, referrer }) => {
  const { episodeId } = episode;

  const dispatch = useDispatch();

  const region = useSelector(selectUserRegion);
  const isEpisodeRedeemRequired = useSelector(state =>
    selectIsEpisodeRedeemRequired(state, episodeId),
  );
  const userEpisode = useSelector(state =>
    userEpisodesSelectors.selectById(state, episodeId),
  );
  const episodeStatus = userEpisode?.status;

  const {
    toggleBookmark: onToggleBookmark,
    isBookmarking,
    isBookmarked,
  } = useBookmarkManagement({
    episodeId,
    location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.BOOKMARK },
    referrer,
  });

  if (episode.isArchived) return null;

  const onRedeem = () => {
    dispatch(userEpisodesThunks.initiateRedemption({ episodeId }));
  };

  const onPlayNow = () => {
    dispatch(
      playerThunks.episodePlayPressed({
        episodeId,
        location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.PLAY_NOW },
        referrer,
      }),
    );
  };

  const onTrailer = () => {
    dispatch(
      playerThunks.chapterPlayPressed({
        activeChapter: {
          episodeId,
          chapterId: episode.trailerId,
        },
        location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.PREVIEW },
        referrer,
      }),
    );
  };

  const onTakeAssessment = () => {
    if (isEpisodeRedeemRequired) {
      dispatch(userEpisodesThunks.initiateRedemption({ episodeId }));
      return;
    }
    dispatch(
      uiActions.setActiveModal({
        name: Modals.ASSESSMENT,
        params: { episodeId },
      }),
    );
  };

  const onGiveFeedback = () => {
    dispatch(
      uiActions.setActiveModal({
        name: Modals.EPISODE_FEEDBACK,
        params: { episodeId },
      }),
    );
  };

  const episodeControlButtonsProps = {
    region,
    isEpisodeRedeemRequired,
    episodeStatus,
    onRedeem,
    onPlayNow,
    isBookmarked,
    isBookmarking,
    onToggleBookmark,
    onTrailer,
    onTakeAssessment,
    onGiveFeedback,
  };

  return <EpisodeControlButtonsComponent {...episodeControlButtonsProps} />;
};
