import { Slider } from '@mui/material';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  selectIsPlayerSeeking,
  selectIsTrackLoaded,
  selectPlayerProgress,
  selectPlayerSeekToPosition,
} from '@/store';

export const ProgressBar = ({ chapter, setSeekPosition }) => {
  const [sliderValue, setSliderValue] = useState(null);
  const [isSliding, setIsSliding] = useState(false);

  const playerProgress = useSelector(selectPlayerProgress);
  const seekToPosition = useSelector(selectPlayerSeekToPosition);
  const isPlayerSeeking = useSelector(selectIsPlayerSeeking);
  const isTrackLoaded = useSelector(selectIsTrackLoaded);

  const chapterDuration = chapter?.duration;

  useEffect(() => {
    if (
      !isSliding &&
      !isPlayerSeeking &&
      !R.isNil(playerProgress.position) &&
      sliderValue !== playerProgress.position
    ) {
      setSliderValue(playerProgress.position);
    }
  }, [isPlayerSeeking, playerProgress, sliderValue, isSliding]);

  const onSlide = (e, val) => {
    setSliderValue(val);
    setIsSliding(true);
  };

  const onSlidingComplete = (e, val) => {
    setSeekPosition({ position: val });
    setIsSliding(false);
  };

  const position =
    sliderValue ||
    (isPlayerSeeking ? seekToPosition : playerProgress.position) ||
    0;

  return (
    <Slider
      value={position}
      max={chapterDuration}
      onChange={onSlide}
      onChangeCommitted={onSlidingComplete}
      sx={{
        ...styles.root,
        '& .MuiSlider-thumb': styles.thumb,
        '& .MuiSlider-track': styles.track,
        '& .MuiSlider-rail': styles.rail,
      }}
      disabled={!isTrackLoaded}
    />
  );
};

const styles = {
  root: {
    height: theme => theme.typography.pxToRem(4),
    p: theme => `${theme.typography.pxToRem(10)} 0`,
    verticalAlign: 'middle',
  },
  track: {
    color: 'aqua',
    height: theme => theme.typography.pxToRem(4),
  },
  rail: {
    color: 'white',
    height: theme => theme.typography.pxToRem(4),
  },
  thumb: {
    color: 'aqua',
    height: theme => theme.typography.pxToRem(12),
    width: theme => theme.typography.pxToRem(12),
  },
};
