import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components';
import { Modals, TrackerMessages } from '@/constants';
import { AppModal } from '@/containers/AppModals/components/AppModal';
import {
  selectActiveModalParams,
  uiActions,
  useDeleteCustomCPDRecordMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

export const TrackerDeleteModal = () => {
  const dispatch = useDispatch();

  const { credit } = useSelector(selectActiveModalParams) || {};

  const [deleteCustomCPDRecord, { isLoading: isDeleteLoading }] =
    useDeleteCustomCPDRecordMutation();

  const onClose = () => dispatch(uiActions.closeActiveModal());

  const onDelete = async () => {
    const { error } = await deleteCustomCPDRecord({
      id: credit.creditId,
    });
    if (error) {
      SnackbarUtils.error(TrackerMessages.DELETE_CUSTOM_CPD_ERROR);
    } else {
      onClose();
      SnackbarUtils.success(TrackerMessages.DELETE_CUSTOM_CPD_SUCCESS);
    }
  };

  if (!credit) return null;

  return (
    <AppModal modalName={Modals.TRACKER_DELETE} fullWidth scroll="body">
      <Box
        sx={{
          p: 3,
          textAlign: 'center',
        }}>
        <Typography sx={{ mb: 3 }}>
          Are you sure you would like to delete this record?
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
          <Box sx={{ mr: 3 }}>
            <Button
              variant="tertiary"
              disabled={isDeleteLoading}
              onClick={onClose}
              label="No"
              fullWidth={false}
            />
          </Box>
          <Button
            variant="gradientPrimary"
            disabled={isDeleteLoading}
            onClick={onDelete}
            label="Yes"
            fullWidth={false}
          />
        </Box>
      </Box>
    </AppModal>
  );
};
