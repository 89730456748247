import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Routes } from '@/constants';

export const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ ml: { xs: -2, sm: 0 } }}>
      <IconButton onClick={() => navigate(Routes.LEARNING_PATHS)}>
        <ChevronLeftIcon sx={{ fontSize: { xs: 32, sm: 42 } }} />
      </IconButton>
    </Box>
  );
};
