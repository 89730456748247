import * as R from 'ramda';

import { Pill } from '@/components/Pill';
import { convertSecondsToHoursMinutes } from '@/utils';

export const DurationPill = ({ duration }) => {
  if (R.isNil(duration)) return null;
  return (
    <Pill size="small" label={convertSecondsToHoursMinutes(duration, true)} />
  );
};
