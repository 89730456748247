import { Box, Typography } from '@mui/material';
import * as R from 'ramda';

import { thumbsDown, thumbsUp } from '@/images';

export const FeedbackRatings = ({ rating, ratingScale, onRatingChange }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
      <Box
        component="img"
        src={thumbsDown}
        alt="Thumbs down"
        sx={[
          styles.thumbsDownUp,
          !R.isNil(rating) &&
            rating < Math.floor(ratingScale / 2) &&
            styles.thumbsDownUpSelected,
        ]}
      />
      <Box sx={styles.ratings}>
        {Array.from(Array(ratingScale).keys()).map((_, index) => (
          <Box
            key={index}
            sx={[styles.rating, rating === index && styles.ratingSelected]}
            onClick={() => onRatingChange?.(index)}>
            <Typography variant="subtitle3">{index + 1}</Typography>
          </Box>
        ))}
      </Box>
      <Box
        component="img"
        src={thumbsUp}
        alt="Thumbs down"
        sx={[
          styles.thumbsDownUp,
          !R.isNil(rating) &&
            rating >= Math.floor(ratingScale / 2) &&
            styles.thumbsDownUpSelected,
        ]}
      />
    </Box>
  );
};

const styles = {
  thumbsDownUp: theme => ({
    width: 55,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
  thumbsDownUpSelected: {
    opacity: 1,
  },
  ratings: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    pl: 1,
    pr: 1,
  },
  rating: {
    cursor: 'pointer',
    width: 20,
    height: 20,
    ml: 1,
    mr: 1,
    borderRadius: '50%',
    backgroundColor: 'blueLight',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ratingSelected: {
    backgroundColor: 'blue',
    color: 'white',
  },
};
