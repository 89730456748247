import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { EpisodeGrid } from '@/components';
import { EVENT_CONSTANTS } from '@/services';
import { selectUserRegion, useFetchEpisodeSearchResultsQuery } from '@/store';

export const Search = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { searchTerm } = Object.fromEntries(params);

  const userRegion = useSelector(selectUserRegion);

  const {
    data: episodes,
    isLoading,
    isFetching,
  } = useFetchEpisodeSearchResultsQuery({
    searchTerm,
    region: userRegion,
  });

  const isEmpty = episodes?.length === 0;

  return (
    <Box>
      <Typography variant="h3">Episodes</Typography>
      {!isLoading && !isFetching && isEmpty ? (
        <Typography
          sx={{ mt: 2 }}>{`No search results for '${searchTerm}'`}</Typography>
      ) : (
        <EpisodeGrid
          episodeIds={episodes?.map(e => e.episodeId)}
          isLoading={isLoading || isFetching}
          referrer={{ page: EVENT_CONSTANTS.PAGE.SEARCH }}
        />
      )}
    </Box>
  );
};
