import { isNil } from 'ramda';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useFocusListener } from '@/hooks';
import { log } from '@/services';
import { selectSessionIsLoading, selectSessionUserId } from '@/store';
import { getAccessTokenUserId } from '@/utils';

export const useCheckAccessTokenOnDocumentFocus = () => {
  const sessionIsLoading = useSelector(selectSessionIsLoading);
  const userId = useSelector(selectSessionUserId);
  const hasFocus = useFocusListener();

  const checkForAccessTokenChanges = useCallback(() => {
    try {
      if (sessionIsLoading) return;
      const browserTokenUserId = getAccessTokenUserId();

      const isLoggedOut = !isNil(userId) && isNil(browserTokenUserId);
      const isLoggedInAsDifferentUser =
        browserTokenUserId?.toString() !== userId?.toString();

      if (isLoggedOut || isLoggedInAsDifferentUser) {
        window.location.reload();
      }
    } catch (error) {
      log('Error checking for access token changes', error);
    }
  }, [userId, sessionIsLoading]);

  useEffect(() => {
    if (hasFocus) checkForAccessTokenChanges();
  }, [hasFocus, checkForAccessTokenChanges]);
};
