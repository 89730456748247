import { Box, Typography } from '@mui/material';

import { FilterLibraryPill } from '@/components/FilterLibraryPill';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';

export const SelectedOptions = ({ selectedOptions, options, onClear }) => {
  const onCancel = categoryId => {
    AnalyticsService.filterLibraryClear(
      categoryId
        ? { categoryName: options[categoryId] }
        : { categoryName: EVENT_CONSTANTS.CLEAR_ALL },
    );

    onClear(categoryId ? selectedOptions.filter(c => c !== categoryId) : []);
  };

  const validOptions = selectedOptions.filter(_id => !!options[_id]) || [];

  if (validOptions.length === 0) return null;

  return (
    <Box sx={styles.selectedOptionContainer}>
      <Box sx={styles.filterLabelContainer}>
        {validOptions.map(categoryId => (
          <Box key={categoryId} sx={{ mb: 0.75, mr: 0.75 }}>
            <FilterLibraryPill
              label={options[categoryId]}
              onDelete={() => onCancel(categoryId)}
            />
          </Box>
        ))}
        <Box
          sx={[
            validOptions.length <= 0
              ? styles.buttonHidden
              : styles.buttonClearFilter,
          ]}
          onClick={() => onCancel()}>
          <Typography variant="caption">Clear Filter</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  selectedOptionContainer: {
    marginTop: 0.75,
    marginRight: 0.75,
    display: 'flex',
    color: 'grayLight',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  filterLabelContainer: {
    display: 'flex',
    width: 'fit-content',
    flexWrap: 'wrap',
  },
  buttonHidden: {
    visibility: 'hidden',
  },
  buttonClearFilter: {
    cursor: 'pointer',
    py: 0.5,
    px: 1.5,
    display: 'flex',
    alignItems: 'center',
    color: 'bluePrimary',
    background: 'transparent',
    height: 26,
  },
};
