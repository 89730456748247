import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { episodesApi } from '@/store';
import { Episode, EpisodeCard } from '@/types';

import { EPISODES_SLICE_KEY } from './episodes.constants';

export const episodesAdapter = createEntityAdapter({
  selectId: (episode: Episode | EpisodeCard) => episode.episodeId,
});

export const episodesSlice = createSlice({
  name: EPISODES_SLICE_KEY,
  initialState: episodesAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      episodesApi.endpoints.fetchFeaturedEpisodes.matchFulfilled,
      (state, { payload }) => {
        episodesAdapter.setMany(state, payload);
      },
    );
    builder.addMatcher(
      episodesApi.endpoints.fetchCategoryEpisodes.matchFulfilled,
      (state, { payload }) => {
        episodesAdapter.setMany(state, payload);
      },
    );
    builder.addMatcher(
      episodesApi.endpoints.fetchTrendingEpisodes.matchFulfilled,
      (state, { payload }) => {
        episodesAdapter.setMany(state, payload);
      },
    );
    builder.addMatcher(
      episodesApi.endpoints.fetchNewEpisodes.matchFulfilled,
      (state, { payload }) => {
        episodesAdapter.setMany(state, payload);
      },
    );
    builder.addMatcher(
      episodesApi.endpoints.fetchLibraryEpisodes.matchFulfilled,
      (state, { payload }) => {
        episodesAdapter.setMany(state, payload);
      },
    );
    builder.addMatcher(
      episodesApi.endpoints.fetchEpisodeSearchResults.matchFulfilled,
      (state, { payload }) => {
        episodesAdapter.setMany(state, payload);
      },
    );
    builder.addMatcher(
      episodesApi.endpoints.fetchRecommendedEpisodes.matchFulfilled,
      (state, { payload }) => {
        episodesAdapter.setMany(state, payload);
      },
    );
    builder.addMatcher(
      episodesApi.endpoints.fetchEpisodes.matchFulfilled,
      (state, { payload }) => {
        episodesAdapter.setMany(state, payload);
      },
    );
    builder.addMatcher(
      episodesApi.endpoints.fetchOrganizationPlaylistEpisodes.matchFulfilled,
      (state, { payload }) => {
        episodesAdapter.setMany(state, payload);
      },
    );
  },
});

export const { reducer: episodesReducer } = episodesSlice;
