const TWO_FOR_ONE_SKU_IDS = {
  september2024: 'sku_s2uE4',
  october2024: 'sku_6eiui',
  november2024: 'sku_g6yKK',
  december2024: 'sku_0ph82',
};

export const getActiveTwoForOneSkuId = () => {
  const year = new Date().getFullYear();
  if (year !== 2024) return null;

  const month = new Date().getMonth();
  if (month === 8) {
    return TWO_FOR_ONE_SKU_IDS.september2024;
  } else if (month === 9) {
    return TWO_FOR_ONE_SKU_IDS.october2024;
  } else if (month === 10) {
    return TWO_FOR_ONE_SKU_IDS.november2024;
  } else if (month === 11) {
    return TWO_FOR_ONE_SKU_IDS.december2024;
  }

  return null;
};
