import { GrowthBookProvider } from '@growthbook/growthbook-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Elements } from '@stripe/react-stripe-js';
import { SnackbarProvider } from 'notistack';

import { ErrorBoundary } from '@/containers';
import { Router } from '@/router';
import { growthbook, stripePromise } from '@/services';
import { StoreProvider } from '@/store';
import { theme } from '@/theme';

function App() {
  return (
    <ErrorBoundary>
      <CssBaseline />
      <GrowthBookProvider growthbook={growthbook}>
        <StoreProvider>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SnackbarProvider
                autoHideDuration={3500}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <Elements stripe={stripePromise}>
                  <div className="App">
                    <Router />
                  </div>
                </Elements>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StoreProvider>
      </GrowthBookProvider>
    </ErrorBoundary>
  );
}

export default App;
