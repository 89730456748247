import { format } from 'date-fns';

import {
  createCustomCPDRecordRequest,
  deleteCustomCPDRecordRequest,
  fetchCertificateDownloadUrlRequest,
  fetchCertificateDownloadUrlsRequest,
  fetchCertificateSummaryRequest,
  fetchTriennialSummaryRequest,
  updateCustomCPDRecordRequest,
  uploadCPDRecordFileRequest,
} from '@/requests';
import { Region, TriennialSummary, YearlySummary } from '@/types';

import { nodeApi } from '../node-api';
import { postgrestApi } from '../postgrest-api';
import { NodeApiTags, PostgrestApiTags } from '../store.constants';
import { handleOnQueryStartedError, rtkQueryError } from '../store.utils';
import { DEFAULT_DATE_RANGE } from './tracker-node-api.constants';
import { saveCertificateZip } from './tracker-node-api.utils';

export type CertificateFile = {
  name: string;
  content: Blob;
};

type CreateCustomCPDRecordPayload = {
  dateCompleted: Date;
  description: string;
  file?: File;
  credit: number;
  isEthics: boolean;
  source: string;
};

type UpdateCustomCPDRecordPayload = CreateCustomCPDRecordPayload & {
  id: number;
  file: File & {
    fileUri: string;
  };
  origFile?: {
    name: string;
    size: number;
    fileUri: string;
  };
};

type FetchTriennialSummaryResponse = {
  triennialSummary: TriennialSummary;
  yearlySummary: YearlySummary[];
  region: Region;
};

export const trackerNodeApi = nodeApi.injectEndpoints({
  endpoints: builder => ({
    fetchTriennialSummary: builder.query<FetchTriennialSummaryResponse, void>({
      query: fetchTriennialSummaryRequest,
      transformResponse: (response: any) => response.data,
      providesTags: [NodeApiTags.TRIENNIAL_SUMMARY],
    }),
    downloadCertificate: builder.mutation<unknown, { certificateId: number }>({
      queryFn: async ({ certificateId }, redux, extraOptions, baseQuery) => {
        try {
          const { data, error: downloadUrlError } = await baseQuery(
            fetchCertificateDownloadUrlRequest({ certificateId }),
          );

          if (downloadUrlError) return { error: downloadUrlError };

          const url = (data as any)?.data?.url;

          if (!url)
            return {
              error: {
                status: 404,
                data: {
                  message: 'Certificate not found',
                },
              },
            };

          window.open(url, '_self');
          return { data: null };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
    }),
    downloadCertificateSummary: builder.mutation<
      unknown,
      { fromDate: string; toDate: string }
    >({
      queryFn: async ({ fromDate, toDate }, redux, extraOptions, baseQuery) => {
        try {
          const fromDateFormatted = fromDate
            ? format(fromDate, 'yyyy-MM-dd')
            : DEFAULT_DATE_RANGE.fromDate;
          const toDateFormatted = toDate
            ? format(toDate, 'yyyy-MM-dd')
            : DEFAULT_DATE_RANGE.toDate;

          const { data, error: downloadUrlsError } = await baseQuery(
            fetchCertificateDownloadUrlsRequest({
              fromDate: fromDateFormatted,
              toDate: toDateFormatted,
            }),
          );

          if (downloadUrlsError) return { error: downloadUrlsError };

          const certificates = (data as any).data;

          const certificateFiles: CertificateFile[] = [];
          await Promise.all(
            certificates.map(async (certificate: any) => {
              const response = await fetch(certificate.url);
              const data = await response.blob();
              certificateFiles.push({ ...certificate, content: data });
            }),
          );

          const { data: summaryFile, error: downloadSummaryError } =
            await baseQuery({
              ...fetchCertificateSummaryRequest({
                fromDate: fromDateFormatted,
                toDate: toDateFormatted,
              }),
              responseHandler: response => response.blob(),
            });

          if (downloadSummaryError) return { error: downloadSummaryError };

          await saveCertificateZip({
            certificateFiles,
            summaryFile: summaryFile as Blob,
          });

          return { data: null };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
    }),
    createCustomCPDRecord: builder.mutation<
      unknown,
      CreateCustomCPDRecordPayload
    >({
      queryFn: async (args, redux, extraOptions, baseQuery) => {
        let fileData: {
          fileName?: string;
          fileSize?: number;
          fileUri?: string;
        } = {};
        try {
          if (args.file) {
            const formData = new FormData();
            formData.append('file', args.file);
            const { data, error } = await baseQuery(
              uploadCPDRecordFileRequest({ formData }),
            );
            if (error) return { error };
            fileData = data as any;
          }

          const { error } = await baseQuery(
            createCustomCPDRecordRequest({
              source: args.source,
              description: args.description,
              credit: args.credit,
              isEthics: args.isEthics,
              dateCompleted: args.dateCompleted,
              ...fileData,
            }),
          );

          if (error) return { error };

          return { data: null };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            nodeApi.util.invalidateTags([NodeApiTags.TRIENNIAL_SUMMARY]),
          );
          dispatch(
            postgrestApi.util.invalidateTags([PostgrestApiTags.PD_HOURS]),
          );
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
    }),
    updateCustomCPDRecord: builder.mutation<
      unknown,
      { newPdHour: UpdateCustomCPDRecordPayload; pdHourId: number }
    >({
      queryFn: async (
        { newPdHour, pdHourId },
        redux,
        extraOptions,
        baseQuery,
      ) => {
        let fileData = {};
        if (
          newPdHour.file &&
          newPdHour.file?.fileUri !== newPdHour.origFile?.fileUri
        ) {
          const formData = new FormData();
          formData.append('file', newPdHour.file);
          const { data, error } = await baseQuery(
            uploadCPDRecordFileRequest({ formData }),
          );
          if (error) return { error };
          fileData = data as any;
        } else if (
          newPdHour.file &&
          newPdHour.file?.fileUri === newPdHour.origFile?.fileUri
        ) {
          fileData = {
            fileName: newPdHour.origFile?.name,
            fileSize: newPdHour.origFile?.size,
            fileUri: newPdHour.origFile?.fileUri,
          };
        }

        const { error } = await baseQuery(
          updateCustomCPDRecordRequest({
            id: pdHourId,
            source: newPdHour.source,
            description: newPdHour.description,
            credit: newPdHour.credit,
            isEthics: newPdHour.isEthics,
            dateCompleted: newPdHour.dateCompleted,
            ...fileData,
          }),
        );

        if (error) return { error };

        return { data: null };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            nodeApi.util.invalidateTags([NodeApiTags.TRIENNIAL_SUMMARY]),
          );
          dispatch(
            postgrestApi.util.invalidateTags([PostgrestApiTags.PD_HOURS]),
          );
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
    }),
    deleteCustomCPDRecord: builder.mutation<void, { id: number }>({
      query: deleteCustomCPDRecordRequest,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            nodeApi.util.invalidateTags([NodeApiTags.TRIENNIAL_SUMMARY]),
          );
          dispatch(
            postgrestApi.util.invalidateTags([PostgrestApiTags.PD_HOURS]),
          );
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
    }),
  }),
});

export const {
  useFetchTriennialSummaryQuery,
  useDownloadCertificateMutation,
  useDownloadCertificateSummaryMutation,
  useCreateCustomCPDRecordMutation,
  useUpdateCustomCPDRecordMutation,
  useDeleteCustomCPDRecordMutation,
} = trackerNodeApi;
