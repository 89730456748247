import * as R from 'ramda';

import { LearningPath } from '@/types';
import { camelCaseKeys } from '@/utils';

const sumDurationAndCredits = (learningPath: any) => {
  const { duration, credits } = learningPath.episodes.reduce(
    (acc: { duration: number; credits: number }, episode: any) => {
      acc.duration += episode.duration || 0;
      acc.credits += episode.credits || 0;
      return acc;
    },
    { duration: 0, credits: 0 },
  );

  return {
    ...learningPath,
    duration,
    credits,
  };
};

const castCreditsFromStringToNumber = (lp: any) => ({
  ...lp,
  episodes: lp.episodes.map((e: any) => ({ ...e, credits: Number(e.credits) })),
});

export const transformLearningPath: (arg: unknown) => LearningPath = R.pipe(
  camelCaseKeys,
  castCreditsFromStringToNumber,
  sumDurationAndCredits,
);
