import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import { technicalSearchActions } from '@/store';

import { TechnicalFilterPill } from '../TechnicalFilterPill';

export const RegionOptionsDesktop = ({ regions, selectedRegions }) => {
  const dispatch = useDispatch();

  const onRegionSelect = region => {
    dispatch(technicalSearchActions.selectRegion(region));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      {regions.map(region => (
        <Box key={region} sx={{ mr: 0.75 }}>
          <TechnicalFilterPill
            label={region}
            isSelected={selectedRegions[region]}
            onClick={() => onRegionSelect(region)}
          />
        </Box>
      ))}
    </Box>
  );
};
