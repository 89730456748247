import { VolumeDown, VolumeOff, VolumeUp } from '@mui/icons-material';
import { Box, Slider } from '@mui/material';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsService } from '@/services';
import {
  playerActions,
  selectActiveChapterId,
  selectActiveEpisodeId,
  selectPlayerVolume,
  selectUserRegion,
  useFetchChapterQuery,
  useFetchEpisodeQuery,
} from '@/store';

export const VolumeSlider = ({ iconSize = 24, referrer }) => {
  const dispatch = useDispatch();

  const activeEpisodeId = useSelector(selectActiveEpisodeId);
  const activeChapterId = useSelector(selectActiveChapterId);
  const region = useSelector(selectUserRegion);
  const isNasba = region === 'USA';
  const location = referrer;

  const { data: episode } = useFetchEpisodeQuery({
    episodeId: activeEpisodeId,
    region,
  });
  const { data: chapter } = useFetchChapterQuery({
    chapterId: activeChapterId,
    region,
  });

  const [sliderValue, setSliderValue] = useState(null);
  const previousSliderValueRef = useRef(null);
  const playerVolume = useSelector(selectPlayerVolume);

  const displayVolume = sliderValue || playerVolume;
  const Icon =
    playerVolume === 0 ? VolumeOff : displayVolume < 50 ? VolumeDown : VolumeUp;

  const changeVolume = val => {
    setSliderValue(val);

    if (
      val !== playerVolume &&
      (Math.abs(val - playerVolume) > 10 || val === 0)
    ) {
      dispatch(playerActions.setVolume(val));
    }
  };

  const onSlide = (e, val) => {
    changeVolume(val);
  };

  const onSlideComplete = (e, val) => {
    if (val > 0) {
      previousSliderValueRef.current = sliderValue;
    }
    AnalyticsService.trackPlayerVolume({
      chapter,
      episode,
      val,
      isNasba,
      location,
    });
  };

  const handleIconClick = () => {
    if (!previousSliderValueRef.current) {
      previousSliderValueRef.current = displayVolume;
    }

    const targetVolume =
      playerVolume > 0
        ? 0
        : previousSliderValueRef.current > 20
          ? previousSliderValueRef.current
          : 20;

    changeVolume(targetVolume);
    AnalyticsService.trackPlayerVolume({
      chapter,
      episode,
      val: targetVolume,
      isNasba,
      location,
    });
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.volumeIconContainer} onClick={handleIconClick}>
        <Icon
          sx={{
            fontSize: theme => theme.typography.pxToRem(iconSize),
          }}
        />
      </Box>
      <Box sx={styles.volumeControlContainer}>
        <Slider
          orientation="horizontal"
          value={displayVolume}
          min={0}
          max={100}
          onMouseDown={() =>
            (previousSliderValueRef.current = sliderValue || playerVolume)
          }
          onChange={onSlide}
          onChangeCommitted={onSlideComplete}
          sx={{
            ...styles.root,
            '& .MuiSlider-thumb': styles.thumb,
            '& .MuiSlider-track': styles.track,
            '& .MuiSlider-rail': styles.rail,
          }}
        />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  volumeIconContainer: {
    color: 'aqua',
    mr: 2,
    mt: 0.5,
    cursor: 'pointer',
  },
  volumeControlContainer: {
    width: theme => theme.typography.pxToRem(150),
  },
  root: {
    height: theme => theme.typography.pxToRem(4),
    padding: theme => `${theme.typography.pxToRem(10)} 0`,
    verticalAlign: 'middle',
  },
  track: {
    color: 'aqua',
    height: theme => theme.typography.pxToRem(4),
  },
  rail: {
    color: 'white',
    height: theme => theme.typography.pxToRem(4),
  },
  thumb: {
    color: 'aqua',
    height: theme => theme.typography.pxToRem(12),
    width: theme => theme.typography.pxToRem(12),
  },
};
