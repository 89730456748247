import { Forward30, Pause, PlayArrow, Replay30 } from '@mui/icons-material';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { PLAYER_FORWARD_30, PLAYER_REPLAY_30 } from '@/constants';
import { useDebouncedValue } from '@/hooks';
import {
  playerThunks,
  selectIsChangingChapter,
  selectIsPlayerBuffering,
  selectIsPlayerPlaying,
  selectIsPlayerSeeking,
  selectPlayerProgress,
} from '@/store';

export const MediaControls = ({ chapter, setSeekPosition }) => {
  const dispatch = useDispatch();

  const isBuffering = useSelector(selectIsPlayerBuffering);
  const isSeeking = useSelector(selectIsPlayerSeeking);
  const isPlaying = useSelector(selectIsPlayerPlaying);
  const isChangingChapter = useSelector(selectIsChangingChapter);
  const { position } = useSelector(selectPlayerProgress);

  const showLoadingIndicator = useDebouncedValue(
    isBuffering || isChangingChapter,
    1000,
  );

  const togglePlay = () => {
    dispatch(
      isPlaying ? playerThunks.pausePlayer() : playerThunks.playPlayer(),
    );
  };

  const seekEpisode = delta => {
    const seekToPosition = Math.max(
      Math.min(position + delta, chapter.duration),
      1,
    );

    setSeekPosition({ position: seekToPosition });
  };

  return (
    <Box sx={styles.mediaControlsContainer}>
      <IconButton
        sx={styles.iconButton}
        onClick={() => seekEpisode(PLAYER_REPLAY_30)}
        disabled={isSeeking}>
        <Replay30 sx={styles.icon} />
      </IconButton>

      {showLoadingIndicator ? (
        <CircularProgress sx={styles.circularProgressIndicator} />
      ) : (
        <IconButton onClick={togglePlay}>
          {isPlaying ? (
            <Pause sx={[styles.playPauseIcon, styles.iconSelected]} />
          ) : (
            <PlayArrow sx={[styles.playPauseIcon, styles.iconSelected]} />
          )}
        </IconButton>
      )}

      <IconButton
        sx={styles.iconButton}
        onClick={() => seekEpisode(PLAYER_FORWARD_30)}
        disabled={isSeeking}>
        <Forward30 sx={styles.icon} />
      </IconButton>
    </Box>
  );
};

const styles = {
  mediaControlsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    margin: '0 auto',
    paddingTop: theme => theme.typography.pxToRem(4),
  },
  iconButton: {
    height: 56,
    width: 56,
  },
  playPauseIcon: theme => ({
    fontSize: theme.typography.pxToRem(60),
    color: 'blueBlackLight',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
  }),
  icon: {
    fontSize: theme => theme.typography.pxToRem(30),
    color: 'blueBlackLight',
  },
  activeIcon: {
    color: 'tealBright',
  },
  iconSelected: {
    color: 'aqua',
  },
  playEpisodeButton: {
    color: 'aqua',
    fontSize: theme => theme.typography.pxToRem(18),
  },
  circularProgressIndicator: {
    color: 'aqua',
    fontSize: theme => theme.typography.pxToRem(32),
  },
};
