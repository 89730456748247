import { Box, Grid, Hidden } from '@mui/material';
import { useDispatch } from 'react-redux';

import { playerActions } from '@/store';

import {
  ChapterInfo,
  MediaControls,
  ProgressBar,
  ProgressInfo,
  VolumeSlider,
} from './components';

export const MiniPlayer = ({ cpaCanadaContent }) => {
  const dispatch = useDispatch();
  const { episode, chapter } = cpaCanadaContent;

  const setSeekPosition = ({ position }) => {
    dispatch(playerActions.setSeekToPosition(position));
  };

  return (
    <Box sx={styles.miniPlayerContainer}>
      <Box sx={styles.progressBarContainer}>
        <ProgressBar chapter={chapter} setSeekPosition={setSeekPosition} />
      </Box>
      <Grid container alignItems="center">
        <Grid item xs={3} sm={3} md={4} lg={4}>
          <ChapterInfo episode={episode} chapter={chapter} />
        </Grid>
        <Grid item xs={4} sm={5} md={6} lg={4}>
          <MediaControls chapter={chapter} setSeekPosition={setSeekPosition} />
        </Grid>
        <Grid item xs={5} sm={4} md={2} lg={4}>
          <Box sx={styles.playerRight}>
            <ProgressInfo chapter={chapter} />
            <Hidden mdDown>
              <Box sx={styles.volumeControls}>
                <VolumeSlider />
              </Box>
            </Hidden>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  miniPlayerContainer: theme => ({
    position: 'relative',
    backgroundColor: 'blueBlack',
    boxShadow: '0 -5px 20px 0 rgba(0,0,0,0.5)',
    color: 'white',
    height: theme.typography.pxToRem(90),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(66),
    },
  }),
  progressBarContainer: theme => ({
    bottom: theme.typography.pxToRem(76),
    ml: theme.typography.pxToRem(90),
    position: 'absolute',
    width: 'calc(100% - 90px)',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.typography.pxToRem(52),
      marginLeft: theme.typography.pxToRem(66),
      width: 'calc(100% - 66px)',
    },
  }),
  playerRight: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'flex-end',
    paddingTop: theme => theme.typography.pxToRem(4),
  },
  volumeControls: {
    mr: 2.5,
  },
  expandButton: theme => ({
    height: theme.typography.pxToRem(50),
    minHeight: theme.typography.pxToRem(32),
    minWidth: theme.typography.pxToRem(32),
    padding: 0,
    width: theme.typography.pxToRem(50),
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(44),
      width: theme.typography.pxToRem(44),
    },
  }),
};
