import { createApi } from '@reduxjs/toolkit/query/react';

import { POSTGREST_BASE_URL } from '@/requests';
import { PostgrestApiTags as TAGS } from '@/store/store.constants';
import { createBaseQueryWithRefresh } from '@/store/store.utils';

export const postgrestApi = createApi({
  reducerPath: 'postgrestApi',
  baseQuery: createBaseQueryWithRefresh({ baseUrl: POSTGREST_BASE_URL }),
  tagTypes: Object.values(TAGS),
  endpoints: builder => ({}),
});
