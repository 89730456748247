import { Modals } from '@/constants';

import { AppModal } from '../AppModal';
import { RedeemModalContent } from './RedeemModalContent.container';

export const RedeemModal = () => {
  return (
    <AppModal modalName={Modals.REDEEM}>
      <RedeemModalContent />
    </AppModal>
  );
};
