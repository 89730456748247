import { Box } from '@mui/material';

import { Skeleton } from '@/components';

export const AssessmentQuestionSkeleton = () => (
  <Box>
    <Skeleton />
    {Array.from({ length: 4 }).map((_, idx) => (
      <Skeleton key={idx} width="70%" />
    ))}
  </Box>
);
