import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import format from 'date-fns/format';
import { useState } from 'react';

import { CertificateDownloadLink } from '..';

export const CreditMenu = ({ credit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  if (!credit) return null;

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="certificate-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Popover
        id="certificate-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleClose}>
        <Box sx={styles.menu}>
          <Typography variant="select">
            <strong>Field of Study: </strong>
            {credit.fieldsOfStudy[0].name}
          </Typography>
          <Typography variant="select">
            <strong>Credits: </strong> {credit.credit.toFixed(1)}
          </Typography>
          <Typography variant="select">
            <strong>Completed On: </strong>
            {format(credit.dateCompleted, 'MMM d, yyyy')}
          </Typography>
          <CertificateDownloadLink pdHourId={credit.id} />
        </Box>
      </Popover>
    </div>
  );
};

const styles = {
  menu: {
    p: 2,
    display: 'flex',
    flexDirection: 'column',
  },
};
