import { createSvgIcon } from '@mui/material/utils';

export const RadioBoxCheckedIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
    <rect
      x="0.5"
      y="0.5"
      width="23"
      height="23"
      rx="11.5"
      stroke="currentColor"
    />
    <path
      d="M16.2702 7L15.4054 7.91153C13.5 9.92394 12.0318 11.6113 10.2862 13.4946L8.51193 11.9128L7.57711 11.0817L6 13.0456L6.93483 13.8767L9.58029 16.2359L10.4388 17L11.2337 16.1622C13.4292 13.8433 14.9897 11.994 17.1351 9.72785L18 8.81631L16.2702 7Z"
      fill="#3784C3"
    />
  </svg>,
  'RadioBoxChecked',
);
