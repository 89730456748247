import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Button as MuiButton,
  Skeleton,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import * as R from 'ramda';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@/components';
import { LUMIQ_ORGANIZATION_NAME, Routes, urls } from '@/constants';
import { Link } from '@/containers';
import { googleLogo } from '@/images';
import { useGetInviteDetailsQuery, useRegisterMutation } from '@/store';
import { getOauthURL } from '@/utils';

import { PASSWORD_REQUREMENT_TEXT, RegisterErrorTypes } from './constants';
import { FormConfig, RegisterFormState } from './utils';

type RegisterFormProps = {
  inputRef: React.RefObject<HTMLDivElement>;
  onLoginClick?: () => void;
  onRegisterSuccess?: () => void;
};

type RegisterFormErrorState = {
  email?: string;
  password?: string;
  confirmPassword?: string;
};

export const RegisterForm = ({
  inputRef,
  onLoginClick,
  onRegisterSuccess,
}: RegisterFormProps) => {
  const location = useLocation();

  const [form, setForm] = useState<RegisterFormState>({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState<RegisterFormErrorState>({
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
  });

  const [register, { isLoading: isRegisterLoading }] = useRegisterMutation();

  const onChange = ({
    target: { id, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevState => ({
      ...prevState,
      [id]: value,
    }));
    setErrors(prevState => ({
      ...prevState,
      [id]: null,
    }));
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const newErrors: RegisterFormErrorState = {};
    R.pipe(
      R.keys<RegisterFormState>,
      R.forEach<keyof RegisterFormState>(id => {
        if (!FormConfig[id].validator(form)) {
          newErrors[id] = FormConfig[id].errorMessage;
        }
      }),
    )(form);
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    const { offerId, inviteToken } = Object.fromEntries(
      new URLSearchParams(location.search),
    );

    const { error: requestError } = (await register({
      email: form.email.trim(),
      password: form.password.trim(),
      offerId,
      inviteToken,
      searchParams: Object.fromEntries(new URLSearchParams(location.search)),
    })) as { error: FetchBaseQueryError };

    if (requestError) {
      const errorMessage = (requestError?.data as { message: string })?.message;
      const errorType = RegisterErrorTypes.find(
        item => item.response === errorMessage,
      );
      if (errorType) {
        setErrors({ [errorType.field]: errorType.message });
      } else {
        setErrors({
          password: 'Something went wrong. Please try again later.',
        });
      }
      return;
    }

    onRegisterSuccess?.();
  };

  const googleOauthURL = getOauthURL('google');

  const { inviteToken } = Object.fromEntries(
    new URLSearchParams(location.search),
  );

  const { data, isLoading: isInviteDetailsLoading } = useGetInviteDetailsQuery(
    { inviteToken },
    { skip: !inviteToken },
  );
  const { organizationName } = data || {};

  const isOrgInvite =
    !!inviteToken && organizationName !== LUMIQ_ORGANIZATION_NAME;

  return (
    <form onSubmit={onSubmit}>
      <Box ref={inputRef}>
        {isInviteDetailsLoading ? (
          <Skeleton variant="text" sx={{ fontSize: 20 }} />
        ) : (
          <Typography variant="h1">
            {isOrgInvite
              ? `Sign up to join ${organizationName || 'your company'}`
              : 'Sign up to start learning'}
          </Typography>
        )}
        <Box sx={styles.textFieldContainer}>
          <Typography variant="body3">Company Email</Typography>
          <TextField
            id="email"
            onChange={onChange}
            error={!!errors.email}
            fullWidth
          />
          {errors.email && (
            <Typography color="red" variant="body3">
              {errors.email}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            sx={[
              styles.textFieldContainer,
              styles.passwordContainer,
              { mr: 2 },
            ]}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body3">Password</Typography>
              <Tooltip
                title={PASSWORD_REQUREMENT_TEXT}
                placement="right"
                disableInteractive>
                <InfoOutlined sx={styles.tooltipIcon} />
              </Tooltip>
            </Box>
            <TextField
              type="password"
              id="password"
              onChange={onChange}
              error={!!errors.password}
              fullWidth
            />
          </Box>
          <Box sx={[styles.textFieldContainer, styles.passwordContainer]}>
            <Typography variant="body3">Confirm Password</Typography>
            <TextField
              type="password"
              id="confirmPassword"
              onChange={onChange}
              error={!!errors.confirmPassword}
              fullWidth
            />
          </Box>
        </Box>
        {(errors.password || errors.confirmPassword) && (
          <Typography color="red" variant="body3">
            {errors.password || errors.confirmPassword}
          </Typography>
        )}
        <Box sx={{ mt: 3 }}>
          <Typography variant="body3" component="span">
            By clicking "Agree and Continue", you agree to LumiQ's{' '}
          </Typography>
          <Link inline to={urls.lumiqTC}>
            <Typography variant="body3" component="span">
              Terms & Conditions
            </Typography>
          </Link>{' '}
          <Typography variant="body3" component="span">
            and acknowledge our{' '}
          </Typography>
          <Link inline to={urls.lumiqPrivacy}>
            <Typography variant="body3" component="span">
              Privacy Policy
            </Typography>
          </Link>
          <Typography component="span">.</Typography>
        </Box>
        <Box sx={styles.submitContainer}>
          <Button
            disabled={isRegisterLoading}
            label="Agree and Continue"
            skinny={false}
            sx={{ flex: 1 }}
            type="submit"
          />
        </Box>
        <Box sx={styles.loginLinkContainer}>
          <Typography sx={{ textWrap: 'nowrap', mr: 0.5 }}>
            {isOrgInvite ? 'Existing user?' : 'Already have an account?'}
          </Typography>
          <Link
            {...(onLoginClick
              ? { onClick: onLoginClick }
              : {
                  to: { pathname: Routes.LOGIN, search: location.search },
                })}>
            {isOrgInvite ? 'Log in to link your account' : 'Log In'}
          </Link>
        </Box>
        <Box sx={styles.otherWaysText}>
          <Box sx={styles.horizontalLine} />
          <Typography variant="subtitle1" sx={styles.registerOptions}>
            Other ways to sign up
          </Typography>
          <Box sx={styles.horizontalLine} />
        </Box>
        <MuiButton href={googleOauthURL} sx={styles.oauthButton}>
          <Box
            component="img"
            alt="google logo"
            src={googleLogo}
            sx={styles.oauthIcon}
          />
          <Typography variant="button2">Sign up with Google</Typography>
        </MuiButton>
      </Box>
    </form>
  );
};

const styles = {
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    mt: 2,
  },
  passwordContainer: {
    flex: 1,
  },
  submitContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    mt: 3,
  },
  loginLinkContainer: {
    backgroundColor: 'blueLight',
    borderRadius: 2,
    p: 1,
    mt: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textWrap: 'nowrap',
    flexWrap: 'wrap',
  },
  oauthButton: {
    mt: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    color: 'blueBlack',
    border: (theme: Theme) => `1px solid ${theme.palette.blueBlack}`,
    p: 1.5,
    borderRadius: 2,
  },
  oauthIcon: {
    height: 24,
    mr: 3,
  },
  tooltipIcon: {
    fontSize: 16,
    ml: 0.5,
    '&:hover': {
      opacity: 0.6,
    },
  },
  otherWaysText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    mt: 5,
  },
  horizontalLine: {
    border: (theme: Theme) => `1px solid ${theme.palette.blueBlack}`,
    height: 0,
    flex: 1,
  },
  registerOptions: { flexGrow: 0, mx: 3 },
};
