import { fetchCategoriesRequest, fetchFieldsOfStudyRequest } from '@/requests';
import { postgrestApi } from '@/store/postgrest-api';

export const categoriesApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchCategories: builder.query({
      query: fetchCategoriesRequest,
      transformResponse: (res: any[]) => {
        return res
          .map(c => ({
            id: c.original_category_id,
            name: c.name,
            fieldsOfStudy: c.fields_of_study,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
      },
    }),
    fetchFieldsOfStudy: builder.query({
      query: fetchFieldsOfStudyRequest,
      transformResponse: (res: any[]) =>
        res.reduce((acc, cur) => {
          acc[cur.field_of_study_id] = cur.name;
          return acc;
        }, {}),
    }),
  }),
});

export const { useFetchCategoriesQuery, useFetchFieldsOfStudyQuery } =
  categoriesApi;
