import { UserChapter, UserChapterProgress } from '@/types';

import { selectIsFsmPlayer } from '../player';
import { selectPlayerSessionDetailState } from '../player-fsm';
import { RootState } from '../store';
import { userEpisodeChapterProgressApi } from './user-chapters-api.slice';

export const selectEpisodeChaptersProgress = ({
  episodeId,
}: {
  episodeId: string;
}) =>
  userEpisodeChapterProgressApi.endpoints.fetchEpisodeChapterProgress.select({
    episodeId,
  });

/**
 * Selects array of all Chapters of an Episode
 *
 * For Player Session it selects from the playerSessionDetailAdapter, so it streams in FSM updates automatically
 */
export const selectEpisodeProgress = (
  state: RootState,
  episodeId: string,
): UserChapterProgress[] | UserChapter[] | undefined => {
  const isFSMPlayerUser = selectIsFsmPlayer(state);
  if (!isFSMPlayerUser) {
    const { data: userChapters } = selectEpisodeChaptersProgress({
      episodeId,
    })(state);
    return userChapters;
  }

  const { chapters: userChapters } =
    selectPlayerSessionDetailState(state, episodeId) || {};
  return userChapters;
};

/**
 * Selects a specific Chapter
 *
 * For Player Session it selects from the playerSessionDetailAdapter, so it streams in FSM updates automatically
 * */
export const selectChapterProgress = (
  state: RootState,
  { episodeId, chapterId }: { episodeId: string; chapterId: string },
): UserChapterProgress | UserChapter | undefined => {
  const isFSMPlayerUser = selectIsFsmPlayer(state);
  return selectEpisodeProgress(state, episodeId)?.find(
    u =>
      (isFSMPlayerUser ? u.id : (u as UserChapterProgress).chapterId) ===
      chapterId,
  );
};
