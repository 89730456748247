import { Box } from '@mui/material';
import { useRef } from 'react';

import { AuthInfo } from '@/components';
import { LoginForm } from '@/containers';

export const Login = () => {
  const inputRef = useRef(null);

  return (
    <Box sx={styles.pageContainer}>
      <AuthInfo inputRef={inputRef} />
      <Box sx={styles.pageSection}>
        <LoginForm inputRef={inputRef} />
      </Box>
    </Box>
  );
};

const styles = {
  pageContainer: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  },
  pageSection: {
    display: 'flex',
    flexDirection: 'column',
    p: {
      xs: 4,
      sm: 8,
    },
    flex: 1,
  },
};
