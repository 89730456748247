import { useSelector } from 'react-redux';

import { EpisodeCardCarousel } from '@/components';
import { selectUserRegion, useFetchTrendingEpisodesQuery } from '@/store';

export const TrendingEpisodesCarousel = () => {
  const userRegion = useSelector(selectUserRegion);

  const {
    data: trendingEpisodes,
    isError,
    isLoading,
    isUninitialized,
  } = useFetchTrendingEpisodesQuery({ region: userRegion });

  return (
    <>
      {isError && null}
      {!isError && (
        <EpisodeCardCarousel
          title="Trending Episodes"
          uKey="Trending Episodes"
          episodes={trendingEpisodes}
          isLoading={isLoading || isUninitialized}
          referrer={{ page: 'discover', category: 'trending episodes' }}
        />
      )}
    </>
  );
};
