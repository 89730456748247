import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';

import { Select } from '@/components';

import {
  formatCPDDateRangeLabel,
  selectCPDDateSelectorOptions,
} from '../utils';

export const DateRangeSelector = ({ province, dateRange, setDateRange }) => {
  const [opened, setOpened] = useState(false);
  const [selectorOptions, setSelectorOptions] = useState([]);

  const onSelect = event => {
    event.preventDefault();
    const newDateRange = event.target.value;
    setDateRange(newDateRange);
  };

  useEffect(() => {
    setSelectorOptions(selectCPDDateSelectorOptions(province));
  }, [province]);

  return (
    <Select
      selectedOptions={[dateRange]}
      isOpened={opened}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      onChange={onSelect}
      title={formatCPDDateRangeLabel({
        province,
        year: parseInt(dateRange.fromDate.substring(0, 4)),
      })}>
      {selectorOptions.map((option, idx) => (
        <MenuItem key={idx} value={option}>
          {formatCPDDateRangeLabel({
            province,
            year: parseInt(option.fromDate.substring(0, 4)),
          })}
        </MenuItem>
      ))}
    </Select>
  );
};
