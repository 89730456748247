import * as R from 'ramda';

import { Credit, Region } from '@/types';
import { camelCaseKeys, getLocalDate } from '@/utils';

const LUMIQ_PREFIX = 'LumiQ - ';

const getCreditTitle = (credit: any): string =>
  credit.source.replace(LUMIQ_PREFIX, '');

export const transformCredit = (credit: any): Credit =>
  R.pipe(camelCaseKeys, (c: any) => ({
    creditId: c.id,
    userId: c.userId,
    name: getCreditTitle(c),
    source: c.source,
    description: c.description,
    credit: c.hours,
    ethicsCredit: c.fieldsOfStudy
      ? c.fieldsOfStudy.reduce(
          (acc: number, fos: any) => (acc + fos.isEthics ? fos.credit : 0),
          0,
        )
      : c.ethicHours,
    technicalCredit:
      c.fieldsOfStudy?.reduce(
        (acc: number, fos: any) => (acc + fos.isTechnical ? fos.credit : 0),
        0,
      ) || 0,
    dateCompleted: getLocalDate(c.dateCompleted).toISOString(),
    type: c.type,
    fileName: c.fileName,
    fileSize: c.fileSize,
    fileUri: c.fileUri,
    episodeId: c.seriesId,
    region: (c.nasba ? 'USA' : 'CAN') as Region,
    fieldsOfStudy: c.fieldsOfStudy?.map((fos: any) => ({
      credit: fos.credit,
      name: fos.name,
      isTechnical: fos.isTechnical,
      fieldOfStudyId: fos.fieldOfStudy,
    })),
  }))(credit);

//   {
//     "id": 548943,
//     "user_id": "34425",
//     "source": "LumiQ - Wrexham AFC: Red Cards in Asset Management",
//     "description": "Learn about asset management in relation to the Wrexham Football Club with Retired Construction and Real Estate Advisory Leader at EY, Mark Gibson. ",
//     "hours": 2.5,
//     "ethic_hours": 0,
//     "date_completed": "2023-12-25",
//     "type": "lumiq",
//     "file_name": "LumiQ - Wrexham_AFC__Red_Cards_in_Asset_Management.pdf",
//     "file_size": 265847,
//     "file_uri": "pd-tracker/34425/60026c32-0cf1-42ad-8e23-cd01373ee18d",
//     "series_id": "kFMtd0Dv1a2vevMWhgtQ",
//     "nasba": true,
//     "fields_of_study": [
//         {
//             "name": "Finance",
//             "credit": 2.5,
//             "is_ethics": false,
//             "is_technical": true,
//             "field_of_study": "FINANCE"
//         }
//     ]
// },
