import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

import { Link } from '@/containers';

export const CPEDetailsModal = ({ isOpened, setOpened, CPEDetails }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpened}
      onClose={() => setOpened(false)}>
      <Box sx={{ py: 4, px: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
          <Typography variant="h1">CPE / QAS Requirements</Typography>
          <IconButton onClick={() => setOpened(false)}>
            <Close />
          </IconButton>
        </Box>
        <Grid container direction="row" alignItems="stretch" spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <Typography>
              <strong>Publication Date:</strong>
              <br />
              {CPEDetails.publishDate &&
                format(CPEDetails.publishDate, 'MMMM d, yyyy')}
            </Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="subtitle2">Expiration Date:</Typography>
            <Typography>
              1 year from start of the course or upon subscription expiry
            </Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="subtitle2">CPE Credits:</Typography>
            <Typography>{CPEDetails.credits}</Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="subtitle2">
              Instructional Delivery Method:
            </Typography>
            <Typography>QAS Self Study</Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="subtitle2">Program Level:</Typography>
            <Typography>{CPEDetails.programLevel}</Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="subtitle2">Course Registration:</Typography>
            <Typography>LumiQ subscription and account required</Typography>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="subtitle2">Field of Study:</Typography>
            <Typography>{CPEDetails.fieldOfStudy}</Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="subtitle2">Prerequisites:</Typography>
            {arrayToList(CPEDetails.prerequisites)}
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="subtitle2">Advanced Preparations:</Typography>
            {arrayToList(CPEDetails.advancedPreparations)}
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

const arrayToList = array => {
  return array?.length > 0 ? (
    <List sx={{ listStyleType: 'disc', pl: 2 }}>
      {array.map((element, i) => (
        <ListItem key={i} sx={{ display: 'list-item' }}>
          {element.url ? (
            <Link to={element.url}>{element.name}</Link>
          ) : (
            <Typography>{element.name}</Typography>
          )}
        </ListItem>
      ))}
    </List>
  ) : (
    <Typography>None</Typography>
  );
};
