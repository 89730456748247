import { createSelector } from '@reduxjs/toolkit';

import {
  selectActiveEpisodeId,
  selectIsPlayerPlaying,
  selectPlayerProgress,
} from '@/store';

export const selectEpisodePlayerPosition = createSelector(
  [
    selectActiveEpisodeId,
    selectPlayerProgress,
    (state, episodeId) => episodeId,
  ],
  (activeEpisodeId, playerProgress, episodeId) => {
    const isEpisodeActive = activeEpisodeId === episodeId;
    return isEpisodeActive ? playerProgress.position : null;
  },
);

export const selectIsEpisodePlaying = createSelector(
  [
    selectActiveEpisodeId,
    selectIsPlayerPlaying,
    (state, episodeId) => episodeId,
  ],
  (activeEpisodeId, isPlayerPlaying, episodeId) => {
    return activeEpisodeId === episodeId && isPlayerPlaying;
  },
);
