import { createSvgIcon } from '@mui/material/utils';

export const PlayArrowFilledIcon = createSvgIcon(
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.54076 0.990487C0.875246 0.562658 0 1.0405 0 1.83167V16.1683C0 16.9595 0.875246 17.4373 1.54076 17.0095L12.6915 9.84118C13.3038 9.44754 13.3038 8.55246 12.6915 8.15882L1.54076 0.990487Z"
      fill="currentColor"
    />
  </svg>,
  'PlayArrowFilled',
);
