import {
  fetchEpisodeAssessmentRequest,
  fetchUserAssessmentRequest,
} from '@/requests';
import { Assessment, LegacyAssessment } from '@/types';
import { camelCaseKeys } from '@/utils';

import { postgrestApi } from '../postgrest-api';

export const assessmentsApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchEpisodeAssessment: builder.query<
      Assessment,
      { episodeId: string; antiCheatingActive: boolean }
    >({
      query: ({ antiCheatingActive, ...args }) =>
        antiCheatingActive
          ? fetchUserAssessmentRequest(args)
          : fetchEpisodeAssessmentRequest(args),
      transformResponse: (response: unknown[]) =>
        toUserAssessment(camelCaseKeys(response)),
    }),
  }),
});

export const { useFetchEpisodeAssessmentQuery } = assessmentsApi;

const toUserAssessment = (
  maybeOldAssessment: [LegacyAssessment] | Assessment,
): Assessment => {
  if (!Array.isArray(maybeOldAssessment)) {
    return maybeOldAssessment;
  }
  const { assessmentId, episodeId, questions } = maybeOldAssessment[0];
  return {
    userAssessmentId: assessmentId,
    episodeId,
    passRate: 70, // hard code this as legacy assessments only support the default 70% pass rate
    questions: questions.map(q => ({
      userAssessmentQuestionId: q.id,
      question: q.question,
      answers: q.answers.map(a => ({
        assessmentAnswerId: Number.parseInt(a.id, 10),
        answerLabel: a.answer,
        isCorrect: a.isCorrect,
      })),
    })),
  };
};
