import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modals } from '@/constants';
import {
  selectIsPlayerPlaying,
  selectPlayerProgress,
  selectSessionUserId,
  uiActions,
} from '@/store';

import { AudioVideoPlayer, MiniPlayer } from './components';

export const CPAPlayer = ({ cpaCanadaContent }) => {
  const dispatch = useDispatch();
  const [episodeStarted, setStarted] = useState(false);
  const [contentEnded, setContentEnded] = useState(false);
  const [timer, setTimer] = useState();

  const isPlaying = useSelector(selectIsPlayerPlaying);
  const { position } = useSelector(selectPlayerProgress);
  const isAnonymous = !useSelector(selectSessionUserId);
  const cpaModalTimerDelay = isAnonymous ? 1 * 60 * 1000 : 8 * 1000;

  useEffect(() => {
    if (isPlaying) {
      setStarted(true);
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isPlaying && !timer) {
      setTimer(
        setTimeout(() => {
          dispatch(
            uiActions.setActiveModal({
              name: Modals.CPA_SIGNUP,
            }),
          );
        }, cpaModalTimerDelay),
      );
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isPlaying, cpaModalTimerDelay, dispatch, timer]);

  useEffect(() => {
    if (
      !contentEnded &&
      isAnonymous &&
      cpaCanadaContent.episode.duration - position < 2
    ) {
      setContentEnded(true);
      dispatch(
        uiActions.setActiveModal({
          name: Modals.CPA_CONTENT_END,
        }),
      );
    }
  }, [dispatch, isAnonymous, cpaCanadaContent, position, contentEnded]);

  if (!episodeStarted) return null;

  return (
    <>
      <Box sx={[styles.backdrop]} />
      <Box sx={styles.container}>
        <Box sx={styles.audioPlayer}>
          <AudioVideoPlayer
            playerStreamUrl={cpaCanadaContent.episode.contentLink}
          />
        </Box>
        <MiniPlayer cpaCanadaContent={cpaCanadaContent} />
      </Box>
    </>
  );
};

const styles = {
  backdrop: {
    backgroundColor: 'backdrop',
    display: 'none',
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 'player',
  },
  backdropExpanded: {
    display: 'block',
  },
  container: {
    bottom: 0,
    left: 0,
    position: 'fixed',
    width: '100%',
    zIndex: 'player',
  },
  audioPlayer: {
    height: 0,
    width: 0,
  },
  playerContainer: theme => ({
    backgroundColor: 'blueBlack',
    borderTopLeftRadius: theme.typography.pxToRem(32),
    borderTopRightRadius: theme.typography.pxToRem(32),
    bottom: 0,
    boxShadow: '0 -5px 20px 0 rgba(0,0,0,0.5)',
    height: 0,
    padding: 0,
    position: 'relative',
    width: '100%',
    zIndex: -1, // render behind the parent container that is placed at index 1300
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  }),
  playerContainerExpanded: theme => ({
    height: 'auto',
    padding: `${theme.typography.pxToRem(50)} ${theme.typography.pxToRem(40)}`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.typography.pxToRem(30)} ${theme.typography.pxToRem(10)}`,
    },
  }),
};
