import { useTheme } from '@mui/material';

export const OverviewProgressRing = ({
  ethicsPercent,
  externalPercent,
  lumiqPercent,
  radius,
  stroke,
}) => {
  const theme = useTheme();

  const normalizedRadius = radius - stroke;
  const circumference = 2 * Math.PI * normalizedRadius;
  const lumiqDashOffset = circumference - (lumiqPercent / 100) * circumference;
  const ethicsDashOffset =
    circumference - (ethicsPercent / 100) * circumference;
  const externalDashOffset =
    circumference - (externalPercent / 100) * circumference;
  const ethicsRotateOffset = (lumiqPercent / 100) * 360;
  const externalRotateOffset = ((lumiqPercent + ethicsPercent) / 100) * 360;

  return (
    <svg height={radius * 2} width={radius * 2}>
      <circle
        cx={radius}
        cy={radius}
        fill="none"
        r={normalizedRadius}
        stroke={theme.palette.grayLight}
        strokeWidth={stroke}
      />
      <circle
        cx={radius}
        cy={radius}
        fill="none"
        r={normalizedRadius}
        stroke={theme.palette.orange}
        strokeDasharray={`${circumference}, ${circumference}`}
        strokeLinecap="round"
        strokeWidth={stroke}
        style={{ strokeDashoffset: externalDashOffset }}
        transform={`rotate(${-90 + externalRotateOffset}, ${radius}, ${radius})`}
      />
      <circle
        cx={radius}
        cy={radius}
        fill="none"
        r={normalizedRadius}
        stroke={theme.palette.green}
        strokeDasharray={`${circumference}, ${circumference}`}
        strokeLinecap="round"
        strokeWidth={stroke}
        style={{ strokeDashoffset: ethicsDashOffset }}
        transform={`rotate(${-90 + ethicsRotateOffset}, ${radius}, ${radius})`}
      />
      <circle
        cx={radius}
        cy={radius}
        fill="none"
        r={normalizedRadius}
        stroke={theme.palette.teal}
        strokeDasharray={`${circumference}, ${circumference}`}
        strokeLinecap="round"
        strokeWidth={stroke}
        style={{ strokeDashoffset: lumiqDashOffset }}
        transform={`rotate(-90, ${radius}, ${radius})`}
      />
    </svg>
  );
};
