import { Box } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { EpisodeCardCarousel, PageTabs } from '@/components';
import { selectUserRegion, useFetchNewEpisodesQuery } from '@/store';

const generalTab = { value: 'general', label: 'General' };
const technicalTab = {
  value: 'technical',
  label: 'Accounting, Audit, & Tax',
};

const tabs = [generalTab, technicalTab];

export const NewEpisodesCarousel = () => {
  const userRegion = useSelector(selectUserRegion);

  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  const {
    data: newEpisodes,
    isError,
    isLoading,
    isUninitialized,
  } = useFetchNewEpisodesQuery({
    isTechnical: selectedTab === technicalTab.value,
    region: userRegion,
  });

  const Tab = () => {
    return (
      <Box mb={1}>
        <PageTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onChange={(_, value) => {
            setSelectedTab(value);
          }}
        />
      </Box>
    );
  };

  return (
    <>
      {isError && null}
      {!isError && (
        <EpisodeCardCarousel
          title="New Episodes"
          uKey="New Episodes"
          episodes={newEpisodes}
          isLoading={isLoading || isUninitialized}
          Tab={Tab}
          referrer={{ page: 'discover', category: 'new episodes' }}
        />
      )}
    </>
  );
};
