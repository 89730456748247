import { Close, East } from '@mui/icons-material';
import {
  Box,
  Dialog,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';

import { Link } from '@/containers';

const arrayToList = array => {
  return array?.length > 0 ? (
    <List sx={{ listStyleType: 'disc', pl: 2 }}>
      {array.map((element, i) => (
        <ListItem key={i} sx={{ display: 'list-item' }}>
          {element.url ? (
            <Link to={element.url}>{element.name}</Link>
          ) : (
            <Typography>{element.name}</Typography>
          )}
        </ListItem>
      ))}
    </List>
  ) : (
    <Typography>None</Typography>
  );
};

export const CPESummary = ({ CPEDetails }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);

  if (!CPEDetails.credits && !CPEDetails.fieldOfStudy) return null;

  return (
    <>
      <Box
        sx={[
          theme => ({
            borderRadius: 2,
            border: `1px solid ${theme.palette.blueBlackLight}`,
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
          }),
          isMobile && {
            flexDirection: 'column',
            alignItems: 'flex-start',
            minHeight: 160,
          },
          !isMobile && {
            flexDirection: 'row',
            alignItems: 'center',
          },
        ]}>
        <Box sx={{ display: 'flex', mr: 2 }}>
          <Typography>
            <strong>CPE Credits:</strong> {CPEDetails.credits}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', mr: 2 }}>
          <Typography>
            <strong>Field of Study:</strong> {CPEDetails.fieldOfStudy}
          </Typography>
        </Box>
        <Box>
          <Box
            onClick={() => setOpen(true)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              textWrap: 'nowrap',
              cursor: 'pointer',
              color: 'blue',
            }}>
            {' '}
            See More
            <East sx={{ ml: 1 }} />
          </Box>
        </Box>
      </Box>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}>
        <Typography variant="h2" sx={{ fontWeight: 400, mt: 2, ml: 5, mr: 5 }}>
          CPE / QAS Requirements
        </Typography>
        <Close
          sx={{
            position: 'absolute',
            top: 22,
            right: 14,
            cursor: 'pointer',
            color: 'gray',
          }}
          onClick={() => setOpen(false)}
        />
        <Box sx={{ p: 5 }}>
          <Grid container direction="row" alignItems="stretch" spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography>
                <strong>Publication Date:</strong>
                <br />
                {CPEDetails.publishDate &&
                  format(CPEDetails.publishDate, 'MMMM d, yyyy')}
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2">Expiration Date:</Typography>
              <Typography>
                1 year from start of the course or upon subscription expiry
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2">CPE Credits:</Typography>
              <Typography>{CPEDetails.credits}</Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2">
                Instructional Delivery Method:
              </Typography>
              <Typography>QAS Self Study</Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2">Program Level:</Typography>
              <Typography>{CPEDetails.programLevel}</Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2">Course Registration:</Typography>
              <Typography>LumiQ subscription and account required</Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2">Field of Study:</Typography>
              <Typography>{CPEDetails.fieldOfStudy}</Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2">Prerequisites:</Typography>
              {arrayToList(CPEDetails.prerequisites)}
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2">
                Advanced Preparations:
              </Typography>
              {arrayToList(CPEDetails.advancedPreparations)}
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};
