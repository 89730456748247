import { createSvgIcon } from '@mui/material/utils';

export const PlayArrowOutlinedIcon = createSvgIcon(
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.75 1.83167C2.75 1.63387 2.96881 1.51441 3.13519 1.62137L14.2859 8.78971C14.439 8.88811 14.439 9.11189 14.2859 9.21029L3.13519 16.3786C2.96881 16.4856 2.75 16.3661 2.75 16.1683V1.83167Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>,
  'PlayArrowOutlined',
);
