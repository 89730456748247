import { MenuItem, Radio } from '@mui/material';
import { useState } from 'react';

import { Select } from '@/components';

export const SingleSelectDropdown = ({
  selectedOption,
  options,
  onSave,
  title,
}) => {
  const [opened, setOpened] = useState(false);

  const onSelectOption = e => onSave(e.target.value);

  const selectTitle = `${title}${options[selectedOption]?.text || ''}`;

  return (
    <Select
      selectedOptions={selectedOption}
      isOpened={opened}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      onChange={onSelectOption}
      title={selectTitle}>
      {Object.keys(options).map(key => (
        <MenuItem sx={styles.selectOption} key={key} value={key}>
          <Radio sx={styles.sortByRadio} checked={selectedOption === key} />
          {options[key].text}
        </MenuItem>
      ))}
    </Select>
  );
};

const styles = {
  selectOption: {
    whiteSpace: 'unset',
    wordBreak: 'break-word',
  },
  sortByRadio: theme => ({
    '&.Mui-checked': {
      color: theme.palette.neutral40,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
  }),
};
