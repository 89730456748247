import { useSelector } from 'react-redux';

import { EpisodeCardCarousel } from '@/components';
import { LibraryUrlSearchParams, Routes } from '@/constants';
import { Link } from '@/containers';
import { selectUserRegion, useFetchCategoryEpisodesQuery } from '@/store';

const { CATEGORY } = LibraryUrlSearchParams;

export const CategoryEpisodesCarousel = ({ category }) => {
  const region = useSelector(selectUserRegion);

  const {
    data: episodes,
    isUninitialized,
    isLoading,
    isError,
  } = useFetchCategoryEpisodesQuery({
    categoryId: category.id,
    region,
  });

  // Don't render the carousel if there are too few episodes
  if (!isLoading && !isUninitialized && episodes?.length < 5) {
    return null;
  }

  const ViewAllLink = () => (
    <Link
      to={{
        pathname: Routes.LIBRARY,
        search: `?${CATEGORY}=${category.id}`,
      }}>
      View All
    </Link>
  );

  return (
    <>
      {isError && null}
      {!isError && (
        <EpisodeCardCarousel
          title={category.name}
          uKey={category.name + ' Episodes'}
          episodes={episodes}
          isLoading={isLoading || isUninitialized}
          ViewAllLink={ViewAllLink}
          referrer={{ page: 'discover', category: category.name }}
        />
      )}
    </>
  );
};
