import { VolumeDown, VolumeOff, VolumeUp } from '@mui/icons-material';
import { Box, Slider } from '@mui/material';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { playerActions, selectPlayerVolume } from '@/store';

export const VolumeSlider = ({ iconSize = 24 }) => {
  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = useState(null);
  const previousSliderValueRef = useRef(null);
  const playerVolume = useSelector(selectPlayerVolume);

  const displayVolume = sliderValue || playerVolume;
  const Icon =
    playerVolume === 0 ? VolumeOff : displayVolume < 50 ? VolumeDown : VolumeUp;

  const onSlide = (e, val) => {
    setSliderValue(val);
    previousSliderValueRef.current = sliderValue;

    if (val === playerVolume) return;
    if (Math.abs(val - playerVolume) > 10 || val === 0) {
      dispatch(playerActions.setVolume(val));
    }
  };

  const handleIconClick = () => {
    if (playerVolume > 0) {
      onSlide(null, 0);
    } else {
      onSlide(null, previousSliderValueRef.current || 20);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.volumeIconContainer} onClick={handleIconClick}>
        <Icon
          sx={{
            fontSize: theme => theme.typography.pxToRem(iconSize),
          }}
        />
      </Box>
      <Box sx={styles.volumeControlContainer}>
        <Slider
          orientation="horizontal"
          value={displayVolume}
          min={0}
          max={100}
          onChange={onSlide}
          sx={{
            ...styles.root,
            '& .MuiSlider-thumb': styles.thumb,
            '& .MuiSlider-track': styles.track,
            '& .MuiSlider-rail': styles.rail,
          }}
        />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  volumeIconContainer: {
    color: 'aqua',
    mr: 2,
    mt: 0.5,
    cursor: 'pointer',
  },
  volumeControlContainer: {
    width: theme => theme.typography.pxToRem(150),
  },
  root: {
    height: theme => theme.typography.pxToRem(4),
    padding: theme => `${theme.typography.pxToRem(10)} 0`,
    verticalAlign: 'middle',
  },
  track: {
    color: 'aqua',
    height: theme => theme.typography.pxToRem(4),
  },
  rail: {
    color: 'white',
    height: theme => theme.typography.pxToRem(4),
  },
  thumb: {
    color: 'aqua',
    height: theme => theme.typography.pxToRem(12),
    width: theme => theme.typography.pxToRem(12),
  },
};
