import { Box } from '@mui/material';

export const Header = ({ Title, Subtitle }) => {
  return (
    <Box
      sx={{
        pb: 1,
        pt: { xs: 2, sm: 3 },
        zIndex: 1,
      }}>
      <Box>{Title}</Box>
      {Subtitle && <Box mt={1}>{Subtitle}</Box>}
    </Box>
  );
};
