import { Error } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AuthPageWrapper } from '@/components';
import { Routes } from '@/constants';
import { useValidateInviteTokenQuery } from '@/store';

export const ValidateInvite = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { inviteToken } = useParams();

  const {
    data: isTokenValid,
    error,
    isLoading,
  } = useValidateInviteTokenQuery({
    inviteToken,
  });

  useEffect(() => {
    if (!isLoading && isTokenValid && !error) {
      const searchParams = Object.fromEntries(
        new URLSearchParams(location.search),
      );
      searchParams.inviteToken = inviteToken;

      navigate(
        {
          pathname:
            searchParams.view === 'login' ? Routes.LOGIN : Routes.REGISTER,
          search: new URLSearchParams(searchParams).toString(),
        },
        {
          replace: true,
        },
      );
    }
  }, [location, navigate, isLoading, error, isTokenValid, inviteToken]);

  return (!isLoading && !isTokenValid) || error ? (
    <AuthPageWrapper>
      <Box>
        <Error sx={{ color: 'red', width: 60, height: 60, mb: 1 }} />
        <Typography variant="h3">Uh oh!</Typography>
        <Typography variant="body1" sx={{ my: 2 }}>
          This invite has already been claimed or is expired. Please check if
          you have already created an account.
        </Typography>
      </Box>
    </AuthPageWrapper>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
      <CircularProgress />
    </Box>
  );
};
