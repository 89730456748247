import { Box, Typography } from '@mui/material';

import { Link } from '@/containers';

export const Speaker = ({ speaker }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Link to={speaker.companyUrl} target="_blank">
        <Box
          component="img"
          alt="company logo"
          src={speaker.companyLogo}
          sx={{
            width: 80,
            height: 80,
            objectFit: 'cover',
            border: 1,
            borderColor: 'grayBrightLight',
          }}
        />
      </Link>
      <Box
        sx={{
          color: 'gray',
          ml: 2,
          pr: 1,
        }}>
        <Typography>{speaker.name}</Typography>
        <Typography>{speaker.title}</Typography>
        {speaker.companyUrl ? (
          <Link to={speaker.companyUrl} target="_blank">
            {speaker.companyName}
          </Link>
        ) : (
          <Typography>{speaker.companyName}</Typography>
        )}
      </Box>
    </Box>
  );
};
