import { Box, Typography } from '@mui/material';

import { roundFloat } from '@/utils';

export const OverviewCredit = ({ credits, image, label }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.imageContainer}>
        <Box component="img" sx={styles.image} src={image} alt="chart" />
      </Box>
      <Box sx={styles.labelContainer}>
        <Typography variant="select" sx={styles.label}>
          {label}
        </Typography>
        <Typography variant="h3">{roundFloat(credits, 2)}</Typography>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    py: 1.5,
  },
  imageContainer: {
    alignItems: 'center',
    backgroundColor: 'beige',
    borderRadius: '50%',
    display: 'flex',
    flex: '0 0 auto',
    height: 64,
    width: 64,
    justifyContent: 'center',
  },
  image: {
    height: '50%',
    width: 'auto',
  },
  labelContainer: {
    ml: 1.5,
  },
  label: {
    color: 'gray',
  },
};
