import { Box, TextField } from '@mui/material';
import { useState } from 'react';

import { corporateBuilding } from '@/images';

import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalSubtitle } from './ModalSubtitle';
import { ModalTitle } from './ModalTitle';

export const EmailForm = ({ goToPrevStep, goToNextStep }) => {
  const [form, setForm] = useState('');
  const [error, setError] = useState();

  const handleChange = event => {
    const { value } = event.target;
    setForm(value);

    if (error && isFormValid(value)) setError();
  };

  const handleNext = () => {
    if (isFormValid(form)) {
      goToNextStep(form);
    } else {
      setError('Please enter a valid email address.');
    }
  };

  return (
    <Box sx={styles.formContainer}>
      <Header Title={<Title />} Subtitle={<Subtitle />} />
      <Box
        sx={[
          styles.formPrimarySection,
          { display: 'flex', flexDirection: 'column' },
        ]}>
        <TextField
          id={'email'}
          value={form}
          onChange={handleChange}
          label="Email"
          variant="outlined"
          fullWidth
          error={!!error}
          helperText={error}
        />
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box component="img" src={corporateBuilding} sx={{ width: 140 }} />
        </Box>
      </Box>
      <Footer
        handlePrev={() => goToPrevStep()}
        handleNext={handleNext}
        formHasInput={!!form.length}
      />
    </Box>
  );
};

const isFormValid = form => {
  if (!form) return true;

  const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegexPattern.test(form);
};

const Title = () => (
  <ModalTitle>
    If your company has purchased LumiQ for you, enter your work email below
  </ModalTitle>
);

const Subtitle = () => {
  return (
    <ModalSubtitle>
      You currently have access to a <b>free trial</b>. If you are expecting to
      login to a corporate plan, please enter your work email below to receive
      an invitation.
    </ModalSubtitle>
  );
};
