import { Box, TextField } from '@mui/material';
import * as R from 'ramda';
import { useState } from 'react';

import { profileDetails } from '@/images';

import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalSubtitle } from './ModalSubtitle';
import { ModalTitle } from './ModalTitle';

export const JobDetailsForm = ({ goToPrevStep, goToNextStep }) => {
  const [form, setForm] = useState({
    jobTitle: '',
    companyName: '',
  });
  const [errors, setErrors] = useState({
    jobTitle: null,
    companyName: null,
  });

  const handleChange = event => {
    const { id, value } = event.target;

    setForm(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleNext = () => {
    if (isFormValid(form)) {
      goToNextStep(R.map(i => i.trim(), form));
    } else {
      setErrors({
        companyName: isInputValid(form.companyName)
          ? null
          : 'This field is required.',
        jobTitle: isInputValid(form.jobTitle)
          ? null
          : 'This field is required.',
      });
    }
  };

  return (
    <Box sx={styles.formContainer}>
      <Header Title={<Title />} Subtitle={<Subtitle />} />
      <Box
        sx={[
          styles.formPrimarySection,
          {
            display: 'flex',
            flexDirection: 'column',
          },
        ]}>
        <TextField
          id={'companyName'}
          label="Company Name"
          variant="outlined"
          onChange={handleChange}
          value={form.companyName}
          fullWidth
          sx={{ mb: 2 }}
          error={!!errors.companyName}
          helperText={errors.companyName}
        />
        <TextField
          id={'jobTitle'}
          label="Job Title"
          variant="outlined"
          onChange={handleChange}
          value={form.jobTitle}
          fullWidth
          error={!!errors.jobTitle}
          helperText={errors.jobTitle}
        />
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box
            component="img"
            src={profileDetails}
            style={{
              width: 140,
            }}
          />
        </Box>
      </Box>
      <Footer handlePrev={() => goToPrevStep()} handleNext={handleNext} />
    </Box>
  );
};

const isFormValid = form =>
  isInputValid(form.companyName) && isInputValid(form.jobTitle);

const isInputValid = input => input?.trim().length > 0;

const Title = () => <ModalTitle>Build your profile</ModalTitle>;

const Subtitle = () => (
  <ModalSubtitle>
    Add more information about your current role to your LumiQ profile.
  </ModalSubtitle>
);
