import {
  FetchCategoryEpisodesParams,
  fetchCategoryEpisodesRequest,
  fetchCPACanadaEpisodeRequest,
  FetchEpisodeParams,
  fetchEpisodeRequest,
  FetchEpisodesParams,
  fetchEpisodesRequest,
  fetchFeaturedEpisodesRequest,
  FetchLibraryEpisodesParams,
  fetchLibraryEpisodesRequest,
  FetchNewEpisodesParams,
  fetchNewEpisodesRequest,
  fetchOrganizationPlaylistEpisodes,
  fetchRecommendedEpisodesRequest,
  fetchRelatedEpisodesRequest,
  fetchTrendingEpisodesRequest,
} from '@/requests';
import { postgrestApi } from '@/store/postgrest-api';
import { Episode, EpisodeCard, Region } from '@/types';
import { camelCaseKeys, searchIndex } from '@/utils';

import { rtkQueryError } from '../store.utils';
import { transformEpisode, transformEpisodeCard } from './episodes-api.utils';

type FetchEpisodeSearchResultsParams = {
  searchTerm: string;
  region: Region;
};

export const episodesApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchFeaturedEpisodes: builder.query<
      EpisodeCard[],
      Parameters<typeof fetchFeaturedEpisodesRequest>[0]
    >({
      query: fetchFeaturedEpisodesRequest,
      transformResponse: (response: unknown[]) =>
        response.map(transformEpisodeCard),
    }),
    fetchTrendingEpisodes: builder.query<
      EpisodeCard[],
      Parameters<typeof fetchTrendingEpisodesRequest>[0]
    >({
      query: fetchTrendingEpisodesRequest,
      transformResponse: (response: unknown[]) =>
        response.map(transformEpisodeCard),
    }),
    fetchNewEpisodes: builder.query<EpisodeCard[], FetchNewEpisodesParams>({
      query: fetchNewEpisodesRequest,
      transformResponse: (response: unknown[]) =>
        response.map(transformEpisodeCard),
    }),
    fetchLibraryEpisodes: builder.query<
      EpisodeCard[],
      FetchLibraryEpisodesParams
    >({
      query: fetchLibraryEpisodesRequest,
      transformResponse: (response: unknown[]) =>
        response.map(transformEpisodeCard),
    }),
    fetchCategoryEpisodes: builder.query<
      EpisodeCard[],
      FetchCategoryEpisodesParams
    >({
      query: fetchCategoryEpisodesRequest,
      transformResponse: (response: unknown[]) =>
        response.map(transformEpisodeCard),
    }),
    fetchEpisode: builder.query<Episode | undefined, FetchEpisodeParams>({
      query: fetchEpisodeRequest,
      transformResponse: (response: unknown[]) =>
        response[0] ? transformEpisode(response[0]) : undefined,
    }),
    fetchEpisodes: builder.query<EpisodeCard[], FetchEpisodesParams>({
      query: fetchEpisodesRequest,
      transformResponse: (response: unknown[]) =>
        response.map(transformEpisodeCard),
    }),

    fetchEpisodeSearchResults: builder.query<
      EpisodeCard[],
      FetchEpisodeSearchResultsParams
    >({
      queryFn: async (
        { searchTerm, region },
        redux,
        extraOptions,
        baseQuery,
      ) => {
        try {
          const { hits } = await searchIndex.search(searchTerm, {
            attributesToRetrieve: ['id'],
            hitsPerPage: 40,
            filters: 'archived=0',
          });
          const episodeIds = hits.map((hit: any) => hit.id);

          const { data: episodes, error } = await baseQuery(
            fetchEpisodesRequest({ episodeIds, region }),
          );
          if (error) return { error };

          return {
            data: (episodes as unknown[])
              .map(transformEpisodeCard)
              .sort(
                (a, b) =>
                  episodeIds.indexOf(a.episodeId) -
                  episodeIds.indexOf(b.episodeId),
              ),
          };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
    }),
    fetchOrganizationPlaylistEpisodes: builder.query<
      EpisodeCard[],
      Parameters<typeof fetchOrganizationPlaylistEpisodes>[0]
    >({
      query: fetchOrganizationPlaylistEpisodes,
      transformResponse: (response: unknown[]) =>
        response.map(transformEpisodeCard),
    }),
    fetchRecommendedEpisodes: builder.query<EpisodeCard[], Region>({
      query: fetchRecommendedEpisodesRequest,
      transformResponse: (response: unknown[]) =>
        response.map(transformEpisodeCard),
    }),
    fetchRelatedEpisodes: builder.query<
      EpisodeCard[],
      {
        region: Region;
        episodeId: string;
      }
    >({
      query: fetchRelatedEpisodesRequest,
      transformResponse: (response: unknown[]) =>
        response.map(transformEpisodeCard),
    }),
    // TODO type CPACanadaEpisode
    fetchCPACanadaEpisode: builder.query({
      query: fetchCPACanadaEpisodeRequest,
      transformResponse: (response: any[]) => {
        const cpaCanadaContent = response[0]?.cpa_canada_episode;

        return cpaCanadaContent
          ? camelCaseKeys({
              ...cpaCanadaContent,
              episode: {
                name: cpaCanadaContent.episode.title,
                supplemental: cpaCanadaContent.episode.non_verifiable_text,
                cover: cpaCanadaContent.episode.share_cover,
                companyLogo: cpaCanadaContent.header.logo,
                speakerBio: cpaCanadaContent.speaker.bio.replace(
                  /\\n/g,
                  '  \n',
                ),
                speakers: [
                  {
                    companyLogo: cpaCanadaContent.header.logo,
                    companyName: cpaCanadaContent.speaker.company,
                    ...cpaCanadaContent.speaker,
                  },
                ],
                takeaways: cpaCanadaContent.episode.key_takeaways
                  .split('\\n\\n')
                  .filter(
                    (takeaway: any) => !takeaway.includes('Learning Objective'),
                  ),
                ...cpaCanadaContent.episode,
                description: cpaCanadaContent.episode.description
                  .replace(/\\n/g, '  \n')
                  .replace(/&nbsp/g, ' '),
                glossary: cpaCanadaContent.episode.glossary
                  .split('\\n\\n')
                  .map((item: any) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [_summary, term, definition] =
                      /\**-(.*):\s*\**\s*(.*)/.exec(item) || [];
                    return { term, definition };
                  }),
              },
              chapter: {
                name: cpaCanadaContent.episode.hook,
                duration: cpaCanadaContent.episode.duration,
              },
            })
          : null;
      },
    }),
  }),
});

export const {
  useFetchFeaturedEpisodesQuery,
  useFetchTrendingEpisodesQuery,
  useFetchLibraryEpisodesQuery,
  useFetchCategoryEpisodesQuery,
  useFetchEpisodeQuery,
  useFetchEpisodesQuery,
  useFetchEpisodeSearchResultsQuery,
  useFetchRecommendedEpisodesQuery,
  useFetchRelatedEpisodesQuery,
  useFetchCPACanadaEpisodeQuery,
  useFetchNewEpisodesQuery,
  useFetchOrganizationPlaylistEpisodesQuery,
} = episodesApi;
