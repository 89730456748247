import { isAfter, isBefore } from 'date-fns';
import * as R from 'ramda';

export const validateCustomCPDHourRecord = values => {
  const errors = {};
  const decimalNumberRegex = /^(\d*\.)?\d+$/i;

  if (R.isNil(values.source) || values.source === '') {
    errors.source = 'Please enter the name of the course/seminar.';
  } else if (values.source.length > 255) {
    errors.source = 'Please enter a name that is below 255 characters.';
  }

  if (values.description?.length > 255) {
    errors.description =
      'Please enter a course description that is below 255 characters.';
  }

  if (R.isNil(values.credit) || values.credit === '') {
    errors.credit = 'Please enter the amount of hours completed.';
  } else if (!decimalNumberRegex.test(values.credit)) {
    errors.credit = 'Hours must be a number.';
  } else if (
    parseFloat(values.credit) <= 0 ||
    parseFloat(values.credit) >= 1000
  ) {
    errors.credit = 'Please enter a number above 0 and under 1000.';
  }

  if (R.isNil(values.dateCompleted)) {
    errors.dateCompleted = 'Please enter a valid date.';
  } else {
    if (isBefore(values.dateCompleted, new Date(2012, 8, 1))) {
      errors.dateCompleted = 'Date cannot be before 2012-09-01.';
    }

    const nextYear = new Date().getFullYear() + 1;
    if (isAfter(values.dateCompleted, new Date(nextYear, 11, 31))) {
      errors.dateCompleted = `Date cannot be after ${nextYear}-12-31.`;
    }
  }

  if (values.file?.size > 2 ** 20 * 10) {
    errors.file = 'The selected file is above 10 MB.';
  }

  return errors;
};
