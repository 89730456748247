import * as R from 'ramda';

import { Episode, EpisodeCard, Region } from '@/types';
import { camelCaseKeys } from '@/utils';

export const transformEpisodeCard = (episode: any): EpisodeCard =>
  R.pipe(camelCaseKeys, e => ({
    episodeId: e.episodeId,
    name: e.name,
    hook: e.hook,
    cover: e.cover,
    chapters: e.chapters,
    deepLink: e.deepLink,
    duration: e.duration,
    companyLogo: e.companyLogo,
    companyName: e.companyName,
    isPremium: e.isPremium,
    isRecordedLive: e.isRecordedLive,
    isVideo: e.isVideo,
    totalEthics: e.totalEthics,
    trailerId: e.trailerId,
    fieldOfStudyId: e.fieldOfStudyId,
    isArchived: e.isArchived,
    publishDate: e.publishDate,
    credits: e.credits,
  }))(episode);

export const transformEpisode = (episode: any): Episode =>
  R.pipe(camelCaseKeys, e => ({
    episodeId: e.episodeId,
    name: e.name,
    hook: e.hook,
    description: (e.formattedDescription ?? e.description)?.replace(
      /\\n/g,
      '  \n',
    ),
    marketingDescription: e.marketingDescription,
    shortDescription: e.shortDescription,
    speakerBio: (e.formattedSpeakerBio ?? e.speakerBio)?.replace(
      /\\n/g,
      '  \n',
    ),
    quote: e.quote,
    callToAction: e.callToAction,
    glossary: e.glossary,
    takeaways: e.takeaways,
    keywords: e.keywords,
    resources: e.resources,
    visualAid: e.visualAid,
    cover: e.cover,
    shareCover: e.shareCover,
    deepLink: e.deepLink,
    duration: e.duration,
    verifiableChapterCount: e.verifiableChapterCount,
    companyId: e.companyId,
    companyLogo: e.companyLogo,
    companyName: e.companyName,
    companyUrl: e.companyUrl,
    isPremium: e.isPremium,
    isEthics: e.isEthics,
    isRecordedLive: e.isRecordedLive,
    isVideo: e.isVideo,
    totalEthics: e.totalEthics,
    trailerId: e.trailerId,
    chapters: e.chapters,
    categories: e.categories,
    isTechnical: e.isTechnical,
    fieldOfStudyId: e.fieldOfStudyId,
    assessmentId: e.assessmentId,
    region: (e.region === 'usa' ? 'USA' : 'CAN') as Region,
    speakers: e.speakers,
    relatedEpisodes: e.relatedEpisodes,
    isArchived: e.isArchived,
    CPEDetails: {
      advancedPreparations: e.advancedPreparations,
      fieldOfStudy: e.fieldOfStudy,
      prerequisites: e.prerequisites,
      programLevel: e.programLevel,
      credits: e.credits,
      publishDate: e.publishDate,
    },
    publishDate: e.publishDate,
  }))(episode);
