import { submitAssessment, submitUserAssessment } from '@/requests';
import { AnalyticsService, EVENTS } from '@/services';

import { nodeApi } from '../node-api';
import { NodeApiTags } from '../store.constants';
import { handleOnQueryStartedError } from '../store.utils';

type SubmitUserAssessmentPayload = {
  episodeId: string;
  userAssessmentId: string;
  answers: number[];
  antiCheatingActive: boolean;
};

type SubmitUserAssessmentResponse = {
  pass: boolean;
  score: number;
};

export const userAssessmentsApi = nodeApi.injectEndpoints({
  endpoints: builder => ({
    submitUserAssessment: builder.mutation<
      SubmitUserAssessmentResponse,
      SubmitUserAssessmentPayload
    >({
      query: ({ antiCheatingActive, ...args }) =>
        antiCheatingActive
          ? submitUserAssessment(args)
          : submitAssessment({
              assessmentId: args.userAssessmentId,
              answers: args.answers.map(a => `${a}`),
            }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          AnalyticsService.logEvent(EVENTS.ASSESSMENT_EVENTS.SUBMIT.INTENT);
          await queryFulfilled;
          AnalyticsService.logEvent(EVENTS.ASSESSMENT_EVENTS.SUBMIT.SUCCESS);
        } catch (error: any) {
          AnalyticsService.logEvent(EVENTS.ASSESSMENT_EVENTS.SUBMIT.FAIL, {
            error: error?.message,
          });
          handleOnQueryStartedError(error);
        }
      },
      transformResponse: (response: any) => ({
        ...response.data,
        score: Math.round(response.data.score * 100) / 100,
      }),
      invalidatesTags: result =>
        result?.pass ? [NodeApiTags.USER_EPISODES] : [],
    }),
  }),
});

export const { useSubmitUserAssessmentMutation } = userAssessmentsApi;
