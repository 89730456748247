import { format } from 'date-fns';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

import { PlanTypes } from '@/constants';
import { selectPlan, selectUserRegion } from '@/store';
import { TimePlan, UsagePlan } from '@/types';

export const useAccessInfo = () => {
  const isNasba = useSelector(selectUserRegion) === 'USA';
  const { data: plan } = useSelector(selectPlan);

  if (!plan || plan.planType === PlanTypes.NONE) return null;

  const { validUntil = '', freeTrial } = plan as TimePlan;
  const { creditsRemaining = 0 } = plan as UsagePlan;

  const infoTitle = `${freeTrial ? 'Free Trial ' : ''}${isNasba ? 'Credits' : 'Time'} Remaining`;

  const formattedUsage = isNasba
    ? Math.round(creditsRemaining * 10) / 10
    : Math.round(creditsRemaining * 60);
  const usageUnits = `${isNasba ? 'credit' : 'minute'}${formattedUsage === 1 ? '' : 's'}`;

  return {
    showExpiry: !isNil(validUntil),
    showUsage: !isNil(creditsRemaining),
    showUpgradeBanner: freeTrial,
    infoTitle,
    usageRemaining: formattedUsage,
    usageUnits,
    expiryDate: format(new Date(validUntil), 'MMM d, yyyy'),
  };
};
