import { fetchUserQuizzes, submitQuiz } from '@/requests';
import { Region, UserQuiz } from '@/types';

import { nodeApi } from '../node-api';
import { quizzesApi } from '../quizzes-api';
import { selectSessionUserId } from '../session';
import { RootState } from '../store';
import { NodeApiTags } from '../store.constants';
import { rtkQueryError } from '../store.utils';

type SubmitQuizPayload = {
  region: Region;
  quizId: string;
  episodeId: string;
  answerId: string;
};

export const userQuizzesApi = nodeApi.injectEndpoints({
  endpoints: builder => ({
    fetchUserQuizzes: builder.query<UserQuiz[], null>({
      queryFn: async (args, redux, extraOptions, baseQuery) => {
        try {
          const userId = selectSessionUserId(redux.getState() as RootState);
          if (!userId) return { data: [] };
          const result: any = await baseQuery(fetchUserQuizzes());
          return { ...result, data: result.data.data };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
      providesTags: [NodeApiTags.USER_QUIZZES],
    }),
    submitQuizLegacy: builder.mutation<boolean, SubmitQuizPayload>({
      query: submitQuiz,
      transformResponse: (response: any) => response.data,
      invalidatesTags: (result, error, { episodeId }) =>
        result
          ? [
              NodeApiTags.USER_QUIZZES,
              NodeApiTags.USER_EPISODES,
              {
                type: NodeApiTags.USER_CHAPTER_PROGRESS,
                id: episodeId,
              },
            ]
          : [],
    }),
  }),
});

const userEpisodeQuizzesApi = userQuizzesApi.injectEndpoints({
  endpoints: builder => ({
    fetchEpisodePendingQuizzes: builder.query({
      queryFn: async ({ episodeId, region }, { dispatch }) => {
        try {
          const [quizzes, userQuizzes] = await Promise.all([
            dispatch(
              quizzesApi.endpoints.fetchEpisodeQuizzes.initiate(
                {
                  episodeId,
                  region,
                },
                {
                  subscribe: false,
                },
              ),
            ).unwrap(),
            dispatch(
              userQuizzesApi.endpoints.fetchUserQuizzes.initiate(null, {
                subscribe: false,
                forceRefetch: true,
              }),
            ).unwrap(),
          ]);

          const quizzesWithStatus = quizzes.map(quiz => {
            const userQuiz = userQuizzes?.find(
              userQuiz => userQuiz.quizId === quiz.quizId,
            );
            return {
              ...quiz,
              completed: userQuiz?.completed,
            };
          });
          return { data: quizzesWithStatus };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
      providesTags: [NodeApiTags.USER_QUIZZES],
    }),
  }),
});

export const { useFetchUserQuizzesQuery, useSubmitQuizLegacyMutation } =
  userQuizzesApi;
export const { useFetchEpisodePendingQuizzesQuery } = userEpisodeQuizzesApi;
