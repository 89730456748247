import { ALL_REGION, ALL_TOPIC } from '@/constants';
import {
  fetchTechnicalSearchOptionsRequest,
  fetchTechnicalSearchResultRequest,
} from '@/requests';
import { postgrestApi } from '@/store/postgrest-api';

import { technicalSearchEpisodeTransform } from './technical-search-api.utils';

export const technicalSearchApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchTechnicalSearchCategories: builder.query({
      query: () =>
        fetchTechnicalSearchOptionsRequest({ optionType: 'category' }),
      transformResponse: (res: any) => res.map((r: any) => r.value),
    }),
    fetchTechnicalSearchRegions: builder.query({
      query: () => fetchTechnicalSearchOptionsRequest({ optionType: 'region' }),
      transformResponse: (res: any) => [
        ALL_REGION,
        ...res.map((r: any) => r.value),
      ],
    }),
    fetchTechnicalSearchTopics: builder.query({
      query: fetchTechnicalSearchResultRequest,
      transformResponse: (res: any) => [
        ALL_TOPIC,
        ...(res.grouped_episode_tags?.find((gr: any) => gr.type === 'topic')
          ?.values ?? []),
      ],
    }),
    fetchTechnicalSearchEpisodes: builder.query({
      query: fetchTechnicalSearchResultRequest,
      transformResponse: (res: any) =>
        res.matching_episodes?.map(technicalSearchEpisodeTransform) ?? [],
    }),
  }),
});

export const {
  useFetchTechnicalSearchCategoriesQuery,
  useFetchTechnicalSearchRegionsQuery,
  useFetchTechnicalSearchTopicsQuery,
  useFetchTechnicalSearchEpisodesQuery,
} = technicalSearchApi;
