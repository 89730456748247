import { Box, Tab, Tabs } from '@mui/material';

export const PageTabs = ({ tabs, selectedTab, onChange }) => {
  return (
    <Box sx={[styles.container]}>
      <Tabs
        sx={{ height: 'auto', minHeight: 0 }}
        value={selectedTab}
        onChange={onChange}
        variant="scrollable"
        TabIndicatorProps={{ sx: styles.tabIndicator }}>
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            disableRipple
            sx={styles.tabRoot}
          />
        ))}
      </Tabs>
    </Box>
  );
};

const styles = {
  container: theme => ({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.blueBlackLight}`,
  }),
  tabIndicator: {
    height: 5,
    width: '100%',
    backgroundColor: 'blue',
  },
  tabRoot: theme => ({
    ...theme.typography.h5,
    fontWeight: 400,
    textTransform: 'none',
    alignItems: 'flex-start',
    p: 0,
    mr: 2,
    minWidth: 48,
    opacity: 1,
    '&.Mui-selected': {
      color: 'blue',
      opacity: 1,
    },
    '&.MuiTab-root': {
      py: 1,
      minHeight: 0,
    },
    '& span': {
      alignItems: 'flex-start',
      width: 'fit-content',
    },
  }),
};
