import { useMediaQuery } from '@mui/material';

import { Modals } from '@/constants';
import { OnboardingForm } from '@/containers/OnboardingForm';

import { AppModal } from '../AppModal';

export const OnboardingModal = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <AppModal
      modalName={Modals.ONBOARDING}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={isMobile}>
      <OnboardingForm />
    </AppModal>
  );
};
