import { ETHICS_FIELDS, EthicsField } from '@/constants';
import { Episode, Region } from '@/types';

import { RootState } from '../store';
import { EPISODES_SLICE_KEY } from './episodes.constants';
import { episodesAdapter } from './episodes.slice';

export const { selectById: selectEpisode, selectEntities: selectEpisodes } =
  episodesAdapter.getSelectors(
    (state: RootState) =>
      state[EPISODES_SLICE_KEY] ?? episodesAdapter.getInitialState(),
  );

export const getIsEpisodeEthics = ({
  episode,
  userRegion,
}: {
  episode: Episode;
  userRegion: Region;
}) =>
  userRegion === 'USA'
    ? ETHICS_FIELDS.includes(episode?.fieldOfStudyId as EthicsField)
    : (episode?.totalEthics ?? 0) > 0;
