import { CreditType, ProvinceCodes } from '@/constants';

import { MIN_CPD_YEAR } from './constants';

export const selectPDHourTotalsCanada = credits =>
  credits.reduce(
    (totals, credit) => ({
      total: totals.total + credit.credit,
      ethics: totals.ethics + credit.ethicsCredit,
      lumiq:
        credit.type === CreditType.LUMIQ
          ? totals.lumiq + credit.credit
          : totals.lumiq,
      lumiqEthics:
        credit.type === CreditType.LUMIQ
          ? totals.lumiqEthics + credit.ethicsCredit
          : totals.lumiqEthics,
      external:
        credit.type === CreditType.CUSTOM
          ? totals.external + credit.credit
          : totals.external,
      externalEthics:
        credit.type === CreditType.CUSTOM
          ? totals.externalEthics + credit.ethicsCredit
          : totals.externalEthics,
    }),
    {
      total: 0,
      ethics: 0,
      lumiq: 0,
      lumiqEthics: 0,
      external: 0,
      externalEthics: 0,
    },
  );

export const selectCPDTrackerCurrentDateRange = province => {
  const currentYear = new Date().getFullYear();
  const isCurrentMonthAfterAug = new Date().getMonth() > 7;
  if (province === ProvinceCodes.QC) {
    return {
      fromDate: `${isCurrentMonthAfterAug ? currentYear : currentYear - 1}-09-01`,
      toDate: `${isCurrentMonthAfterAug ? currentYear + 1 : currentYear}-08-31`,
    };
  }
  return {
    fromDate: `${currentYear}-01-01`,
    toDate: `${currentYear}-12-31`,
  };
};

export const selectCPDDateSelectorOptions = province => {
  const options = [];
  const currentYear = new Date().getFullYear();
  let year = currentYear + 1; // records are allowed to be created for next year too

  while (year > MIN_CPD_YEAR) {
    if (province === ProvinceCodes.QC) {
      options.push({
        fromDate: `${year}-09-01`,
        toDate: `${year + 1}-08-31`,
      });
    } else {
      options.push({
        fromDate: `${year}-01-01`,
        toDate: `${year}-12-31`,
      });
    }
    year -= 1;
  }

  return options;
};

export const formatCPDDateRangeLabel = ({ province, year }) => {
  if (province === ProvinceCodes.QC) {
    return `Sept. ${year} to Aug. ${year + 1}`;
  } else {
    return year;
  }
};

export const formatTriennialDateRangeLabel = ({ province, yearlySummary }) => {
  if (province === ProvinceCodes.QC) {
    return {
      fromDate: {
        label: `Sept. ${yearlySummary[0].year}`,
        value: `${yearlySummary[0].year}-09-01`,
      },
      toDate: {
        label: `Aug. ${yearlySummary[2].year + 1}`,
        value: `${yearlySummary[2].year + 1}-08-31`,
      },
    };
  }
  return {
    fromDate: {
      label: yearlySummary[0].year,
      value: `${yearlySummary[0].year}-01-01`,
    },
    toDate: {
      label: yearlySummary[2].year,
      value: `${yearlySummary[2].year}-12-31`,
    },
  };
};
