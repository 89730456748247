import { useSelector } from 'react-redux';

import { EpisodeCardCarousel } from '@/components';
import {
  selectPlan,
  selectSessionUserId,
  selectUserRegion,
  useFetchOrganizationPlaylistEpisodesQuery,
} from '@/store';

export const OrganizationPlaylistCarousel = () => {
  const userRegion = useSelector(selectUserRegion);
  const userId = useSelector(selectSessionUserId);
  const userPlan = useSelector(selectPlan);

  const { data: episodes } = useFetchOrganizationPlaylistEpisodesQuery(
    { region: userRegion },
    { skip: !userId },
  );

  return (
    <>
      {episodes?.length > 0 && (
        <EpisodeCardCarousel
          title={`Recommended By ${userPlan?.data?.organizationName}`}
          uKey="Recommended By"
          episodes={episodes}
          referrer={{ page: 'discover', category: 'organization playlist' }}
        />
      )}
    </>
  );
};
