export const FOOTER_HEIGHT = 96;
export const MODAL_HEIGHT = 600;

export const styles = {
  formContainer: {
    width: '100%',
    pb: `${FOOTER_HEIGHT}px`,
    px: { xs: 2, md: 6 },
    display: 'flex',
    flexDirection: 'column',
  },
  headerLogo: {
    height: 48,
    width: 48,
  },
  formPrimarySection: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    willChange: 'z-index',
    py: 1,
    zIndex: 1,
    '&::-webkit-scrollbar': {
      display: 'block',
      width: 8,
      height: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      borderRadius: 5,
    },
    msOverflowStyle: 'scrollbar',
    scrollbarWidth: 'thin',
  },
  checkboxContainer: {
    mb: 1.5,
  },
  disclaimerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    mb: 1.5,
    pb: 1.5,
  },
};
