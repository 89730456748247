const enableToggleTestMode = () => {
  window.toggleTestMode = () => {
    const testMode = window.localStorage.getItem('testMode');
    if (testMode) {
      window.localStorage.removeItem('testMode');
    } else {
      window.localStorage.setItem('testMode', 'true');
    }
    console.log('Test Mode On: ', !testMode);
  };
};

const enableToggleAnalyticsConsoleLogs = () => {
  window.toggleAnalyticsConsoleLogs = () => {
    const analyticsConsoleLogs = window.localStorage.getItem(
      'analyticsConsoleLogs',
    );
    if (analyticsConsoleLogs) {
      window.localStorage.removeItem('analyticsConsoleLogs');
    } else {
      window.localStorage.setItem('analyticsConsoleLogs', 'true');
    }
    console.log('Analytics Console Logs On: ', !analyticsConsoleLogs);
  };
};

const enableToggleFSMPlayer = () => {
  window.toggleFSMPlayer = () => {
    const FSMPlayer = window.localStorage.getItem('FSMPlayer');
    if (FSMPlayer) {
      window.localStorage.removeItem('FSMPlayer');
    } else {
      window.localStorage.setItem('FSMPlayer', 'true');
    }

    window.location.reload();
  };
};

const enableViewDevUtilsState = () => {
  window.viewDevUtilsState = () => {
    console.log({
      FSMPlayer: window.localStorage.getItem('FSMPlayer'),
      testMode: window.localStorage.getItem('testMode'),
      analyticsConsoleLogs: window.localStorage.getItem('analyticsConsoleLogs'),
    });
  };
};

export const enableDeveloperConsoleUtils = () => {
  enableToggleTestMode();
  enableToggleAnalyticsConsoleLogs();
  enableToggleFSMPlayer();
  enableViewDevUtilsState();
};
